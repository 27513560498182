import { type FC, type MouseEvent, memo, useCallback, type MouseEventHandler } from 'react';
import { Box, type BoxProps, Button, Typography } from '@mui/joy';

export interface ItemProps extends Omit<BoxProps, 'id' | 'onClick'> {
  id: string;
  label: string;
  selected?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>, id: string) => void;
}

const Item: FC<ItemProps> = (props) => {
  const { id, label, selected, onClick } = props;

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const itemId = event.currentTarget.getAttribute('itemId');
      if (!itemId) {
        return;
      }
      onClick?.(event, itemId);
    },
    [onClick],
  );

  return (
    <Button
      key={id}
      itemID={id}
      variant={!selected ? 'soft' : 'solid'}
      color="neutral"
      size="sm"
      sx={{
        fontWeight: 400,
      }}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default memo(Item);
