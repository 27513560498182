import { type FC, memo, type PropsWithChildren } from 'react';

import { Box, useTheme } from '@mui/joy';

import { usePlusContext } from '../context';

const Body: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const theme = useTheme();
  const context = usePlusContext();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      padding="1.5em"
      fontFamily={theme.fontFamily.body}
      borderRadius={context.variant === 'dropdown' ? '1em' : 0}
      bgcolor={context.variant === 'dropdown' ? 'var(--joy-palette-background-level1)' : 'transparent'}
      component="div"
    >
      {children}
    </Box>
  );
};

export default memo(Body);
