import { memo, useCallback, useMemo, forwardRef, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Box, type BoxProps } from '@mui/joy';

import TutorialStep from 'components/TutorialStep';
import NewSpacePromoTooltip from 'components/NewSpacePromoTooltip';

import { useGetShare } from 'hooks';
import { useNavigate } from 'navigation/hooks';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as selectors from '../../store/selectors';

import useItemFocusHandler from '../../model/useItemFocusHandler';
import { useFolderRoute } from '../../model/folderRoute';
import isEntityIdIsRoot from '../../model/isEntityIdIsRoot';
import { parseItemId } from '../../model/itemIdParser';
import useTreeDrop from '../../model/useTreeDrop';
import usePermissionsGetter from '../../model/usePermissionsGetter';

import useTree from './model/useTree';
import useContextMenu, { type UseContentMenuParamsActionContext, type UseContentMenuParams } from './model/useContextMenu';

import NewSpaceButton from './ui/NewSpaceButton';
import SpaceTreeView, { type SpaceTreeViewRef, type SpaceTreeViewProps } from '../../elements/SpaceTreeView';

interface MenuTreeProps extends BoxProps {}

const MenuTree = forwardRef<HTMLDivElement, MenuTreeProps>((props, forwardedRef) => {
  const dispatcher = useDispatcher();

  const { spaceId: currentSpaceId, folderId: currentFolderId } = useFolderRoute();
  const defaultSpaceId = useSelector(selectors.defaultSpaceId);
  const currentLibraryId = useSelector(selectors.libraryIdBySpaceId(currentSpaceId));
  const libraryIds = useSelector(selectors.libraryIds);
  const navigate = useNavigate();
  const share = useGetShare();

  const {
    spaceTreeViewRef,
    expandedItems,
    hasItemAdd,
    hasItemMenu,
    canItemDrag,
    canMoveItemToNewPosition,
    handleExpandedItemsChange,
    handleItemPositionChange,
    handleItemAdd,
  } = useTree();
  const permissionsGetter = usePermissionsGetter();

  const { handleDrop, handleDragOver } = useTreeDrop({
    onFilesDrop: (spaceId: number, folderId: string, files: FileList) => {
      dispatcher.plus.open({
        hasChangeFolder: false,
        parseFiles: {
          files: Array.from(files),
          spaceId,
          folderId,
          privacy: true,
        },
      });
    },
  });

  const { ContextMenu, handleContextMenu } = useContextMenu({
    onAction: useCallback<NonNullable<UseContentMenuParams['onAction']>>(
      async (event, context) => {
        if (context.name === 'Settings' && context.entityId === 'space') {
          dispatcher.modal.open('SpaceManageData', { mode: 'edit', spaceId: context.spaceId });
        }
        if (context.name === 'Settings' && context.entityId !== 'space') {
          dispatcher.modal.open('SpaceFolderSettings', { folderId: context.entityId });
        }
        if (context.name === 'Permissions' && context.entityId === 'space') {
          dispatcher.modal.open('SpacePermission', { spaceId: context.spaceId });
        }
        if (context.name === 'Delete' && context.entityId === 'space') {
          dispatcher.spaceList.delete({ id: context.spaceId });
        }
        if (context.name === 'Delete' && context.entityId !== 'space' && context.entityId !== 'root') {
          dispatcher.spaceResource.remove({ entityIds: [context.entityId] });
        }
        if (context.name === 'Rename' && context.entityId !== 'space') {
          spaceTreeViewRef.current?.getInteractionsBridge()?.getTreeItemInteractions(context.itemId)?.toggleItemEditing();
        }
        if (context.name === 'New') {
          handleItemAdd(event, context.itemId);
        }
        if (context.name === 'Share') {
          const { spaceId, entityId } = parseItemId(context.itemId);
          if (entityId === 'space' || libraryIds.includes(entityId)) {
            share({ type: 'Space', id: spaceId });
          } else {
            share({ type: 'Folder', id: entityId });
          }
        }
        if (context.name === 'TogglePrivate' && 'newValue' in context) {
          const { spaceId, entityId } = parseItemId(context.itemId);
          if (entityId === 'space') {
            dispatcher.spaceList.update({ id: spaceId, data: { isPrivate: context.newValue } });
          } else {
            dispatcher.spaceResource.updateFolder({ id: entityId, data: { isPrivate: context.newValue } });
          }
        }
      },
      [libraryIds, share],
    ),
    onOpenRequest: useCallback<NonNullable<UseContentMenuParams['onOpenRequest']>>(
      (event, context) => {
        const { addMenuItem } = context;
        const permissions = permissionsGetter.get(context.itemId);
        const isSpace = context.entityId === 'space';
        const isLibrary = libraryIds.includes(context.entityId);
        if (isLibrary) {
          return false;
        }
        addMenuItem('share');
        if (isSpace && permissions?.hasAction('INVITE')) {
          addMenuItem('permissions');
        }
        if (permissions?.hasAction('WRITE')) {
          addMenuItem('settings');
        }
        if (!isSpace && permissions?.hasAction('WRITE')) {
          addMenuItem('rename');
        }
        if (permissions?.hasAction('DELETE') && (!isSpace || (isSpace && context.spaceId !== defaultSpaceId))) {
          addMenuItem('delete');
        }
        if (permissions?.hasAction('CHANGE_PRIVACY')) {
          addMenuItem('private');
        }
        return !libraryIds.includes(context.entityId);
      },
      [defaultSpaceId, libraryIds, permissionsGetter],
    ),
    onOpen: useCallback<NonNullable<UseContentMenuParams['onOpen']>>(() => {
      dispatcher.spaceResource.cancelCreateFolderAll({ markerIdPattern: /^new-item-.*/ });
    }, []),
  });

  const handleItemLabelChangingSave = useCallback((itemId: string, newLabel: string) => {
    const { entityId } = parseItemId(itemId);
    if (/^new-item/.test(entityId)) {
      dispatcher.spaceResource.confirmCreateFolder({ markerId: entityId, title: newLabel || 'New folder' });
    } else {
      dispatcher.spaceResource.updateFolder({ id: entityId, data: { title: newLabel || 'Folder' } });
    }
  }, []);

  const handleItemLabelChangingCancel = useCallback((itemId: string) => {
    const { entityId } = parseItemId(itemId);
    if (/^new-item/.test(entityId)) {
      dispatcher.spaceResource.cancelCreateFolder({ markerId: entityId });
    }
  }, []);

  const { handler: selectHandler } = useItemFocusHandler(
    useCallback(
      (spaceId, folderId) => {
        if (/^new-item/.test(folderId) || folderId === 'space' || !folderId) {
          return;
        }
        if (folderId === 'space') {
          return;
        }
        const isRoot = isEntityIdIsRoot(spaceId, folderId);
        const nextFolderId = isRoot ? 'root' : folderId;
        if (currentSpaceId && currentFolderId && currentSpaceId === spaceId && currentFolderId === nextFolderId) {
          return;
        }
        navigate('Space', { spaceId, folderId: nextFolderId });
      },
      [navigate, currentSpaceId, currentFolderId],
    ),
  );

  const selectedItems = useMemo(
    () => `${currentSpaceId}::${currentFolderId?.replace('root', currentLibraryId || 'root')}`,
    [currentSpaceId, currentFolderId, currentLibraryId],
  );

  useEffect(() => {
    if (expandedItems === null && defaultSpaceId) {
      spaceTreeViewRef.current?.getApi()?.setItemExpansion?.({} as any, `${defaultSpaceId}::space`, true);
    }
  }, [expandedItems, defaultSpaceId]);

  return (
    <Box ref={forwardedRef} display="flex" flexDirection="column" alignItems="stretch" position="relative" {...props}>
      <SpaceTreeView
        ref={spaceTreeViewRef}
        enableReordering
        enableEditing
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        selectedItems={selectedItems}
        onSelectedItemsChange={selectHandler}
        defaultExpandedItems={expandedItems || undefined}
        onExpandedItemsChange={handleExpandedItemsChange}
        onItemPositionChange={handleItemPositionChange}
        hasItemAdd={hasItemAdd}
        hasItemMenu={hasItemMenu}
        canItemDrag={canItemDrag}
        canMoveItemToNewPosition={canMoveItemToNewPosition}
        onContextMenu={handleContextMenu}
        onItemLabelChangingSave={handleItemLabelChangingSave}
        onItemLabelChangingCancel={handleItemLabelChangingCancel}
        onMenuClick={handleContextMenu}
        onAddClick={handleItemAdd}
      />
      <TutorialStep
        stepIndex={2}
        title="Your Libray"
        description="is a default space to save your knowledge. Enjoy informative cards,
          enter any source and chat with it, create and share collections."
        placement="right"
        slotProps={{
          wrapper: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            maxWidth: 260,
            right: '0',
            top: '3.375rem',
          },
        }}
      >
        <Box width={0} height={0} />
      </TutorialStep>
      <NewSpacePromoTooltip>
        <NewSpaceButton />
      </NewSpacePromoTooltip>
      <ContextMenu />
    </Box>
  );
});

export default memo(MenuTree, isEqual);
