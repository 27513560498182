import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { Box, type BoxProps, Stepper, Step, StepIndicator } from '@mui/joy';

import { useRoute } from 'navigation/hooks';

import * as onboardingStore from 'widgets/Onboarding/store';

type IndicatorTopStepperProps = BoxProps;

const IndicatorTopStepper: FC<IndicatorTopStepperProps> = (props) => {
  const { ...rest } = props;

  const ignoreSteps = useSelector(onboardingStore.selectors.ignoreSteps);

  const route = useRoute();
  return (
    <Box {...rest}>
      <Stepper
        size="sm"
        orientation="horizontal"
        sx={{
          maxWidth: 46,
        }}
      >
        {!ignoreSteps.profile && (
          <Step>
            <StepIndicator
              variant={route.isScreen('Auth/Onboarding/Profile') ? 'solid' : 'soft'}
              sx={{
                borderRadius: '50%',
                width: '8px',
                height: '8px',
              }}
            />
          </Step>
        )}
        {!ignoreSteps.about && (
          <Step>
            <StepIndicator
              variant={route.isScreen('Auth/Onboarding/AboutYou') ? 'solid' : 'soft'}
              sx={{
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                width: '8px',
                height: '8px',
              }}
            />
          </Step>
        )}
        {!ignoreSteps.invite && (
          <Step>
            <StepIndicator
              variant={route.isScreen('Auth/Onboarding/InviteTeam') ? 'solid' : 'soft'}
              sx={{
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                width: '8px',
                height: '8px',
              }}
            />
          </Step>
        )}
        {!ignoreSteps.trial && (
          <Step>
            <StepIndicator
              variant={route.isScreen('Auth/Onboarding/TryFree') ? 'solid' : 'soft'}
              sx={{
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                width: '8px',
                height: '8px',
              }}
            />
          </Step>
        )}
      </Stepper>
    </Box>
  );
};

export default memo(IndicatorTopStepper, isEqual);
