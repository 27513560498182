import { takeEvery, put, cancel } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Profile'>());
  if (!route.isScreen('Profile')) {
    yield cancel();
    return;
  }

  const { login } = route.params;
  yield* call(() => track('Profile', { login }));
}
