import { type FC, memo } from 'react';
import { type DefaultRootState } from 'react-redux';
import { Box, Typography } from '@mui/joy';

import { useThemeColor } from 'hooks';
import { useSelector } from 'store/utils/redux/hooks';
import * as plusStore from 'widgets/Plus/store';

import useResultMany from '../model/useResultMany';

// import CollectionSelectInput from '../elements/CollectionSelectInput';
import Body from '../elements/Body';

// import { usePlusContext } from '../context';

export interface MaterialsLayoutProps {
  onCollectionChange?: (value: number | null) => void;
}

const MaterialsLayout: FC<MaterialsLayoutProps> = (props) => {
  const { onCollectionChange } = props;
  // const { hasNewCollectionButton } = usePlusContext();
  const color = useThemeColor({ light: '#000000', dark: '#FFFFFF' });

  const result = useResultMany();
  const { isOpen, props: stateProps } = useSelector(plusStore.selectors.state);
  // const { toCollection, hasChangeFolder } = stateProps || {};
  const upload = useSelector((s: DefaultRootState) => s.plus.request.upload);

  // const [collectionId, setCollectionId] = useState<number | null>((toCollection as { id?: number })?.id || null);

  // const handleCollectionChange = useCallback(
  //   (value: number | null) => {
  //     setCollectionId(value);
  //     onCollectionChange?.(value);
  //   },
  //   [onCollectionChange],
  // );

  // useEffect(() => {
  //   if (!collectionId || !data?.uploadSessionId || isOpen) {
  //     return;
  //   }
  //   dispatcher.plus.addMaterialsToCollection(Number(collectionId), data?.uploadSessionId);
  // }, [isOpen, collectionId, data?.uploadSessionId]);

  const isFiles = upload && upload?.count > 0;
  const isLinks = !isFiles;

  return (
    <Body>
      <Box mb={1.5}>
        <Typography fontSize={12} color="neutral">
          Success
        </Typography>
        {isLinks && (
          <Typography fontSize={16} textColor={color}>
            {`We've successfully parsed ${result?.parsedLinksCount ?? 'provided'} links`} {/* , would you like to add them to collection? */}
          </Typography>
        )}
        {isFiles && (
          <Typography fontSize={16} textColor={color}>
            {`We've successfully uploaded ${result?.parsedLinksCount ?? 'provided'} files`} {/* , would you like to add them to collection? */}
          </Typography>
        )}
      </Box>
      {/* {hasChangeFolder && ( */}
      {/*  <Box> */}
      {/*    <Typography fontSize={12} color="neutral"> */}
      {/*      Add to collection */}
      {/*    </Typography> */}
      {/*    <CollectionSelectInput hasNewCollectionButton={hasNewCollectionButton} value={collectionId} onChange={handleCollectionChange} /> */}
      {/*  </Box> */}
      {/* )} */}
    </Body>
  );
};

export default memo(MaterialsLayout);
