import { type ForwardedRef, type ReactElement, type ReactNode, memo, forwardRef, useMemo, useCallback, useEffect, useRef } from 'react';
import { type GestureResponderEvent, type NativeScrollEvent, type NativeSyntheticEvent, Platform, ScrollView, StyleSheet } from 'react-native';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { unit } from 'utils';
import { View } from 'components/Themed';

import useScrollMethods from './utils/useScrollViewMethods';
import usePrepareData from './utils/usePrepareData';
import useSizer from './utils/useSizer';
import type { FeedListMethods, FeedListProps } from './types';

const FeedList = forwardRef((props: FeedListProps, forwardedRef: ForwardedRef<FeedListMethods> | undefined): ReactElement | null => {
  const {
    data,
    columnsScheme,
    scrollable = false,
    paddingHorizontal = unit(0),
    columnGap = 0,
    rowGap = 0,
    HeaderComponent,
    FooterComponent,
    ItemComponent,
    RefreshComponent,
    hasNextPage = false,
    isInverted = false,
    isRefreshing = false,
    isLoading = false,
    onRefresh,
    onEndReached,
    onResize,
    onScroll,
    style,
  } = props;

  const scrollValue = useRef<number>(0);

  const { scrollableElementRef } = useScrollMethods(forwardedRef);
  const { handleContainerLayout, handleContentLayout } = useSizer(onResize);
  const { rows, breakpoint } = usePrepareData(data, columnsScheme);

  const handleLoadMore = useCallback(() => {
    onEndReached?.({
      distanceFromEnd: 0,
    });
  }, [onEndReached]);

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: handleLoadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 200px 0px',
  });

  const scrollViewRefSetter = useCallback(
    (element: ScrollView | Document | any) => {
      scrollableElementRef(element);
    },
    [scrollableElementRef],
  );

  const handleScrollViewScroll = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { contentOffset, contentSize, layoutMeasurement } = nativeEvent;
      scrollValue.current = contentOffset.y * -1;
      onScroll?.({
        position: contentOffset.y / (contentSize.width - layoutMeasurement.height),
        top: contentOffset.y * -1,
        width: layoutMeasurement.width,
        height: layoutMeasurement.height,
      });
    },
    [onScroll],
  );

  const handleWindowScroll = useCallback(() => {
    const top = document.documentElement.scrollTop - (document.documentElement.clientTop || 0);
    const position = top / (document.documentElement.scrollHeight - document.body.clientHeight);
    onScroll?.({
      position,
      width: document.body.clientWidth,
      height: document.body.clientHeight,
      top: top * -1,
    });
  }, []);

  const handleTouch = useCallback((event: GestureResponderEvent) => {
    if (scrollValue.current !== 0) {
      event.stopPropagation();
    }
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web' && !scrollable) {
      scrollViewRefSetter(document);
      window.addEventListener('scroll', handleWindowScroll);
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('scroll', handleWindowScroll);
      }
    };
  }, [scrollable]);

  const renderBody = useMemo(
    () => (
      <View onLayout={handleContentLayout}>
        {!!HeaderComponent && (HeaderComponent as unknown as ReactNode)}
        <View style={styles.rows}>
          {Array.isArray(rows) &&
            rows.map((row, rowKey) => (
              <View
                key={`row-${rowKey}`}
                style={[
                  styles.rowWrapper,
                  styles[`rowWrapper${breakpoint.toUpperCase()}` as 'rowWrapperSX' | 'rowWrapperSM' | 'rowWrapperMD' | 'rowWrapperLG'],
                ]}
              >
                <View
                  style={[
                    styles.row,
                    {
                      marginTop: unit(rowGap / 2),
                      marginBottom: unit(rowGap / 2),
                      paddingLeft: paddingHorizontal,
                      paddingRight: paddingHorizontal,
                    },
                    rowKey === 0 ? styles.rowFirst : undefined,
                    rowKey === rows.length - 1 ? styles.rowLast : undefined,
                  ]}
                >
                  {row.map((item, itemKey) => (
                    <View
                      key={`item-${item?.id || `fill-${rowKey + itemKey}`}`}
                      style={[
                        styles.item,
                        {
                          marginLeft: unit(columnGap / 2),
                          marginRight: unit(columnGap / 2),
                        },
                        itemKey === 0 ? styles.itemFirst : undefined,
                        itemKey === row.length - 1 ? styles.itemLast : undefined,
                        isInverted ? styles.scrollInverted : undefined,
                      ]}
                    >
                      {item?.type !== 'fill' && <ItemComponent id={item?.id} type={item?.type} item={item} index={rowKey + itemKey} />}
                    </View>
                  ))}
                </View>
              </View>
            ))}
        </View>
        <View ref={infiniteRef as any}>{RefreshComponent as any}</View>
        {!!FooterComponent && (FooterComponent as any)}
      </View>
    ),
    [handleContentLayout, HeaderComponent, RefreshComponent, isInverted, rows, rowGap, columnGap, paddingHorizontal, breakpoint],
  );

  return (
    <View style={[styles.FeedList, styles.FeedListScrollable, style]}>
      <View onLayout={handleContainerLayout} style={styles.contentSizer} />
      {!scrollable && renderBody}
      {scrollable && (
        <ScrollView
          ref={scrollViewRefSetter}
          onScroll={handleScrollViewScroll}
          scrollEventThrottle={16}
          onTouchStart={handleTouch}
          onTouchMove={handleTouch}
          style={[styles.scrollContainer, isInverted ? styles.scrollInverted : undefined]}
        >
          {renderBody}
        </ScrollView>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  FeedList: {
    position: 'relative',
    alignItems: 'stretch',
  },
  FeedListScrollable: {
    flex: 1,
  },
  contentSizer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  scrollContainerView: {},
  scrollInverted: {
    transform: [{ scaleY: -1 }],
  },
  rows: {
    alignItems: 'center',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  rowWrapperSX: {},
  rowWrapperSM: {
    maxWidth: unit(425),
  },
  rowWrapperMD: {
    maxWidth: unit(768),
  },
  rowWrapperLG: {
    maxWidth: unit(1280),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  rowFirst: {
    marginTop: 0,
  },
  rowLast: {
    marginBottom: 0,
  },
  item: {
    flex: 1,
  },
  itemFirst: {
    marginLeft: 0,
  },
  itemLast: {
    marginRight: 0,
  },
});

export default memo(FeedList);
