import { memo } from 'react';
import { Box, CircularProgress, useColorScheme } from '@mui/material';

const Progress = () => {
  const { mode } = useColorScheme();
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      height="calc(100vh - 160px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ pointerEvents: 'none' }}
    >
      <CircularProgress sx={{ color: mode === 'light' ? 'var(--joy-palette-primary-500)' : 'var(--joy-palette-neutral-100)' }} />
    </Box>
  );
};

export default memo(Progress);
