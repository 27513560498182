import { memo, useCallback } from 'react';
import { Box, Button, Divider, FormControl, FormLabel, Stack, Typography } from '@mui/joy';
import { DateTime } from 'luxon';

import { ui } from 'utils';
import { usePerson } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as selectors from 'widgets/Subscription/store/selectors';
import SubscriptionPricing from 'widgets/Subscription/Pricing';

const PlansLayout = () => {
  const dispatcher = useDispatcher();
  const currentPlan = useSelector(selectors.currentPlan);
  const { person } = usePerson('User', 'my');

  const handleCancel = useCallback(async () => {
    const isConfigured = await ui.confirm(
      <Box display="flex" flexDirection="column" maxWidth={500}>
        <Typography mt={0} fontWeight={400} textColor="text.secondary">
          Your subscription will be canceled, but you can continue to use the plan until the end of the billing period, which is until
          {currentPlan.expirationDate
            ? ` ${DateTime.fromISO(currentPlan.expirationDate).toLocaleString(DateTime.DATE_FULL, { locale: 'en' })}.`
            : '.'}
        </Typography>
        <Typography mt={2}>If you change your mind, you can renew your subscription.</Typography>
      </Box>,
      {
        title: <Typography fontSize={18}>{`Current plan: ${currentPlan.name}`}</Typography>,
        cancelLabel: 'Close',
        confirmLabel: 'Cancel plan',
      },
    );
    if (!isConfigured) {
      return;
    }
    dispatcher.subscription.cancel();
  }, [currentPlan.name, currentPlan.expirationDate]);

  const handleOpenPortal = useCallback(() => {
    dispatcher.subscription.openBillingPortal({ openMode: 'blank' });
  }, []);

  return (
    <Box
      width="calc(100% - 11rem - 3.5rem)"
      sx={{
        '@media (max-width: 767px)': {
          width: '100%',
        },
      }}
    >
      {!person?.isAppsumo && currentPlan.id !== '0' && (
        <Stack direction="column" spacing={2} flex={1} mt={2}>
          <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{ fontSize: 13 }}
            >{`Current plan: ${currentPlan.name}, ${currentPlan.period === 'monthly' ? 'monthly' : 'yearly'}`}</Typography>
            <SubscriptionPricing
              layout="modal"
              modalOpenerTextProperty="text"
              modalOpenerClickProperty="onClick"
              modalOpener={
                <Button size="sm" sx={{ fontSize: 13 }}>
                  Change Plan
                </Button>
              }
            />
          </FormControl>
          <Divider />
          <Box>
            <Typography
              onClick={handleOpenPortal}
              sx={{
                fontSize: 13,
                cursor: 'pointer',
                '&:hover': {
                  fontWeight: 500,
                },
              }}
            >
              View Invoices
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Typography
              onClick={handleCancel}
              sx={{
                fontSize: 13,
                cursor: 'pointer',
                '&:hover': {
                  fontWeight: 500,
                },
              }}
            >
              Cancel Plan
            </Typography>
          </Box>
        </Stack>
      )}
      {!person?.isAppsumo && currentPlan.id === '0' && (
        <Box display="flex" flexDirection="column" flex={1} alignItems="center" justifyContent="center" mt={15} gap={2}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography fontSize={13}>No active subscription</Typography>
            <Typography fontSize={13}>Upgrade your plan.</Typography>
          </Box>
          <SubscriptionPricing
            layout="modal"
            modalOpenerTextProperty="text"
            modalOpenerClickProperty="onClick"
            modalOpener={<Button sx={{ fontSize: 13 }}>Upgrade plan</Button>}
          />
        </Box>
      )}
      {person?.isAppsumo && (
        <Stack direction="column" spacing={2.5} width="100%" flex={1} mt={2}>
          <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between' }}>
            <FormLabel sx={{ fontSize: 16 }}>{`Current plan: ${currentPlan.name}`}</FormLabel>
            <SubscriptionPricing
              layout="modal"
              modalOpenerTextProperty="text"
              modalOpenerClickProperty="onClick"
              modalOpener={<Button size="md">View Details</Button>}
            />
          </FormControl>
        </Stack>
      )}
    </Box>
  );
};

export default memo(PlansLayout);
