import { memo } from 'react';
import { CardActions } from '@mui/joy';

import Actions from './Actions';

const Footer = () => {
  return (
    <CardActions
      sx={{
        minHeight: 32,
        '--Card-padding': 0,
        justifyContent: 'space-between',
        '@container (width < 400px )': {
          flexDirection: 'column',
          gap: 1.5,
        },
        '@container (width > 400px )': {
          flexDirection: 'row',
        },
      }}
    >
      <Actions />
    </CardActions>
  );
};

export default memo(Footer);
