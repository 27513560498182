import type { SagaReturnType } from 'redux-saga/effects';
import * as actions from 'store/nodes/search/actions';

const getDelayMs = (action: SagaReturnType<typeof actions.setFilterText> | any, defaultValue: number) => {
  let result = defaultValue;
  if (action.type === actions.setFilterText.type && action.payload.immediateUpdate) {
    result = 0;
  }
  return result;
};

export default getDelayMs;
