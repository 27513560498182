import { type FC, type MouseEvent, memo, useCallback, type MouseEventHandler } from 'react';
import { Box, type BoxProps, Typography } from '@mui/joy';
import Icon from 'ui/Icon';

import useIcon from '../model/useIcon';

export interface ItemProps extends Omit<BoxProps, 'id' | 'onClick'> {
  id: string;
  label: string;
  selected?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>, id: string) => void;
}

const Item: FC<ItemProps> = (props) => {
  const { id, label, selected, onClick } = props;
  const icon = useIcon(label);

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const itemId = event.currentTarget.getAttribute('itemId');
      if (!itemId) {
        return;
      }
      onClick?.(event, itemId);
    },
    [onClick],
  );

  return (
    <Box
      key={id}
      itemID={id}
      display="flex"
      alignItems="center"
      gap={1.5}
      sx={{
        padding: '4px 8px',
        borderRadius: 6,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
          backgroundColor: 'var(--joy-palette-neutral-softBg)',
        },
      }}
      onClick={handleClick}
    >
      <Icon name={icon} weight={selected ? 'solid' : 'light'} size="sm" fw sx={{ opacity: selected ? 1 : 0.75 }} />
      <Typography fontSize={14} sx={{ opacity: selected ? 1 : 0.75 }}>
        {label}
      </Typography>
    </Box>
  );
};

export default memo(Item);
