import createCachedSelector from 're-reselect';
import { type DefaultRootState } from 'react-redux';

const getThinkingItems = (state: DefaultRootState, requestId: string) => state.copilot.thinkMap[requestId];

const getCombined = createCachedSelector(
  getThinkingItems,
  (_: any, requestId) => requestId,
  (thinkingItems, requestId) => {
    return thinkingItems || [];
  },
)({
  keySelector: (_, requestId) => requestId,
});

export default (requestId: string) => (state: DefaultRootState) => getCombined(state, requestId);
