import { md5 } from 'js-md5';
import { cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';

import { FilterItemType } from 'app/entities';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import network from 'lib/network';

import { actions as resourceActions } from '../slices/resource';

export const config = {
  action: resourceActions.loadAvailableFilters.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof resourceActions.loadAvailableFilters>) {
  const { data, hasError, errors } = yield* call(() => network.request<Omit<FilterItemType, 'id'>[]>(`/stack-2/user/team-space/filters`).get());

  if (hasError || !data) {
    Alert.error(errors);
    yield put(resourceActions.setAvailableFilters({ data: [] }));
    yield put(resourceActions.loadAvailableFiltersDone());
    yield cancel();
    return;
  }

  yield put(
    resourceActions.setAvailableFilters({
      data: data.map((item) => ({
        id: md5(item.label),
        ...item,
      })) as FilterItemType[],
    }),
  );
  yield put(resourceActions.loadAvailableFiltersDone());
}
