import { memo, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import hljs from 'highlight.js';
import { isEqual } from 'lodash';
import { EditorCommand, EditorCommandEmpty, EditorCommandItem, EditorCommandList, EditorContent, type EditorInstance, EditorRoot } from 'novel';
// @ts-ignore
import { ImageResizer, handleCommandNavigation, MarkdownExtension } from 'novel/extensions';
// @ts-ignore
import { handleImageDrop, handleImagePaste } from 'novel/plugins';
import { useDebouncedCallback } from 'use-debounce';

import { generateJSON, type EditorEvents } from '@tiptap/core';
import { Box, Typography, useColorScheme } from '@mui/joy';

import ColorSelector from './components/Selectors/colorSelector';
import LinkSelector from './components/Selectors/linkSelector';
import NodeSelector from './components/Selectors/nodeSelector';
import MathSelector from './components/Selectors/mathSelector';
import TextButtons from './components/Selectors/textButtons';

import GenerativeMenuSwitch from './components/Generative/generativeMenuSwitch';
import { Separator } from './ui/separator';
import { defaultExtensions } from './lib/extensions';
import { uploadFn } from './lib/imageUpload';

import { slashCommand, suggestionItems } from './components/SlashCommand';
import ContentCommand, { type ContentCommandMethods } from './components/ContentCommand';

import './css/noveltailwindstyle.css';
import './css/prosemirror.css';

import EditorStatusPortal from './ui/EditorStatusPortal';

export type { ContentCommandMethods };

export interface NovelEditorProps {
  onFocus?: (props: EditorEvents['focus']) => void;
  onBlur?: (props: EditorEvents['blur']) => void;
  onHtmlChanged?: (html: string) => void;
  initHtml: string;
}

const extensions = [...defaultExtensions, slashCommand, MarkdownExtension];

const NovelEditor = forwardRef<ContentCommandMethods, NovelEditorProps>((props, forwardedRef) => {
  const { onFocus, onBlur, onHtmlChanged, initHtml } = props;
  const { mode } = useColorScheme();
  const [saveStatus, setSaveStatus] = useState('Saved');
  const [charsCount, setCharsCount] = useState<number | undefined>(undefined);

  const [openNode, setOpenNode] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openAI, setOpenAI] = useState(false);

  const contentCommandRef = useRef<ContentCommandMethods | null>(null);

  useImperativeHandle(forwardedRef, () => ({
    insert: ((...args) => contentCommandRef?.current?.insert(...args)) as ContentCommandMethods['insert'],
    focus: ((...args) => contentCommandRef?.current?.focus(...args)) as ContentCommandMethods['focus'],
  }));

  const highlightCodeblocks = (content: string) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    doc.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightElement(el as HTMLElement);
    });
    return new XMLSerializer().serializeToString(doc);
  };

  const debouncedUpdates = useDebouncedCallback(async (editor: EditorInstance) => {
    if (editor.isEmpty) {
      return;
    }
    setCharsCount(editor.storage.characterCount.words());
    onHtmlChanged?.(highlightCodeblocks(editor.getHTML()));
    setSaveStatus('Saved');
  }, 500);

  useEffect(() => {
    const root = document.documentElement;
    if (mode === 'dark') {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [mode]);

  return (
    <div className="flex min-h-screen-80 flex-col items-center">
      <EditorStatusPortal portalId="editor-status-portal">
        <Box display="flex" gap={1.5} alignItems="center">
          <Typography fontSize={12} mb={0.125} color="neutral">
            {saveStatus}
          </Typography>
          {charsCount != null && (
            <Typography fontSize={12} mb={0.125} color="neutral">
              {charsCount} Words
            </Typography>
          )}
        </Box>
      </EditorStatusPortal>
      <div className="relative w-full">
        <EditorRoot>
          <EditorContent
            onFocus={onFocus}
            onBlur={onBlur}
            initialContent={generateJSON(initHtml, extensions)}
            extensions={extensions}
            className="relative min-h-[500px] w-full border-muted sm:mb-[calc(20vh)] sm:rounded-lg"
            editorProps={{
              handleDOMEvents: {
                keydown: (_view, event) => handleCommandNavigation(event),
              },
              handlePaste: (view, event) => handleImagePaste(view, event, uploadFn),
              handleDrop: (view, event, _slice, moved) => handleImageDrop(view, event, moved, uploadFn),
              attributes: {
                class: 'prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full',
              },
            }}
            onUpdate={({ editor }) => {
              debouncedUpdates(editor);
              setSaveStatus('Unsaved');
            }}
            slotAfter={<ImageResizer />}
          >
            {/* eslint-disable-next-line max-len */}
            <EditorCommand className="z-50 h-auto max-h-[330px] overflow-y-auto rounded-md border border-muted bg-background px-1 py-2 shadow-md transition-all">
              <EditorCommandEmpty className="px-2 text-muted-foreground">No results</EditorCommandEmpty>
              <EditorCommandList>
                {suggestionItems.map((item) => (
                  <EditorCommandItem
                    value={item.title}
                    onCommand={(val) => item.command(val)}
                    className="flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm hover:bg-accent aria-selected:bg-accent"
                    key={item.title}
                  >
                    <div className="flex h-10 w-10 items-center justify-center rounded-md border border-muted bg-background">{item.icon}</div>
                    <div>
                      <p className="font-medium">{item.title}</p>
                      <p className="text-xs text-muted-foreground">{item.description}</p>
                    </div>
                  </EditorCommandItem>
                ))}
              </EditorCommandList>
            </EditorCommand>
            <GenerativeMenuSwitch open={openAI} onOpenChange={setOpenAI}>
              <Separator orientation="vertical" />
              <NodeSelector open={openNode} onOpenChange={setOpenNode} />
              <Separator orientation="vertical" />
              <LinkSelector open={openLink} onOpenChange={setOpenLink} />
              <Separator orientation="vertical" />
              <MathSelector />
              <Separator orientation="vertical" />
              <TextButtons />
              <Separator orientation="vertical" />
              <ColorSelector open={openColor} onOpenChange={setOpenColor} />
            </GenerativeMenuSwitch>
            <ContentCommand ref={contentCommandRef} />
          </EditorContent>
        </EditorRoot>
      </div>
    </div>
  );
});

export default memo(NovelEditor, isEqual);
