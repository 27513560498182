import { type SagaReturnType, put, cancel, delay, takeEvery } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import * as searchStore from 'store/nodes/search';

import * as actions from '../actions';

import getDoNavigate from '../model/getDoNavigate';
import getDelayMs from '../model/getDelayMs';

export const config = {
  action: [actions.triggerSearch.type, actions.setFilterText.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.triggerSearch | typeof actions.setFilterText>) {
  const { spaceId, triggerReload } = action.payload;
  const isListConsistent = yield* select(searchStore.selectors.isListConsistent);

  if (!triggerReload || isListConsistent) {
    yield cancel();
    return;
  }

  const text = yield* select(searchStore.selectors.text);

  const doNavigate = yield* call(() => getDoNavigate(spaceId, text));

  const delayMs = yield* call(() => getDelayMs(action, 6000));
  yield delay(delayMs);

  yield call(doNavigate);
  yield put(actions.loadPage({ spaceId }));
}
