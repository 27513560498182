import React, { useMemo } from 'react';
import type { MaterialType } from 'app/entities';
import Copilot from 'widgets/Copilot';
import Notes from 'widgets/Notes';
import RelatedMaterials from 'widgets/RelatedMaterials';

import { guard } from 'utils';

const usePanelsRender = (data: MaterialType | unknown) =>
  useMemo(() => {
    if (!guard.isMaterial(data)) {
      return false;
    }
    return [
      ...(data?.id
        ? [
            {
              label: 'AI Copilot',
              value: 'copilot',
              component: <Copilot variant="material" resourceId={data.id} />,
            },
            {
              label: 'Remarks',
              value: 'notes',
              component: <Notes resourceType="material" resourceId={data.id} mixHighlight />,
            },
          ]
        : []),
      ...(data?.relatedIds
        ? [
            {
              label: 'Related',
              value: 'related',
              component: <RelatedMaterials resourceIds={data.relatedIds} />,
            },
          ]
        : []),
    ];
  }, [(data as MaterialType)?.id, (data as MaterialType)?.__typename, (data as MaterialType)?.relatedIds]);

export default usePanelsRender;
