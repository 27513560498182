import { type MouseEventHandler, type MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Popover from '@radix-ui/react-popover';

import { useDispatcher } from 'store/utils/redux/hooks';

import * as spaceStore from '../../../../../store';

function useFilter() {
  const dispatcher = useDispatcher();

  const allAvailable = useSelector(spaceStore.selectors.availableFilters);
  const allSelected = useSelector(spaceStore.selectors.selectedFilter);

  const allAvailableTypeMap = useMemo(() => {
    const result: Record<string, string> = {};
    allAvailable.forEach((item) => {
      result[item.id] = item.type;
    });
    return result;
  }, [allAvailable]);

  const available = useMemo(() => allAvailable.filter((item) => item.type !== 'TAG'), [allAvailable]);
  const selected = useMemo(() => allSelected.filter((item) => allAvailableTypeMap[item] !== 'TAG'), [allSelected]);
  const enabled = selected.length > 0;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, itemId: string) => {
      if (selected.includes(itemId)) {
        dispatcher.spaceResource.disableFilter({ id: itemId });
      } else {
        dispatcher.spaceResource.enableFilter({ id: itemId });
      }
    },
    [selected],
  );

  const handleReset = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    dispatcher.spaceResource.resetFilter();
  }, []);

  const handleOpenChange = useCallback<NonNullable<Popover.PopoverProps['onOpenChange']>>((openState) => {
    if (!openState) {
      dispatcher.spaceResource.applyFilterImmediately();
    }
  }, []);

  return useMemo(
    () => ({
      available,
      selected,
      enabled,
      handleClick,
      handleReset,
      handleOpenChange,
    }),
    [available, selected, enabled, handleClick, handleReset, handleOpenChange],
  );
}

export default useFilter;
