import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/joy';

import { useRoute } from 'navigation/hooks';
import * as contentStore from 'store/nodes/content';

import Icon from 'ui/Icon';
import useIsIndexing from 'widgets/Card/model/useIsIndexing';

const IndexingStatus = () => {
  const route = useRoute<'Content'>();
  const { resourceId } = route.params;

  const data = useSelector(contentStore.selectors.dataById(resourceId));
  const { isIndexed } = useIsIndexing(data);

  if (!data || isIndexed) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={0.5}
      sx={{
        pointerEvents: 'none',
        transition: 'opacity 200ms ease',
      }}
    >
      <Icon name="arrows-rotate" animate="spin" size="sm" fw />
      <Typography fontSize={12} mb={0.125} color="neutral">
        Indexing
      </Typography>
    </Box>
  );
};

export default memo(IndexingStatus);
