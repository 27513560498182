import { type FC, memo } from 'react';
import { AspectRatio, Box, Typography, type BoxProps } from '@mui/joy';

import { guard } from 'utils';

import type { MaterialType, ContentErrorType } from 'app/entities';

type SourceInfoProps = BoxProps & {
  data?: MaterialType | ContentErrorType | null;
};

const SourceInfo: FC<SourceInfoProps> = (props) => {
  const { data, ...rest } = props;

  if (guard.isContentError(data)) {
    return null;
  }

  const isPDF = /\.pdf(\??.*)/i.test(data?.originalUrl || '');

  if (!data?.source) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...rest}>
      {data?.source?.image?.url && (
        <AspectRatio
          objectFit="cover"
          minHeight={32}
          maxHeight={32}
          sx={{
            minWidth: 32,
            '--AspectRatio-radius': (theme) => theme.radius.sm,
          }}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={data?.source?.image?.url}
            alt="Logo"
          />
        </AspectRatio>
      )}
      <Box ml={data?.source?.image?.url ? 0.5 : 0}>
        <Typography
          fontSize={12}
          fontWeight={400}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
          }}
        >
          {data?.source?.name}
        </Typography>
        {!isPDF && (
          <Typography
            fontSize={12}
            fontWeight={400}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
            }}
          >
            {data?.originalUrl}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(SourceInfo);
