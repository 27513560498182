import { type MutableRefObject, useLayoutEffect, useMemo, useRef, useState } from 'react';

export interface UseLayoutWidthOptions {
  /**
   * Если задано, то ширина будет обновлена только спустя этот интервал
   * (в мс) после последнего события изменения размеров.
   * Если не задано – изменения применяются сразу.
   */
  batchDelay?: number;
  detect?: 'auto' | 'manual';
}

/**
 * Хук возвращает { width } – актуальную ширину контейнера,
 * используя ResizeObserver, если он доступен в браузере.
 *
 * Если указать `batchDelay` (в мс), обновление ширины будет отложено
 * до момента, когда поток событий "замолчит" на заданное время.
 * Это позволяет уменьшить частоту перерисовок при интенсивном ресайзе.
 */
const useLayoutWidth = (elementRef: MutableRefObject<HTMLElement | null>, options?: UseLayoutWidthOptions) => {
  const [width, setWidth] = useState<number | null>(null);

  const { batchDelay, detect = 'auto' } = options || {};

  // Для отслеживания "последнего зафиксированного значения", которое
  // применим при истечении батча
  const lastMeasuredWidthRef = useRef<number | null>(null);

  // Таймер, который сбрасываем при каждом новом событии ресайза,
  // если используем режим "batching"
  const resizeTimerRef = useRef<any | null>(null);

  useLayoutEffect(() => {
    const el = elementRef.current;
    if (!el) {
      return undefined;
    }

    // Функция, которая устанавливает новое значение width,
    // либо сразу, либо с учётом задержки batchDelay
    const scheduleWidthUpdate = (newWidth: number) => {
      // Запоминаем последнее полученное значение
      lastMeasuredWidthRef.current = newWidth;

      // Если batchDelay не задан, обновляем сразу
      if (!batchDelay || batchDelay <= 0) {
        setWidth((prev) => (prev !== newWidth ? newWidth : prev));
        return;
      }

      // Если batchDelay > 0, тогда пользуемся дебаунсом
      // Сбрасываем предыдущий таймер (если был)
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }

      // Запускаем новый таймер
      resizeTimerRef.current = setTimeout(() => {
        // Применяем последнее зафиксированное значение
        const finalWidth = lastMeasuredWidthRef.current;
        if (finalWidth !== null) {
          setWidth((prev) => (prev !== finalWidth ? finalWidth : prev));
        }
        // Сбрасываем ссылку на таймер
        resizeTimerRef.current = null;
      }, batchDelay);
    };

    // Инициализируем width один раз при монтировании
    const initialWidth = el.getBoundingClientRect().width;
    scheduleWidthUpdate(initialWidth);

    // Создаём наблюдатель
    const observer =
      detect === 'auto'
        ? new ResizeObserver((entries) => {
            for (const entry of entries) {
              if (entry.target === el) {
                const newWidth = entry.contentRect.width;
                scheduleWidthUpdate(newWidth);
              }
            }
          })
        : undefined;

    if (observer) {
      observer.observe(el);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
      // Если был запущен таймер на отложенное обновление, сбрасываем его
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }
    };
  }, [elementRef, batchDelay, detect]);

  // Мемоизация, чтобы объект { width } не пересоздавался без нужды
  return useMemo(
    () => ({
      width,
      updateWidth: () => {
        const el = elementRef.current;
        if (!el) {
          return;
        }
        setWidth(el.getBoundingClientRect().width);
      },
    }),
    [width],
  );
};

export default useLayoutWidth;
