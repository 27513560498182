import {
  type ForwardRefRenderFunction,
  type ReactNode,
  type RefObject,
  type CSSProperties,
  memo,
  forwardRef,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { type ViewStyle, type StyleProp, type LayoutChangeEvent, Platform, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

import { useResponsive, useThemeColor } from 'hooks';

import { GradientView } from 'components/Themed';
import { unit } from 'utils';

import useMethods from '../utils/useMethods';
import { type Controller } from '../../types';

interface PanelProps {
  children?: ReactNode;
  onShow?: (context: any) => void;
  onHide?: () => void;
  onContext?: any;
}

const Panel: ForwardRefRenderFunction<Controller, PanelProps> = (props, forwardedRef) => {
  const { children, onShow, onHide, onContext } = props;

  const responsive = useResponsive();
  const backdropColor = useThemeColor({ light: '#ffffffff', dark: '#000000' });

  const [rootLayout, setRootLayout] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>();
  const [modalSize, setModalSize] = useState<{
    width: number;
    height: number;
  }>();

  const handleHideMiddleware = useCallback(() => {
    setRootLayout(undefined);
    onHide?.();
  }, [onHide]);

  const { isVisible, handleShow, handleHide, handleCloseQuery } = useMethods(
    forwardedRef,
    onShow,
    undefined,
    handleHideMiddleware,
    undefined,
    onContext,
  );

  const handleLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    const { current } = forwardedRef as RefObject<Controller>;
    if (!current) {
      return;
    }
    const context = current.getContext();
    if (!context?.callingTarget) {
      return;
    }
    setModalSize({
      width: nativeEvent.layout.width,
      height: nativeEvent.layout.height,
    });
    if (Platform.OS === 'web' && context?.callingTarget instanceof HTMLDivElement) {
      const rect = context?.callingTarget.getBoundingClientRect();
      setRootLayout({
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  const animation = useMemo((): Record<
    'in' | 'out',
    {
      method: 'fadeIn' | 'fadeOut' | 'slideInUp' | 'slideOutDown';
      timing: number;
    }
  > => {
    if (responsive.isMoreThen.mobileLarge) {
      return {
        in: {
          method: 'fadeIn',
          timing: 200,
        },
        out: {
          method: 'fadeOut',
          timing: 200,
        },
      };
    }
    return {
      in: {
        method: 'slideInUp',
        timing: 400,
      },
      out: {
        method: 'slideOutDown',
        timing: 300,
      },
    };
  }, [responsive.isMoreThen.mobileLarge]);

  const modalStyle = useMemo(() => {
    if (responsive.isLessThen.tablet) {
      return styles.mobileModal;
    }
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.desktopModal),
    };
    if (!rootLayout || !modalSize) {
      return result;
    }
    const window = Dimensions.get('window');
    const rootBottom = Math.round(rootLayout.y + modalSize.height);
    result.left = rootLayout.x - modalSize.width;
    result.top = rootBottom + 20 < window.height ? rootLayout.y : rootLayout.y - (rootBottom - window.height + 20);
    return result;
  }, [responsive.isLessThen.tablet, rootLayout, modalSize]);

  const panelStyle = useMemo(() => {
    if (responsive.isLessThen.tablet) {
      return styles.mobilePanel;
    }
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.desktopPanel),
    };
    if (!rootLayout || !modalSize) {
      result.opacity = 0;
    }
    return result;
  }, [responsive.isLessThen.tablet, rootLayout, modalSize]);

  const backdropStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.backdropStyle),
      backgroundColor: backdropColor,
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).position = 'fixed';
    }
    return result;
  }, [backdropColor]);

  return (
    <Modal
      isVisible={isVisible}
      style={modalStyle}
      // onLayout={handleLayout}
      onShow={handleShow}
      onModalHide={handleHide}
      onBackdropPress={handleCloseQuery}
      backdropOpacity={responsive.isLessThen.tablet ? 0.7 : 0.3}
      backdropTransitionInTiming={!responsive.isLessThen.tablet ? 1 : undefined}
      backdropTransitionOutTiming={!responsive.isLessThen.tablet ? 1 : undefined}
      customBackdrop={<TouchableOpacity style={backdropStyle} onPress={handleCloseQuery} activeOpacity={1} />}
      onSwipeComplete={handleCloseQuery}
      swipeDirection={responsive.isLessThen.tablet ? ['down'] : []}
      animationIn={animation.in.method}
      animationInTiming={animation.in.timing}
      animationOut={animation.out.method}
      animationOutTiming={animation.out.timing}
    >
      <GradientView style={panelStyle} lightColors={['#f2f2f1', '#f2f2f1']} darkColors={['#222222', '#101010']} onLayout={handleLayout}>
        {children}
      </GradientView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  backdropStyle: {
    position: 'absolute',
    backgroundColor: '#000000',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  mobileModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  mobilePanel: {
    paddingHorizontal: unit(32),
    paddingTop: unit(24),
    paddingBottom: unit(28),
    borderTopLeftRadius: unit(30),
    borderTopRightRadius: unit(30),
    maxWidth: unit(425),
    alignSelf: 'center',
    width: '100%',
  },
  desktopModal: {
    position: 'absolute',
    top: 'none',
    right: 'none',
    bottom: 'none',
    left: 'none',
    margin: 0,
  },
  desktopPanel: {
    paddingHorizontal: unit(32),
    paddingVertical: unit(16),
    borderRadius: unit(16),
  },
});

export default memo(forwardRef<Controller, PanelProps>(Panel));
