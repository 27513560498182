import { type FC, memo, useCallback } from 'react';
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

import MenuButton from 'components/UIBar/elements/MenuButton';
import Icon from 'ui/Icon';

interface ThemeSwitcherProps {
  isSlim?: boolean;
}

const ThemeSwitcher: FC<ThemeSwitcherProps> = (props) => {
  const { isSlim } = props;

  const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();

  const handleThemeChange = useCallback(
    (newSchemeValue: 'light' | 'dark') => {
      const newMode = mode === 'dark' ? 'light' : 'dark';
      // Storage.set('theme.type', newMode);
      setMaterialMode(newMode);
      setJoyMode(newMode);
    },
    [mode],
  );

  return (
    <>
      {mode === 'dark' && (
        <MenuButton
          style={{ justifyContent: 'space-between' }}
          onPress={() => {
            handleThemeChange('light');
          }}
          text="Light mode"
          isSlim={isSlim}
          isSelected={false}
          revertSlim
          iconRight={<Icon name="sun-bright" fw size="lg" weight="light" color="icon" />}
        />
      )}
      {mode === 'light' && (
        <MenuButton
          style={{ justifyContent: 'space-between' }}
          onPress={() => {
            handleThemeChange('dark');
          }}
          text="Dark mode"
          isSlim={isSlim}
          isSelected={false}
          revertSlim
          iconRight={<Icon name="moon" fw size="lg" weight="light" color="icon" />}
        />
      )}
    </>
  );
};

export default memo(ThemeSwitcher);
