import { type PropsWithChildren, useCallback, memo, forwardRef, useImperativeHandle } from 'react';
import { type Accept, type DropzoneRef, useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';

import { useThemeColor } from 'hooks';

import Text from './Text';

export interface DropzoneProps {
  accept?: Accept;
  onAcceptedFiles?: (files: File[]) => void;
}

const Dropzone = forwardRef<DropzoneRef, PropsWithChildren<DropzoneProps>>((props, ref) => {
  const { accept, children, onAcceptedFiles } = props;
  const borderColor = useThemeColor({ dark: '#2F2F2F', light: '#515151' });
  const backgroundColor = useThemeColor({ dark: '#000000', light: '#E6E4EA' });

  const classes = useStyle({
    backgroundColor,
    borderColor,
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onAcceptedFiles?.(acceptedFiles);
      }
    },
    [onAcceptedFiles],
  );

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    accept,
    onDrop,
    noClick: true,
  });

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <div className={classes.DropZone} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
      {isDragActive && (
        <div className={classes.zone}>
          <Text lightColor="#515151" darkColor="#FFFFFF">
            Drop a file here
          </Text>
        </div>
      )}
    </div>
  );
});

const useStyle = createUseStyles<
  'DropZone' | 'zone',
  {
    backgroundColor?: string;
    borderColor?: string;
  }
>({
  DropZone: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  zone: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: '0.625em',
    backgroundColor: (props) => props.backgroundColor,
    border: (props) => `0.0625em dashed ${props.borderColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default memo(Dropzone);
