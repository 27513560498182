import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import type { CollectionPermissionType, CollectionType } from 'app/entities';

import moment from 'moment-timezone';
import interaction from 'services/api/interaction/route';
import network from 'lib/network';

import { actions } from '../slice';

export const config = {
  action: actions.loadById.type,
  method: takeEvery,
};

const loadCollections = async (ids: number[]) => {
  const response = await Promise.all(ids.map((id) => network.request<CollectionType>(`/stack-1/share/user/playlists/${id}`).get()));
  let data: CollectionType[] = [];
  let error: Error | null = null;

  response.forEach((result, key) => {
    if (result.errors && result.errors?.[0]?.message === 'object_not_found') {
      data.push({
        id: ids[key],
        internalUrl: '',
        contentImages: [],
        materialsMetadata: [],
        cover: null,
        createdAt: '',
        description: '',
        duration: '',
        isCompleted: false,
        isPrivate: false,
        isShown: false,
        level: '',
        tags: [],
        title: '',
        updatedAt: '',
        userId: 0,
        deletedAt: moment().toISOString(),
        __typename: 'Collection',
      });
      return;
    }
    if (result.errors?.[0]?.message || !result.data) {
      error = result.errors?.[0] || new Error('Error server #15');
      data = [];
      return;
    }
    data.push(result.data);
  });

  return {
    data,
    error,
  };
};

const loadPermissions = async (ids: number[]) => {
  const response = await Promise.all(
    ids.map((id) => network.request<CollectionPermissionType[]>(`/stack-1/share/user/playlists/${id}/actions`).get()),
  );

  let data: Record<number, CollectionPermissionType[]> = {};
  let error: Error | null = null;

  response.forEach((result, index) => {
    if (result.errors?.[0]?.message || !result.data) {
      error = result.errors?.[0] || new Error('Error server #15');
      data = [];
      return;
    }
    data[ids[index]] = result.data;
  });

  return {
    data,
    error,
  };
};

const loadData = async (
  ids: number[],
  mode: 'full' | 'preview',
): Promise<{
  collections: CollectionType[];
  error: Error | Error[] | null;
}> => {
  if (!ids || (Array.isArray(ids) && ids.length === 0)) {
    return {
      collections: [],
      error: null,
    };
  }
  const idsFinal = Array.isArray(ids) ? ids : [ids];

  const [playlists, interactionMap, permissions, pendingCount] = await Promise.all([
    loadCollections(idsFinal),
    interaction.items('playlist', idsFinal),
    mode === 'full' ? loadPermissions(idsFinal) : false,
    mode === 'full'
      ? network
          .request<{
            pendingContentCount: Record<number, number | null>;
          }>('/stack-1/user/playlists/pendingContentCount')
          .query({ resourceIds: idsFinal })
          .get()
      : false,
  ]);

  if (playlists.error || !playlists.data || interactionMap.error || !interactionMap.data) {
    return {
      collections: [],
      error: playlists.error || interactionMap.error,
    };
  }

  return {
    collections: playlists.data.map((collection) => ({
      ...collection,
      ...interactionMap.data?.[collection?.id],
      ...(typeof pendingCount !== 'boolean' ? { pendingMaterialsCount: pendingCount.data?.pendingContentCount?.[collection?.id] } : undefined),
      ...(typeof permissions !== 'boolean' ? { permissions: permissions.data?.[collection?.id] } : undefined),
    })),
    error: null,
  };
};

export function* func(action: SagaReturnType<typeof actions.loadById>) {
  const { payload } = action;
  if (!payload.id) {
    yield cancel();
    return;
  }

  const { mode = 'full' } = payload?.options || {};

  const ids = !Array.isArray(payload.id) ? [payload.id] : payload.id;

  const { collections, error } = yield* call(() => loadData(ids, mode));
  if (error || !collections.length) {
    Alert.error(error);
    yield put(actions.loadByIdDone({ id: payload.id }));
    yield cancel();
    return;
  }

  yield put(actions.setItem({ data: collections }));
  yield put(actions.loadByIdDone({ id: payload.id }));
}
