import AuthStart from 'screens/auth/Start';
import AuthSignIn from 'screens/auth/SignIn';
import AuthPasswordReset from 'screens/auth/PasswordReset';
import EmailConfirm from 'screens/auth/EmailConfirm';
import AuthTryFree from 'widgets/Onboarding/layout/TryFree';
import InviteTeam from 'widgets/Onboarding/layout/InviteTeam';
import TellUsAboutYou from 'widgets/Onboarding/layout/TellUsAboutYou';
import SetUpProfile from 'widgets/Onboarding/layout/SetUpProfile';
import Ask from 'screens/Ask';
import Community from 'screens/Community';
import Content from 'screens/Content/Page';
import Note from 'screens/Note';
import Profile from 'screens/Profile';
import NotFound from 'screens/NotFound';
import Space from 'screens/Space';

import UIBar, { UIBarProvider } from 'components/UIBar';
import ScrollerProvider from 'components/Scroller';

import { Routes, Route } from './router';
import linking from './linking';

const Stack = () => {
  return (
    <ScrollerProvider>
      <UIBarProvider>
        <Routes>
          <Route path={linking.Ask} element={<Ask />} />
          <Route path={linking.Content} element={<Content />} />
          {/* <Route path={linking.CommunityPeers} element={<Community />} /> */}
          {/* <Route path={linking.CommunityCollection} element={<Community />} /> */}
          <Route path={linking.Profile} element={<Profile />} />
          <Route path={linking.Author} element={<Profile />} />
          <Route path={linking.Note} element={<Note />} />
          <Route path={linking['Auth/EmailConfirm']} element={<EmailConfirm />} />
          <Route path={linking['Auth/Start']} element={<AuthStart />} />
          <Route path={linking['Auth/SignIn']} element={<AuthSignIn />} />
          <Route path={linking['Auth/PasswordReset']} element={<AuthPasswordReset />} />
          <Route path={linking['Auth/Onboarding/Profile']} element={<SetUpProfile />} />
          <Route path={linking['Auth/Onboarding/AboutYou']} element={<TellUsAboutYou />} />
          <Route path={linking['Auth/Onboarding/InviteTeam']} element={<InviteTeam />} />
          <Route path={linking['Auth/Onboarding/TryFree']} element={<AuthTryFree />} />
          <Route path={linking['Auth/WithAppSumo']} element={<AuthStart />} />
          <Route path={linking.NotFound} element={<NotFound />} />
          <Route path={linking.Space} element={<Space />} />
          <Route path={linking.Search} element={<Space />} />
        </Routes>
        <UIBar />
      </UIBarProvider>
    </ScrollerProvider>
  );
};

export default Stack;
