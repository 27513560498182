import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatcher } from 'store/utils/redux/hooks';
import type { SpaceType } from 'app/entities';
import { useSelector } from 'react-redux';
import * as spaceStore from 'screens/Space/store';

const useSpaceState = (spaceId?: number) => {
  const dispatcher = useDispatcher();

  const source = useSelector(spaceStore.selectors.spaceById(spaceId));
  const [data, setData] = useState<SpaceType | null>(source);

  useEffect(() => {
    setData((prevState) => {
      if (prevState === null) {
        return source;
      }
      if (prevState.id !== source?.id) {
        return source;
      }
      return prevState;
    });
  }, [source, spaceId]);

  const change = useCallback((event: { target: { name: string; value: string | boolean } }) => {
    const { name, value } = event.target;
    setData((prevFormData) => {
      return {
        ...(prevFormData as SpaceType),
        [name]: value,
      };
    });
  }, []);

  const create = useCallback(() => {
    if (!data) {
      return;
    }
    dispatcher.spaceList.create({ data });
  }, [data]);

  const update = useCallback(() => {
    if (!data) {
      return;
    }
    dispatcher.spaceList.update({ id: data.id, data });
  }, [data]);

  return useMemo(
    () => ({
      data,
      change,
      create,
      update,
    }),
    [data, change, create, update],
  );
};

export default useSpaceState;
