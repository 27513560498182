import { put, all, take, takeLatest } from 'redux-saga/effects';

import { actions as authActions } from 'store/nodes/auth';
import { actions as spaceRootActions } from '../slices/root';
import { actions as spaceListActions } from '../slices/list';
import { actions as spacePermissionActions } from '../slices/permission';
import { actions as resourceActions } from '../slices/resource';

export const config = {
  action: [spaceRootActions.init.type, authActions.loginSuccess.type],
  method: takeLatest,
};

export function* func() {
  yield put(spaceListActions.load());
  yield put(spacePermissionActions.loadAvailable());
  yield put(resourceActions.loadAvailableFilters());
  yield all([
    take(spaceListActions.loadDone.type),
    take(spacePermissionActions.loadAvailableDone.type),
    take(resourceActions.loadAvailableFiltersDone.type),
  ]);
  yield put(spaceRootActions.initDone());
}
