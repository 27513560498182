import { type AuthorType, type ContentErrorType, type MaterialType, type UserType } from 'app/entities';
import { guard } from 'utils';

const useAuthor = (data?: MaterialType | ContentErrorType | null): AuthorType | UserType | null => {
  if (!data || guard.isEntityError(data)) {
    return null;
  }
  const { authors } = data;
  return (!Array.isArray(authors) ? [authors] : authors)?.[0] || null;
};

export default useAuthor;
