import { type SagaReturnType, takeEvery, cancel, put, delay } from 'redux-saga/effects';
import { call } from 'store/utils/saga/effects';

import loadPages from '../model/loadPages';
import prepareData from '../model/prepareData';

import { actions } from '../slice';

export const config = {
  action: actions.loadById.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadById>) {
  const { id, options } = action.payload;

  if (!id) {
    yield put(actions.loadByIdDone({ id, options }));
    yield cancel();
    return;
  }

  const isPreview = options?.mode === 'preview';
  const pageSize = isPreview ? 3000 : 5000000;

  const entity = yield* call(() => loadPages(id, options?.spaceId, pageSize, !isPreview));
  const preparedNote = yield* prepareData(entity);

  yield put(actions.setItem({ data: preparedNote }));
  yield delay(10);
  yield put(actions.loadByIdDone({ id, options }));
}
