import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { DialogTitle, Modal, ModalClose, ModalDialog, DialogContent, Box, Divider } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';

import * as modalStore from 'widgets/modals/store';

import MenuBar from 'widgets/Profile/ui/MenuBar';
import ProfileLayout from 'widgets/Profile/layuot/ProfileLayout';
import GeneralLayout from 'widgets/Profile/layuot/GeneralLayout';
import PlansLayout from 'widgets/Profile/layuot/PlansLayout';
import useProfileState from 'widgets/Profile/model/useProfileState';

/**
 * @constructor
 */
const Profile = () => {
  const dispatcher = useDispatcher();
  const { isOpen } = useSelector(modalStore.selectors.state('Profile'));
  const [currentLayout, setCurrentLayout] = useState('Profile');

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('Profile');
  }, []);

  const { formData, handleChange } = useProfileState();

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="plain"
        color="neutral"
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 'min(457px, calc(100% - 32px))',
          maxWidth: 'min(710px, calc(100% - 32px))',
          borderRadius: '1.25rem',
          '--FormLabel-color': '--joy-palette-neutral-plainDisabledColor',
          '@media (max-width: 767px)': {
            maxHeight: 'calc(100% - 32px)',
          },
        }}
      >
        <ModalClose onClick={handleModalClose} size="md" sx={{ borderRadius: '1rem', m: 1 }} />
        <DialogTitle sx={{ fontSize: 17, ml: 1 }}>Settings</DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            display="flex"
            flexDirection="row"
            gap={7}
            sx={{
              '--joy-palette-background-surface': 'var(--joy-palette-background-level1)',
              mr: 1,
              '@media (max-width: 767px)': {
                flexDirection: 'column',
                gap: 0,
                mr: 'unset',
              },
            }}
          >
            <MenuBar value={currentLayout} onChange={setCurrentLayout} />
            {currentLayout === 'Profile' && <ProfileLayout formData={formData} handleChange={handleChange} />}
            {currentLayout === 'General' && <GeneralLayout formData={formData} handleChange={handleChange} />}
            {currentLayout === 'Plans' && <PlansLayout />}
          </Box>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default memo(Profile);
