import { type SagaReturnType, takeEvery, put, take } from 'redux-saga/effects';

import * as messageStore from 'store/nodes/message';
import { actions as listActions } from '../slices/list';
import { actions as structureActions } from '../slices/structure';

import { actions as permissionActions } from '../slices/permission';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { context } = action.payload;
  const { type, teamSpaceId } = context;

  if (type === 'teamSpaceAccessProvided' && typeof teamSpaceId === 'number') {
    yield put(listActions.load({ id: teamSpaceId }));
    yield take(listActions.loadDone.type);
    yield put(structureActions.loadFull({ spaceId: teamSpaceId, folderId: 'root' }));
  }
  if (type === 'teamSpaceAccessRemoved' && typeof teamSpaceId === 'number') {
    yield put(listActions.remove({ id: teamSpaceId }));
  }
  if (type === 'teamSpaceAccessProvided' && typeof teamSpaceId === 'number') {
    yield put(permissionActions.loadUserPermissions({ data: [teamSpaceId] }));
  }
}
