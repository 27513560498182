import { type FilterItemType } from 'app/entities';

export default (available: FilterItemType[], selected: string[]) => {
  const result: Record<string, string> = {};
  if (!available) {
    return result;
  }
  available.forEach((item) => {
    if (!selected.includes(item.id)) {
      return;
    }
    Object.entries(item.query).forEach(([property, newItems]) => {
      const oldItems = result[property] ? (result[property] || '').split(',') : [];
      result[property] = [...new Set([...oldItems, ...newItems])].join(',');
      if (result[property] === '') {
        delete result[property];
      }
    });
  });
  return result;
};
