import type { DefaultRootState } from 'react-redux';
import { type SagaReturnType, call, put, takeLatest, cancel } from 'redux-saga/effects';
import network from 'lib/network';

import { select } from 'store/utils/saga/effects';
import dialog from 'components/Dialog';

import { actions } from '../slices/resource';

const selectResourcesInfo = (resourceIds: string[]) => (state: DefaultRootState) => {
  return resourceIds.map((id) => state.spaceResource.data[id]);
};

export const config = {
  action: actions.remove.type,
  method: takeLatest,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: {
          icon: {
            name: 'circle-exclamation',
            weight: 'solid',
          },
          content: 'Confirmation',
        },
        content: <>Are you sure want to delete this?</>,
      },
      [
        {
          label: 'Yes',
          color: 'danger',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
        {
          label: 'Cancel',
          variant: 'plain',
          color: 'neutral',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
      ],
    );
  });

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const { entityIds } = action.payload;

  const isConfirmed: boolean = yield call(confirm);
  if (!isConfirmed) {
    yield put(actions.removeDone({ entityIds }));
    yield cancel();
    return;
  }

  const resourcesInfo = yield* select(selectResourcesInfo(entityIds));

  yield put(actions.removeApply({ entityIds }));

  for (const info of resourcesInfo) {
    if (!info) {
      continue;
    }

    const resourceType = info.__typename === 'Folder' ? 'folder' : { Material: 'content', Document: 'document' }[info.resourceType];

    try {
      const url = `/stack-2/user/team-space/${info.spaceId}/resources/${resourceType}/${info.id}`;
      const response: { data: null | any; hasError: boolean; errors: Error[] | null } = yield call(() => network.request(url).delete());

      if (response.hasError) {
        // Если ошибка — решаем, что делать
        // Можно показать алерт, отменить удаление, и т.д.
        // Для простоты пропустим
      }
    } catch (error) {
      // Если запрос упал
      // Можно откатить, показать сообщение и т.п.
      // Пока ничего не делаем
    }
  }

  yield put(actions.removeDone({ entityIds }));
}
