import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/joy';

import { useRoute } from 'navigation/hooks';
import { guard } from 'utils';

import * as materialStore from 'store/nodes/content';

import { usePerson, useCardContextHandlers } from 'hooks';

import Icon from 'ui/Icon';

const ActionsBar = () => {
  const route = useRoute<'Content'>();

  const { resourceId } = route.params;

  const { person } = usePerson('User', 'my');
  const data = useSelector(materialStore.selectors.dataById(resourceId));

  const { handleToCollection, handleLink, handleEdit } = useCardContextHandlers(data);
  const idDisabledAll = guard.isContentError(data);

  return (
    <Box display="flex" flexDirection="row" alignSelf="stretch">
      <Box display="flex" justifyContent="center" gap={1.5} flexGrow={1}>
        {!!person && guard.isMaterial(data) && data?.creator?.id === person?.id && (
          <Tooltip arrow variant="outlined" size="sm" title="Edit material">
            <IconButton size="sm" color="neutral" variant="soft" disabled={idDisabledAll} onClick={handleEdit}>
              <Icon name="pen-to-square" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip arrow variant="outlined" size="sm" title="Share material">
          <IconButton size="sm" color="neutral" variant="soft" disabled={!data || idDisabledAll} onClick={handleLink}>
            <Icon name="link" fw weight="light" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip disableInteractive arrow variant="outlined" size="sm" title="Add to collection">
          <IconButton size="sm" color="neutral" variant="soft" disabled={!data || idDisabledAll} onClick={handleToCollection}>
            <Icon family="kit" name="sharp-light-bars-circle-plus" fw color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default memo(ActionsBar);
