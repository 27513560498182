import { takeLatest, put, cancel, type SagaReturnType } from 'redux-saga/effects';

import type { AuthorType, CommunityEntityType, LinkToContentType, UserType } from 'app/entities';

import { guard } from 'utils';
import { call } from 'store/utils/saga/effects';
import Alert from 'components/Alert';

import fetchCommunityFeed from '../model/fetchCommunityFeed';
import * as actions from '../actions';

export const config = {
  action: actions.loadCollections.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadCollections>) {
  const { data, error } = yield* call(() => fetchCommunityFeed());

  if (error.hasErrors || !data) {
    Alert.error(error?.list);
    yield put(actions.loadCollectionDone());
    yield cancel();
    return;
  }

  const result = data.map((pool) => ({
    title: pool.title,
    payload: pool.payload
      .map((item) => {
        if (guard.isLikeUser(item)) {
          return {
            ...item,
            name: item?.name || '',
            surname: item?.surname || '',
            __typename: 'User',
          };
        }
        if (guard.isAuthor(item)) {
          return {
            ...item,
            name: item?.name || '',
            surname: item?.surname || '',
            __typename: 'Author',
          };
        }
        if (item.resourceType === 'Collection') {
          return item;
        }
        return null;
      })
      .filter(Boolean),
  })) as CommunityEntityType<UserType | AuthorType | LinkToContentType<'Collection'>>[];

  yield put(actions.setCollection(result));
  yield put(actions.loadCollectionDone());
}
