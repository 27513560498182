import { useState, useCallback, type DragEvent, useMemo } from 'react';

type DragOverType = 'files' | 'nodes';

export interface UseDragStateOptions {
  /**
   * Дополнительная логика, чтобы решить,
   * "можно ли" подсвечивать элемент (или обрабатывать drag) в принципе.
   * Возвращает true/false.
   */
  canDrag: (target: HTMLElement, type: DragOverType) => boolean;
  onDragEnter: (event: DragEvent) => void;
  onDragOver: (event: DragEvent) => void;
  onDragLeave: (event: DragEvent) => void;
  onDrop: (event: DragEvent) => void;
}

/**
 * Хук, который различает, тащит ли пользователь файлы (hasFiles)
 * или внутренние DOM‐узлы (reordering). Возвращает `dragOverType`:
 * 'files' / 'nodes' / false
 * и соответствующие обработчики onDragEnter / onDragOver / ...
 *
 * Если мы уже в состоянии 'files', а очередной dragover тоже 'files',
 * мы не вызываем setState повторно, чтобы избежать лишних ререндеров.
 */
function useDragState(options: Partial<UseDragStateOptions> = {}) {
  const { canDrag, onDragEnter, onDragOver, onDragLeave, onDrop } = options;

  // dragOverType = 'files', 'nodes' или false
  const [dragOverType, setDragOverType] = useState<DragOverType | false>(false);

  /**
   * Определяем, перетаскивает ли пользователь файлы
   * (event.dataTransfer.items[kind === 'file']) или нет.
   */
  const detectDragType = useCallback((e: DragEvent<HTMLElement>): DragOverType | false => {
    const dt = e.dataTransfer;
    if (!dt.items || dt.items.length === 0) {
      return false;
    }
    const hasFile = Array.from(dt.items).some((item) => item.kind === 'file');
    return hasFile ? 'files' : 'nodes';
  }, []);

  const handleDragEnter = useCallback(
    (e: DragEvent<HTMLElement>) => {
      e.preventDefault();
      const newType = detectDragType(e);
      if (newType === false) return;

      // Проверяем кастомную логику canDrag
      const isAllowed = canDrag ? canDrag(e.target as HTMLElement, newType) : true;
      if (!isAllowed) return;

      // Устанавливаем dragOverType только если действительно поменялось значение
      setDragOverType((prev) => {
        if (prev !== newType) {
          onDragEnter?.(e);
          return newType;
        }
        return prev;
      });
    },
    [canDrag, onDragEnter, detectDragType],
  );

  const handleDragOver = useCallback(
    (e: DragEvent<HTMLElement>) => {
      e.preventDefault();
      const newType = detectDragType(e);
      if (newType === false) return;

      const isAllowed = canDrag ? canDrag(e.target as HTMLElement, newType) : true;
      if (!isAllowed) return;

      setDragOverType((prev) => {
        if (prev !== newType) {
          onDragOver?.(e);
          return newType;
        }
        return prev;
      });
    },
    [canDrag, onDragOver, detectDragType],
  );

  const handleDragLeave = useCallback(
    (e: DragEvent<HTMLElement>) => {
      setDragOverType((prev) => {
        if (prev !== false) {
          onDragLeave?.(e);
        }
        return false;
      });
    },
    [onDragLeave],
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      setDragOverType((prev) => {
        if (prev !== false) {
          onDrop?.(e);
        }
        return false;
      });
    },
    [onDrop],
  );

  return useMemo(
    () => ({
      dragOverType,
      handleDragEnter,
      handleDragOver,
      handleDragLeave,
      handleDrop,
    }),
    [dragOverType, handleDragEnter, handleDragOver, handleDragLeave, handleDrop],
  );
}

export default useDragState;
