import { createAction } from '@reduxjs/toolkit';

import type { MaterialType, ContentErrorType, PaginationType } from 'app/entities';

export const clear = createAction('content/clear');

export const removeFromSequence = createAction('content/removeFromSequence', (type: 'content' | 'playlist', id: number) => ({
  payload: {
    type,
    id,
  },
}));

export const loadNextPage = createAction('content/loadPage', (reload = false) => ({
  payload: {
    reload,
  },
}));

export const loadNextPageDone = createAction('content/loadPageDone', (reload = false) => ({
  payload: {
    reload,
  },
}));

export const addPage = createAction('content/setPage', (items: MaterialType[], paginationInfo: PaginationType, reload = false) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));

export const loadRelated = createAction('content/loadRelated', (contentId: number) => ({
  payload: {
    contentId,
  },
}));

export const loadRelatedDone = createAction('content/loadRelatedDone', (contentId: number, relatedIds: number[]) => ({
  payload: {
    contentId,
    relatedIds,
  },
}));

export const loadById = createAction(
  'content/loadById',
  (payload: { id: number | number[]; options?: { spaceId?: number; mode?: 'full' | 'preview' } }) => {
    return {
      payload,
    };
  },
);

export const loadByIdDone = createAction(
  'content/loadByIdDone',
  (payload: { id: number | number[]; options?: { spaceId?: number; mode?: 'full' | 'preview' } }) => ({
    payload,
  }),
);

export const setItem = createAction('content/setItem', (data: (MaterialType | ContentErrorType)[] | null) => ({
  payload: {
    data,
  },
}));

export const thumbnailReady = createAction('content/thumbnailReady', (payload: { id: number }) => ({
  payload,
}));

export const loadFilters = createAction('content/loadFilters', () => ({
  payload: {},
}));

export const loadFiltersDone = createAction('content/loadFiltersDone', (isError = false) => ({
  payload: {
    isError,
  },
}));

export const setFilters = createAction('content/setFilters', ({ type }: { type: { options: string[] } }) => ({
  payload: {
    type: {
      options: [...(type?.options || [])],
    },
  },
}));

export const setFilterType = createAction('content/setFilterType', (value: string, triggerReload = true) => ({
  payload: {
    value,
    triggerReload,
  },
}));

export const hide = createAction('content/hideContent', (id: number) => ({
  payload: {
    id,
  },
}));

export const remove = createAction('content/remove', (id: number | number[]) => ({
  payload: {
    id,
  },
}));

export const removeDone = createAction('content/removeDone', (id: number | number[]) => ({
  payload: {
    id,
  },
}));

export const logOut = createAction('content/logout');

export const highlightDelete = createAction('content/highlightDelete', (id: number, resourceId: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const highlightDeleteDone = createAction('content/highlightDeleteDone', (id: number, resourceId: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const noteUpdate = createAction('content/noteUpdate', (resourceId: number, text: string, id?: number) => ({
  payload: {
    id,
    resourceId,
    text,
  },
}));

export const noteUpdateDone = createAction('content/noteUpdateDone', (resourceId: number, id?: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const noteDelete = createAction('content/noteDelete', (resourceId: number, id: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const materialIndexed = createAction('content/materialIndexed', (payload: { id: number }) => ({
  payload,
}));

export const noteDeleteDone = createAction('content/noteDeleteDone', (resourceId: number, id: number) => ({
  payload: {
    id,
    resourceId,
  },
}));
