import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import { Box } from '@mui/joy';

import CopilotConnector from './CopilotConnector';

export interface FolderCopilotProps {
  id: number | 'create';
  onCopyToText: (text: string) => void;
}

const FolderCopilot: FC<FolderCopilotProps> = (props) => {
  const { id, onCopyToText } = props;

  return (
    <Box
      position="fixed"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      width="var(--panels-document-right-width)"
      height="calc(100vh - 80px)"
    >
      <CopilotConnector id={id} onCopyToText={onCopyToText} autofocus={id !== 'create'} />
    </Box>
  );
};

export default memo(FolderCopilot, isEqual);
