import { type DefaultRootState } from 'react-redux';

const isLoading = (identifier?: 'my' | string | number) => (state: DefaultRootState) => {
  if (!identifier) {
    return false;
  }
  return Boolean(state.user.meta.loadingMap[identifier]);
};

export default isLoading;
