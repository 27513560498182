import { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useColorScheme } from 'hooks';

const Light = (): null => {
  useLightWebStyles();
  return null;
};

const Dark = (): null => {
  useDarkWebStyles();
  return null;
};

const WebStyles = () => {
  const colorScheme = useColorScheme();
  useWebStyles();

  return (
    <>
      {colorScheme === 'light' && <Light />}
      {colorScheme === 'dark' && <Dark />}
    </>
  );
};

const useWebStyles = createUseStyles({
  '@global': {
    'html, body': {
      fontFamily: '-apple-system, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif',
    },
    'body.ReactModal__Body--open': {
      '&': {},
      '@media (max-width: 424px)': {
        overflow: 'hidden',
      },
    },
    'body[data-rout-name="Auth/Start"]': {
      overflow: 'auto',
    },
  },
});

const useLightWebStyles = createUseStyles({
  '@global': {
    body: {
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0.625rem',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--joy-palette-neutral-400, #9FA6AD)',
        borderRadius: '0.5rem',
        border: '2px solid transparent',
        backgroundClip: 'content-box',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--joy-palette-neutral-400, #9FA6AD)',
      },
      '&:hover::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'var(--joy-palette-neutral-400, #9FA6AD)',
      },
    },
  },
});

const useDarkWebStyles = createUseStyles({
  '@global': {
    body: {
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0.625rem',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--joy-palette-neutral-600, #555E68)',
        borderRadius: '0.5rem',
        border: '2px solid transparent',
        backgroundClip: 'content-box',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--joy-palette-neutral-600, #555E68)',
      },
      '&:hover::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'var(--joy-palette-neutral-600, #555E68)',
      },
    },
  },
});

export default memo(WebStyles);
