import MarkdownIt from 'markdown-it';

const isHtml = (text: string): boolean => {
  const trimmedText = text.trim();

  if (!trimmedText) {
    return false;
  }

  const hasHtmlTag = /^<html/.test(trimmedText);
  const startsWithTag = /^<[^>]+>/.test(trimmedText);
  const endsWithClosingTag = /<\/[^>]+>$/.test(trimmedText);

  return hasHtmlTag || (startsWithTag && endsWithClosingTag);
};

function stripHtmlBodyWrapper(htmlString: string) {
  if (!htmlString) {
    return htmlString;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  return doc.body.innerHTML;
}

interface PrepareHtmlOptions<Async extends boolean> {
  async?: Async;
  html?: boolean;
}

type PrepareHtmlReturn<Async extends boolean> = Async extends true ? Promise<string | null> : string | null;

function prepareHtml<Async extends boolean = false>(
  data: { text?: string | null } | unknown | null,
  options?: PrepareHtmlOptions<Async>,
): PrepareHtmlReturn<Async> {
  if (!data) {
    return (options?.async ? Promise.resolve(null) : null) as PrepareHtmlReturn<Async>;
  }
  if (typeof data !== 'object' || !('text' in data) || typeof data.text !== 'string' || !data.text) {
    return (options?.async ? Promise.resolve('') : '') as PrepareHtmlReturn<Async>;
  }
  const text = data.text.trim();

  if (isHtml(text)) {
    const html = stripHtmlBodyWrapper(text);
    return (options?.async ? Promise.resolve(html) : html) as PrepareHtmlReturn<Async>;
  }
  if (!options?.async) {
    const md = new MarkdownIt({
      html: options?.html ?? false,
      linkify: true,
      typographer: true,
    });
    return md.render(text) as PrepareHtmlReturn<Async>;
  }
  // @ts-ignore
  const worker = new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' });

  return new Promise<string | null>((resolve, reject) => {
    worker.onmessage = (e: MessageEvent) => {
      resolve(e.data.result);
      worker.terminate();
    };
    worker.onerror = (err) => {
      reject(err);
      worker.terminate();
    };
    worker.postMessage(text);
  }) as PrepareHtmlReturn<Async>;
}

export default prepareHtml;
