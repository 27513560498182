import { forwardRef, memo } from 'react';
import { Box, useColorScheme } from '@mui/joy';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import RichTextPanel from 'ui/EditorInput/ui/RichTextPanel';
import ListPanel from 'ui/EditorInput/ui/ListPanel';
import HeadingPanel from 'ui/EditorInput/ui/HeadingPanel';
import LinkPanel from 'ui/EditorInput/ui/LinkPanel';
import LinkEditorPanel from 'ui/EditorInput/ui/LinkEditorPanel';

export interface DefaultToolbarCoords {
  x: number;
  y: number;
}

export interface DefaultToolbarProps {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
  coords: DefaultToolbarCoords | undefined;
  setIsLinkEditMode: (value: boolean) => void;
  isLink: boolean;
  isLinkEditMode: boolean;
  hasRichPanel?: boolean;
  hasListPanel?: boolean;
  hasHeadingPanel?: boolean;
}

const DefaultToolbar = forwardRef<HTMLDivElement, DefaultToolbarProps>((props, forwardedRef) => {
  const { editor, coords, setIsLinkEditMode, isLink, isLinkEditMode, hasRichPanel = true, hasListPanel = true, hasHeadingPanel = true } = props;

  const { mode } = useColorScheme();
  const shouldShow = coords !== undefined;

  return (
    <Box
      ref={forwardedRef}
      aria-hidden={!shouldShow}
      sx={{
        position: 'absolute',
        top: coords?.y,
        left: coords?.x,
        visibility: shouldShow ? 'visible' : 'hidden',
        opacity: shouldShow ? 1 : 0,
        backgroundColor: mode === 'light' ? 'var(--joy-palette-neutral-50)' : 'var(--joy-palette-neutral-700)',
        boxShadow: (theme) => theme.shadow.xl,
        border: `1px solid var(${mode === 'light' ? '--joy-palette-neutral-100' : '--joy-palette-neutral-800'})`,
        borderRadius: '0.5rem',
      }}
    >
      <Box
        sx={{
          '& button': {
            borderRadius: 'unset',
          },
          '& button:first-child': {
            borderRadius: 'unset',
            borderBottomLeftRadius: 'calc(var(--radius) - 2px)',
            borderTopLeftRadius: 'calc(var(--radius) - 2px)',
          },
          '& button:last-child': {
            borderRadius: 'unset',
            borderBottomRightRadius: 'calc(var(--radius) - 2px)',
            borderTopRightRadius: 'calc(var(--radius) - 2px)',
          },
          '& button:hover, & button:active': {
            backgroundColor: `var(${mode === 'light' ? '--joy-palette-primary-100' : '--joy-palette-neutral-600'})`,
          },
          '& button[aria-pressed="true"]': {
            backgroundColor: 'transparent',
            color: 'var(--joy-palette-primary-500)',
          },
          '& button[aria-pressed="true"]:hover, & button[aria-pressed="true"]:active': {
            backgroundColor: `var(${mode === 'light' ? '--joy-palette-primary-100' : '--joy-palette-neutral-600'})`,
          },
        }}
      >
        {hasHeadingPanel && <HeadingPanel editor={editor} />}
        {hasRichPanel && <RichTextPanel editor={editor} />}
        {hasListPanel && <ListPanel editor={editor} />}
        {/* <FontSizePanel editor={editor} /> */}
        {/* <FontColorPanel editor={editor} /> */}
        {/* <LinkPanel setIsLinkEditMode={setIsLinkEditMode} isLink={isLink} editor={editor} /> */}
        {/* <LinkEditorPanel */}
        {/*   editor={editor} */}
        {/*   isLinkEditMode={isLinkEditMode} */}
        {/*   setIsLinkEditMode={setIsLinkEditMode} */}
        {/* /> */}
      </Box>
    </Box>
  );
});

export default memo(DefaultToolbar);
