import { type SyntheticEvent, useCallback } from 'react';

/**
 * Колбэк, который вызывается, когда пользователь выбрал элемент дерева.
 * Если выбран только space, folderId будет 'root'.
 */
export type OnFocusCallback = (spaceId: number, folderId: string) => void;

/**
 * Хук возвращает обработчик itemFocusHandler:
 *   - Если itemId = '1::54', то spaceId='1', folderId='54'
 *   - Если itemId = '1', то spaceId='1', folderId='root'
 */
function useItemFocusHandler(onFocus: OnFocusCallback) {
  const handler = useCallback(
    (event: SyntheticEvent, itemIds: string | null) => {
      if (!itemIds) {
        return;
      }
      if (itemIds.includes('::')) {
        const [spaceId, folderId] = itemIds.split('::');
        onFocus?.(Number(spaceId), folderId);
      } else {
        onFocus?.(Number(itemIds), 'root');
      }
    },
    [onFocus],
  );

  return { handler };
}

export default useItemFocusHandler;
