/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import React, { type FC, memo, useCallback, useEffect } from 'react';
import { type ColorSchemeName } from 'react-native';

import Alert from 'components/Alert';

import { Router } from './router';
import { useNavigate, useRoute } from './hooks';
import * as navigateMethod from './methods/navigate';

import Stack from './Stack';

const MethodsProvider = memo(() => {
  const navigate = useNavigate();

  useEffect(() => {
    navigateMethod.setMethod(navigate);
  }, [navigate]);

  return null;
});

export interface LocationListenerProps {
  onChange?: () => void;
}

const LocationListener = memo(({ onChange }: LocationListenerProps) => {
  const route = useRoute();

  useEffect(() => {
    onChange?.();
  }, [route]);

  return null;
});

export interface NavigationProps {
  colorScheme: ColorSchemeName;
  onReady?: () => void;
  onLocationChange?: () => void;
}

const Toasts = memo(() => <Alert.Init />);

const Navigation: FC<NavigationProps> = (props) => {
  const { colorScheme, onReady, onLocationChange } = props;

  const handleReady = useCallback(() => {
    onReady?.();
  }, [onReady]);

  return (
    <Router onReady={handleReady}>
      <MethodsProvider />
      <LocationListener onChange={onLocationChange} />
      <Toasts />
      <Stack />
    </Router>
  );
};

export default memo(Navigation);
