import { createAction } from '@reduxjs/toolkit';

import type { BasicUserType, NoUserExistsType, UserType } from 'app/entities';

import { prepare, guard } from 'utils';

export const loadUser = createAction(
  'user/loadUser',
  (
    payload: (
      | { login: string; email?: never; id?: never }
      | { email: string; login?: never; id?: never }
      | { id: number; login?: never; email?: never }
    )[],
  ) => ({
    payload,
  }),
);

export const loadUserDone = createAction(
  'user/loadUserDone',
  (
    payload: (
      | { login: string; email?: never; id?: never }
      | { email: string; login?: never; id?: never }
      | { id: number; login?: never; email?: never }
    )[],
  ) => ({
    payload,
  }),
);

export const setProcessingKey = createAction(
  'user/setProcessingKey',
  (
    payload:
      | { login: string; email?: never; id?: never }
      | { email: string; login?: never; id?: never }
      | { id: number; login?: never; email?: never },
  ) => ({
    payload,
  }),
);

export const loadProfile = createAction('user/loadProfile', (login: string) => ({
  payload: {
    login,
    optimistic: false,
  },
}));

export const loadProfileOptimistic = createAction('user/loadProfileOptimistic', () => ({
  payload: {
    login: 'my',
    optimistic: true,
  },
}));

export const loadProfileDone = createAction(
  'user/loadProfileDone',
  (payload: { login: string; data?: UserType } | { login: string; error: string }) => ({
    payload,
  }),
);

export const loadMe = createAction('user/loadMe', () => ({
  payload: {
    login: 'my',
    optimistic: false,
  },
}));

export const loadMeDone = createAction('user/loadMeDone', (payload: { login: string; data?: UserType } | { login: string; error: string }) => ({
  payload,
}));

export const setData = createAction(
  'user/setData',
  (payload: { data: (BasicUserType | UserType | Partial<BasicUserType> | Partial<UserType> | NoUserExistsType)[] }) => {
    const { data } = payload;
    const items = Array.isArray(data) ? data : [data];
    const prepared = items.map((item) => {
      if (guard.isNoUserExists(item)) {
        return item;
      }
      return prepare.user(item);
    });
    return {
      payload: {
        data: prepared,
      },
    };
  },
);

export const updateProperty = createAction(
  'user/updateProperty',
  <K extends keyof UserType>(payload: { target: 'my' | string | number; name: K; value: UserType[K] }) => {
    return {
      payload,
    };
  },
);

export const subscribe = createAction('user/subscribe', (login: string) => ({
  payload: {
    login,
  },
}));

export const subscribeSuccess = createAction('user/subscribeSuccess', (login: string) => ({
  payload: {
    login,
  },
}));

export const subscribeDone = createAction('user/subscribeDone', (login: string) => ({
  payload: {
    login,
  },
}));

export const unsubscribeSuccess = createAction('user/unsubscribeSuccess', (login: string) => ({
  payload: {
    login,
  },
}));

export const unsubscribe = createAction('user/unsubscribe', (login: string) => ({
  payload: {
    login,
  },
}));

export const unsubscribeDone = createAction('user/unsubscribeDone', (login: string) => ({
  payload: {
    login,
  },
}));

export const logOut = createAction('user/logout');
