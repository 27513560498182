import { type FC, type ReactElement, type MouseEvent, memo, useCallback, useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';

import DialogItem from './DialogItem';
import controller from './classes/Controller';

export const Dialogs: FC = () => {
  const dialogs = useSelector((state) => state.app.dialogs);

  const getContext = useCallback((itemID?: string | null) => {
    if (!itemID) {
      return null;
    }
    try {
      return JSON.parse(itemID) as { dialogId: string; handleId: string };
    } catch {
      return null;
    }
  }, []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const itemID = event.currentTarget?.getAttribute('itemID');
      const context = getContext(itemID);
      if (!context) {
        return;
      }
      const { dialogId, handleId } = context;
      const handler = controller.getHandler(dialogId, handleId);
      if (!handler) {
        return;
      }
      handler(dialogId);
    },
    [getContext],
  );

  const renderedDialogs: ReactElement[] = useMemo(
    () => dialogs.map((dialog) => <DialogItem key={dialog.id} id={dialog.id} isVisible={dialog.isVisible} onButtonClick={handleClick} />),
    [dialogs, handleClick],
  );

  return <>{renderedDialogs}</>;
};

export default controller;
