import { type FC, useCallback, memo, useState, useEffect, useMemo, useRef } from 'react';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import * as noteStore from 'store/nodes/note';
import Box from '@mui/joy/Box';
import { ButtonGroup, IconButton, Card, CardActions, Tooltip } from '@mui/joy';
import Typography from '@mui/joy/Typography';

import { useEditorState } from 'hooks';

import Icon from 'ui/Icon';
import { type EditorInputMethodsType } from 'ui/EditorInput';

import EditorInput from 'ui/EditorInput';
import Avatar from 'components/Avatar';
import * as materialStore from 'store/nodes/content';
import CircularProgressCounter, { type CircularProgressCounterRef } from '../ui/CircularProgressCounter';

export interface ItemNoteProps {
  id: number;
  onRemove?: (id: number, options?: { force?: boolean }) => void;
  onRevert?: (id: number) => void;
  onSave?: (id: number, text: string) => void;
  sx?: {
    flex: number;
  };
}

const ItemNote: FC<ItemNoteProps> = (props) => {
  const { id, onRemove, onRevert, onSave, sx } = props;

  const data = useSelector(noteStore.selectors.dataById(id));
  const isLoading = useSelector(noteStore.selectors.isLoading({ noteId: id }));
  const item = useSelector(materialStore.selectors.remark(id));

  const autoSaveCounterRef = useRef<CircularProgressCounterRef | null>(null);
  const removeCounterRef = useRef<CircularProgressCounterRef | null>(null);
  const editorRef = useRef<EditorInputMethodsType | null>(null);

  const { html, hasContent, onChange } = useEditorState(data?.text);
  const hasUpdate = useMemo(() => data?.text !== html, [data?.text, html]);
  const [, setKey] = useState(uuidV4());
  const photo = data?.user || item?.user;

  useEffect(() => {
    const { current: autoSaveCounter } = autoSaveCounterRef;
    const { current: removeCounter } = removeCounterRef;
    if (!autoSaveCounter || !removeCounter) {
      return undefined;
    }
    autoSaveCounter.stop();
    removeCounter.stop();
    if (!hasUpdate) {
      return undefined;
    }
    if (!hasContent) {
      removeCounter.start();
      return undefined;
    }
    autoSaveCounter.start();
    return undefined;
  }, [html, hasUpdate]);

  useEffect(() => {
    const timeout: any = setTimeout(() => {
      setKey(uuidV4());
    }, 1000 * 60);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    if (!isLoading && data?.text) {
      editorRef.current?.set(data?.text);
    }
  }, [data, isLoading]);

  const handleRevert = useCallback(() => {
    editorRef.current?.set(data?.text || '');
    onRevert?.(id);
  }, [onRevert, id, data?.text]);

  const handleSave = useCallback(() => {
    if (!html) {
      return;
    }
    onSave?.(id, html);
  }, [onSave, id, html]);

  const handleRemove = useCallback(() => {
    onRemove?.(id);
  }, [onRemove, id]);

  const handleRemoveForce = useCallback(() => {
    onRemove?.(id, { force: true });
  }, [onRemove, id]);

  return (
    <Card
      variant="soft"
      color="neutral"
      size="sm"
      sx={{
        borderRadius: 26,
        '--Avatar-size': '24px',
        '.MuiIconButton-root': {
          opacity: 0,
          transition: '300ms opacity ease',
          '@media (max-width: 767px)': {
            opacity: 1,
          },
        },
        '&:hover .MuiIconButton-root': {
          opacity: 1,
        },
        ...sx,
      }}
    >
      <Box p={0.5} pb={0} fontSize={16}>
        {data?.isMy ? <EditorInput ref={editorRef} onChange={onChange} /> : <Box dangerouslySetInnerHTML={{ __html: item?.text || '' }} />}
      </Box>
      <CardActions sx={{ minHeight: 36, '--Card-padding': 0 }}>
        <Box display="flex" gap={1} ml={0.5} mr="auto" alignItems="center">
          <Avatar user={photo} vars={{ Background: 3 }} />
          <Typography fontSize={12} fontWeight={500}>
            {data?.user.name || item?.user?.name}
          </Typography>
          <Box height={6} width={6} overflow="hidden" sx={{ '& > i': { transform: 'translateY(-11px)' } }}>
            <Icon name="period" weight="solid" />
          </Box>
          <Typography fontSize={12} fontWeight={400}>
            {moment(item?.createdAt).fromNow()}
          </Typography>
        </Box>
        {data?.isMy && (
          <ButtonGroup
            variant="soft"
            color="neutral"
            spacing={1}
            sx={{
              '--ButtonGroup-radius': '50%',
              '--ButtonGroup-separatorSize': 0,
            }}
          >
            <Tooltip arrow variant="outlined" size="sm" title="Delete">
              <IconButton
                onClick={handleRemove}
                sx={{
                  opacity: !hasContent ? '1!important' : undefined,
                  color: (theme) => theme.palette.danger['500'],
                }}
              >
                <Icon name="trash" color="inherit" />
                <CircularProgressCounter
                  ref={removeCounterRef}
                  determinate
                  color="danger"
                  size="md"
                  sx={{ position: 'absolute', opacity: 0.2 }}
                  onFinish={handleRemoveForce}
                />
              </IconButton>
            </Tooltip>
            {hasUpdate && (
              <Tooltip arrow variant="outlined" size="sm" title="Revert">
                <IconButton type="button" onClick={handleRevert} sx={{ opacity: '1!important' }}>
                  <Icon name="rotate-left" weight="solid" color="inherit" />
                </IconButton>
              </Tooltip>
            )}
            {hasUpdate && (
              <Tooltip arrow variant="outlined" size="sm" title="Save">
                <IconButton type="button" onClick={handleSave} sx={{ opacity: '1!important' }}>
                  <Icon name="floppy-disk-circle-arrow-right" color="inherit" />
                  <CircularProgressCounter
                    ref={autoSaveCounterRef}
                    determinate
                    color="primary"
                    size="md"
                    sx={{
                      position: 'absolute',
                    }}
                    onFinish={handleSave}
                  />
                </IconButton>
              </Tooltip>
            )}
          </ButtonGroup>
        )}
      </CardActions>
    </Card>
  );
};

export default memo(ItemNote);
