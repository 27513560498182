import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';

import type { ResultType } from '../store/types';

const useResultMany = (result?: ResultType | null) => {
  const data = useSelector((state) => {
    if (result?.__typename !== 'ResultMany') {
      return null;
    }
    const { parsedLinksCount, uploadSessionId } = result;
    return { parsedLinksCount, uploadSessionId };
  });

  return useMemo(() => {
    if (!data) {
      return null;
    }
    return data;
  }, [data]);
};

export default useResultMany;
