import { type FocusEventHandler, useCallback, useMemo, useState } from 'react';

type UseFocusStateParams = {
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

const useFocusState = (params: UseFocusStateParams) => {
  const [isFocused, setFocus] = useState<boolean>(false);

  const handleFocus = useCallback<FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
    (event) => {
      params.onFocus?.(event);
      setFocus(true);
    },
    [params.onFocus],
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
    (event) => {
      params.onBlur?.(event);
      setFocus(false);
    },
    [params.onBlur],
  );

  return useMemo(
    () => ({
      isFocused,
      handleFocus,
      handleBlur,
    }),
    [isFocused, handleFocus, handleBlur],
  );
};

export default useFocusState;
