import { memo } from 'react';
import { Box, Typography } from '@mui/joy';

import AdaptivePage from 'components/AdaptivePage';
import TutorialStep from 'components/TutorialStep';

import AddKnowledgeButton from '../../components/AddKnowledgeButton';
import NewDocumentButton from '../../components/NewDocumentButton';

import CopilotInputConnector from './ui/CopilotInputConnector';

const Start = () => {
  return (
    <AdaptivePage hasTopBar={false} desktopHeaderRightPanel={false} bodyMaxWidth={1152} paddingHorizontalScheme={{ 320: 16 }}>
      <Box display="flex" flex={1} flexDirection="column" justifyContent="center">
        <Typography textColor="text.primary" fontSize={45} fontWeight={400} lineHeight={1.25} my={3} textAlign="center">
          Intelligent Knowledge Interface
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="stretch" gap={4}>
          <CopilotInputConnector />
          <Box display="flex" flexDirection="row" justifyContent="center" alignSelf="stretch" gap={1}>
            <TutorialStep
              stepIndex={4}
              isLast
              title="Write in IKI"
              description="Create a document with IKI assistant."
              slotProps={{
                wrapper: {
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  maxWidth: 260,
                },
              }}
            >
              <NewDocumentButton />
            </TutorialStep>
            <TutorialStep
              stepIndex={1}
              title="Add information to your library"
              description="Save URLs, PDFs, YouTube videos, etc. Cloud integrations are coming soon."
              slotProps={{
                wrapper: {
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  maxWidth: 260,
                },
              }}
            >
              <AddKnowledgeButton />
            </TutorialStep>
          </Box>
        </Box>
      </Box>
    </AdaptivePage>
  );
};

export default memo(Start);
