import { type PropsWithChildren, useCallback, memo } from 'react';
import { createUseStyles } from 'react-jss';
import { Box, type BoxProps, useTheme } from '@mui/joy';

export interface EmptyTextProps extends BoxProps {
  title: string;
  titleProps?: BoxProps;
  contentProps?: BoxProps;
  onClick?: () => void;
}

const EmptyText = (props: PropsWithChildren<EmptyTextProps>) => {
  const { title, titleProps, contentProps, children, onClick, ...rest } = props;

  const theme = useTheme();

  const classes = useStyle({
    hasClick: !!onClick,
    titleColor: theme.palette.text.primary,
    contentColor: theme.palette.text.secondary,
  });

  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <Box className={classes.EmptyText} onClick={onClick ? handleClick : undefined} {...rest}>
      <Box className={classes.title} {...titleProps}>
        {title}
      </Box>
      <Box className={classes.content} {...contentProps}>
        {children}
      </Box>
    </Box>
  );
};

const useStyle = createUseStyles<
  'EmptyText' | 'title' | 'content',
  {
    hasClick?: boolean;
    titleColor?: string;
    contentColor?: string;
  }
>({
  EmptyText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    cursor: (props) => (props.hasClick ? 'pointer' : 'default'),
    fontSize: 'clamp(0.75em, 2.67vw, 1em)',
  },
  title: {
    color: (props) => props.titleColor,
    lineHeight: 1.375,
    fontSize: '1.5em',
  },
  content: {
    color: (props) => props.contentColor,
    lineHeight: 1.25,
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: '300',
    padding: '0.5em 0.5em 0',
  },
});

export default memo(EmptyText);
