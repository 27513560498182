import { call } from 'store/utils/saga/effects';
import network from 'lib/network';
import type { EntityDataType, EntityErrorType, EntityType } from 'app/entities';
import { guard, requestCursorModifier } from 'utils';
import interactionGetter from 'services/api/interaction/route';
import remarkGetter from 'services/api/remark';

/**
 * Тип группы, которую сформирует наша сага.
 */
export interface LoadGroup {
  spaceId?: number;
  ids: number[];
  join: 'all' | 'interaction';
}

/**
 * Принимает массив групп (у каждой может быть свой spaceId) и загружает
 * контент из /stack-2/user/content, а также, при необходимости, interaction и remarks.
 */
function* loadItems(groups: LoadGroup[]) {
  // Сформируем Promise для контента (их может быть несколько — по количеству уникальных spaceId).
  const contentPromises = groups.map((group) => {
    const { spaceId, ids } = group;
    const query: Record<string, string> = {
      ids: ids.join(','),
    };
    if (spaceId !== undefined) {
      query.teamSpaceId = String(spaceId);
    }

    return network.request<EntityType[]>('/stack-2/user/content').query(query).get(requestCursorModifier());
  });

  // Собираем все ID, которым нужны interaction, и все ID, которым нужны remarks.
  const interactionIds = new Set<number>();
  const remarksIds = new Set<number>();

  for (const group of groups) {
    if (group.join === 'all' || group.join === 'interaction') {
      group.ids.forEach((id) => interactionIds.add(id));
    }
    if (group.join === 'all') {
      group.ids.forEach((id) => remarksIds.add(id));
    }
  }

  // Запросы к interaction и remarks делаем по всему пулу (если size > 0).
  const [contentResults, interactionsMap, remarksMap] = yield* call(() =>
    Promise.all([
      Promise.all(contentPromises),
      interactionIds.size > 0 ? interactionGetter.items('content', Array.from(interactionIds)) : false,
      remarksIds.size > 0 ? remarkGetter.items(Array.from(remarksIds), 100) : false,
    ] as const),
  );

  // Сольём все сущности из ответов content-запросов
  const allItems: EntityType[] = [];
  for (const resp of contentResults) {
    if (resp.hasError || !resp.data) {
      return {
        entities: [],
        errorMessage: resp.errors && resp.errors.length > 0 ? resp.errors[0].message : 'Error load materials',
      };
    }
    if (resp.data.items) {
      allItems.push(...resp.data.items);
    }
  }

  // Подмешиваем interaction и remarks (если есть) к каждой сущности.
  const entities = allItems.map((entity) => {
    if (guard.isEntityError(entity)) {
      return entity;
    }
    if (!guard.isEntityMaterial(entity)) {
      return {
        type: 'UnknownError',
        id: entity.id,
        data: null,
      } as EntityErrorType<'UnknownError'>;
    }

    return {
      ...entity,
      data: {
        ...entity.data,
        ...(interactionsMap !== false ? interactionsMap.data?.[entity.id] : {}),
        ...(remarksMap !== false ? { remarks: remarksMap.data?.[entity.id]?.items || [] } : {}),
        __typename: 'Material',
      },
    } as EntityDataType<'Material'>;
  });

  return { entities, errorMessage: null };
}

export default loadItems;
