import { type FC, memo } from 'react';
import { Box } from '@mui/material';

import { acceptContentTypes } from 'utils/extensionByContentType';

import useDisplayMode from 'hooks/useDisplayMode';

import Header from './ui/Header';
import Entities from './ui/Entities';
import SelectionBar from './ui/SelectionBar';
import Dropzone from './ui/DropZone';
import ActionBar from './ui/ActionBar';

interface FolderContentProps {
  spaceId?: number;
  folderId?: string;
  hadPermissionWrite?: boolean;
  isDragActiveGlobal?: boolean;
  handleAcceptedFiles?: (files?: File[]) => void;
}

const FolderContent: FC<FolderContentProps> = (props) => {
  const { spaceId, folderId, hadPermissionWrite, isDragActiveGlobal, handleAcceptedFiles } = props;

  const { mode, setMode } = useDisplayMode({
    key: `display-mode/space-${folderId === 'root' ? 'library' : 'folder'}`,
    allowedValues: ['grid', 'list'],
    defaultValue: folderId === 'root' ? 'grid' : 'list',
  });

  return (
    <Box position="relative">
      <Header spaceId={spaceId} folderId={folderId} mb={4} />
      <ActionBar displayMode={mode} onDisplayModeChange={setMode} mb={3.5} />
      <Entities spaceId={spaceId} folderId={folderId} displayMode={mode} canSelect={hadPermissionWrite} canRemove={hadPermissionWrite} />
      {hadPermissionWrite && <SelectionBar spaceId={spaceId} folderId={folderId} detectPositionSelector="div.entities" />}
      {hadPermissionWrite && <Dropzone accept={acceptContentTypes} onAcceptedFiles={handleAcceptedFiles} zIndex={100} isShow={isDragActiveGlobal} />}
    </Box>
  );
};

export default memo(FolderContent);
