import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, CardContent, Tooltip, Typography } from '@mui/joy';

import { type AuthorType, type UserType } from 'app/entities';

import * as contentStore from 'store/nodes/content';

import CompositePoster from 'components/CompositePoster';
import Icon from 'ui/Icon';

import { useCardPoster, useCardProps } from '../model/CardContext';
import useAuthor from '../model/useAuthor';

import SourceInfo from '../ui/SourceInfo';
import DescriptionBlock from '../ui/DescriptionBlock';
import Remarks from '../ui/Remarks';
import AuthorBlock from '../ui/AuthorBlock';

export interface MaterialProps {
  handleAuthorPress?: (value: UserType | AuthorType | null) => void;
}

const Material: FC<MaterialProps> = (props) => {
  const { handleAuthorPress } = props;
  const { id, hasDescription, hasSummary, hasHighlight, hasAuthor, descriptionLineLimit, AuthorBlockComponent } = useCardProps();
  const posterSources = useCardPoster();

  const data = useSelector(contentStore.selectors.dataById(id));
  const { person } = useAuthor(data);

  const handlePress = useCallback(() => {
    if (!person) {
      return;
    }
    handleAuthorPress?.(person);
  }, [person]);

  return (
    <>
      {posterSources && posterSources.length > 0 && (
        <Box
          sx={{
            '@container (width > 400px )': {
              display: 'none',
            },
          }}
        >
          <CompositePoster sources={posterSources} radius={8} aspectRatio={4 / 3} />
        </Box>
      )}
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            flex={1}
            sx={{
              '@container (width <= 400px )': {
                gap: 1.5,
              },
            }}
          >
            <SourceInfo
              sx={{
                '@container (width > 400px )': {
                  marginRight: '40%',
                },
              }}
              data={data}
            />
            {!!data && 'title' in data && data?.title && (
              <Typography
                fontSize={17}
                sx={{
                  wordBreak: 'break-word',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={600}
              >
                {data?.private && (
                  <Tooltip placement="top" arrow disableInteractive variant="outlined" size="sm" title="This material is visible only to you.">
                    <Typography component="span" fontSize={16}>
                      <Icon size="sm" marginRight={1} weight="solid" color="var(--joy-palette-primary-solidDisabledColor)" name="eye-slash" />
                    </Typography>
                  </Tooltip>
                )}
                {data?.title?.trim?.()}
              </Typography>
            )}
            {hasDescription && !!data && 'description' in data && data?.description && (
              <DescriptionBlock ellipsizeMode="tail" numberOfLines={descriptionLineLimit || 20} text={data.description} />
            )}
            {((hasSummary && !!data && 'gptSummary' in data && data?.gptSummary) ||
              (hasHighlight && !!data && 'highlight' in data && data?.highlight)) && (
              <Remarks
                items={[
                  {
                    type: 'Summary',
                    text: data?.gptSummary,
                  },
                  {
                    type: 'Highlight',
                    text: data?.highlight?.text,
                  },
                ]}
              />
            )}
            {!!AuthorBlockComponent && AuthorBlockComponent}
            {!AuthorBlockComponent && hasAuthor && !!data && 'publishedAt' in data && !!person && (
              <AuthorBlock onPress={handlePress} createData={data?.publishedAt} data={person} />
            )}
          </Box>
          {posterSources && posterSources.length > 0 && (
            <Box
              sx={{
                ml: 1,
                '@container (width <= 400px)': {
                  display: 'none',
                },
              }}
            >
              <CompositePoster width={120} sources={posterSources} radius={8} aspectRatio={16 / 9} />
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default memo(Material);
