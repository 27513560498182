import type { ResultLegacy, AuthRefreshType } from 'app/entities';
import request from 'services/api/request';

export default async (refreshToken: string): Promise<ResultLegacy<AuthRefreshType>> => {
  const { data, error } = await request.post<AuthRefreshType>('/stack-1/auth/refresh', {
    body: {
      refresh: refreshToken,
    },
  });
  if (error || !data?.access) {
    return {
      data: null,
      error,
    };
  }
  return {
    data: {
      access: data?.access,
      refresh: refreshToken,
    },
    error: null,
  };
};
