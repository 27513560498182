import { type FC, type PropsWithChildren, memo, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';
import { useColorScheme, useTheme } from '@mui/joy';

const StripeElementsWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { colorScheme } = useColorScheme();

  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY || '');

  const options: StripeElementsOptions = useMemo(
    () => ({
      appearance: {
        variables: {
          colorPrimary: colorScheme === 'dark' ? '#f1f1f1' : '#cdcdcc',
          colorBackground: colorScheme === 'dark' ? '#282828' : '#f1f1f0',
          colorText: colorScheme === 'dark' ? '#f1f1f1' : '#181817',
          colorTextPlaceholder: colorScheme === 'dark' ? '#9f9f9f' : '#555554',
          colorTextSecondary: colorScheme === 'dark' ? '#cdcdcd' : '#323232',
          focusBoxShadow: colorScheme === 'dark' ? '0 0 0 1px #f1f1f1' : '0 0 0 2px #323232',
          borderRadius: '10px',
        },
      },
    }),
    [colorScheme],
  );

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default memo(StripeElementsWrapper);
