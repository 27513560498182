import { type ReactElement } from 'react';

import dialog from 'components/Dialog';

type OptionsType = {
  title: ReactElement | string;
  cancelLabel?: string;
  confirmLabel?: string;
};

export const confirm = (body: ReactElement | string, options?: OptionsType): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: options?.title || 'Confirmation',
        content: body,
      },
      [
        {
          label: options?.confirmLabel || 'Confirm',
          color: 'danger',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
        {
          label: options?.cancelLabel || 'Cancel',
          variant: 'plain',
          color: 'neutral',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
      ],
    );
  });
