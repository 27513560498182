import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, type BoxProps } from '@mui/joy';
import { isEqual } from 'lodash';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import { TextInput } from 'components/Form';
import Icon from 'ui/Icon';
import { isLibraryScreen } from 'navigation/guards';
import { useRoute } from 'navigation/hooks';

import { useFontSize, useBorderRadius, useButtonSize, usePadding, usePlaceholder } from './hooks';

interface SearchInputProps extends BoxProps {
  size?: 'small' | 'medium' | 'large';
  spaceId?: number;
  autoFocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const SearchInput: FC<SearchInputProps> = (props) => {
  const { size = 'medium', spaceId, autoFocus = false, placeholder = 'Search...', disabled, ...rest } = props;
  const dispatcher = useDispatcher();
  const route = useRoute<'Library'>();

  const value = useSelector(searchStore.selectors.filterText);
  const isProcessed = route.params.interaction === 'search' && route.params.text === value;

  const borderRadius = useBorderRadius(size);
  const { inputVerticalPaddings, inputHorizontalPadding } = usePadding(size, value);
  const buttonSize = useButtonSize(size);
  const fontSize = useFontSize(size);

  const handleChange = useCallback(
    (text: string) => {
      if (!spaceId) {
        return;
      }
      dispatcher.search.setFilterText({
        spaceId,
        value: text,
        triggerReload: false,
        immediateUpdate: false,
      });
    },
    [spaceId],
  );

  const handleSubmit = useCallback(async () => {
    if (isProcessed || !spaceId) {
      return;
    }
    dispatcher.search.setFilterText({
      spaceId,
      value,
      triggerReload: true,
      immediateUpdate: true,
    });
  }, [value, spaceId, isProcessed]);

  return (
    <Box position="relative" flexDirection="row" width="80%" {...rest}>
      <TextInput
        autoFocus={autoFocus}
        placeholder={isLibraryScreen(route) ? 'Search by title in my library' : placeholder}
        style={{ flex: 1 }}
        inputFont={{
          size: fontSize as any,
        }}
        radius={borderRadius}
        pickerStyle={[
          inputVerticalPaddings,
          inputHorizontalPadding,
          {
            backgroundColor: 'var(--joy-palette-background-level1)',
          },
        ]}
        disabled={disabled}
        onChange={handleChange}
        value={value}
        onEnter={handleSubmit}
      />
      <Box position="absolute" flexDirection="row" right="4px" top="4px" gap="4px">
        {!!value && !isProcessed && (
          <IconButton
            variant="plain"
            onClick={handleSubmit}
            color="neutral"
            sx={{
              width: buttonSize,
              height: buttonSize,
              borderRadius: '50%',
              minHeight: 'unset',
            }}
          >
            <Icon
              name="up"
              fw
              weight="light"
              color="var(--joy-palette-neutral-plainColor)"
              sx={{
                fontSize,
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default memo(SearchInput, isEqual);
