import { type FC, memo, useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { Box, IconButton, Typography, useTheme } from '@mui/joy';

import useSpaceTitleById from 'screens/Space/model/useSpaceTitleById';
import * as plusSelectors from 'widgets/Plus/store/selectors';

import Icon from 'ui/Icon';
import StateIcon from './StateIcon';

export interface HeaderProps {
  variant: 'add' | 'processing' | 'material' | 'materials';
  onCrossPress?: () => void;
}

const Header: FC<HeaderProps> = (props) => {
  const { variant, onCrossPress } = props;

  const theme = useTheme();
  const requestInfo = useSelector(plusSelectors.requestInfo);
  const spaceTitle = useSpaceTitleById(requestInfo.spaceId);

  const classes = useStyle({
    titleColor: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.level1,
  });

  const handleCrossPress = useCallback(() => {
    onCrossPress?.();
  }, [onCrossPress]);

  const titleState = useMemo(
    () => ({
      add: 'Add to IKI' as const,
      processing: 'Analyzing content' as const,
      material: `Added to "${spaceTitle}"` as const,
      materials: `Added to "${spaceTitle}"` as const,
    }),
    [spaceTitle],
  );

  const title = titleState[variant];

  return (
    <Box className={classes.Header}>
      <StateIcon variant={variant} size="1.5em" />
      <Typography ml={0.5} fontSize={16} flex={1}>
        {title}
      </Typography>
      {onCrossPress && (
        <IconButton
          onClick={handleCrossPress}
          sx={{
            '&:hover': {
              background: theme.palette.background.level2,
            },
            '@media (hover: none)': {
              '&:hover': {
                background: theme.palette.background.level1,
              },
            },
            '&:active': {
              background: theme.palette.background.level3,
            },
          }}
        >
          <Icon name="close" size="lg" />
        </IconButton>
      )}
    </Box>
  );
};

const useStyle = createUseStyles<
  'Header',
  {
    titleColor?: string;
    backgroundColor?: string;
  }
>({
  Header: {
    display: 'flex',
    borderRadius: '1em',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '1em',
    paddingRight: '1em',
    height: '3em',
    backgroundColor: (props) => props.backgroundColor,
  },
});

export default memo(Header);
