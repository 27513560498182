import { takeEvery, put, cancel } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as searchStore from 'store/nodes/search';

import { actions } from 'store/nodes/navigation/slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Search'>());
  if (!route.isScreen('Search')) {
    yield cancel();
    return;
  }

  const { spaceId } = route.params;

  if (!route.params.text) {
    yield cancel();
    return;
  }

  if (route.params.text) {
    const text = yield* select(searchStore.selectors.text);
    if (!text) {
      yield put(
        searchStore.actions.setFilterText({
          spaceId,
          value: route.params.text,
          triggerReload: true,
          immediateUpdate: true,
        }),
      );
    }
    yield* call(() => track('Search'));
    yield cancel();
    return;
  }

  yield* call(() => track('Search'));
}
