import { useState, useEffect, useRef, useMemo } from 'react';

const useDividerVisibility = () => {
  const [shouldShowDivider, setShouldShowDivider] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const spaceMenuTreeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkHeights = () => {
      if (boxRef.current && spaceMenuTreeRef.current) {
        const boxHeight = boxRef.current.clientHeight;
        const spaceMenuTreeHeight = spaceMenuTreeRef.current.scrollHeight;
        const newValue = spaceMenuTreeHeight >= boxHeight;
        setShouldShowDivider((prev) => (prev !== newValue ? newValue : prev));
      }
    };

    checkHeights();

    const observer = new ResizeObserver(checkHeights);
    if (spaceMenuTreeRef.current) {
      observer.observe(spaceMenuTreeRef.current);
    }
    window.addEventListener('resize', checkHeights);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', checkHeights);
    };
  }, []);

  return useMemo(() => ({ boxRef, spaceMenuTreeRef, shouldShowDivider }), [boxRef, spaceMenuTreeRef, shouldShowDivider]);
};

export default useDividerVisibility;
