import connect from 'store/utils/saga/connect';
import * as init from './sagas/init';
import * as handleRoute from './sagas/handleRoute';
import * as loadSpace from './sagas/loadSpace';
import * as loadAvailablePermissions from './sagas/loadAvailablePermissions';
import * as loadAvailableFilters from './sagas/loadAvailableFilters';
import * as loadUserPermissions from './sagas/loadUserPermissions';
import * as loadFullStructure from './sagas/loadFullStructure';
import * as loadStructure from './sagas/loadStructure';
import * as loadResources from './sagas/loadResources';
import * as createSpace from './sagas/createSpace';
import * as autoSave from './sagas/autoSave';
import * as updateSpace from './sagas/updateSpace';
import * as deleteSpace from './sagas/deleteSpace';
import * as createPermissionById from './sagas/createPermissionById';
import * as createPermissionByEmail from './sagas/createPermissionByEmail';
import * as updatePermission from './sagas/updatePermission';
import * as deletePermission from './sagas/deletePermission';
import * as updatePosition from './sagas/updatePosition';
import * as removeResource from './sagas/removeResource';
import * as removeByResourceId from './sagas/removeByResourceId';
import * as moveToFolder from './sagas/moveToFolder';
import * as confirmCreateFolder from './sagas/confirmCreateFolder';
import * as updateFolder from './sagas/updateFolder';
import * as addResource from './sagas/addResource';
import * as loadFolders from './sagas/loadFolders';
import * as materialFolders from './sagas/materialFolders';
import * as handleContentAdd from './sagas/handleContentAdd';
import * as handlePermissionUpdate from './sagas/handlePermissionUpdate';
import * as handleLogout from './sagas/handleLogout';
import * as handleEntityAdded from './sagas/handleEntityAdded';
import * as handleEntityUpdated from './sagas/handleEntityUpdated';
import * as handleEntityDeleted from './sagas/handleEntityDeleted';
import * as handleSearchEntry from './sagas/handleSearchEntry';
import * as handleSpaceLoaded from './sagas/handleSpaceLoaded';
import * as handleMaterialFoldersLoaded from './sagas/handleMaterialFoldersLoaded';
import * as handleSpaceCreated from './sagas/handleSpaceCreated';
import * as handleSpaceDeleted from './sagas/handleSpaceDeleted';
import * as handleFilterUpdate from './sagas/handleFilterUpdate';

export default connect([
  init,
  handleRoute,
  loadSpace,
  loadAvailablePermissions,
  loadAvailableFilters,
  loadUserPermissions,
  loadFullStructure,
  loadStructure,
  loadResources,
  createSpace,
  autoSave,
  updateSpace,
  deleteSpace,
  createPermissionById,
  createPermissionByEmail,
  updatePermission,
  deletePermission,
  updatePosition,
  removeResource,
  removeByResourceId,
  moveToFolder,
  confirmCreateFolder,
  updateFolder,
  addResource,
  loadFolders,
  materialFolders,
  handleContentAdd,
  handlePermissionUpdate,
  handleLogout,
  handleEntityAdded,
  handleEntityUpdated,
  handleEntityDeleted,
  handleSearchEntry,
  handleSpaceLoaded,
  handleMaterialFoldersLoaded,
  handleSpaceCreated,
  handleSpaceDeleted,
  handleFilterUpdate,
]);
