import { put, takeLatest } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';

import { actions as resourceActions } from '../slices/resource';
import { actions as relationActions } from '../slices/relation';

export const config = {
  action: relationActions.setMaterialFolders.type,
  method: takeLatest,
};

export function* func(action: ReturnType<typeof relationActions.setMaterialFolders>) {
  const { items } = action.payload;

  for (const item of items) {
    const isExists = yield* select((state) => !!state.spaceResource.data[item.id]);
    if (!isExists) {
      yield put(resourceActions.loadFolder({ folderId: item.id }));
    }
  }
}
