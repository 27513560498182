import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import type { CollectionRoleType } from 'app/entities';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import Alert from 'components/Alert';
import network from 'lib/network';

import { actions } from '../slice';

export const config = {
  action: actions.loadPermissionRole.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadPermissionRole>) {
  const user = yield* select(userStore.selectors.getMy);

  if (!user) {
    yield put(actions.loadPermissionRoleDone({ permissions: [] }));
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() => network.request<CollectionRoleType[]>('/stack-1/share/user/playlists/permissions').get());

  if (errors || !data) {
    Alert.error('Error load permission');
    yield put(actions.loadPermissionRoleDone({ permissions: [] }));
    yield cancel();
    return;
  }
  yield put(actions.loadPermissionRoleDone({ permissions: data }));
}
