import { type FC, type KeyboardEvent, type SyntheticEvent, type HTMLAttributes, memo, useCallback, useState, useMemo, useEffect } from 'react';
import { Autocomplete, AutocompleteOption, ListItemContent, ListItemDecorator, Typography, Box } from '@mui/joy';

import { type UserType } from 'app/entities';
import { guard, userFullName } from 'utils';

import Icon from 'ui/Icon';

import Avatar from 'components/Avatar';
import PermissionSelect from '../ui/PermissionSelect';
import useUsers from '../model/useUser';

export interface UserSelectProps {
  onEnter?: () => void;
  onUserChange?: (value: number | string) => void;
  onPermissionsChange?: (value: string) => void;
  reset?: boolean;
}

const UserSelect: FC<UserSelectProps> = (props) => {
  const { onEnter, onUserChange, onPermissionsChange, reset } = props;

  const [invitePermissions, setInvitePermissions] = useState('');
  const [value, setValue] = useState<UserType | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');

  const users = useUsers();

  useEffect(() => {
    if (reset) {
      setInvitePermissions('');
      setValue(undefined);
      setInputValue('');
    }
  }, [reset]);

  const handleChange = useCallback(
    (event: SyntheticEvent | null, text: string | null, reason: 'input' | 'reset' | 'clear' | 'enter' | string) => {
      if (reason === 'reset' && !!event) {
        const userId = Number((event.currentTarget as HTMLLIElement).value);
        const user = users?.find((item) => item.id === userId) || undefined;
        if (user) {
          setValue(user);
          setInputValue(userFullName(user));
          onUserChange?.(user.id);
        }
        return;
      }
      if (reason === 'input' && text !== null) {
        setInputValue(text);
        onUserChange?.(text);
        setValue(undefined);
      }
      if (reason === 'clear') {
        setInputValue('');
        setValue(undefined);
      }
      if (reason === 'enter') {
        onEnter?.();
      }
    },
    [value, users, onEnter, onUserChange],
  );

  const handlePermissionsChange = useCallback(
    (newValue: string) => {
      setInvitePermissions(newValue ?? '');
      onPermissionsChange?.(newValue ?? '');
    },
    [onPermissionsChange],
  );

  const handleInputKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (['Enter', 'NumpadEnter'].includes(event.code)) {
        handleChange(null, null, 'enter');
      }
    },
    [handleChange],
  );

  const renderOption = useCallback(
    (optionProps: Omit<HTMLAttributes<HTMLLIElement>, 'color'>, option: UserType) => (
      <AutocompleteOption value={option.id} {...optionProps}>
        <ListItemDecorator>
          <Avatar user={option} vars={{ Background: 2 }} />
        </ListItemDecorator>
        <ListItemContent sx={{ ml: 0.75 }}>
          <Typography fontSize={15} fontWeight={500}>
            {userFullName(option)}
          </Typography>
        </ListItemContent>
      </AutocompleteOption>
    ),
    [],
  );

  const getOptionKey = useCallback((option: string | UserType) => (guard.isLikeUser(option) ? option.id : ''), []);

  const getOptionLabel = useCallback((option: string | UserType) => (guard.isLikeUser(option) ? userFullName(option) : ''), []);

  const startDecoratorRender = useMemo(() => {
    if (/.+@.+\..+/.test(inputValue)) {
      return (
        <Avatar user={value} vars={{ AvatarSize: '24px', Background: 2 }}>
          <Box mt="1px">
            <Icon name="paper-plane" size="xl" weight="solid" fw />
          </Box>
        </Avatar>
      );
    }
    if (guard.isLikeUser(value)) {
      return (
        <Avatar user={value} vars={{ AvatarSize: '24px', Background: 2 }}>
          {userFullName(value).slice(0, 1)}
        </Avatar>
      );
    }
    return undefined;
  }, [inputValue, value]);

  return (
    <Autocomplete
      placeholder="Add via name or email"
      sx={{ flex: 1, borderRadius: (theme) => theme.radius.xl }}
      required
      variant="plain"
      onInputChange={handleChange}
      inputValue={inputValue}
      value={value}
      // onChange={handleChange}
      clearOnEscape
      freeSolo={/.+@.+\..+/.test(inputValue)}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      // @ts-ignore
      options={users}
      loading={!users}
      loadingText="Loading user list"
      disableClearable
      clearOnBlur={false}
      renderOption={renderOption}
      onKeyUp={handleInputKeyUp}
      startDecorator={startDecoratorRender}
      endDecorator={
        <PermissionSelect
          variant="select"
          placeholder="Select role"
          disabled={!value && !/.+@.+\..+/.test(inputValue)}
          value={invitePermissions}
          onChange={(_, newValue) => handlePermissionsChange(newValue)}
        />
      }
      slotProps={{
        popupIndicator: {
          sx: {
            display: 'none',
          },
        },
      }}
    />
  );
};

export default memo(UserSelect);
