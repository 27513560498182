import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (id: number): Promise<SuccessResult> => {
  const { error } = await request.delete<never>(`/stack-1/user/subscriptions/${id}`);
  return {
    data: { success: !error },
    error,
  };
};
