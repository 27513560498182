import { type FC, type MouseEvent, type MouseEventHandler, memo, useCallback } from 'react';
import { Box, Button, Tooltip, Typography, Chip, IconButton, ChipDelete } from '@mui/joy';
import { isEqual } from 'lodash';

import type { SpaceFolderType } from 'app/entities';

import useEntityById from 'screens/Space/model/useEntityById';
import useSpaceTitleById from 'screens/Space/model/useSpaceTitleById';

import CollectionSingleCover from 'components/CollectionSingleCover';
import Icon from 'ui/Icon';

export interface FolderBadgeProps {
  resourceId: number;
  folderId: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>, context: { spaceId: number; folderId: string }) => void;
  onRemove?: (event: MouseEvent<HTMLButtonElement>, context: { resourceId: number; spaceId: number; folderId: string }) => void;
}

const FolderBadge: FC<FolderBadgeProps> = (props) => {
  const { resourceId, folderId, onClick, onRemove } = props;

  const folder = useEntityById<SpaceFolderType | null>(folderId);
  const spaceTitle = useSpaceTitleById(folder?.spaceId);

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (folder?.spaceId && folder?.id) {
        onClick?.(event, { spaceId: folder?.spaceId, folderId: folder?.id });
      }
    },
    [folder?.spaceId, folder?.id, onClick],
  );

  const handleRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (folder?.spaceId && folder?.id) {
        onRemove?.(event, { resourceId, spaceId: folder?.spaceId, folderId: folder?.id });
      }
    },
    [resourceId, folder?.spaceId, folder?.id, onRemove],
  );

  if (!folder) {
    return null;
  }

  return (
    <Chip
      variant="soft"
      color="neutral"
      onClick={handleClick}
      sx={{
        borderRadius: 'var(--joy-radius-sm)',
        '--Chip-paddingInline': 0,
        pr: 0.5,
      }}
      // @todo добавить данные для запроса на удаление.
      // endDecorator={
      //   <Tooltip
      //     arrow
      //     variant="outlined"
      //     size="sm"
      //     title={
      //       <Typography fontSize={14} fontWeight={400} sx={{ opacity: 0.75 }} bottom={0}>
      //         Remove from this collection
      //       </Typography>
      //     }
      //     disableInteractive
      //   >
      //     <ChipDelete variant="plain" onClick={handleRemove}>
      //       <Icon name="trash" weight="light" color="inherit" fw />
      //     </ChipDelete>
      //   </Tooltip>
      // }
    >
      <Tooltip
        arrow
        variant="outlined"
        size="sm"
        title={
          <Typography fontSize={14} fontWeight={400} sx={{ opacity: 0.75 }} bottom={0}>
            {`In space "${spaceTitle}"`}
          </Typography>
        }
        disableInteractive
      >
        <Box display="flex" alignItems="center" py={0.5} pl={0.5} gap={0.5}>
          <CollectionSingleCover
            data={folder.isLibrary ? {} : folder}
            iconSize={20}
            widthPicture={20}
            heightPicture={20}
            radiusPicture={4}
            defaultIcon={folder.isLibrary ? 'inbox' : 'rectangle-history'}
          />
          <Typography fontSize={14} fontWeight={400}>
            {folder.title?.replace(/LIBRARY_ROOT/i, 'Library')}
          </Typography>
        </Box>
      </Tooltip>
    </Chip>
  );
};

export default memo(FolderBadge, isEqual);
