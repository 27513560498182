import { memo, type ReactNode, type FC } from 'react';
import { createPortal } from 'react-dom';

export interface EditorStatusPortalProps {
  children: ReactNode;
  portalId: string;
}

const EditorStatusPortal: FC<EditorStatusPortalProps> = ({ children, portalId }) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const portalElement = document.getElementById(portalId);
  return portalElement ? createPortal(children, portalElement) : null;
};

export default memo(EditorStatusPortal);
