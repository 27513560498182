import { memo, type PropsWithChildren, useCallback, useEffect } from 'react';
import Tooltip, { type TooltipProps } from '@mui/joy/Tooltip';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import { isEqual } from 'lodash';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import * as valueStore from 'store/nodes/value';
import { type BoxProps, IconButton } from '@mui/joy';
import Icon from 'ui/Icon';
import Typography from '@mui/joy/Typography';
import * as subscriptionStore from 'widgets/Subscription/store';

export interface TutorialStepProps {
  stepIndex: number;
  title: string;
  description: string;
  isLast?: boolean;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  slotProps?: {
    wrapper?: BoxProps;
  };
}

const TutorialStep = (props: PropsWithChildren<TutorialStepProps>) => {
  const { isLast, stepIndex, title, description, placement, slotProps, children } = props;

  const dispatcher = useDispatcher();

  const isAppPrepared = useSelector((state) => state.app.prepared);
  const isPricingShow = useSelector(subscriptionStore.selectors.isPricingShow);
  const currentStepIndex = useSelector(valueStore.selectors.get<number | 'complete'>('tutorialCurrentStep'));
  const lastStepIndex = useSelector(valueStore.selectors.get<number | 'complete'>('tutorialLastIndex'));

  useEffect(() => {
    if (isLast) {
      dispatcher.value.set('tutorialLastIndex', stepIndex);
    }
  }, []);

  const handleNext = useCallback(() => {
    if (!currentStepIndex || currentStepIndex === 'complete') {
      return;
    }
    dispatcher.value.set('tutorialCurrentStep', currentStepIndex + 1);
  }, [currentStepIndex]);

  const handleClose = useCallback(() => {
    dispatcher.value.set('tutorialCurrentStep', 'complete');
    window?.localStorage?.setItem('tutorialStatus', 'complete');
  }, []);

  return (
    <Tooltip
      open={!(stepIndex === currentStepIndex) ? false : isAppPrepared && !isPricingShow}
      arrow
      sx={{ maxWidth: 272 }}
      placement={placement}
      variant="soft"
      title={
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" pl={1.5}>
            <Typography fontSize={12} fontWeight={600}>{`Step ${stepIndex} of ${lastStepIndex}`}</Typography>
            <IconButton size="sm" onClick={handleClose}>
              <Icon name="xmark" />
            </IconButton>
          </Box>
          <Typography position="relative" px={1.5} fontWeight={600} sx={{ color: 'inherit' }}>
            {title}
          </Typography>
          <Typography position="relative" px={1.5} fontSize={13} sx={{ color: 'inherit' }}>
            {description}
          </Typography>
          <Box mb={1} mt={1} px={0.5} display="flex" justifyContent="flex-end" gap={1}>
            {!isLast && (
              <Button variant="solid" size="sm" onClick={handleNext}>
                Next
              </Button>
            )}
            {isLast && (
              <Button variant="solid" size="sm" onClick={handleClose}>
                Finish
              </Button>
            )}
          </Box>
        </>
      }
    >
      <Box {...slotProps?.wrapper}>{children}</Box>
    </Tooltip>
  );
};

export default memo(TutorialStep, isEqual);
