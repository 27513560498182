import { type SagaReturnType, put, cancel, debounce } from 'redux-saga/effects';
import { type UserType } from 'app/entities';

import * as api from 'services/api';

import { select, call } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as userStore from 'store/nodes/user';

import Alert from 'components/Alert';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.loadNextPage.type,
  method: debounce.bind(null, 500),
};

export function* func(action: SagaReturnType<typeof actions.loadNextPage>) {
  const { reload } = action.meta;
  const { text, type } = action.payload;

  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  const response = yield* call(() =>
    api.resource.user.search.items(
      text,
      {
        cursor: nextCursor,
        pageSize: 20,
      },
      {
        type,
      },
    ),
  );
  yield checkUnauthorized(response);

  if (response.error || !response.data) {
    Alert.error(response?.error);
    yield put(actions.loadNextPageDone(reload));
    yield cancel();
    return;
  }

  yield put(userStore.actions.setData({ data: response.data.items as UserType[] }));
  yield put(actions.addPage(response.data.items as UserType[], response.data.paginationInfo, reload));
  yield put(actions.loadNextPageDone(reload));
}
