import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';
import network from 'lib/network';

import { actions } from '../slices/permission';
import sourceUserPermissionToSpaceUserPermission, { type SourceUserPermissionType } from '../../model/sourceUserPermissionToSpaceUserPermission';

export const config = {
  action: actions.update.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.update>) {
  const { spaceId, userId, permissionId } = action.payload;

  if (!spaceId || !userId || !permissionId) {
    yield put(actions.updateDone({ spaceId, userId, permissionId }));
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() =>
    network
      .request<SourceUserPermissionType[]>(`/stack-2/user/team-space/${spaceId}/updatePermission`)
      .body({ user_id: userId, permission_id: permissionId })
      .post(),
  );

  if (!data || errors) {
    Alert.error('Error update permission');
    yield put(actions.updateDone({ spaceId, userId, permissionId }));
    yield cancel();
    return;
  }

  const updatedData = data.find((item) => item.user.id === userId);
  if (!updatedData) {
    Alert.error('Error update permission');
    yield put(actions.updateDone({ spaceId, userId, permissionId }));
    yield cancel();
    return;
  }

  yield put(actions.setUserPermissions({ items: sourceUserPermissionToSpaceUserPermission([updatedData]), mode: 'extend' }));
  yield put(actions.updateDone({ spaceId, userId, permissionId }));
}
