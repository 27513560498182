import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, mergeWith } from 'lodash';

import type { SpaceAvailablePermissionType, SpaceType, SpaceUserPermissionType } from 'app/entities';

import type { SpacePermissionStore } from '../types';

const initialState: SpacePermissionStore = {
  availablePermissions: null,
  userPermissions: {},
  isLoading: false,
  meta: {
    loadingMap: {},
    deletingMap: {},
    loadingPermissionMap: {},
  },
};

const noteSlice = createSlice({
  name: 'space/permission',
  initialState,
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    loadAvailable: (state) => {
      state.isLoading = true;
    },
    loadAvailableDone: (state) => {
      state.isLoading = false;
    },
    setAvailable: (state, action: PayloadAction<{ data: SpaceAvailablePermissionType[] }>) => {
      const { data } = action.payload;
      state.availablePermissions = {};

      data.forEach((permission) => {
        state.availablePermissions![permission.id] = permission;
      });
    },
    loadUserPermissions: (state, action: PayloadAction<{ data: (number | SpaceType)[] }>) => undefined,
    loadUserPermissionsDone: (state, action: PayloadAction<{ ids: number | number[] }>) => undefined,
    setUserPermissions: (state, action: PayloadAction<{ items: SpaceUserPermissionType[]; mode?: 'reset' | 'extend' }>) => {
      const { items, mode } = action.payload;
      items.forEach((item) => {
        const { spaceId } = item;
        if (!state.userPermissions[spaceId] || mode === 'reset') {
          state.userPermissions[spaceId] = [];
        }
      });
      items.forEach((item) => {
        const { spaceId } = item;
        const arr = state.userPermissions[spaceId];
        const idx = arr.findIndex((x) => {
          if (typeof item.user?.id !== 'undefined') {
            return x.user?.id === item.user?.id;
          }
          if (typeof item.user?.email !== 'undefined') {
            return x.user?.email === item.user?.email;
          }
          return false;
        });
        if (idx >= 0) {
          arr[idx] = mergeWith({}, arr[idx], item, (objVal, srcVal) => {
            if (srcVal === undefined) {
              return objVal;
            }
            return undefined;
          });
        } else {
          arr.push(item);
        }
      });
    },
    removeUserPermissions: (
      state,
      action: PayloadAction<{
        keys: { spaceId: number; id: number }[];
      }>,
    ) => {
      const { keys } = action.payload;
      keys.forEach((key) => {
        const { spaceId, id } = key;
        const index = state.userPermissions[spaceId].findIndex((item) => item.id === id);
        if (index === -1) {
          return;
        }
        state.userPermissions[spaceId].splice(index, 1);
      });
    },
    createByEmail: (
      state,
      action: PayloadAction<{
        spaceId: number;
        userEmail: string;
        permissionId: number;
      }>,
    ) => {
      const { spaceId, userEmail } = action.payload;
      const key = `${spaceId}-${userEmail}`;
      state.meta.loadingPermissionMap[key] = true;
    },
    createByEmailDone: (
      state,
      action: PayloadAction<{
        spaceId: number;
        userEmail: string;
        permissionId: number;
      }>,
    ) => {
      const { spaceId, userEmail } = action.payload;
      const key = `${spaceId}-${userEmail}`;
      delete state.meta.loadingPermissionMap[key];
    },
    createById: (
      state,
      action: PayloadAction<{
        spaceId: number;
        userId: number;
        permissionId: number;
      }>,
    ) => {
      const { spaceId, userId } = action.payload;
      const key = `${spaceId}-${userId}`;
      state.meta.loadingPermissionMap[key] = true;
    },
    createByIdDone: (
      state,
      action: PayloadAction<{
        spaceId: number;
        userId: number;
        permissionId: number;
      }>,
    ) => {
      const { spaceId, userId } = action.payload;
      const key = `${spaceId}-${userId}`;
      delete state.meta.loadingPermissionMap[key];
    },
    delete: (
      state,
      action: PayloadAction<{
        spaceId: number;
        id: number;
      }>,
    ) => {
      const { spaceId, id } = action.payload;
      const key = `${spaceId}-${id}`;
      state.meta.loadingPermissionMap[key] = true;
    },
    deleteDone: (
      state,
      action: PayloadAction<{
        spaceId: number;
        id: number;
      }>,
    ) => {
      const { spaceId, id } = action.payload;
      const key = `${spaceId}-${id}`;
      delete state.meta.loadingPermissionMap[key];
    },
    update: (
      state,
      action: PayloadAction<{
        spaceId: number;
        userId: number;
        permissionId: number;
      }>,
    ) => {
      const { userId, spaceId } = action.payload;
      const key = `${spaceId}-${userId}`;
      state.meta.loadingPermissionMap[key] = true;
    },
    updateDone: (
      state,
      action: PayloadAction<{
        spaceId: number;
        userId: number | string;
        permissionId: number;
      }>,
    ) => {
      const { userId, spaceId } = action.payload;
      const key = `${spaceId}-${userId}`;
      delete state.meta.loadingPermissionMap[key];
    },
  },
});

export const { reducer, actions } = noteSlice;
