import { type FC, memo } from 'react';
import { Box, type BoxProps, CircularProgress, Typography } from '@mui/joy';
import { isEqual } from 'lodash';

export interface SkipButtonProps extends BoxProps {
  loading: boolean;
  disabled: boolean;
}

const SkipButton: FC<SkipButtonProps> = (props) => {
  const { onClick, loading, disabled, ...rest } = props;
  return (
    <Box position="relative" display="flex" flexDirection="row" alignItems="center" {...rest}>
      <Typography
        component="span"
        onClick={onClick}
        fontSize={14}
        color={loading || disabled ? 'neutral' : undefined}
        sx={{
          borderBottom: '1px solid currentColor',
          pointerEvents: loading || disabled ? 'none' : 'all',
          cursor: loading || disabled ? 'not-allowed' : 'pointer',
          transition: '100ms border-color ease',
          '&:hover': {
            borderColor: 'transparent',
            transition: '300ms border-color ease',
          },
        }}
      >
        Skip this step
      </Typography>
      <CircularProgress size="sm" color="neutral" sx={{ '--_root-size': '16px', ml: 1, opacity: loading ? 1 : 0 }} />
    </Box>
  );
};

export default memo(SkipButton, isEqual);
