import { useMemo } from 'react';
import { Typography } from '@mui/joy';

import type { ContentErrorType, MaterialType } from 'app/entities';

import { guard } from 'utils';

const useTitleRender = (materialType: string | null | undefined, data?: MaterialType | ContentErrorType | null, hasTitle?: boolean) => {
  return useMemo(() => {
    if (!data || guard.isContentError(data)) {
      return null;
    }
    if (!guard.isMaterial(data)) {
      return null;
    }
    if (hasTitle && materialType === 'image') {
      return (
        <Typography mt={6} mb={2} fontSize={34} fontWeight={500}>
          {data?.originalUrl?.replace(/.*?([^/]+\.(png|jpe?g|gif|webp|tiff?)).*/i, '$1')}
        </Typography>
      );
    }
    if (hasTitle && data?.title) {
      return (
        <Typography mt={2} mb={2} fontSize={34} fontWeight={500}>
          {data?.title}
        </Typography>
      );
    }
    return null;
  }, [hasTitle, (data as any)?.title, (data as any)?.originalUrl, materialType]);
};

export default useTitleRender;
