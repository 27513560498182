import { type SagaReturnType, put, cancel, delay, takeEvery } from 'redux-saga/effects';

import type { MaterialType, CollectionType, RagMaterialType } from 'app/entities';

import { guard } from 'utils';

import { call, select } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as materialStore from 'store/nodes/content';
import * as searchStore from 'store/nodes/search';
import * as collectionStore from 'store/nodes/playlist';
import * as userStore from 'store/nodes/user';

import trackSearchEvent from '../model/trackSearchEvent';
import loadItems from '../model/loadItems';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.loadPage.type, actions.loadNextPage.type],
  method: takeEvery,
};

type ActionType = SagaReturnType<typeof actions.loadPage | typeof actions.loadNextPage>;

function* getParams(action: ActionType) {
  const spaceId = yield* select((state) => state.search.filter.spaceId);
  const reload = action.type === actions.loadPage.type;
  const actionDone = action.type === actions.loadPage.type ? actions.loadPageDone : actions.loadNextPageDone;
  return {
    spaceId,
    reload,
    actionDone,
  };
}

export function* func(action: ActionType) {
  const { spaceId, reload, actionDone } = yield* getParams(action);
  const text = yield* select(selectors.filterText);
  const user = yield* select(userStore.selectors.getMy);

  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  const pagination = {
    cursor: nextCursor || undefined,
    pageSize: user?.type === 'legal' ? 12 : 24,
  };

  const filters = {
    q: text,
  };

  if (reload) {
    yield* trackSearchEvent(text);
  }

  if (!spaceId) {
    yield put(actionDone({ status: 'error' }));
    yield cancel();
    return;
  }

  const { data, error } = yield* call(() => loadItems(spaceId, pagination, filters));
  yield checkUnauthorized({ error });

  if (error || !data) {
    yield put(actionDone({ status: 'error' }));
    yield cancel();
    return;
  }

  const materials: MaterialType[] = [];
  const collections: CollectionType[] = [];
  const ragMaterial: RagMaterialType[] = [];

  data.items.forEach((item) => {
    if (guard.isMaterial(item.element)) {
      materials.push(item.element);
    }
    if (guard.isCollection(item.element)) {
      collections.push(item.element);
    }
    if (guard.isRagMaterial(item.element)) {
      ragMaterial.push(item.element);
    }
  });

  if (materials.length > 0) {
    yield put(materialStore.actions.setItem(materials));
  }
  if (collections.length > 0) {
    yield put(collectionStore.actions.setItem({ data: collections }));
  }

  if (ragMaterial.length > 0) {
    yield put(searchStore.actions.setRagMaterial(ragMaterial));
  }

  yield delay(10);
  yield put(actions.addPage(data.items, data.paginationInfo, reload));
  let newStatus: 'has-results' | 'no-results' | 'nothing-to-search' = 'has-results';
  if (data.items.length === 0 && data.metadata?.isMaterialsExists === false) {
    newStatus = 'nothing-to-search';
  } else if (data.items.length === 0) {
    newStatus = 'no-results';
  }

  yield put(actionDone({ status: newStatus }));
}
