import { type FC, memo } from 'react';
import { Box } from '@mui/joy';

import Icon from 'components/LegacyIcon';

export interface StateIconProps {
  variant: 'add' | 'processing' | 'material' | 'materials';
  size: number | string;
}

const StateIcon: FC<StateIconProps> = (props) => {
  const { variant, size } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width={size} height={size}>
      {variant === 'add' && <Icon name="MenuPlusOutline" />}
      {variant === 'processing' && <Icon name="IkiCoPilot" />}
      {variant === 'material' && <Icon name="Done" />}
      {variant === 'materials' && <Icon name="Done" />}
    </Box>
  );
};

export default memo(StateIcon);
