import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { DefaultRootState } from 'react-redux';
import type { EntityErrorType } from 'app/entities';

interface UseLoadErrorResult {
  hasError: boolean;
  message: string | null;
}

const useLoadError = (spaceId?: number, folderId?: string): UseLoadErrorResult => {
  if (!spaceId) {
    return { hasError: false, message: null };
  }
  const key = `${spaceId}::${folderId || 'root'}`;
  const error:
    | EntityErrorType<'AccessError'>
    | EntityErrorType<'NotFoundError'>
    | EntityErrorType<'UnknownError'>
    | EntityErrorType<'ServerError'>
    | null = useSelector((state: DefaultRootState) => state.spaceResource.errors[key]);

  return useMemo(() => {
    if (!error) {
      return { hasError: false, message: null };
    }
    let message = '';
    switch (error.type) {
      case 'AccessError':
        message = 'You do not have access to this resource.';
        break;
      case 'NotFoundError':
        message = 'The requested resource does not exist.';
        break;
      case 'UnknownError':
        message = 'Unknown error, please try again later.';
        break;
      case 'ServerError':
        message = 'Server error occurred.';
        break;
      default:
        message = 'An error occurred.';
    }
    return { hasError: true, message };
  }, [error]);
};

export default useLoadError;
