import { cancel, put, takeLatest } from 'redux-saga/effects';

import { actions as listActions } from '../slices/list';
import { actions as resourceActions } from '../slices/resource';

export const config = {
  action: listActions.set.type,
  method: takeLatest,
};

export function* func(action: ReturnType<typeof listActions.set>) {
  const { data } = action.payload;
  if (Array.isArray(data)) {
    yield put(
      resourceActions.updateLibraryMap({
        data: data.map((item) => ({
          spaceId: item.id,
          libraryId: item.libraryId,
        })),
      }),
    );
    yield cancel();
    return;
  }
  if (data.id && data.libraryId) {
    yield put(
      resourceActions.updateLibraryMap({
        data: [
          {
            spaceId: data.id,
            libraryId: data.libraryId,
          },
        ],
      }),
    );
  }
}
