import { store } from 'store';

const isEntityIdIsRoot = (spaceId: number, entityId: string) => {
  const state = store.getState();
  const space = state.spaceList.data?.[spaceId];
  if (!space) {
    return null;
  }
  return space.libraryId === entityId;
};

export default isEntityIdIsRoot;
