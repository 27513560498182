import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatcher } from 'store/utils/redux/hooks';
import type { UserType } from 'app/entities';
import { usePerson } from 'hooks';

const useProfileState = () => {
  const { person } = usePerson('User', 'my');
  const dispatcher = useDispatcher();

  useEffect(() => {
    if (!person) {
      return;
    }
    setFormData(person);
  }, [person]);

  const [formData, setFormData] = useState<UserType | null>(person);

  const handleChange = useCallback((event: { target: { name: string; value: string | boolean } }) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...(prevFormData as UserType),
        [name]: value,
      };
      dispatcher.profile.autoSave(updatedFormData);
      return updatedFormData;
    });
  }, []);

  return useMemo(
    () => ({
      formData,
      handleChange,
    }),
    [formData, handleChange],
  );
};

export default useProfileState;
