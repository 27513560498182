import { type FC, memo, useCallback } from 'react';
import MenuButton from 'components/UIBar/elements/MenuButton';
import { Platform, StyleSheet } from 'react-native';

import Icon from 'ui/Icon';

interface ExtensionProps {
  isSlim?: boolean;
}

const Extension: FC<ExtensionProps> = (props) => {
  const { isSlim } = props;

  const handleExtensionChromePress = useCallback(() => {
    if (Platform.OS === 'web' && !!document) {
      const link = document.createElement('a');
      link.setAttribute('href', 'https://chrome.google.com/webstore/detail/ikiai-your-knowledge-hub/bjdlhnaghjcjihjiojhpnimlmfnehbga');
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, []);

  const handleExtensionSafariPress = useCallback(() => {
    console.log('need to implement');
  }, []);

  if (!window?.navigator?.userAgent) {
    return null;
  }

  return (
    <MenuButton
      style={styles.extensionItem}
      onPress={handleExtensionChromePress}
      text="Chrome extension"
      isSlim={isSlim}
      isSelected={false}
      revertSlim
      iconRight={<Icon name="puzzle-piece" fw size="lg" weight="light" color="icon" />}
    />
  );
};

const styles = StyleSheet.create({
  extensionItem: {
    justifyContent: 'space-between',
  },
});

export default memo(Extension);
