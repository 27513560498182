import { type FC, type PropsWithChildren, type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as valueStore from 'store/nodes/value';
import * as subscriptionStore from 'widgets/Subscription/store';

import { useTutorialSteps } from 'hooks';
import Typography from '@mui/joy/Typography';
import Icon from 'ui/Icon';

const NewSpacePromoTooltip: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const currentPlan = useSelector(subscriptionStore.selectors.currentPlan);
  const isAppPrepared = useSelector((state) => state.app.prepared);
  const isPricingShow = useSelector(subscriptionStore.selectors.isPricingShow);
  const state = useSelector(valueStore.selectors.get<string | null>('NewSpacePromoTooltip'));

  const dispatcher = useDispatcher();
  const { init: initTutorialSteps, isCompleted: isTutorialStepsCompleted } = useTutorialSteps();

  const handleClose = useCallback(() => {
    dispatcher.value.set('NewSpacePromoTooltip', 'closed');
    initTutorialSteps();
  }, [initTutorialSteps]);

  useEffect(() => {
    const isStartPromoShown = localStorage.getItem('startPromoShownAt');
    if (state === null && isAppPrepared && currentPlan?.id === '0' && isStartPromoShown && !isPricingShow && !isTutorialStepsCompleted) {
      dispatcher.value.set('NewSpacePromoTooltip', 'opened');
      console.log('REOPEN 1');
    }
    if (state === null && isAppPrepared && !['0', '-1'].includes(currentPlan?.id || '') && !isPricingShow && !isTutorialStepsCompleted) {
      dispatcher.value.set('NewSpacePromoTooltip', 'opened');
      console.log('REOPEN 2');
    }
  }, [isAppPrepared, currentPlan, isPricingShow]);

  const isOpen = useMemo(() => {
    if (state === null) {
      return false;
    }
    if (state === 'closed') {
      return false;
    }
    return isAppPrepared && !isPricingShow;
  }, [state, isAppPrepared, isPricingShow]);

  return (
    <Tooltip
      open={isOpen}
      arrow
      sx={{ maxWidth: 272 }}
      variant="solid"
      color="primary"
      size="sm"
      title={
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" pl={1.5}>
            <Typography position="relative" fontWeight={600} sx={{ color: 'inherit' }}>
              Create a new space
            </Typography>
            <IconButton size="sm" onClick={handleClose} color="primary" variant="solid" sx={{ mb: -0.5 }}>
              <Icon name="xmark" color="inherit" />
            </IconButton>
          </Box>
          <Typography position="relative" px={1.5} fontSize={13} pb={1} sx={{ color: 'inherit' }}>
            for your company and
            <br />
            invite your team to collaborate!
          </Typography>
        </>
      }
      placement="right"
    >
      <Box alignSelf="stretch" display="flex" flexDirection="column">
        {children}
      </Box>
    </Tooltip>
  );
};

export default memo(NewSpacePromoTooltip);
