import {
  type FC,
  type ReactNode,
  type ReactElement,
  type JSXElementConstructor,
  type MouseEvent,
  memo,
  useCallback,
  useMemo,
  cloneElement,
} from 'react';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { Box, type BoxProps, Typography } from '@mui/joy';

import type { AuthorType, UserType } from 'app/entities';
import { guard } from 'utils';
import TextExpand from 'ui/TextExpand';
import Avatar from 'components/Avatar';

export interface AuthorBlockProps extends Omit<BoxProps, 'onPress'> {
  data: AuthorType | UserType;
  createData?: string;
  Component?: ReactNode;
  onPress?: (event: MouseEvent<HTMLElement>) => void;
  variant?: 'regular' | 'slim';
}

const prepareTime = (value: string) => {
  if (value.toLowerCase() === 'a few seconds ago') {
    return 'just now';
  }
  return value;
};

const AuthorBlock: FC<AuthorBlockProps> = (props) => {
  const { data, Component, createData, onPress, variant, style, ...rest } = props;

  const handlePress = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onPress?.(event);
    },
    [onPress],
  );

  const nameFinal = useMemo(() => {
    if (Component) {
      return null;
    }
    let name = '';
    let surname = '';
    if (guard.isLikeUser(data)) {
      name = data.name;
      surname = data.surname;
    }
    if (guard.isAuthor(data) && data?.owner) {
      name = data.owner.name;
      surname = data.owner.surname;
    }
    if (guard.isAuthor(data) && !data?.owner) {
      name = data.name || '';
      surname = data.surname || '';
    }
    if (!name && !surname) {
      return 'Guest';
    }
    const result = [];
    if (name) {
      result.push(name);
    }
    if (surname) {
      result.push(surname);
    }
    return result.join(' ');
  }, [Component, data]);

  const componentProps = useMemo(() => {
    if (!Component) {
      return null;
    }
    return { data };
  }, [Component, data, handlePress]);

  if (variant === 'slim') {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" gap={1} onClick={onPress} {...rest}>
        <Typography fontSize={11}>{nameFinal}</Typography>
        <Avatar user={data} vars={{ AvatarSize: '16px', Background: 2 }} />
      </Box>
    );
  }

  if (onPress) {
    return (
      <Box onClick={handlePress} display="flex" flexDirection="row" alignItems="center" gap={1} {...rest}>
        {!!Component && componentProps && cloneElement(Component as ReactElement<any, string | JSXElementConstructor<any>>, componentProps)}
        {!Component && (
          <>
            <Avatar user={data} vars={{ AvatarSize: '24px', Background: 2 }} />
            <TextExpand
              lines={1}
              style={style}
              slotsProps={{
                typography: {
                  fontSize: 12,
                  fontWeight: 400,
                },
              }}
            >
              {nameFinal}
            </TextExpand>
            {createData && (
              <>
                <Typography fontSize={12} mx={0.25}>
                  •
                </Typography>
                <TextExpand
                  lines={1}
                  style={style}
                  slotsProps={{
                    typography: {
                      fontSize: 12,
                      fontWeight: 400,
                    },
                  }}
                >
                  {prepareTime(moment(createData).fromNow())}
                </TextExpand>
              </>
            )}
          </>
        )}
      </Box>
    );
  }

  return (
    <Box style={style} display="flex" flexDirection="row" alignItems="center">
      {!!Component && componentProps && cloneElement(Component as ReactElement<any, string | JSXElementConstructor<any>>, componentProps)}
      {!Component && (
        <>
          <Avatar user={data} vars={{ AvatarSize: '20px', Background: 2 }} sx={{ marginRight: 10 }} />
          <Typography fontSize={16} fontWeight="bolder">
            {nameFinal}
          </Typography>
          <Typography fontSize={12} mx={0.25}>
            •
          </Typography>
          <Typography fontSize={13}>{prepareTime(moment(createData).fromNow())}</Typography>
        </>
      )}
    </Box>
  );
};

export default memo(AuthorBlock, isEqual);
