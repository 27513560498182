import { useCallback, useMemo } from 'react';

import { useDispatcher } from 'store/utils/redux/hooks';

const useFolderNextPage = (spaceId: number, folderId: string | 'root') => {
  const dispatcher = useDispatcher();

  const handler = useCallback(() => {
    dispatcher.spaceResource.loadPage({ spaceId, folderIds: [folderId], mode: 'next', treeName: 'folder' });
  }, [dispatcher]);

  return useMemo(
    () => ({
      handler,
    }),
    [handler],
  );
};

export default useFolderNextPage;
