import { takeEvery, put, cancel } from 'redux-saga/effects';

import network from 'lib/network';
import { guard } from 'utils';

import { select, call } from 'store/utils/saga/effects';
import * as spaceStore from 'screens/Space/store';

import { actions as resourceActions } from '../slices/resource';
import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

export const config = {
  action: resourceActions.addResource.type,
  method: takeEvery,
};

export function* func(action: ReturnType<typeof resourceActions.addResource>) {
  const { spaceId, entityId, resourceType, resourceId } = action.payload;
  const spaceLibraryId = yield* select(spaceStore.selectors.libraryIdBySpaceId(spaceId));
  if (!spaceLibraryId) {
    yield cancel();
    return;
  }

  const body = {
    teamSpaceId: spaceId,
    teamSpaceFolderId: entityId,
    resourceId,
    resourceType: resourceType.toUpperCase(),
  };
  const { data, hasError } = yield* call(() => network.request<SourceEntityType>('/stack-2/user/team-space/folder/resource').body(body).post());

  if (hasError || !data) {
    yield cancel();
    return;
  }

  const entity = sourceEntityToSpaceEntity(spaceLibraryId, data);
  yield put(spaceStore.slices.spaceResource.actions.add({ entity, position: 'first' }));

  if (guard.isSpaceContent(entity)) {
    yield put(
      spaceStore.slices.spaceRelation.actions.loadMaterialFolders({
        type: resourceType as 'Document' | 'Content',
        id: resourceId,
      }),
    );
  }
}
