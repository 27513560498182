import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as materialStore from 'store/nodes/content';

import { guard } from 'utils';

import type { ResultType } from '../store/types';

const useResultOne = (result?: ResultType | null) => {
  const data = useSelector((state) => {
    if (result?.__typename !== 'ResultOne') {
      return null;
    }
    return materialStore.selectors.dataById(result.content.resourceId)(state);
  });

  return useMemo(() => {
    if (!data || guard.isContentError(data)) {
      return null;
    }
    return data;
  }, [data]);
};

export default useResultOne;
