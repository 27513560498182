import { takeLatest, delay, put, cancel, type SagaReturnType } from 'redux-saga/effects';
import { isEqual } from 'lodash';

import { call, select } from 'store/utils/saga/effects';

import { folderRoute } from '../../model/folderRoute';

import { actions as spaceResourceActions } from '../slices/resource';
import { selectedFilter, loadedFilter } from '../selectors';

export const config = {
  action: [
    spaceResourceActions.enableFilter.type,
    spaceResourceActions.disableFilter.type,
    spaceResourceActions.resetFilter.type,
    spaceResourceActions.applyFilterImmediately.type,
  ],
  method: takeLatest,
};

function* checkHasUpdated() {
  const selected = yield* select(selectedFilter);
  const loaded = yield* select(loadedFilter);
  return !isEqual(new Set(loaded), new Set(selected));
}

export function* func(action: SagaReturnType<typeof spaceResourceActions.applyFilterImmediately> | unknown) {
  if (!(action && typeof action === 'object' && 'type' in action && action.type === spaceResourceActions.applyFilterImmediately.type)) {
    yield delay(2000);
    yield put(spaceResourceActions.applyFilterImmediately());
    yield cancel();
    return;
  }

  yield delay(10);

  const hasUpdated = yield* checkHasUpdated();
  if (!hasUpdated) {
    yield cancel();
    return;
  }

  const { spaceId, folderId } = yield* call(folderRoute);
  if (!folderId) {
    yield cancel();
    return;
  }

  yield put(spaceResourceActions.updateFilterLoaded());
  yield put(spaceResourceActions.loadPage({ spaceId, folderIds: [folderId], treeName: 'folder', mode: 'first' }));
}
