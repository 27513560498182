import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import { Box, type BoxProps } from '@mui/joy';

import FilterSelect from './FilterSelect';
import DisplayModeSelect from './DisplayModeSelect';
import TagsSelect from './TagsSelect';

export interface ActionBarProps extends BoxProps {
  displayMode?: 'grid' | 'list';
  onDisplayModeChange?: (newValue: 'grid' | 'list') => void;
}

const ActionBar: FC<ActionBarProps> = (props) => {
  const { displayMode, onDisplayModeChange, ...rest } = props;
  return (
    <Box display="flex" flexDirection="row" alignSelf="stretch" alignItems="center" gap={1} {...rest}>
      <TagsSelect />
      <Box flex={1} />
      <FilterSelect label="Filter" size="small" />
      <DisplayModeSelect value={displayMode} onChange={onDisplayModeChange} />
      {/* <IconButton onClick={onDisplayModeToggle} size="md"> */}
      {/*  <Icon name={displayMode === 'grid' ? 'list' : 'grid'} weight="light" fw size="lg" /> */}
      {/* </IconButton> */}
    </Box>
  );
};

export default memo(ActionBar, isEqual);
