import { type FC, memo, useCallback } from 'react';
import { Box, type BoxProps, Typography } from '@mui/joy';

import useThemeColor from 'hooks/useThemeColor';
import useAuthor from 'hooks/useAuthor';
import useMaterialFolders from 'screens/Space/model/useMaterialFolders';

import AuthorBlock from 'components/AuthorBlock';
import Tags from 'components/Tags';

import type { ContentErrorType, MaterialType } from 'app/entities';

import { guard } from 'utils';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as spaceStore from 'screens/Space/store';

import { navigate } from 'navigation/methods';

import usePublishedAgo from '../hooks/usePublishedAgo';
import FolderBadge, { type FolderBadgeProps } from './FolderBadge';

interface InfoType extends BoxProps {
  data: MaterialType | ContentErrorType;
}

const Info: FC<InfoType> = (props) => {
  const { data } = props;
  const dispatcher = useDispatcher();

  const { items: folderItems } = useMaterialFolders('Content', data.id);
  const libraryIds = useSelector(spaceStore.selectors.libraryIds);

  const handleFolderClick = useCallback<NonNullable<FolderBadgeProps['onClick']>>(
    (event, context) => {
      const { spaceId, folderId } = context;
      navigate('Space', { spaceId, folderId: libraryIds.includes(folderId) ? 'root' : folderId });
    },
    [libraryIds],
  );

  const handleRemoveClick = useCallback<NonNullable<FolderBadgeProps['onRemove']>>((event, context) => {
    const { resourceId, spaceId, folderId } = context;
    dispatcher.spaceResource.removeByResourceId({ resources: [{ resourceId, spaceId, folderId }] });
  }, []);

  const color = useThemeColor({ dark: '#ffffff', light: '#000000' });

  const author = useAuthor(data);
  const publishedAgo = usePublishedAgo(data);

  if (guard.isEntityError(data)) {
    return null;
  }

  if (guard.isContentError(data)) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.325em',
          alignItems: 'center',
        }}
      >
        {author && <AuthorBlock data={author} />}
        {author && data.source?.name && <span style={{ color }}> &middot; </span>}
        <Typography fontSize={16}>{data.source?.name}</Typography>
        {data.source?.name && data.duration && <span style={{ color }}> &middot; </span>}
        {data.source?.name && <Typography fontSize={16}>{data.duration?.replace(/([0-9]+)(pages?)/, '$1 $2')}</Typography>}
        {(author || data.source?.name || data.duration) && publishedAgo && <span style={{ color }}> &middot; </span>}
        {publishedAgo && <Typography fontSize={16}>{publishedAgo}</Typography>}
      </Box>
      {data?.tags && <Tags data={data} tagsLimit={6} sx={{ marginTop: '0.750em', marginBottom: '1.250em' }} />}
      {folderItems.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '0.325em',
          }}
        >
          <Typography fontSize={17} pb={0.5}>
            In collections:{' '}
          </Typography>
          {folderItems.map((folder) => (
            <FolderBadge key={folder.id} resourceId={data.id} folderId={folder.id} onClick={handleFolderClick} onRemove={handleRemoveClick} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default memo(Info);
