import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRoute } from 'navigation/hooks';

import * as askStore from 'store/nodes/ask';
import * as copilotStore from 'widgets/Copilot/store';

/**
 * Логика:
 * - Если scope = 'research' и экран = 'Ask', то показываем все модели,
 *   кроме тех, что содержат "clode 3.7" в названии.
 * - Во всех других случаях убираем 'o1'.
 */
function useFilteredModels() {
  const scope = useSelector(askStore.selectors.scope);
  const route = useRoute();
  const availableModels = useSelector(copilotStore.selectors.availableModels);

  return useMemo(() => {
    const isAskScreen = route.isScreen('Ask');
    const isResearch = scope === 'research';

    // Если scope="research" и экран "Ask":
    // - скрываем "clode 3.7"
    if (isResearch && isAskScreen) {
      return availableModels.filter((item) => !item.model.includes('Claude-3.7-Sonnet') && !item.model.includes('o1-mini'));
    }

    // Иначе (не research+Ask):
    // - скрываем "o1"
    return availableModels.filter((item) => !item.model.includes('o1-mini'));
  }, [availableModels, scope, route]);
}

export default useFilteredModels;
