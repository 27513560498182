import { cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import network from 'lib/network';
import type { ModelType } from 'widgets/Copilot/store/types';
import * as selectors from 'widgets/Copilot/store/selectors';

import { actions } from '../slice';

export const config = {
  action: actions.loadSelectedModel.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadSelectedModel>) {
  const { data: selectedModel, hasError, errors } = yield* call(() => network.request<ModelType>('/stack-1/user/model').get());
  if (hasError || !selectedModel) {
    yield put(actions.loadSelectedModelDone());
    yield cancel();
    return;
  }

  const availableModels = yield* select(selectors.availableModels);
  if (availableModels.findIndex((item) => item.id === selectedModel.id) > -1) {
    yield put(actions.loadSelectedModelDone({ model: selectedModel }));
    yield cancel();
    return;
  }

  yield put(actions.loadSelectedModelDone({ model: availableModels[0] }));
}
