import { put, call, takeLatest, cancel } from 'redux-saga/effects';

import type { SpaceType } from 'app/entities';
import network, { type ResponseType } from 'lib/network';

import { select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import { actions as listActions } from '../slices/list';
import { actions as permissionActions } from '../slices/permission';
import { actions as structureActions } from '../slices/structure';

export const config = {
  action: listActions.load.type,
  method: takeLatest,
};

export function* func(action: ReturnType<typeof listActions.load>) {
  const user = yield* select(userStore.selectors.getMy);

  const response: ResponseType<SpaceType[]> = yield call(() => {
    if (action.payload?.id) {
      return network
        .request<SpaceType[]>(`/stack-2/user/${user?.id}/team-spaces/${action.payload?.id}`)
        .query({ pageSize: 100 })
        .get()
        .then((res) => {
          const { data, errors, hasError } = res;
          if (hasError) {
            return { data: [], errors, hasError };
          }
          return { data: [data], errors, hasError };
        });
    }
    return network.request<SpaceType[]>(`/stack-2/user/${user?.id}/team-spaces`).query({ pageSize: 100 }).get();
  });

  if (response.hasError || !response.data) {
    console.error('Ошибка при загрузке пространств:', response.errors);
    yield put(listActions.loadDone());
    yield cancel();
    return;
  }

  const spaces = response.data.map(({ id, libraryId, isDefault, ...space }) => ({
    id,
    libraryId: String(libraryId),
    isDefault: isDefault && space.user.id === user?.id,
    ...space,
    __typename: 'Space',
  })) as SpaceType[];

  yield put(permissionActions.loadUserPermissions({ data: spaces }));
  if (!action.payload?.id) {
    yield put(listActions.set({ data: spaces }));
  } else {
    yield put(listActions.set({ data: spaces[0], mode: 'extend' }));
  }
  yield put(listActions.loadDone());

  for (const space of spaces) {
    yield put(structureActions.loadFull({ spaceId: space.id, folderId: 'root' }));
  }
}
