import { type FC, memo } from 'react';
import { Box, type BoxProps } from '@mui/joy';

interface SpinnerProps extends BoxProps {
  size?: number;
}

/**
 * Компонент «спиннер» в виде 4-х кружочков, вращающихся по кругу.
 * Цвет кружочков (text.primary) будет автоматически меняться
 * при смене темы (dark/light) в MUI Joy.
 */
const Spinner: FC<SpinnerProps> = (props) => {
  const { size = 26, sx, ...restProps } = props;
  const circleSize = size / 2;

  return (
    <Box
      {...restProps}
      sx={{
        width: size,
        height: size,
        position: 'relative',
        animation: 'spin 2s ease-in-out infinite',
        '@keyframes spin': {
          '0%': { transform: 'rotate(0deg)' },
          '100%': { transform: 'rotate(360deg)' },
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          width: circleSize,
          height: circleSize,
          borderRadius: '50%',
          position: 'absolute',
          top: 0,
          left: (size - circleSize) / 2, // центрируем
          backgroundColor: 'text.primary',
        }}
      />
      <Box
        sx={{
          width: circleSize,
          height: circleSize,
          borderRadius: '50%',
          position: 'absolute',
          top: (size - circleSize) / 2,
          right: 0,
          backgroundColor: 'text.primary',
        }}
      />
      <Box
        sx={{
          width: circleSize,
          height: circleSize,
          borderRadius: '50%',
          position: 'absolute',
          bottom: 0,
          left: (size - circleSize) / 2,
          backgroundColor: 'text.primary',
        }}
      />
      <Box
        sx={{
          width: circleSize,
          height: circleSize,
          borderRadius: '50%',
          position: 'absolute',
          top: (size - circleSize) / 2,
          left: 0,
          backgroundColor: 'text.primary',
        }}
      />
    </Box>
  );
};

export default memo(Spinner);
