import { type FC, type ReactElement, type MouseEventHandler, memo, useMemo, isValidElement } from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Box } from '@mui/joy';

import Icon from 'ui/Icon';

import controller from './classes/Controller';
import type { BodyTitleType } from './types';

export interface DialogItemProps {
  id: string;
  isVisible: boolean;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
}

const renderTitle = (title: BodyTitleType): ReactElement | string | null => {
  if (!title) {
    return null;
  }
  if (typeof title === 'string') {
    return title;
  }
  if (isValidElement(title)) {
    return title;
  }
  if ('icon' in title) {
    const { icon, content } = title;
    return (
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <Icon {...icon} />
        {content}
      </Box>
    );
  }
  return null;
};

const DialogItem: FC<DialogItemProps> = (props) => {
  const { id, isVisible, onButtonClick } = props;

  const body = useMemo(() => controller.getBody(id), [id]);
  const buttons = useMemo(() => controller.getButtons(id), [id]);

  if (!body) return null;

  return (
    <Modal open={isVisible}>
      <ModalDialog variant="outlined" role="alertdialog" sx={{ '--Card-radius': '0.875rem' }}>
        <DialogTitle>{renderTitle(body.title)}</DialogTitle>
        <Divider />
        <DialogContent>{body.content}</DialogContent>
        <DialogActions>
          {Array.isArray(buttons) &&
            buttons.map(({ label, handleId, ...buttonProps }) => (
              <Button key={label} {...buttonProps} itemID={JSON.stringify({ dialogId: id, handleId })} onClick={onButtonClick}>
                {label}
              </Button>
            ))}
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(DialogItem);
