import type { SpaceMaterialFoldersType } from 'app/entities';

export interface SourceMaterialFoldersType {
  spaceFolderId: number;
  teamSpaceId: number;
  title: string;
}

function convertMaterialFolder(source: SourceMaterialFoldersType): SpaceMaterialFoldersType {
  return {
    id: String(source.spaceFolderId),
    spaceId: source.teamSpaceId,
    title: source.title,
  } as SpaceMaterialFoldersType;
}

function sourceMaterialFolderToSpaceMaterialFolder<T extends SourceMaterialFoldersType | SourceMaterialFoldersType[]>(
  data: T,
): T extends SourceMaterialFoldersType[] ? SpaceMaterialFoldersType[] : SpaceMaterialFoldersType {
  if (Array.isArray(data)) {
    return data.map((item) => convertMaterialFolder(item)) as T extends SourceMaterialFoldersType[]
      ? SpaceMaterialFoldersType[]
      : SpaceMaterialFoldersType;
  }
  return convertMaterialFolder(data) as T extends SourceMaterialFoldersType[] ? SpaceMaterialFoldersType[] : SpaceMaterialFoldersType;
}

export default sourceMaterialFolderToSpaceMaterialFolder;
