import { type SagaReturnType, takeEvery, cancel, put, take, all } from 'redux-saga/effects';
import moment from 'moment-timezone';

import network from 'lib/network';

import { call as gCall, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as modalStore from 'widgets/modals/store';
import { getRoute, navigate } from 'navigation/methods';

import { actions } from '../slice';

type MatchResult = {
  status: 'invalid_code' | 'exists_stripe' | 'exists_appsumo' | 'already_matched' | 'success';
};

export const config = {
  action: [actions.processAppsumo.type, actions.matchAppsumo.type, actions.replaceTo.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.processAppsumo | typeof actions.matchAppsumo | typeof actions.replaceTo>) {
  if (action.type === actions.processAppsumo.type) {
    yield handleProcess();
    yield cancel();
    return;
  }
  if (action.type === actions.matchAppsumo.type) {
    yield handleMatch();
    yield cancel();
    return;
  }
  if (action.type === actions.replaceTo.type && action.payload.target === 'AppSumo') {
    yield replace(action.payload.context);
  }
}

function* setCode(code: string | null) {
  yield gCall(() => {
    localStorage.setItem(
      'appsumo',
      JSON.stringify({
        code,
        createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      }),
    );
  });
}

function* getCode() {
  const route = getRoute<any>();
  if (!route.isScreen('Auth/WithAppSumo') && route.params?.code) {
    return {};
  }
  if (route.params?.code) {
    return {
      code: route.params?.code as string,
      source: 'params',
    };
  }
  const storedValue = yield* gCall(() => localStorage.getItem('appsumo'));
  if (!storedValue) {
    return {};
  }
  let value: string | null = null;
  let createdAt: string | null = null;
  try {
    const parsed = yield* gCall(() => JSON.parse(storedValue));
    value = parsed?.code;
    createdAt = parsed?.createdAt;
  } catch {
    return {};
  }
  if (!value || !createdAt) {
    return {};
  }

  const now = moment();
  const savedAt = moment(createdAt);

  if (now.diff(savedAt, 'minutes') > 10) {
    yield gCall(() => localStorage.removeItem('appsumo'));
    return {};
  }

  return {
    code: value as string,
    source: 'store',
  };
}

function* clearCode() {
  yield gCall(() => localStorage.removeItem('appsumo'));
}

function* handleProcess() {
  const { code, source } = yield* getCode();
  if (!code) {
    yield put(actions.processAppsumoDone());
    return;
  }
  if (source === 'params') {
    yield setCode(code);
    yield* gCall(() => navigate('Auth/WithAppSumo', {}, { replace: true }));
    yield put(actions.processAppsumoDone());
    return;
  }
  const user = yield* select(userStore.selectors.getMy);
  if (user && code) {
    yield put(actions.matchAppsumo());
    return;
  }
  yield put(actions.processAppsumoDone());
}

function* handleMatch() {
  const { code } = yield* getCode();
  if (!code) {
    yield put(actions.processAppsumoDone());
    return;
  }

  yield clearCode();
  const { data, hasError } = yield* gCall(() => network.request<MatchResult>('/stack-1/user/match').query({ appsumoCode: code }).post());

  if (hasError) {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'error' }));
    yield put(actions.processAppsumoDone());
    return;
  }

  if (data!.status === 'invalid_code') {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'invalid_code' }));
    yield put(actions.processAppsumoDone());
    return;
  }

  if (data!.status === 'exists_stripe') {
    yield put(
      modalStore.actions.open('AppSumoMatch', {
        status: 'exists_stripe',
        context: { code },
      }),
    );
    yield put(actions.processAppsumoDone());
    return;
  }

  if (data!.status === 'exists_appsumo') {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'exists_appsumo' }));
    yield put(actions.processAppsumoDone());
    return;
  }

  if (data!.status === 'already_matched') {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'already_matched' }));
    yield put(actions.processAppsumoDone());
    return;
  }

  if (data!.status === 'success') {
    yield put(userStore.actions.loadMe());
    yield put(actions.loadState());
    yield all([take(actions.loadStateDone.type), take(userStore.actions.loadMeDone.type)]);
    yield put(actions.processAppsumoDone());
  }
}

function* replace(context?: Record<string, any>) {
  if (!context || !('code' in context)) {
    return;
  }
  const { code } = context;
  const { data, hasError } = yield* gCall(() =>
    network.request<MatchResult>('/stack-1/user/switch_subscription').query({ appsumoCode: code }).post(),
  );

  if (hasError) {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'error' }));
    return;
  }

  if (data!.status === 'invalid_code') {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'invalid_code' }));
    return;
  }

  if (data!.status === 'success') {
    yield put(actions.loadState());
  }
}
