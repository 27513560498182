import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidV4 } from 'uuid';
import { cloneDeep, mergeWith, isEqual } from 'lodash';

import type {
  SpaceType,
  SpaceContentType,
  SpaceEntityType,
  SpaceEntityKey,
  SpaceFolderType,
  ImageInput,
  SpaceEntityId,
  EntityErrorType,
  FilterItemType,
} from 'app/entities';

import type { SpaceResourceStore } from '../types';

const initialState: SpaceResourceStore = {
  data: {},
  errors: {},
  map: {},
  treeStructure: {},
  treeFolder: {},
  libraryMap: {},
  loading: {},
  pagination: {},
  filter: {
    available: [],
    selected: [],
    loaded: [],
    loading: null,
  },
};

const resourceSlice = createSlice({
  name: 'space/resource',
  initialState,
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },

    updateLibraryMap: (state, action: PayloadAction<{ data: { spaceId: number; libraryId: string }[] }>) => {
      action.payload.data.forEach((item) => {
        state.libraryMap[item.spaceId] = item.libraryId;
      });
    },

    loadFolder: (state, action: PayloadAction<{ folderId: SpaceContentType['parentId'] }>) => undefined,

    loadFolderDone: (state, action: PayloadAction<{ folderId: SpaceContentType['parentId'] }>) => undefined,

    loadPage: (
      state,
      action: PayloadAction<{
        spaceId: SpaceType['id'];
        folderIds: SpaceContentType['parentId'][];
        treeName: 'structure' | 'folder';
        mode?: 'first' | 'next';
      }>,
    ) => {
      const { spaceId, folderIds, treeName, mode } = action.payload;

      if (!state.loading[spaceId]) {
        state.loading[spaceId] = {};
      }

      state.errors = {};

      folderIds.forEach((parentId) => {
        const parentKey = parentId || 'root';
        state.loading[spaceId][parentKey] = true;
        if (mode === 'first' && state.pagination[spaceId]?.[parentKey]) {
          delete state.pagination[spaceId][parentKey];
        }
      });
    },

    loadPageError: (
      state,
      action: PayloadAction<{
        spaceId: SpaceType['id'];
        parentId: string;
        error: EntityErrorType<'AccessError'> | EntityErrorType<'NotFoundError'> | EntityErrorType<'UnknownError'> | EntityErrorType<'ServerError'>;
      }>,
    ) => {
      const { spaceId, parentId, error } = action.payload;
      const key = `${spaceId}::${parentId}`;
      state.errors[key] = error;
      if (state.loading[spaceId]) {
        delete state.loading[spaceId][parentId];
      }
    },

    loadPageDone: (
      state,
      action: PayloadAction<{
        spaceId: SpaceType['id'];
        folderIds: SpaceEntityType['parentId'][];
        treeName: 'structure' | 'folder';
        mode?: 'first' | 'next';
      }>,
    ) => {
      const { spaceId, folderIds } = action.payload;

      if (!state.loading[spaceId]) {
        state.loading[spaceId] = {};
      }

      folderIds.forEach((parentId) => {
        const parentKey = parentId || 'root';
        delete state.loading[spaceId][parentKey];
      });
    },

    set: (
      state,
      action: PayloadAction<{ data: SpaceEntityType[]; mode?: 'reset' } | { id: SpaceEntityId; data: Partial<SpaceEntityType>; mode: 'extend' }>,
    ) => {
      const { payload } = action;

      if (!state.data) {
        state.data = {};
      }

      if (!payload.mode || payload.mode === 'reset') {
        state.data = {};
        payload.data.forEach((entity) => {
          state.data![entity.id] = {
            ...entity,
            parentId: entity.parentId || 'root',
          };
        });
      }
      if (payload.mode === 'extend') {
        const { id } = payload;
        state.data![id] = mergeWith({}, state.data![id], payload.data, (oldVal, newVal, propertyName) => {
          if (propertyName === 'parentId') {
            return typeof newVal !== 'undefined' ? newVal || 'root' : oldVal;
          }
          if (propertyName === 'contentImages') {
            return newVal || oldVal;
          }
          if (newVal === undefined) {
            return oldVal;
          }
          return undefined;
        });
      }
    },

    setPage: (
      state,
      action: PayloadAction<{
        spaceId: number;
        folderIds: string[];
        data: SpaceEntityType[];
        treeName: 'structure' | 'folder';
        mode?: 'first' | 'next';
      }>,
    ) => {
      const { folderIds, data, mode, treeName } = action.payload;

      const currentTree = treeName === 'structure' ? state.treeStructure : state.treeFolder;
      const cleared = new Set<string>();

      if (mode === 'first') {
        folderIds.forEach((folderId) => {
          const parentKey = folderId || 'root';
          if (!currentTree[action.payload.spaceId]) {
            currentTree[action.payload.spaceId] = {};
          }
          currentTree[action.payload.spaceId][parentKey] = [];
        });
      }

      data.forEach((resource) => {
        const { id, spaceId, parentId, __typename } = resource;
        const parentKey = parentId || 'root';
        const comboKey = `${spaceId}::${parentKey}`;

        if (!currentTree[spaceId]) {
          currentTree[spaceId] = {};
        }
        if (!currentTree[spaceId][parentKey]) {
          currentTree[spaceId][parentKey] = [];
        }

        if (mode === 'first' && !cleared.has(comboKey)) {
          currentTree[spaceId][parentKey] = [];
          cleared.add(comboKey);
        }

        state.data[id] = resource;
        state.map[id] = {
          spaceId,
          parentId: parentKey,
        };

        const childrenArr = currentTree[spaceId][parentKey];
        if (childrenArr.findIndex((item) => item.id === id) === -1) {
          childrenArr.push({
            id,
            __typename,
          });
        }
      });
    },

    setPagination: (
      state,
      action: PayloadAction<{
        spaceId: SpaceType['id'];
        folderId: SpaceContentType['parentId'];
        cursor: string | null;
        hasNext: boolean;
      }>,
    ) => {
      const { spaceId, folderId = 'root', cursor, hasNext } = action.payload;
      if (!state.pagination[spaceId]) {
        state.pagination[spaceId] = {};
      }
      state.pagination[spaceId][folderId] = { cursor, hasNext };
    },

    add: (
      state,
      action: PayloadAction<{
        entity: SpaceEntityType;
        position?: 'first' | 'last' | number;
      }>,
    ) => {
      const { entity, position } = action.payload;
      const { id, spaceId, parentId, __typename } = entity;
      const parentKey = parentId || 'root';
      const resolvedPosition = position !== undefined ? position : entity.position;

      const existingData = state.data[id];
      if (!existingData || !isEqual(existingData, entity)) {
        state.data[id] = entity;
      }

      const existingMap = state.map[id];
      if (existingMap && existingMap.spaceId === spaceId && existingMap.parentId === parentKey) {
        return;
      }

      state.map[id] = { spaceId, parentId: parentKey };

      const insertIntoTree = (tree: Record<number, Record<string, Array<SpaceEntityKey>>>) => {
        if (!tree[spaceId]) {
          tree[spaceId] = {};
        }
        if (!tree[spaceId][parentKey]) {
          tree[spaceId][parentKey] = [];
        }
        const arr = tree[spaceId][parentKey];

        if (resolvedPosition === 'first') {
          arr.unshift({ id, __typename });
        } else if (resolvedPosition === 'last') {
          arr.push({ id, __typename });
        } else if (typeof resolvedPosition === 'number') {
          const index = Math.max(0, Math.min(resolvedPosition, arr.length));
          arr.splice(index, 0, { id, __typename });
        } else {
          arr.push({ id, __typename });
        }
      };

      if (__typename === 'Folder') {
        insertIntoTree(state.treeStructure);
        insertIntoTree(state.treeFolder);
      } else {
        insertIntoTree(state.treeFolder);
      }

      if (parentId) {
        const parentFolder = state.data[state.libraryMap[spaceId] || parentId];
        if (parentFolder && parentFolder.__typename === 'Folder') {
          parentFolder.childrenCount = (parentFolder.childrenCount || 0) + 1;
        }
      }
    },

    clear: (state, action: PayloadAction<{ resourceIds: SpaceEntityType['id'][]; treeName?: 'structure' | 'folder' }>) => {
      const { resourceIds, treeName = 'folder' } = action.payload;
      const currentTree = treeName === 'structure' ? state.treeStructure : state.treeFolder;

      resourceIds.forEach((resourceId) => {
        const resource = state.data[resourceId];
        if (!resource) {
          return;
        }
        if (resource.__typename !== 'Folder') {
          return;
        }

        const { spaceId, id: currentId } = resource;
        if (!currentTree[spaceId]) {
          return;
        }

        currentTree[spaceId][currentId] = [];

        if (state.loading[spaceId]) {
          state.loading[spaceId][currentId] = false;
        }
      });
    },

    removeByResourceId: (state, action: PayloadAction<{ resources: { spaceId: number; folderId: string; resourceId: number }[] }>) => undefined,

    remove: (state, action: PayloadAction<{ entityIds: string[] }>) => undefined,

    removeApply: (state, action: PayloadAction<{ entityIds: string[] }>) => {
      const { entityIds } = action.payload;
      const deletedResources = new Set<SpaceContentType['id']>();

      const deleteResourceAndChildren = (id: SpaceContentType['id']) => {
        if (deletedResources.has(id)) return;
        deletedResources.add(id);

        const resourceInfo = state.map[id];
        if (!resourceInfo) {
          return;
        }

        const resource = state.data[id];
        if (!resource) {
          return;
        }

        const { spaceId, parentId } = resourceInfo;
        const parentKey = parentId || 'root';

        [state.treeStructure, state.treeFolder].forEach((someTree) => {
          const parentArray = someTree[spaceId]?.[parentKey];
          if (parentArray) {
            const index = parentArray.findIndex((item) => item.id === id);
            if (index !== -1) {
              parentArray.splice(index, 1);
            }
          }
        });

        if (parentId) {
          const parentFolder = state.data[state.libraryMap[spaceId] || parentId];
          if (parentFolder && parentFolder.__typename === 'Folder') {
            parentFolder.childrenCount = Math.max(0, (parentFolder.childrenCount || 0) - 1);
          }
        }

        delete state.data[id];
        delete state.map[id];
        if (state.loading[spaceId]) {
          delete state.loading[spaceId][id];
        }

        [state.treeStructure, state.treeFolder].forEach((someTree) => {
          const children = someTree[spaceId]?.[id];
          if (children && children.length > 0) {
            const childItems = [...children];
            for (const child of childItems) {
              deleteResourceAndChildren(child.id);
            }
            children.splice(0, children.length);
          }
        });
      };

      for (const entityId of entityIds) {
        deleteResourceAndChildren(entityId);
      }
    },

    removeDone: (state, action: PayloadAction<{ entityIds: SpaceContentType['id'][] }>) => undefined,

    moveToFolder: (
      state,
      action: PayloadAction<{
        entityIds: string[];
        newPosition: {
          spaceId: number;
          parentId: string;
          to: 'start' | 'end';
        };
      }>,
    ) => {
      const { entityIds, newPosition } = action.payload;
      const { spaceId: newSpaceId, parentId: newParentId, to } = newPosition;

      const touchedOldParents = new Set<string>();
      const touchedNewParents = new Set<string>();

      const removedCountByFolder: Record<string, number> = {};
      const addedCountByFolder: Record<string, number> = {};

      const removeFromParent = (tree: Record<number, Record<string, Array<SpaceEntityKey>>>, spId: number, pId: string, entityId: string) => {
        const parentArr = tree[spId]?.[pId];
        if (!parentArr) return;

        const idx = parentArr.findIndex((item) => item.id === entityId);
        if (idx >= 0) {
          parentArr.splice(idx, 1);
        }
      };

      const reindexPositions = (tree: Record<number, Record<string, Array<SpaceEntityKey>>>, spId: number, pId: string) => {
        const parentArr = tree[spId]?.[pId];
        if (!parentArr) return;

        parentArr.forEach((child, idx) => {
          state.data[child.id].position = idx;
        });
      };

      const movingItems: SpaceEntityKey[] = [];

      for (const entityId of entityIds) {
        const oldInfo = state.map[entityId];
        if (!oldInfo) {
          continue;
        }

        const { spaceId: oldSpaceId, parentId: oldParentId } = oldInfo;
        const resource = state.data[entityId];
        if (!resource) {
          continue;
        }

        const { __typename } = resource;

        removeFromParent(state.treeFolder, oldSpaceId, oldParentId, entityId);
        touchedOldParents.add(`${oldSpaceId}::${oldParentId}::folder`);

        if (__typename === 'Folder') {
          removeFromParent(state.treeStructure, oldSpaceId, oldParentId, entityId);
          touchedOldParents.add(`${oldSpaceId}::${oldParentId}::structure`);
        }

        state.map[entityId] = {
          spaceId: newSpaceId,
          parentId: newParentId,
        };
        resource.spaceId = newSpaceId;
        resource.parentId = newParentId;

        movingItems.push({
          id: entityId,
          __typename: resource.__typename,
        });

        if (oldParentId) {
          const oldParentFolder = state.data[state.libraryMap[oldSpaceId] || oldParentId];
          if (oldParentFolder?.__typename === 'Folder') {
            removedCountByFolder[oldParentId] = (removedCountByFolder[oldParentId] || 0) + 1;
          }
        }
      }

      if (!state.treeFolder[newSpaceId]) {
        state.treeFolder[newSpaceId] = {};
      }
      if (!state.treeFolder[newSpaceId][newParentId]) {
        state.treeFolder[newSpaceId][newParentId] = [];
      }
      const newFolderArr = state.treeFolder[newSpaceId][newParentId];
      touchedNewParents.add(`${newSpaceId}::${newParentId}::folder`);

      if (!state.treeStructure[newSpaceId]) {
        state.treeStructure[newSpaceId] = {};
      }
      if (!state.treeStructure[newSpaceId][newParentId]) {
        state.treeStructure[newSpaceId][newParentId] = [];
      }
      const newStructureArr = state.treeStructure[newSpaceId][newParentId];

      for (const item of movingItems) {
        if (to === 'start') {
          newFolderArr.unshift(item);
        } else {
          newFolderArr.push(item);
        }

        if (item.__typename === 'Folder') {
          if (to === 'start') {
            newStructureArr.unshift(item);
          } else {
            newStructureArr.push(item);
          }
        }
      }
      touchedNewParents.add(`${newSpaceId}::${newParentId}::structure`);

      if (newParentId) {
        const newParentFolder = state.data[state.libraryMap[newSpaceId] || newParentId];
        if (newParentFolder?.__typename === 'Folder') {
          addedCountByFolder[newParentId] = (addedCountByFolder[newParentId] || 0) + movingItems.length;
        }
      }

      touchedOldParents.forEach((combo) => {
        const [spIdStr, pId, treeName] = combo.split('::');
        const spId = Number(spIdStr);
        if (treeName === 'folder') {
          reindexPositions(state.treeFolder, spId, pId);
        } else {
          reindexPositions(state.treeStructure, spId, pId);
        }
      });

      touchedNewParents.forEach((combo) => {
        const [spIdStr, pId, treeName] = combo.split('::');
        const spId = Number(spIdStr);
        if (treeName === 'folder') {
          reindexPositions(state.treeFolder, spId, pId);
        } else {
          reindexPositions(state.treeStructure, spId, pId);
        }
      });

      Object.entries(removedCountByFolder).forEach(([folderId, count]) => {
        const folderRes = state.data[folderId];
        if (!folderRes || folderRes.__typename !== 'Folder') return;
        const oldValue = folderRes.childrenCount ?? 0;
        folderRes.childrenCount = Math.max(0, oldValue - count);
      });
      Object.entries(addedCountByFolder).forEach(([folderId, count]) => {
        const folderRes = state.data[folderId];
        if (!folderRes || folderRes.__typename !== 'Folder') return;
        const oldValue = folderRes.childrenCount ?? 0;
        folderRes.childrenCount = oldValue + count;
      });
    },

    moveToFolderDone: (_, action: PayloadAction<{ entityIds: string[] }>) => undefined,

    updatePosition: (
      state,
      action: PayloadAction<{
        entityId: string;
        newPosition: { spaceId: number; parentId: string; index: number };
        treeName: 'structure' | 'folder';
      }>,
    ) => {
      const { entityId, newPosition, treeName } = action.payload;
      const { spaceId: newSpaceId, parentId: newParentId, index } = newPosition;

      const currentTree = treeName === 'structure' ? state.treeStructure : state.treeFolder;

      const oldInfo = state.map[entityId];
      if (!oldInfo) {
        return;
      }

      const { spaceId: oldSpaceId, parentId: oldParentId } = oldInfo;
      const oldArr = currentTree[oldSpaceId]?.[oldParentId];
      if (oldArr) {
        const oldIndex = oldArr.findIndex((item) => String(item.id) === String(entityId));
        if (oldIndex !== -1) {
          oldArr.splice(oldIndex, 1);
          oldArr.forEach((child, idx) => {
            state.data[child.id].position = idx - (newParentId ? -1 : 0);
          });
        }
      }

      state.map[entityId] = { spaceId: newSpaceId, parentId: newParentId };

      if (!currentTree[newSpaceId]) {
        currentTree[newSpaceId] = {};
      }
      if (!currentTree[newSpaceId][newParentId]) {
        currentTree[newSpaceId][newParentId] = [];
      }

      const newArr = currentTree[newSpaceId][newParentId];
      const clampedIndex = Math.max(0, Math.min(index, newArr.length));
      newArr.splice(clampedIndex, 0, {
        id: entityId,
        __typename: state.data[entityId].__typename,
      });

      newArr.forEach((child, idx) => {
        state.data[child.id].position = idx;
      });
    },

    updatePositionDone: (
      state,
      action: PayloadAction<{
        entityId: string;
      }>,
    ) => undefined,

    addResource: (state, action: PayloadAction<{ spaceId: number; entityId: string; resourceType: string; resourceId: number }>) => undefined,

    addResourceDone: (state, action: PayloadAction<{ spaceId: number; entityId: string; resourceType: string; resourceId: number }>) => undefined,

    createFolder: (
      state,
      action: PayloadAction<{
        spaceId: number;
        parentId: string;
        position?: 'first' | 'last' | number;
        markerId?: string;
      }>,
    ) => {
      const { spaceId, parentId, position = 'last', markerId = uuidV4() } = action.payload;
      state.data[markerId] = {
        id: markerId,
        spaceId,
        parentId,
        __typename: 'Folder',
        title: '',
        childrenCount: 0,
      } as any;
      state.map[markerId] = {
        spaceId,
        parentId,
      };
      if (!state.treeStructure[spaceId]) {
        state.treeStructure[spaceId] = {};
      }
      const pKey = parentId || 'root';
      if (!state.treeStructure[spaceId][pKey]) {
        state.treeStructure[spaceId][pKey] = [];
      }
      const arr = state.treeStructure[spaceId][pKey];

      if (position === 'first') {
        arr.unshift({ id: markerId, __typename: 'Folder' });
      } else if (position === 'last') {
        arr.push({ id: markerId, __typename: 'Folder' });
      } else if (typeof position === 'number') {
        const idx = Math.max(0, Math.min(position, arr.length));
        arr.splice(idx, 0, { id: markerId, __typename: 'Folder' });
      } else {
        arr.push({ id: markerId, __typename: 'Folder' });
      }
    },

    updateFolder: (
      state,
      action: PayloadAction<{
        id: SpaceFolderType['id'];
        data: Partial<Pick<SpaceFolderType, 'title' | 'description' | 'isPrivate'> & { cover: ImageInput }>;
      }>,
    ) => undefined,

    updateFolderDone: (
      state,
      action: PayloadAction<{
        id: SpaceFolderType['id'];
      }>,
    ) => undefined,

    cancelCreateFolderAll: (
      state,
      action: PayloadAction<{
        markerIdPattern: RegExp;
      }>,
    ) => {
      const { markerIdPattern } = action.payload;
      Object.keys(state.map).forEach((entityId) => {
        if (!markerIdPattern.test(entityId)) {
          return;
        }
        const { spaceId, parentId } = state.map[entityId];
        const children = state.treeStructure[spaceId]?.[parentId];
        const targetId = children?.findIndex((item) => item.id === entityId);
        if (targetId > -1) {
          children.splice(targetId, 1);
        }
        delete state.data[entityId];
        delete state.map[entityId];
      });
    },

    cancelCreateFolder: (
      state,
      action: PayloadAction<{
        markerId: string;
      }>,
    ) => {
      const { markerId } = action.payload;
      const { spaceId, parentId } = state.map[markerId];
      const parentArr = state.treeStructure[spaceId]?.[parentId];
      if (parentArr) {
        const idx = parentArr.findIndex((item) => item.id === markerId);
        if (idx >= 0) {
          parentArr.splice(idx, 1);
        }
      }
      delete state.data[markerId];
      delete state.map[markerId];
    },

    confirmCreateFolder: (
      state,
      action: PayloadAction<{
        markerId: string;
        title: string; // введённое пользователем имя
      }>,
    ) => undefined,

    createFolderDone: (
      state,
      action: PayloadAction<{
        markerId: string;
        data: SpaceEntityType;
      }>,
    ) => {
      const { markerId, data } = action.payload;
      const realId = String(data.id);

      const { spaceId, parentId } = state.map[markerId];
      const arr = state.treeStructure[spaceId]?.[parentId];
      if (arr) {
        const idx = arr.findIndex((item) => item.id === markerId);
        if (idx >= 0) {
          arr.splice(idx, 1);
        }
      }
      delete state.data[markerId];
      delete state.map[markerId];

      state.data[realId] = data;
      state.map[realId] = {
        spaceId: data.spaceId,
        parentId: data.parentId || 'root',
      };
      const pKey = data.parentId || 'root';

      if (!state.treeStructure[data.spaceId]) {
        state.treeStructure[data.spaceId] = {};
      }
      if (!state.treeStructure[data.spaceId][pKey]) {
        state.treeStructure[data.spaceId][pKey] = [];
      }
      state.treeStructure[data.spaceId][pKey].push({
        id: realId,
        __typename: 'Folder',
      });

      if (!state.treeFolder[data.spaceId]) {
        state.treeFolder[data.spaceId] = {};
      }
      if (!state.treeFolder[data.spaceId][pKey]) {
        state.treeFolder[data.spaceId][pKey] = [];
      }
      state.treeFolder[data.spaceId][pKey].push({
        id: realId,
        __typename: 'Folder',
      });
    },
    loadAvailableFilters: () => undefined,
    loadAvailableFiltersDone: () => undefined,
    setAvailableFilters: (
      state,
      action: PayloadAction<{
        data: FilterItemType[];
      }>,
    ) => {
      state.filter.available = action.payload.data;
    },
    enableFilter: (
      state,
      action: PayloadAction<{
        id: string;
      }>,
    ) => {
      const { id } = action.payload;
      if (!state.filter.selected.includes(id)) {
        state.filter.selected.push(id);
      }
    },
    disableFilter: (
      state,
      action: PayloadAction<{
        id: string;
      }>,
    ) => {
      const { id } = action.payload;
      const selectedIndex = state.filter.selected.findIndex((item) => item === id);
      if (selectedIndex > -1) {
        state.filter.selected.splice(selectedIndex, 1);
      }
    },
    resetFilter: (state) => {
      state.filter.selected.splice(0);
    },
    applyFilterImmediately: () => undefined,
    updateFilterLoaded: (state) => {
      state.filter.loaded.splice(0);
      state.filter.loaded.push(...state.filter.selected);
    },
  },
});

export const { reducer, actions } = resourceSlice;
