import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/joy';

import { useRoute } from 'navigation/hooks';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import { usePerson, useGetShare } from 'hooks';

import Icon from 'ui/Icon';

const ActionsBar = () => {
  const route = useRoute<'Note'>();
  const { id } = route.params;

  const dispatcher = useDispatcher();

  const { person } = usePerson('User', 'my');
  const data = useSelector(noteStore.selectors.dataById(Number(id)));
  const share = useGetShare();
  const [changingPrivate, setChangingPrivate] = useState(false);

  useEffect(() => {
    setChangingPrivate(false);
  }, [data?.private]);

  const handleAdd = useCallback(() => {
    if (!data?.id) {
      return;
    }
    dispatcher.note.add({
      html: data.text,
    });
  }, [data?.id, data?.text]);

  const handleRemove = useCallback(() => {
    if (!data?.id) {
      return;
    }
    dispatcher.library.remove({
      resourceType: 'note',
      resourceId: data.id,
      onSuccessNavigate: { name: 'Library', params: { interaction: 'all' } },
    });
  }, [data?.id]);

  const handlePrivacyChange = useCallback(() => {
    if (!data?.id) {
      return;
    }
    setChangingPrivate(true);
    dispatcher.note.update({
      id: data.id,
      html: data.text,
      isPrivate: !data.private,
    });
  }, [data?.id, data?.text, data?.private]);

  const handleShare = useCallback(() => {
    share(data);
  }, [share, data]);

  return (
    <Box display="flex" flexDirection="row" alignSelf="stretch" justifyContent="center" gap={1.5}>
      <Tooltip arrow variant="outlined" size="sm" title="Share note">
        <IconButton size="sm" color="neutral" variant="soft" disabled={!data} onClick={handleShare}>
          <Icon name="link" fw weight="light" color="primary" />
        </IconButton>
      </Tooltip>
      {(!person || (data && !data?.isMy)) && (
        <Tooltip disableInteractive arrow variant="outlined" size="sm" title="Add note to library">
          <IconButton size="sm" color="neutral" variant="soft" disabled={!data} onClick={handleAdd}>
            <Icon name="bookmark" fw weight={!data?.isBookmarked ? 'light' : 'solid'} color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {person && data && data?.isMy && (
        <>
          <Tooltip disableInteractive arrow variant="outlined" size="sm" title="Delete">
            <IconButton size="sm" color="neutral" variant="soft" disabled={!data} onClick={handleRemove}>
              <Icon name="trash" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip disableInteractive arrow variant="outlined" size="sm" title={data?.private ? 'Make public' : 'Make private'}>
            <IconButton
              size="sm"
              color={data?.private ? 'neutral' : 'danger'}
              variant="soft"
              disabled={!data || changingPrivate}
              onClick={handlePrivacyChange}
            >
              <Icon name={data?.private ? 'lock' : 'lock-open'} fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default memo(ActionsBar);
