import { useRef } from 'react';
import { css as emotionCss } from '@emotion/css';
import type { CSSInterpolation } from '@emotion/serialize';
import isEqual from 'lodash/isEqual';

/**
 * Интерфейс для перегрузки useCss, который соответствует оригинальной декларации css.
 */
interface UseCss {
  (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): string;
  (...args: Array<CSSInterpolation>): string;
}

/**
 * Хук, который мемоизирует результат вызова emotionCss.
 * Если входные аргументы эквивалентны предыдущим (сравнение через isEqual),
 * возвращается ранее вычисленный SerializedStyles.
 */
const useCss: UseCss = ((...args: any[]): string => {
  // Кэш хранится в ref, чтобы сохранять значение между рендерами.
  const cacheRef = useRef<{ args: any[]; result: string } | null>(null);

  if (cacheRef.current && isEqual(cacheRef.current.args, args)) {
    return cacheRef.current.result;
  }

  const result = emotionCss(...args);
  cacheRef.current = { args, result };
  return result;
}) as UseCss;

export default useCss;
