import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import * as spaceStore from 'screens/Space/store';

const useFolderTree = () => {
  const tree = useSelector(spaceStore.selectors.tree);
  return useMemo(
    () => ({
      tree,
    }),
    [tree],
  );
};

export default useFolderTree;
