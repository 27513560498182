import { call, cancel, put, type SagaReturnType, takeLatest } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';

import network from 'lib/network';

import { actions } from '../slices/resource';
import libraryIdBySpaceId from '../selectors/libraryIdBySpaceId';

export const config = {
  action: actions.updatePosition.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.updatePosition>) {
  const { entityId, newPosition } = action.payload;
  const { spaceId, index } = newPosition;

  const libraryId = yield* select(libraryIdBySpaceId(spaceId));
  if (!spaceId || !libraryId) {
    yield put(actions.updatePositionDone({ entityId }));
    yield cancel();
    return;
  }

  const parentId = newPosition.parentId === 'root' ? libraryId : newPosition.parentId;
  const body = {
    resource_id: Number(entityId),
    team_space_id: spaceId,
    team_space_folder_id: Number(parentId),
    position: index,
  };

  yield call(() => network.request(`/stack-2/user/team-space-folder/change-order`).body(body).post());

  yield put(actions.updatePositionDone({ entityId }));
}
