import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { guard } from 'utils';

import { select } from 'store/utils/saga/effects';
import * as messageStore from 'store/nodes/message';
import * as materialStore from 'store/nodes/content';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type, resourceType, resourceId } = context;

  if (type !== 'thumbnail_ready' || resourceType !== 'CONTENT' || typeof resourceId !== 'number') {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  const material = yield* select(materialStore.selectors.dataById(resourceId));
  if (!material || guard.isContentError(material)) {
    yield cancel();
    return;
  }

  yield put(materialStore.actions.thumbnailReady({ id: resourceId }));
}
