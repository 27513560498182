import { call, cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';
import { select } from 'store/utils/saga/effects';

import network from 'lib/network';

import libraryIdBySpaceId from '../selectors/libraryIdBySpaceId';
import { actions, actions as resourceActions } from '../slices/resource';

export const config = {
  action: resourceActions.moveToFolder.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof resourceActions.moveToFolder>) {
  const { entityIds, newPosition } = action.payload;
  const { spaceId } = newPosition;

  const libraryId = yield* select(libraryIdBySpaceId(spaceId));
  if (!spaceId || !libraryId) {
    yield put(actions.moveToFolderDone({ entityIds }));
    yield cancel();
    return;
  }

  const parentId = newPosition.parentId === 'root' ? libraryId : newPosition.parentId;
  const body = {
    resource_ids: entityIds.map((id) => Number(id)),
    team_space_id: spaceId,
    team_space_folder_id: Number(parentId),
  };

  yield call(() => network.request(`/stack-2/user/team-space-folder/multi-select`).body(body).post());

  yield put(actions.moveToFolderDone({ entityIds }));
}
