import type { ContentErrorType, EntityDataType, EntityErrorType } from 'app/entities';
import { guard } from 'utils';
import * as utils from 'store/nodes/note/utils';

function* prepareData(entity: EntityDataType | EntityErrorType) {
  if (guard.isEntityError(entity)) {
    return {
      id: entity.id,
      __typename: entity.type,
    } as ContentErrorType;
  }
  if (!guard.isEntityDocument(entity)) {
    return {
      id: (entity as any).id || 0,
      __typename: 'UnknownError',
    } as ContentErrorType;
  }
  return yield* utils.prepareNote(entity.data);
}

export default prepareData;
