import { call, put, takeLatest } from 'redux-saga/effects';

import network from 'lib/network';
import type { SpaceAvailablePermissionType } from 'app/entities';

import { actions } from '../slices/permission';

export const config = {
  action: actions.loadAvailable.type,
  method: takeLatest,
};

export function* func() {
  const response: { data: SpaceAvailablePermissionType[] | null; hasError: boolean; errors: Error[] | null } = yield call(() =>
    network.request<SpaceAvailablePermissionType[]>('/stack-2/user/team-space/permissions').get(),
  );

  if (!response.hasError && response.data) {
    yield put(actions.setAvailable({ data: response.data }));
  } else {
    console.error('Ошибка при загрузке прав доступа:', response.errors);
  }

  yield put(actions.loadAvailableDone());
}
