import { type FC, type ChangeEventHandler, memo } from 'react';
import { Switch, Typography } from '@mui/joy';

export interface SwitchPrivacyProps {
  value: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const SwitchPrivacy: FC<SwitchPrivacyProps> = (props) => {
  const { value, onChange } = props;

  return (
    <Typography
      component="label"
      fontWeight={300}
      fontSize={13}
      startDecorator={<Switch checked={value} onChange={onChange} />}
      sx={{ cursor: 'pointer', userSelect: 'none' }}
    >
      {value ? 'private: ON' : 'private: OFF'}
    </Typography>
  );
};

export default memo(SwitchPrivacy);
