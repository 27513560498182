import { memo, useMemo } from 'react';

import { AspectRatio, Box, Card, CardContent, Skeleton, Typography, useColorScheme } from '@mui/joy';
import { CircularProgress } from '@mui/material';

import { useCardProps } from '../model/CardContext';

const Wireframe = () => {
  const { type } = useCardProps();

  const minHeight = useMemo(() => {
    if (type === 'Folder') {
      return 300;
    }
    if (type === 'Material') {
      return 300;
    }
    if (type === 'Collection') {
      return 300;
    }
    if (type === 'Note') {
      return 150;
    }
    if (type === 'Document') {
      return 150;
    }
    if (type === 'RagMaterial') {
      return 150;
    }
    return 300;
  }, [type]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: 150,
        '@container (width <= 400px )': {
          minHeight,
        },
      }}
    >
      <CircularProgress sx={{ color: 'var(--joy-palette-background-level2)' }} />
    </Box>
  );

  // return (
  //   <Card variant="soft" sx={{ maxHeight: 320 }}>
  //     <AspectRatio maxHeight="130px">
  //       <Skeleton>
  //         <img
  //           src="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
  //           srcSet="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x"
  //           loading="lazy"
  //           alt=""
  //         />
  //       </Skeleton>
  //     </AspectRatio>
  //     <div>
  //       <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
  //         <Skeleton> Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</Skeleton>
  //       </Typography>
  //       <Typography level="body-sm">
  //         <Skeleton>Lorem ipsum is placeholder publishing industries.</Skeleton>
  //       </Typography>
  //     </div>
  //     <CardContent orientation="horizontal">
  //       <div>
  //         <Typography fontSize="lg" fontWeight="lg">
  //           <Skeleton>Lorem ipsum is placeholder text publishing</Skeleton>
  //         </Typography>
  //       </div>
  //     </CardContent>
  //   </Card>
  // );
};

export default memo(Wireframe);
