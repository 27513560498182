import { type FC, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import type { BoxProps } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';

import DropdownMenu from 'ui/DropdownMenu';
import useFilteredModels from '../model/useFilteredModels';

export interface ModelSelectProps extends Pick<BoxProps, 'm' | 'mx' | 'my' | 'mt' | 'mr' | 'mb' | 'ml'> {
  size?: 'small' | 'regular';
}

const ModelSelect: FC<ModelSelectProps> = (props) => {
  const { size, ...rest } = props;
  const dispatcher = useDispatcher();

  const selectedModel = useSelector(copilotStore.selectors.selectedModel);
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping('*'));

  const filteredAvailableModels = useFilteredModels();

  const handleChange = useCallback((id: number) => {
    dispatcher.copilot.setSelectedModel({ id: Number(id) });
  }, []);

  useEffect(() => {
    if (!selectedModel) return;
    const isModelPresent = filteredAvailableModels.some((m) => m.id === selectedModel.id);
    if (!isModelPresent) {
      handleChange(0);
    }
  }, [selectedModel, filteredAvailableModels, handleChange]);

  if (!selectedModel) {
    return null;
  }

  return (
    <DropdownMenu
      slotProps={{ menuButton: { size: size === 'small' ? 'sm' : 'md' }, typography: { fontSize: size === 'small' ? 12 : 14 } }}
      disabled={isAnswerTyping || filteredAvailableModels.length === 1}
      options={filteredAvailableModels}
      value={selectedModel.id}
      optionValueName="id"
      optionLabelName="model"
      listLabel="Model"
      onChange={handleChange}
      {...rest}
    />
  );
};

export default memo(ModelSelect, isEqual);
