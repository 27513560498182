import { type FC, type CSSProperties, memo, useCallback, useMemo } from 'react';
import { type StyleProp, type ViewStyle, Platform, StyleSheet, TouchableOpacity } from 'react-native';

import { useNavigate, useRoute } from 'navigation/hooks';
import { useBreakpoint } from 'hooks';
import { useDispatcher } from 'store/utils/redux/hooks';

import { unit } from 'utils';
import { Text, View, BlurView } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import ButtonSwitcher from 'components/ButtonSwitcher';

export interface HeaderProps {
  style?: StyleProp<ViewStyle>;
}

const CommunityHeader: FC<HeaderProps> = (props) => {
  const { style } = props;
  const route = useRoute<'CommunityPeers' | 'CommunityCollection'>();
  const dispatcher = useDispatcher();
  const breakpoint = useBreakpoint();

  const navigate = useNavigate();

  const tabOptions = useMemo(
    () => [
      { label: 'Collections', value: 'CommunityCollection' },
      { label: 'Peers Feed', value: 'CommunityPeers' },
    ],
    [],
  );

  const handleTabChange = useCallback((tab: string) => {
    if (tab === 'CommunityCollection') {
      navigate('CommunityCollection');
    }
    if (tab === 'CommunityPeers') {
      navigate('CommunityPeers');
    }
    window.scrollTo({
      top: 0,
      behavior: 'instant' as unknown as 'smooth' | 'auto',
    });
  }, []);

  const handlePeoplePress = useCallback(() => {
    dispatcher.modal.open('SearchUser');
  }, []);

  const styleFinal = useMemo(() => {
    const result = {
      ...StyleSheet.flatten(styles.Header),
      ...StyleSheet.flatten(style),
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).position = 'fixed';
    }
    return result;
  }, []);

  return (
    <BlurView style={styleFinal} darkColor="#00000055" lightColor="#FFFFFF55" factor={8}>
      <View style={styles.panel}>
        <View style={styles.title}>
          <Text size={22} weight="semi">
            Community
          </Text>
          <View style={styles.buttons}>
            {/* <TouchableOpacity style={styles.button} onPress={handleNotificationPress} activeOpacity={0.8}>
                <Icon name="NotifyOutline28" size={28} />
              </TouchableOpacity> */}
            <TouchableOpacity style={styles.button} onPress={handlePeoplePress} activeOpacity={0.8}>
              <Icon name="PeopleOutline28" size={28} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.contentTypes, ['xs', 'sm'].includes(breakpoint) ? styles.contentTypesSxSm : undefined]}>
          <ButtonSwitcher items={tabOptions} value={route.name} onChange={handleTabChange} />
        </View>
      </View>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  Header: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  panel: {
    flex: 1,
    maxWidth: unit(1280),
    paddingBottom: unit(16),
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: unit(16),
    marginTop: unit(20),
    flex: 1,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: unit(8),
    flex: 1,
  },
  button: {
    marginLeft: unit(8),
  },
  contentTypes: {
    marginTop: unit(8),
    paddingHorizontal: unit(16),
    maxWidth: unit(362 + 32),
    alignSelf: 'center',
    width: '100%',
  },
  contentTypesSxSm: {
    maxWidth: unit(393 + 32),
  },
});

export default memo(CommunityHeader);
