import { useState, useMemo, useCallback } from 'react';

interface Config {
  leftWidth: number;
  rightWidth: number;
}

interface UseConfigOptions {
  defaultLeftWidth: number;
  defaultRightWidth: number;
}

/**
 * Хук для чтения и записи размеров панелей в localStorage по ключу storeKey.
 * Возвращает начальные размеры (только при первом маунте) и метод setSize,
 * чтобы сохранять новые размеры.
 */
export function useConfig(storeKey: string | undefined, { defaultLeftWidth, defaultRightWidth }: UseConfigOptions) {
  const initialConfig = useMemo<Config>(() => {
    if (!storeKey) {
      return { leftWidth: defaultLeftWidth, rightWidth: defaultRightWidth };
    }
    try {
      const stored = localStorage.getItem(storeKey);
      if (stored) {
        const parsed = JSON.parse(stored) as Config;
        if (typeof parsed.leftWidth === 'number' && typeof parsed.rightWidth === 'number') {
          return parsed;
        }
      }
    } catch {
      /* empty */
    }

    return { leftWidth: defaultLeftWidth, rightWidth: defaultRightWidth };
  }, [storeKey, defaultLeftWidth, defaultRightWidth]);

  const [initialLeftSize] = useState<number>(initialConfig.leftWidth);
  const [initialRightSize] = useState<number>(initialConfig.rightWidth);

  const setSize = useCallback(
    (newLeftSize: number, newRightSize: number) => {
      if (!storeKey) {
        return;
      }
      const newConfig: Config = {
        leftWidth: newLeftSize,
        rightWidth: newRightSize,
      };
      localStorage.setItem(storeKey, JSON.stringify(newConfig));
    },
    [storeKey],
  );

  return useMemo(
    () => ({
      initialLeftSize,
      initialRightSize,
      setSize,
    }),
    [initialLeftSize, initialRightSize, setSize],
  );
}

export default useConfig;
