import React, { createContext, useCallback, useContext, useState, type FC, useMemo } from 'react';

interface PanelsResizingContextValue {
  getIsResizing: (storeKey: string) => boolean;
  setIsResizing: (storeKey: string, isResizing: boolean) => void;
}

const PanelsResizingContext = createContext<PanelsResizingContextValue | null>(null);

/**
 * Хранит флаги "идёт ли сейчас ресайз" для каждого storeKey.
 * Не пишет в localStorage (это временное состояние).
 */
export const PanelsResizingContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [resizingState, setResizingState] = useState<Record<string, boolean>>({});

  const getIsResizing = useCallback(
    (storeKey: string) => {
      return resizingState[storeKey] ?? false;
    },
    [resizingState],
  );

  const setIsResizing = useCallback<PanelsResizingContextValue['setIsResizing']>((storeKey, isResizing) => {
    if (!storeKey) return;
    setResizingState((prev) => ({
      ...prev,
      [storeKey]: isResizing,
    }));
  }, []);

  const value = useMemo(() => ({ getIsResizing, setIsResizing }), [getIsResizing, setIsResizing]);

  return <PanelsResizingContext.Provider value={value}>{children}</PanelsResizingContext.Provider>;
};

export function usePanelsResizingContext() {
  const ctx = useContext(PanelsResizingContext);
  if (!ctx) {
    throw new Error('usePanelsResizingContext must be used within PanelsResizingContextProvider');
  }
  return ctx;
}
