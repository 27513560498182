import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import useSpaceById from 'screens/Space/model/useSpaceById';

const useMyLibrary = () => {
  const defaultSpace = useSelector((state) => Object.values(state.spaceList.data || {}).find((item) => item.isDefault));
  const entityId = defaultSpace?.libraryId || 'root';

  return useMemo(
    () => ({
      spaceId: defaultSpace?.id,
      entityId,
    }),
    [defaultSpace?.id, entityId],
  );
};

export default useMyLibrary;
