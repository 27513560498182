import { type FC, memo, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { Box, CardContent, Tooltip, Typography } from '@mui/joy';

import { type AuthorType, type UserType } from 'app/entities';

import Icon from 'ui/Icon';
import { guard } from 'utils';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import AuthorBlock from '../ui/AuthorBlock';

import { useCardProps } from '../model/CardContext';
import useDocumentDescription from '../model/useDocumentDescription';
import useAuthor from '../model/useAuthor';

interface DocumentProps {
  handleAuthorPress?: (value: UserType | AuthorType) => void;
}

const Document: FC<DocumentProps> = (props) => {
  const { handleAuthorPress } = props;
  const { id, hasAuthor, AuthorBlockComponent } = useCardProps();

  const data = useSelector(noteStore.selectors.dataById(id));
  const dataPrivate = guard.isDocument(data) && data?.private;
  const text = (guard.isDocument(data) && (data?.previewText || data?.text || '').trim()) || '';

  const { person } = useAuthor(data);
  const { title, paragraph, hasContent } = useDocumentDescription(text);

  const handlePress = useCallback(() => {
    if (!person) {
      return;
    }
    handleAuthorPress?.(person);
  }, [person]);

  const privateIconRender = useMemo(() => {
    if (!dataPrivate) {
      return null;
    }
    return (
      <Tooltip placement="top" arrow disableInteractive variant="outlined" size="sm" title="This document is visible only to you.">
        <Typography component="span" fontSize={16}>
          <Icon size="sm" marginRight={1} weight="solid" color="var(--joy-palette-primary-solidDisabledColor)" name="eye-slash" />
        </Typography>
      </Tooltip>
    );
  }, [dataPrivate]);

  return (
    <CardContent>
      <Box display="flex" flexDirection="column" gap={1}>
        {hasContent && title && (
          <Typography
            position="relative"
            fontSize={17}
            fontWeight={600}
            sx={{
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            <Box component="span" position="absolute">
              {privateIconRender}
            </Box>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{title}
          </Typography>
        )}
        {hasContent && paragraph && (
          <Typography
            position="relative"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 8,
              overflow: 'hidden',
              opacity: 0.9,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
            fontSize={14}
          >
            {!title && (
              <>
                <Box component="span" position="absolute">
                  {privateIconRender}
                </Box>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{paragraph}
              </>
            )}
            {!!title && paragraph}
          </Typography>
        )}
        {!hasContent && (
          <Typography fontSize={17}>
            {privateIconRender}
            <Typography component="span" fontWeight={400} color="neutral" sx={{ color: 'var(--joy-palette-warning-solidDisabledColor)' }}>
              Empty document
            </Typography>
          </Typography>
        )}
        {!!AuthorBlockComponent && AuthorBlockComponent}
        {!AuthorBlockComponent && hasAuthor && !!person && <AuthorBlock onPress={handlePress} data={person} mt={1} />}
      </Box>
    </CardContent>
  );
};

export default memo(Document, isEqual);
