import { takeEvery, put, cancel, delay, take, type SagaReturnType } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as contentStore from 'store/nodes/content';

import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.runPageHandler>) {
  const route = yield* call(() => getRoute());
  if (!route.isScreen('Feed')) {
    yield cancel();
    return;
  }

  const user = yield* select(userStore.selectors.getMy);

  const filtersMeta = yield* select(contentStore.selectors.filtersMeta);
  const listMeta = yield* select(contentStore.selectors.listMeta);

  if (!filtersMeta.isLoaded) {
    yield put(contentStore.actions.loadFilters());
    yield take(contentStore.actions.loadFiltersDone.type);
    yield delay(5);
  }

  const filterTypeValue = yield* select((state) => state.content.list.filters.type.value);
  if (!filterTypeValue) {
    const [filterTypeDefault] = yield* select((state) => state.content.list.filters.type.options);
    yield put(contentStore.actions.setFilterType(filterTypeDefault));
    yield delay(5);
  }

  if (!user?.isRegisterCompleted) {
    yield delay(2000);
    yield put(actions.runPageHandler(action.payload));
    yield cancel();
    return;
  }

  if (!listMeta.isConsistent) {
    yield put(contentStore.actions.loadNextPage(true));
  }

  yield* call(() => track('Ask'));
}
