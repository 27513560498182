import { type ReactNode, createContext } from 'react';
import { type BoxProps } from '@mui/joy';

export interface ConfigType {
  answer?: {
    actionsBar?: ('Reload' | 'ToClipboard' | 'ToNote' | ReactNode)[];
  };
  input?: {
    autofocus?: boolean;
    footer?: ReactNode;
  };
  body?: BoxProps;
}

export const ConfigContext = createContext<ConfigType | undefined>(undefined);
