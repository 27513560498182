import { createSelector } from 'reselect';
import type { DefaultRootState } from 'react-redux';

import subscriptionConfigSelector from './subscriptionConfig';
import type { CurrentPlanStateType } from '../../types';

/**
 * Выделяем из state нужные части, чтобы передать их в createSelector.
 */
const subscriptionStateSelector = (state: DefaultRootState) => state.subscription.state;

/**
 * Мемоизированный селектор currentPlan,
 * который использует subscriptionConfigSelector и subscriptionStateSelector.
 */
const currentPlanSelector = createSelector(
  [subscriptionConfigSelector, subscriptionStateSelector],
  (subscriptionConfig, subState): CurrentPlanStateType => {
    const { plan: id, hasPaymentMethod, lastPaymentAmount, expirationDate, canceledAt, status } = subState;

    const isExists = id !== '-1' && id !== '0';
    const isTrialing = status === 'trialing';
    const isIncomplete = status === 'incomplete';
    const isPaused = !!canceledAt;
    const isLoaded = id !== '-1';
    const canTrial = isLoaded && (!isExists || isTrialing);

    let period: string | null = null;

    // Если план ещё не загружен
    if (!isLoaded) {
      return {
        id,
        period,
        name: null,
        expirationDate,
        canceledAt,
        lastPaymentAmount: lastPaymentAmount || 0,
        canTrial,
        hasPaymentMethod,
        isExists,
        isTrialing,
        isIncomplete,
        isPaused,
        isLoaded,
      };
    }

    // Ищем план в subscriptionConfig
    const { identity, name = null } = subscriptionConfig.plans.find((plan) => plan.identity.planId === id) || {};

    // Ищем period, если identity есть
    subscriptionConfig.periods.forEach((item) => {
      if (!identity || period) return;
      if (item.planIds.includes(identity.planId)) {
        period = item.id;
      }
    });

    // Если identity отсутствует (план не найден в конфиге)
    if (!identity) {
      return {
        id,
        period,
        name: null,
        expirationDate,
        canceledAt,
        lastPaymentAmount: lastPaymentAmount || 0,
        canTrial,
        hasPaymentMethod,
        isExists,
        isTrialing,
        isIncomplete,
        isPaused,
        isLoaded,
      };
    }

    // Иначе возвращаем найденный план
    return {
      id,
      period,
      name,
      expirationDate,
      canceledAt,
      lastPaymentAmount: lastPaymentAmount || 0,
      canTrial,
      hasPaymentMethod,
      isExists,
      isTrialing,
      isIncomplete,
      isPaused,
      isLoaded,
    };
  },
);

export default currentPlanSelector;
