import { createReducer } from '@reduxjs/toolkit';
import md5 from 'blueimp-md5';

import { guard } from 'utils';

import type { SearchStore } from './types';
import * as actions from './actions';

const initialState: SearchStore = {
  data: {},
  list: {
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
    meta: {
      status: null,
      resultWithFilterHash: md5(JSON.stringify({ spaceId: null, text: '' })),
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  filter: {
    spaceId: null,
    text: '',
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.clear, (state) => {
      const cloned = JSON.parse(JSON.stringify(initialState));
      state.list.sequence = cloned.list.sequence;
      state.list.map = cloned.list.map;
      state.list.paginationInfo = cloned.list.paginationInfo;
      state.list.meta = cloned.list.meta;
      state.filter = cloned.filter;
    })
    .addCase(actions.setFilterText, (state, action) => {
      state.filter.text = action.payload.value;
    })
    .addCase(actions.loadPage, (state, action) => {
      const { spaceId } = action.payload;
      state.filter.spaceId = spaceId;
      state.list.meta.status = 'loading-first';
      state.list.meta.isFirstPageLoading = true;
    })
    .addCase(actions.loadPageDone, (state, action) => {
      const { status } = action.payload;
      state.list.meta.isFirstPageLoading = false;
      state.list.meta.isFirstPageLoaded = true;
      state.list.meta.resultWithFilterHash = md5(JSON.stringify(state.filter));
      state.list.meta.status = status || null;
    })
    .addCase(actions.loadNextPage, (state, action) => {
      state.list.meta.status = 'loading-next';
      state.list.meta.isNextPageLoading = true;
    })
    .addCase(actions.loadNextPageDone, (state, action) => {
      const { status } = action.payload;
      state.list.meta.isNextPageLoading = false;
      state.list.meta.isNextPageLoaded = true;
      state.list.meta.resultWithFilterHash = md5(JSON.stringify(state.filter));
      state.list.meta.status = status || null;
    })
    .addCase(actions.addPage, (state, action) => {
      if (!action.payload.items) {
        return;
      }
      if (action.meta.reload) {
        state.list.sequence = [];
        state.list.map = {};
      }
      action.payload.items.forEach((item) => {
        const { element, searchMeta } = item;
        if (element.id in state.list.map) {
          return;
        }
        let type: 'Material' | 'Folder' | 'RagMaterial' | 'Unknown' = 'Unknown';
        if (guard.isMaterial(element)) {
          type = 'Material';
        }
        if (guard.isSpaceFolder(element)) {
          type = 'Folder';
        }
        if (guard.isRagMaterial(element)) {
          type = 'RagMaterial';
        }
        state.list.sequence.push({
          id: element.id,
          searchMeta,
          type,
        });
        state.list.map[element.id] = state.list.sequence.length - 1;
      });
      state.list.paginationInfo.nextCursor = action.payload.paginationInfo.nextCursor;
      state.list.paginationInfo.hasNext = action.payload.paginationInfo.hasNext;
    })
    .addCase(actions.setRagMaterial, (state, action) => {
      if (!action.payload.items) {
        return;
      }
      action.payload.items.forEach((data) => {
        state.data[data.id] = {
          ...data,
        };
      });
    })
    .addCase(actions.updateRagMaterialState, (state, action) => {
      const { data } = action.payload;
      data.forEach((item) => {
        const { id, isAdded } = item;
        state.data[id].isAdded = isAdded;
      });
    })
    .addCase(actions.logOut, (state) => {
      const closed = JSON.parse(JSON.stringify(state));
      state.list = closed.list;
    }),
);
