import { type FC, memo } from 'react';
import { Box } from '@mui/joy';

import CollectionSingleCover from 'components/CollectionSingleCover';
import Icon from 'components/LegacyIcon';

import type { CollectionType } from 'app/entities';

export interface ItemSelectInputProps {
  title: string;
  data: CollectionType;
}

const ItemSelectInput: FC<ItemSelectInputProps> = (props) => {
  const { title, data } = props;
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="0.75rem">
      <CollectionSingleCover data={data} iconSize={24} widthPicture={24} heightPicture={24} />
      {title}
      {data?.isPrivate && (
        <Icon
          name="LockFill"
          size={16}
          style={{
            position: 'absolute',
            right: '0.75rem',
            opacity: 0.5,
          }}
        />
      )}
    </Box>
  );
};

export default memo(ItemSelectInput);
