import { type SagaReturnType, takeEvery, put, delay, cancel } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';

import { questionByRequestId } from '../selectors';

import { actions } from '../slice';
import { createItemId } from '../utils';

export const config = {
  action: actions.reloadAnswer.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.reloadAnswer>) {
  const { requestId } = action.payload;
  const itemId = createItemId({ requestId, type: 'question' });
  const question = yield* select(questionByRequestId(itemId));

  if (!question) {
    yield cancel();
    return;
  }

  yield delay(10);
  yield put(
    actions.sendQuestion({
      reloadId: requestId,
      resourceType: question.resourceType,
      resourceId: question.resourceId,
      text: question.text,
    }),
  );
}
