import { call, cancel, delay, put, type SagaReturnType, takeLatest } from 'redux-saga/effects';

import network from 'lib/network';

import { select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as spaceList from 'screens/Space/store/slices/list';

import { actions } from '../slice';

export const config = {
  action: actions.loadStateDone.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadStateDone>) {
  const { isSpaceInvite } = action.payload;

  const user = yield* select(userStore.selectors.getMy);

  if (!user || isSpaceInvite) {
    yield cancel();
    return;
  }

  yield call(() => network.request('/stack-2/user/team-space/approve-invite').post());
  yield put(spaceList.actions.reset());
  yield delay(10);
  yield put(spaceList.actions.load());
}
