import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import * as messageStore from 'store/nodes/message';
import * as contentStore from 'store/nodes/content';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context, message } = action.payload;
  const { type, resourceId } = context;

  if (type !== 'material_update' || message !== 'Content indexed' || typeof resourceId !== 'number') {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));
  yield put(contentStore.actions.materialIndexed({ id: resourceId }));
}
