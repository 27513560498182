import { useMemo } from 'react';

interface ParsedItemId {
  spaceId: number;
  entityId: 'space' | string;
}

interface ParseItemId {
  (itemId: string): ParsedItemId;
  (itemId: string | null | undefined): ParsedItemId | null;
}

export const parseItemId: ParseItemId = (itemId) => {
  if (typeof itemId === 'string') {
    const [spaceId, entityId] = itemId.split('::');
    return {
      spaceId: Number(spaceId),
      entityId: String(entityId),
    };
  }
  return null as any;
};

export const useParseItemId = (itemId: string) => {
  return useMemo(() => parseItemId(itemId), [itemId]);
};
