import React, { createContext, useCallback, useContext, useState, type FC, useMemo } from 'react';

type PanelsSize = {
  leftWidth: number;
  rightWidth: number;
};

interface PanelsSizeContextValue {
  /**
   * Возвращает сохранённые размеры (в процентах) для данного ключа
   * или дефолт (60/40), если ничего не найдено.
   */
  getPanelsSize: (storeKey: string) => PanelsSize;

  /**
   * Сохраняет новые размеры (в процентах) и пишет их в localStorage.
   */
  setPanelsSize: (storeKey: string, leftWidth: number, rightWidth: number) => void;
}

const PanelsSizeContext = createContext<PanelsSizeContextValue | null>(null);

/**
 * Хранит размеры панелей (в процентах) по ключам storeKey в State,
 * синхронизируя их с localStorage.
 */
export const PanelsSizeContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  // sizesState: { [storeKey]: { leftWidth, rightWidth } }
  const [sizesState, setSizesState] = useState<Record<string, PanelsSize>>({});

  /**
   * Возвращаем сохранённые размеры или, если их нет, читаем из localStorage,
   * при отсутствии – возвращаем дефолт (60/40).
   */
  const getPanelsSize = useCallback<PanelsSizeContextValue['getPanelsSize']>(
    (storeKey) => {
      if (!storeKey) {
        // Если нет ключа — просто возвращаем дефолт
        return { leftWidth: 60, rightWidth: 40 };
      }

      // Если уже есть в стейте – отдаем
      if (sizesState[storeKey]) {
        return sizesState[storeKey];
      }

      // Иначе пытаемся достать из localStorage
      try {
        const stored = localStorage.getItem(storeKey);
        if (stored) {
          const parsed = JSON.parse(stored) as PanelsSize;
          if (typeof parsed.leftWidth === 'number' && typeof parsed.rightWidth === 'number') {
            setSizesState((prev) => ({
              ...prev,
              [storeKey]: parsed,
            }));
            return parsed;
          }
        }
      } catch {
        // Игнорируем ошибку парсинга
      }

      // Если ничего не нашли, возвращаем дефолт
      const defaultSize = { leftWidth: 60, rightWidth: 40 };
      setSizesState((prev) => ({
        ...prev,
        [storeKey]: defaultSize,
      }));
      return defaultSize;
    },
    [sizesState],
  );

  /**
   * Устанавливаем новые процентные размеры и пишем их в localStorage
   */
  const setPanelsSize = useCallback<PanelsSizeContextValue['setPanelsSize']>((storeKey, leftWidth, rightWidth) => {
    if (!storeKey) return;
    const newSize: PanelsSize = { leftWidth, rightWidth };
    localStorage.setItem(storeKey, JSON.stringify(newSize));
    setSizesState((prev) => ({
      ...prev,
      [storeKey]: newSize,
    }));
  }, []);

  const value = useMemo(() => ({ getPanelsSize, setPanelsSize }), [getPanelsSize, setPanelsSize]);

  return <PanelsSizeContext.Provider value={value}>{children}</PanelsSizeContext.Provider>;
};

/**
 * Вспомогательный хук, чтобы безопасно достать PanelsSizeContextValue
 */
export function usePanelsSizeContext() {
  const ctx = useContext(PanelsSizeContext);
  if (!ctx) {
    throw new Error('usePanelsSizeContext must be used within PanelsSizeContextProvider');
  }
  return ctx;
}
