import { takeEvery, put, cancel, delay, type SagaReturnType } from 'redux-saga/effects';

import type { AuthorType } from 'app/entities';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import fetchAuthor from '../model/fetchAuthor';
import fetchAuthorContent from '../model/fetchAuthorContent';

import * as actions from '../actions';

export const config = {
  action: [actions.loadProfile.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadProfile>) {
  const { payload } = action;
  const { id } = payload;

  const [author, contentIds] = yield* call(() => Promise.all([fetchAuthor(id), fetchAuthorContent(id, { pageSize: 100 })]));

  if (author.error.hasErrors || !author.data || contentIds.error || !contentIds.data) {
    Alert.error(author.error?.list);
    yield put(actions.loadProfileDone(id));
    yield cancel();
    return;
  }
  const result: AuthorType = {
    ...author.data,
    contentIds: contentIds.data?.items || [],
  };

  yield put(actions.setProfile(result));
  yield delay(10);
  yield put(actions.loadProfileDone(id));
}
