import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import { Box, type BoxProps, Typography, type TypographyProps } from '@mui/joy';
import { formatCurrency } from 'utils';

export interface PriceRowProps extends BoxProps, Pick<TypographyProps, 'fontWeight' | 'textColor'> {
  label?: string;
  value?: string | number;
  subtext?: string;
}

const PriceRow: FC<PriceRowProps> = (props) => {
  const { label = '', value, subtext, fontWeight = 400, textColor, ...rest } = props;

  return (
    <Box display="flex" {...rest}>
      <Typography fontWeight={fontWeight} textColor={textColor}>
        {label}
      </Typography>
      <Box flex={1} />
      <Box display="flex" flexDirection="column" textAlign="right">
        {typeof value === 'string' && (
          <Typography fontWeight={fontWeight} textColor={textColor}>
            {value}
          </Typography>
        )}
        {typeof value === 'number' && value >= 0 && (
          <Typography fontWeight={fontWeight} textColor={textColor}>
            {formatCurrency(value)}
          </Typography>
        )}
        {typeof value === 'number' && value < 0 && (
          <Typography fontWeight={fontWeight} textColor={textColor}>{`-${formatCurrency(value)}`}</Typography>
        )}
        {!!subtext && (
          <Typography color="neutral" level="body-xs">
            {subtext}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(PriceRow, isEqual);
