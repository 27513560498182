import { type FC, memo } from 'react';

import { useRoute } from 'navigation/hooks';

import FolderLayout from './layouts/Folder';
import SearchLayout from './layouts/Search';

interface SpaceProps {}

const Space: FC<SpaceProps> = (props) => {
  const route = useRoute();
  if (route.isScreen('Space')) {
    return <FolderLayout />;
  }
  if (route.isScreen('Search')) {
    return <SearchLayout />;
  }
  return null;
};

export default memo(Space);
