import { useSelector } from 'react-redux';
import folderHasNext from '../store/selectors/folderHasNext';

/**
 * Хук для проверки, есть ли ещё страницы для загрузки.
 */
function useFolderHasNext(spaceId: number, folderId: string | number | 'root'): boolean {
  return useSelector(folderHasNext(spaceId, folderId));
}

export default useFolderHasNext;
