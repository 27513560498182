import { type SagaReturnType, takeEvery, cancel, put, delay } from 'redux-saga/effects';

import { guard } from 'utils';
import { select } from 'store/utils/saga/effects';
import * as messageStore from 'store/nodes/message';
import * as spaceStore from 'screens/Space/store';

import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';
import * as selectors from '../../store/selectors';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type } = context;

  if (type !== 'space_entity_added' || !context.data) {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  const libraryIds = yield* select(selectors.libraryIds);
  const sourceEntity = context.data as SourceEntityType;
  const entity = sourceEntityToSpaceEntity(libraryIds, sourceEntity);
  if (guard.isSpaceFolder(entity)) {
    yield delay(200);
  }
  const hasEntity = yield* select(selectors.entityById(entity.id));
  if (!hasEntity) {
    yield put(spaceStore.slices.spaceResource.actions.add({ entity, position: 'first' }));
  }
}
