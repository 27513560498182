import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import { Box, IconButton, Tooltip, type BoxProps } from '@mui/joy';

import { usePerson } from 'hooks';

import Icon from 'ui/Icon';

import { useCardData, useCardProps, useCardIndexState } from '../model/CardContext';
import { useCardActions } from '../model/CardActionsContext';

const Actions: FC<BoxProps> = (props) => {
  const { person } = usePerson('User', 'my');

  // @todo удалить
  const data = useCardData();

  const { type, footerStart, hasToCollection = false, hasLink = false, hasRemove = false, hasEdit = false } = useCardProps();
  const { isIndexing, isIndexed } = useCardIndexState();
  const { handleToCollection, handleLink, handleEdit, handleRemove, handleAddRagMaterialLink } = useCardActions();

  return (
    <Box display="flex" flexDirection="row" gap={0.5} alignItems="flex-end" {...props} sx={{ width: '100%', ...props?.sx }}>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          flex: 1,
          opacity: 1,
          transition: 'opacity 0.3s',
        }}
      >
        {footerStart}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        gap={0.5}
        sx={{
          opacity: 'var(--card-hover-opacity)',
          transition: 'opacity 0.3s',
          '@media (hover: none)': {
            opacity: 1,
          },
        }}
      >
        {type !== 'RagMaterial' && hasEdit && data && 'creator' in data && data?.creator?.id === person?.id && (
          <Tooltip arrow disableInteractive variant="outlined" size="sm" title="Edit material">
            <IconButton size="sm" onClick={handleEdit}>
              <Icon name="pen-to-square" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {type !== 'RagMaterial' && hasToCollection && (type === 'Material' || type === 'Document') && (
          <Tooltip arrow disableInteractive variant="outlined" size="sm" title="Add to collection">
            <IconButton size="sm" onClick={handleToCollection}>
              <Icon family="kit" name="sharp-light-bars-circle-plus" fw color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {type !== 'RagMaterial' && hasLink && (
          <Tooltip arrow disableInteractive variant="outlined" size="sm" title="Copy link">
            <IconButton size="sm" onClick={handleLink}>
              <Icon name="link" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {type !== 'RagMaterial' && hasRemove && (
          <Tooltip arrow disableInteractive variant="outlined" size="sm" title="Delete">
            <IconButton size="sm" onClick={handleRemove}>
              <Icon name="trash" weight="light" fw color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {type === 'RagMaterial' && !!data && 'isAdded' in data && data.isAdded && (
          <Tooltip arrow disableInteractive variant="outlined" size="sm" title="Added to library">
            <Box display="flex" width={32} height={32} alignItems="center" justifyContent="center">
              <Icon name="check" fw weight="light" color="primary" />
            </Box>
          </Tooltip>
        )}
        {type === 'RagMaterial' && !!data && 'isAdded' in data && !data.isAdded && (
          <Tooltip arrow disableInteractive variant="outlined" size="sm" title="Add to library">
            <IconButton size="sm" onClick={handleAddRagMaterialLink}>
              <Icon name="plus-large" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box display="flex" alignSelf="flex-end" gap={0.5} flexDirection="row">
        {isIndexing && (
          <Tooltip
            disableInteractive
            arrow
            variant="outlined"
            size="sm"
            title={
              <>
                Processing...
                <br />
                {!isIndexed && 'Assistant is not available for this material yet'}
                {isIndexed && 'Assistant is still working on previous version of this material yet'}
              </>
            }
          >
            <IconButton
              size="sm"
              color="danger"
              sx={{
                '&': {
                  '--variant-plainActiveBg': 'var(--variant-plainHoverBg)',
                },
                '&:hover': {
                  cursor: 'default',
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Icon name="sync" animate="spin" weight="light" fw color="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default memo(Actions, isEqual);
