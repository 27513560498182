export default {
  Index: '/',
  Space: '/space/:spaceId/folder/:folderId',
  Search: '/space/:spaceId/search',
  Ask: '/ask',
  Content: '/content/:resourceType/:resourceId',
  CommunityPeers: '/community/peers',
  CommunityCollection: '/community/collection',
  Library: '/library/:interaction',
  'Auth/EmailConfirm': '/auth/email/confirm',
  'Auth/Start': '/welcome',
  'Auth/SignIn': '/auth/sign-in',
  'Auth/SignUp': '/auth/sign-up',
  'Auth/PasswordReset': '/auth/password-reset',
  'Auth/Onboarding/Profile': '/auth/onboarding/profile',
  'Auth/Onboarding/AboutYou': '/auth/onboarding/about-you',
  'Auth/Onboarding/InviteTeam': '/auth/onboarding/invite-team',
  'Auth/Onboarding/TryFree': '/auth/onboarding/try-free',
  'Auth/WithAppSumo': '/withappsumo',
  Profile: '/profile/:login',
  Author: '/author/:id',
  Playlist: '/playlist/:resourceId',
  Note: '/note/:id',
  Comments: '/:section/:id/comments/',
  NotFound: '*',
};
