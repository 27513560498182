import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import * as messageStore from 'store/nodes/message';

import { actions } from '../../store/slices/resource';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type } = context;

  if ((type !== 'teamSpaceFolderDeleted' && type !== 'teamSpaceResourceDeleted') || !context?.resourceId) {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  yield put(actions.removeApply({ entityIds: [String(context.resourceId)] }));
}
