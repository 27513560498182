import { memo, useCallback } from 'react';
import { Box, Button, Tooltip } from '@mui/joy';

import Icon from 'ui/Icon';
import { useDispatcher } from 'store/utils/redux/hooks';

const AddKnowledgeButton = () => {
  const dispatcher = useDispatcher();

  const handleClick = useCallback(() => {
    dispatcher.plus.open();
  }, []);

  return (
    <Tooltip arrow variant="outlined" size="sm" title="Add information to your library">
      <Button
        variant="solid"
        color="primary"
        sx={{
          fontWeight: 400,
          py: 3,
          borderRadius: 34,
          flex: 1,
          '@media (max-width: 460px)': {
            borderRadius: 22,
            py: 1.5,
          },
        }}
        endDecorator={
          <Box>
            <Icon name="plus" weight="light" color="inherit" />
          </Box>
        }
        onClick={handleClick}
      >
        Add knowledge
      </Button>
    </Tooltip>
  );
};

export default memo(AddKnowledgeButton);
