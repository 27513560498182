import { useCallback, useRef } from 'react';

import { useDispatcher } from 'store/utils/redux/hooks';

interface UseCreateParams {
  spaceId?: number;
  folderId?: string;
}

const useCreate = (params?: UseCreateParams) => {
  const { spaceId, folderId } = params || {};
  const dispatcher = useDispatcher();
  const creatingRef = useRef(false);

  return useCallback(
    (html: string, text: string) => {
      if (creatingRef.current) {
        return;
      }
      creatingRef.current = true;
      dispatcher.note.add({ html, openForEdit: true, spaceId, folderId });
    },
    [spaceId, folderId],
  );
};

export default useCreate;
