import { type FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Box, type BoxProps } from '@mui/material';

import * as contentStore from 'store/nodes/content';
import { useMaterialType } from 'hooks';

import Info from './Info';

import useBodyRender from '../hooks/useBodyRender';
import useTitleRender from '../hooks/useTitleRender';

interface ContentPanelProps extends BoxProps {
  resourceId: number;
  hasTitle?: boolean;
}

const ContentPanel: FC<ContentPanelProps> = (props) => {
  const { resourceId, hasTitle } = props;

  const data = useSelector(contentStore.selectors.dataById(resourceId));
  const materialType = useMaterialType(data);
  const bodyRender = useBodyRender(materialType, data);
  const titleRender = useTitleRender(materialType, data, hasTitle);

  if (!data) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" flex={1} pb={8}>
      <Info data={data} />
      {titleRender}
      {bodyRender}
    </Box>
  );
};

export default memo(ContentPanel, isEqual);
