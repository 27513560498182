import network from 'lib/network';
import interaction from 'services/api/interaction/route';
import type { Result, CommunityItemVariant, CommunityEntityType, UserType, AuthorType, CollectionType, LinkToContentType } from 'app/entities';
import { guard } from 'utils';

async function fetchCommunityFeed(): Promise<Result<CommunityEntityType<UserType | AuthorType | LinkToContentType<'Collection'>>[]>> {
  const { data, hasError, errors } = await network.request<CommunityItemVariant[]>('/stack-1/user/community/feed').get();

  if (hasError || !data) {
    return {
      data: null,
      error: {
        hasErrors: !!errors && errors.length > 0,
        list: errors,
      },
    };
  }

  return {
    data: transformData(data),
    error: {
      hasErrors: false,
      list: [],
    },
  };
}

function transformData(
  groups: CommunityEntityType<UserType | AuthorType | CollectionType>[],
): CommunityEntityType<UserType | AuthorType | LinkToContentType<'Collection'>>[] {
  const replaceTitleMap: Record<string, string> = {
    Playlists: 'Collections',
  };

  return groups.map((group) => {
    return {
      title: replaceTitleMap[group.title] || group.title,
      payload: group.payload.map((item) => {
        if (guard.isCollection(item)) {
          return {
            resourceType: 'Collection',
            resourceId: item.id,
          };
        }
        if (guard.isLikeUser(item)) {
          return {
            ...item,
            __typename: 'User',
          };
        }
        if (guard.isAuthor(item)) {
          return {
            ...item,
            __typename: 'Author',
          };
        }
        return item;
      }),
    };
  });
}

export default fetchCommunityFeed;
