import { type FC, memo, useCallback, useEffect, useMemo, useState, useId, useRef } from 'react';
import Select, {
  type CSSObjectWithLabel,
  type MultiValue,
  type SingleValue,
  type ActionMeta,
  type GroupBase,
  type SelectInstance,
} from 'react-select';
import { StyleSheet } from 'react-native';
import AsyncSelect from 'react-select/async';
import isEqual from 'lodash.isequal';

import { request } from 'services/api';
import { styleSheetToCss, unit } from 'utils';

import { Text } from 'components/Themed';

import { useColors } from './hooks';
import type { SelectInputProps, Option } from './types';

const SelectInput: FC<SelectInputProps> = (props) => {
  const {
    style,
    label,
    value,
    options,
    optionsUrl,
    closeOnSelect,
    isMulti,
    isClearable,
    isSearchable,
    isDisabled,
    onChange,
    gapTop,
    gapBottom,
    colors,
    placeholder,
    components,
  } = props;

  const componentId = useId();
  const selectRef = useRef<SelectInstance<Option, boolean, GroupBase<Option>>>(null);
  const [availableOptions, setAvailableOptions] = useState(options || []);
  const [menuPlacement, setMenuPlacement] = useState<'bottom' | 'top'>('bottom');

  const { textColor, controlBackgroundColor, menuBackgroundColor, outlineColor, optionHoverColor, optionSelectedColor, multiValueColor } =
    useColors(colors);

  useEffect(() => {
    if (!Array.isArray(value)) {
      if (availableOptions.some((option) => option.value === value)) {
        return;
      }
      setAvailableOptions((prevOptions) => [...prevOptions, { value, label: value } as Option]);
      return;
    }
    const addingValues: Option[] = [];
    value.forEach((valueItem) => {
      if (availableOptions.some((option) => option.value === valueItem)) {
        return;
      }
      addingValues.push({ value: valueItem, label: valueItem });
    });
    if (addingValues.length > 0) {
      setAvailableOptions((prevOptions) => [...prevOptions, ...addingValues]);
    }
  }, [value, availableOptions]);

  const translateValue = useMemo(() => {
    if (!availableOptions || !value) {
      return null;
    }
    if (isMulti && Array.isArray(value)) {
      return value.map((item) => availableOptions.find((option) => option.value === item)).filter((item) => item !== undefined) as Option[];
    }
    return availableOptions.find((option) => option.value === value) || null;
  }, [availableOptions, value]);

  const handleChange = useCallback(
    (
      newValue: MultiValue<Option | undefined> | SingleValue<Option | undefined>,
      actionMeta: ActionMeta<Option | MultiValue<Option | undefined> | undefined>,
    ) => {
      if (isClearable && actionMeta.action === 'clear') {
        onChange?.(null);
        return;
      }
      if (!newValue) {
        return;
      }
      if (isMulti) {
        onChange?.((newValue as Option[]).map((item) => item?.value));
        return;
      }
      onChange?.((newValue as Option).value);
    },
    [isClearable],
  );

  const loadOptions = useCallback(
    async (text = '') => {
      if (!optionsUrl) {
        return [];
      }
      const { data, error } = await request.get<{ text: string }[]>(optionsUrl.replace(/\[TEXT]/, encodeURIComponent(text)));
      if (!data || error) {
        console.error('Error fetching data');
        return [];
      }
      const transformedData = data.map((tag) => ({
        value: tag.text,
        label: tag.text,
      }));
      setAvailableOptions(transformedData);
      return transformedData;
    },
    [optionsUrl],
  );

  const prepareOrientation = useCallback((event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLDivElement;
    const componentTarget = target.closest(`[id="${componentId}"]`);
    if (!componentTarget) {
      return;
    }
    const { bottom } = componentTarget.getBoundingClientRect();
    if (window.innerHeight - bottom < 220) {
      setMenuPlacement('top');
    } else {
      setMenuPlacement('bottom');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', prepareOrientation);
    document.addEventListener('touchstart', prepareOrientation);
    return () => {
      document.removeEventListener('mousedown', prepareOrientation);
      document.removeEventListener('touchstart', prepareOrientation);
    };
  }, [prepareOrientation]);

  const handleHackTouchEvent = useCallback(
    (event: TouchEvent) => {
      const target = event.target as HTMLDivElement;
      const isInModal = target.closest('[aria-modal="true"]');
      if (!isInModal) {
        return;
      }
      const isActive = !!target.closest(`[id="${componentId}"]`);
      if (!isActive) {
        return;
      }
      const id = target.getAttribute('id');
      if (!id || !/react-select-[0-9]+-option-[0-9]+/.test(id)) {
        return;
      }
      const [, optionId] = id?.match(/react-select-[0-9]+-option-([0-9]+)/) || [];
      if (!optionId) {
        return;
      }
      const selectedOption = availableOptions[Number(optionId)];
      const { current: select } = selectRef;
      if (!select) {
        return;
      }
      if (!isMulti) {
        select.setValue(selectedOption, 'select-option');
      }
      if (isMulti && Array.isArray(translateValue)) {
        select.setValue([...translateValue, selectedOption], 'select-option', selectedOption);
      }
    },
    [componentId, availableOptions, translateValue, isMulti, handleChange],
  );

  useEffect(() => {
    document.addEventListener('touchstart', handleHackTouchEvent);
    return () => {
      document.removeEventListener('touchstart', handleHackTouchEvent);
    };
  }, [handleHackTouchEvent]);

  const customStyles = useMemo(
    () => ({
      control: (provided: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
        ...provided,
        background: controlBackgroundColor,
        color: textColor,
        boxShadow: state.isFocused ? `0 0 0 1px ${outlineColor}` : provided.boxShadow,
        border: 0,
        borderRadius: unit(10),
      }),
      input: (provided: CSSObjectWithLabel) => ({
        ...provided,
        color: textColor,
      }),
      placeholder: (provided: CSSObjectWithLabel) => ({
        ...provided,
        color: textColor,
      }),
      dropdownIndicator: (provided: CSSObjectWithLabel) => ({
        ...provided,
        '& svg': {
          fill: textColor,
        },
      }),
      menu: (provided: CSSObjectWithLabel) => ({
        ...provided,
        background: menuBackgroundColor,
        borderRadius: unit(10),
      }),
      menuList: (provided: CSSObjectWithLabel) => ({
        ...provided,
        maxHeight: unit(200),
      }),
      option: (provided: CSSObjectWithLabel, state: { isSelected: boolean; isFocused: boolean }) => ({
        ...provided,
        color: textColor,
        cursor: 'pointer',
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: state.isSelected ? optionSelectedColor : state.isFocused ? optionHoverColor : 'transparent',
        '&:active': {
          backgroundColor: state.isSelected ? optionSelectedColor : optionHoverColor,
        },
      }),
      singleValue: (provided: CSSObjectWithLabel) => ({
        ...provided,
        color: textColor,
      }),
      multiValue: (provided: CSSObjectWithLabel) => ({
        ...provided,
        backgroundColor: multiValueColor,
        borderRadius: unit(14),
      }),
      multiValueLabel: (provided: CSSObjectWithLabel) => ({
        ...provided,
        color: textColor,
        fontFamily: '-apple-system, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif, "SF Pro Icons"',
      }),
      multiValueRemove: (provided: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#db332720' : 'transparent',
        '&:hover': {
          backgroundColor: '#db332720',
        },
      }),
    }),
    [textColor, controlBackgroundColor, menuBackgroundColor, outlineColor, optionHoverColor, optionSelectedColor, multiValueColor],
  );

  const wrapperStyles = useMemo(
    () => styleSheetToCss([styles.SelectInput, { marginTop: unit(gapTop), marginBottom: unit(gapBottom) }, style]),
    [gapTop, gapBottom, style],
  );

  return (
    <div role="none" style={wrapperStyles} id={componentId}>
      {!!label && (
        <Text size={15} style={styles.label} lightColor="#797979" darkColor="#B8B6BF">
          {label}
        </Text>
      )}
      {!optionsUrl && (
        <Select
          ref={selectRef}
          options={options}
          isMulti={isMulti}
          isClearable={isClearable}
          isSearchable={isSearchable}
          closeMenuOnSelect={closeOnSelect}
          value={translateValue}
          styles={customStyles}
          menuPlacement={menuPlacement}
          onChange={handleChange}
          placeholder={placeholder}
          components={components}
          isDisabled={isDisabled}
        />
      )}
      {!!optionsUrl && (
        <AsyncSelect
          ref={selectRef}
          loadOptions={loadOptions}
          isMulti={isMulti}
          isClearable={isClearable}
          isSearchable={isSearchable}
          closeMenuOnSelect={closeOnSelect}
          value={translateValue}
          styles={customStyles}
          menuPlacement={menuPlacement}
          onChange={handleChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  SelectInput: {
    width: '100%',
  },
  label: {
    display: 'flex',
    paddingLeft: unit(6),
    paddingBottom: unit(6),
  },
});

export default memo(SelectInput, (prevProps, nextProps) => {
  const { onChange: prevOnChange, ...prevPropsObjects } = prevProps;
  const { onChange: nextOnChange, ...nextPropsObjects } = nextProps;

  return prevOnChange === nextOnChange && isEqual(prevPropsObjects, nextPropsObjects);
});
