import { type FC, type ReactNode, memo, useCallback, useRef, useLayoutEffect, useEffect } from 'react';
import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
  type PanelResizeHandleProps,
  type PanelGroupProps,
  type ImperativePanelHandle,
} from 'react-resizable-panels';
import { isEqual } from 'lodash';
import { Box } from '@mui/joy';
import cn from 'classnames';

import { useCss } from 'hooks';

import useConfig from './model/useConfig';
import { useSetPanelsSize } from './model/usePanelsSize';
import { useSetPanelsResizing, useIsPanelsResizing } from './model/usePanelsResizing';

export { PanelsContextProvider } from './model/PanelsContextProvider';

export interface PanelsProps {
  storeKey?: string;
  varPrefix?: string;
  varTarget?: string;
  left: ReactNode;
  right: ReactNode;
  collapsedRight?: boolean;
}

const Panels: FC<PanelsProps> = (props) => {
  const { storeKey = 'panels', varTarget, varPrefix, left, right, collapsedRight } = props;

  const rightPanelRef = useRef<ImperativePanelHandle>(null);

  const { initialLeftSize, initialRightSize } = useConfig(storeKey, {
    defaultLeftWidth: 60,
    defaultRightWidth: 40,
  });

  const setPanelsSize = useSetPanelsSize(storeKey);
  const setPanelsResizing = useSetPanelsResizing(storeKey);
  const isPanelsResizing = useIsPanelsResizing(storeKey);

  const pointRef = useRef<HTMLDivElement>(null);

  /**
   * Утилита: измеряем текущую ширину контейнеров и ставим CSS-переменные
   * в пикселях (например, "--panels-left-width: 123px").
   */
  const updatePixelVars = useCallback(() => {
    const groupElement = pointRef.current?.closest('[data-panel-group]') as HTMLDivElement | null;
    const rightPanel = rightPanelRef.current;
    if (!groupElement || !rightPanel) {
      return;
    }
    const rightWidthPx = (groupElement.clientWidth - 42) * (rightPanel.getSize() / 100);
    const leftWidthPx = groupElement.clientWidth - 42 - rightWidthPx;
    const target = (varTarget ? document.querySelector(varTarget) : groupElement) as HTMLElement;
    if (!target) {
      return;
    }
    target.style.setProperty(`${varPrefix ? `${varPrefix}` : '--panels'}-right-width`, `${rightWidthPx}px`);
    target.style.setProperty(`${varPrefix ? `${varPrefix}` : '--panels'}-left-width`, `${leftWidthPx}px`);
  }, []);

  /**
   * Колбэк, вызываемый при каждом изменении (перетягивании) панелей.
   * Здесь сохраняем новые процентные размеры и обновляем пиксельные переменные.
   */
  const handleResize = useCallback<NonNullable<PanelGroupProps['onLayout']>>(
    (event) => {
      const [leftSize, rightSize] = event;
      setPanelsSize(leftSize, rightSize);
      window.requestAnimationFrame(updatePixelVars);
    },
    [setPanelsSize, updatePixelVars],
  );

  useEffect(() => {
    const { current: rightPanel } = rightPanelRef;
    if (!rightPanel) {
      return;
    }
    if (collapsedRight) {
      rightPanel.collapse();
    }
    if (!collapsedRight) {
      rightPanel.expand();
    }
    window.requestAnimationFrame(updatePixelVars);
  }, [collapsedRight]);

  useLayoutEffect(() => {
    window.requestAnimationFrame(updatePixelVars);
  }, []);

  const leftStyles = useCss({
    display: 'flex',
    flexDirection: 'column',
    paddingRight: !collapsedRight ? 20 : 0,
  });

  const rightStyles = useCss({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: !collapsedRight ? 20 : 0,
  });

  const resizeZoneStyles = useCss({
    background: 'color-mix(in srgb, var(--joy-palette-background-level1) 30%, var(--joy-palette-background-body) 70%)',
    width: 2,
    pointerEvents: !collapsedRight ? 'all' : 'none',
    opacity: !collapsedRight ? 1 : 0,
    '&:hover': {
      transform: 'scale(2, 1)',
      background: 'var(--joy-palette-background-level1)',
    },
  });

  const resizeZoneActiveStyles = useCss({
    transform: 'scale(2, 1)',
    background: 'var(--joy-palette-background-level2)',
    '&:hover': {
      background: 'var(--joy-palette-background-level2)',
    },
  });

  const handleDragging = useCallback<NonNullable<PanelResizeHandleProps['onDragging']>>(
    (isDragging) => {
      setPanelsResizing(isDragging);
    },
    [setPanelsResizing],
  );

  return (
    <PanelGroup direction="horizontal" onLayout={handleResize}>
      <Panel defaultSize={initialLeftSize} minSize={25} className={leftStyles}>
        <Box ref={pointRef} height={0} alignSelf="stretch" />
        {left}
      </Panel>
      <PanelResizeHandle className={cn(resizeZoneStyles, { [resizeZoneActiveStyles]: isPanelsResizing })} onDragging={handleDragging} />
      <Panel ref={rightPanelRef} defaultSize={initialRightSize} minSize={25} className={rightStyles} collapsible={collapsedRight} collapsedSize={0}>
        {right}
      </Panel>
    </PanelGroup>
  );
};

export default memo(Panels, isEqual);
