import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import type { SpaceMaterialFoldersType } from 'app/entities';

import type { SpaceRelationStore } from '../types';

const initialState: SpaceRelationStore = {
  materialFolders: {},
};

const spaceRelationSlice = createSlice({
  name: 'space/relation',
  initialState,
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    loadMaterialFolders: (state, action: PayloadAction<{ type: 'Content' | 'Document' | 'Note'; id: number }>) => {
      const { type, id } = action.payload;
      const key = `${type}-${id}`;
      if (!state.materialFolders[key]) {
        state.materialFolders[key] = { items: [], isLoading: true };
      } else {
        state.materialFolders[key].isLoading = true;
      }
    },
    loadMaterialFoldersDone: (state, action: PayloadAction<{ type: 'Content' | 'Document' | 'Note'; id: number }>) => {
      const { type, id } = action.payload;
      const key = `${type}-${id}`;
      state.materialFolders[key].isLoading = false;
    },
    setMaterialFolders: (
      state,
      action: PayloadAction<{
        type: 'Content' | 'Document' | 'Note';
        id: number;
        items: SpaceMaterialFoldersType[];
      }>,
    ) => {
      const { type, id, items } = action.payload;
      const key = `${type}-${id}`;
      state.materialFolders[key].items.splice(0);
      state.materialFolders[key].items.push(...items);
    },
  },
});

export const { reducer, actions } = spaceRelationSlice;
