import { useMemo, useEffect } from 'react';

import type { UserType, NoUserExistsType, BasicUserType } from 'app/entities';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

export interface UseUserResult<T> {
  data: T;
  loading: Array<string | number>;
}

type Param =
  | { login: string; email?: never; id?: never; reload?: boolean }
  | { email: string; login?: never; id?: never; reload?: boolean }
  | { id: number; login?: never; email?: never; reload?: boolean };

export default function useUser(param: Param): UseUserResult<UserType | BasicUserType | NoUserExistsType>;

export default function useUser(param1: Param, ...rest: Param[]): UseUserResult<(UserType | BasicUserType | NoUserExistsType)[]>;

export default function useUser(
  firstParam: Param,
  ...maybeRest: Param[]
): UseUserResult<(UserType | BasicUserType | NoUserExistsType) | (UserType | BasicUserType | NoUserExistsType)[]> {
  const dispatcher = useDispatcher();

  const requests = useMemo(() => {
    return [firstParam, ...maybeRest];
  }, [firstParam, maybeRest]);

  const dataFromStore = useSelector((state) => {
    return requests.map((req) => {
      if (req.login) {
        return userStore.selectors.getByLogin(req.login)(state);
      }
      if (req.email) {
        return userStore.selectors.getByEmail(req.email)(state);
      }
      if (typeof req.id === 'number') {
        return userStore.selectors.getById(req.id)(state);
      }
      return null;
    });
  });

  const loading = useSelector((state) => {
    return requests.reduce<Array<string | number>>((acc, req) => {
      const identifier = req.id! || req.login! || req.email!;
      if (userStore.selectors.isLoading(identifier)(state)) {
        acc.push(identifier);
      }
      return acc;
    }, []);
  });

  useEffect(() => {
    const toLoad: Array<
      { login: string; email?: never; id?: never } | { email: string; login?: never; id?: never } | { id: number; login?: never; email?: never }
    > = [];

    requests.forEach((req, index) => {
      const storedValue = dataFromStore[index];
      const doReload = !!req.reload;

      if (storedValue == null || doReload) {
        if (req.login) {
          toLoad.push({ login: req.login });
        } else if (req.email) {
          toLoad.push({ email: req.email });
        } else if (typeof req.id === 'number') {
          toLoad.push({ id: req.id });
        }
      }
    });

    if (toLoad.length > 0) {
      dispatcher.user.loadUser(toLoad);
    }
  }, [requests, dataFromStore, dispatcher]);

  return useMemo(() => {
    if (requests.length === 1) {
      const singleValue = dataFromStore[0] as UserType | BasicUserType | NoUserExistsType | null;
      return {
        data: singleValue ?? null,
        loading,
      };
    }
    const arrayValue = dataFromStore as Array<UserType | BasicUserType | NoUserExistsType | null>;
    return {
      data: arrayValue,
      loading,
    };
  }, [requests.length, dataFromStore, loading]) as unknown as UseUserResult<
    UserType | BasicUserType | NoUserExistsType | (UserType | BasicUserType | NoUserExistsType)[]
  >;
}
