import { useCallback, useMemo } from 'react';
import { useDispatcher } from 'store/utils/redux/hooks';

const useTutorialSteps = () => {
  const dispatcher = useDispatcher();

  const init = useCallback(() => {
    const isComplete = window?.localStorage?.getItem('tutorialStatus') === 'complete';
    if (isComplete) {
      return;
    }
    dispatcher.value.set('tutorialCurrentStep', 1);
  }, []);

  return useMemo(
    () => ({
      init,
      isCompleted: window?.localStorage?.getItem('tutorialStatus') === 'complete',
    }),
    [init],
  );
};

export default useTutorialSteps;
