import { cancel, put, type SagaReturnType, takeLatest } from 'redux-saga/effects';

import Alert from 'components/Alert';

import network from 'lib/network';
import type { SpaceType } from 'app/entities';
import { select, call } from 'store/utils/saga/effects';

import { actions } from '../slices/list';

export const config = {
  action: actions.update.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.update>) {
  const { id } = action.payload;
  const { title, description, cover, instruction, isPrivate } = action.payload.data;

  const space = yield* select((state) => state.spaceList.data?.[id]);
  if (!space) {
    yield put(actions.updateDone({ id }));
    yield cancel();
    return;
  }

  yield put(
    actions.set({
      data: {
        id,
        title,
        description,
        instruction,
        isPrivate,
      },
      mode: 'extend',
    }),
  );

  const { data, hasError, errors } = yield* call(() =>
    network
      .request<SpaceType>(`/stack-2/user/team-space/${id}`)
      .body({
        title: typeof title !== 'undefined' ? title : space.title,
        description: typeof description !== 'undefined' ? description : space.description,
        instruction: typeof instruction !== 'undefined' ? instruction : space.instruction,
        is_private: typeof isPrivate !== 'undefined' ? isPrivate : space.isPrivate,
      })
      .post(),
  );

  if (hasError || !data) {
    Alert.error(`Update space failed.`);
    yield put(actions.set({ data: space, mode: 'extend' }));
    yield cancel();
    return;
  }

  yield put(actions.set({ data, mode: 'extend' }));
  yield put(actions.updateDone({ id }));
}
