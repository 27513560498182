import { type FC, type ReactElement, type ForwardedRef, memo, useCallback } from 'react';
import { StyleSheet, TouchableOpacity, Platform } from 'react-native';

import { Text, View } from 'components/Themed';
import { unit } from 'utils';

import { type Controller } from '../../types';

interface ItemProps {
  panelRef?: ForwardedRef<Controller>;
  icon: ReactElement;
  label: string;
  onPress: (context: any) => void;
}

const Item: FC<ItemProps> = (props) => {
  const { panelRef, icon, label, onPress } = props;

  const handlePress = useCallback(() => {
    const context = panelRef && 'current' in panelRef ? panelRef?.current?.getContext?.() : {};
    onPress?.(context);
  }, [onPress, panelRef]);

  return (
    <TouchableOpacity style={styles.Item} onPress={handlePress} activeOpacity={0.8}>
      <View style={styles.icon}>{icon}</View>
      <Text style={styles[`${Platform.OS}Text` as 'webText' | 'iosText' | 'androidText']} size={16}>
        {label}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  Item: {
    flexDirection: 'row',
    paddingVertical: unit(12),
    alignItems: 'center',
  },
  icon: {
    marginRight: unit(10),
  },
  webText: {
    marginBottom: unit(2),
  },
  iosText: {},
  androidText: {},
});

export default memo(Item);
