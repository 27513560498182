import { takeLatest, put, type SagaReturnType, delay } from 'redux-saga/effects';

import { actions } from '../slices/list';

export const config = {
  action: actions.autoSave.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.autoSave>) {
  const { payload } = action;

  yield delay(5000);
  yield put(actions.update(payload));
}
