import { createCachedSelector } from 're-reselect';
import { type DefaultRootState } from 'react-redux';

const baseSelector = (state: DefaultRootState, { type, id }: { type: 'Content' | 'Document' | 'Note'; id: number }) => {
  const key = `${type}-${id}`;
  const data = state.spaceRelation.materialFolders?.[key];
  if (!data) {
    return {
      isRequested: false,
      isLoading: false,
      items: [],
    };
  }
  return {
    isRequested: true,
    isLoading: data.isLoading,
    items: data.items,
  };
};

const materialFoldersSelector = createCachedSelector(baseSelector, (result) => result)((_state, { type, id }) => `${type}-${id}`);

export default ({ type, id }: { type: 'Content' | 'Document' | 'Note'; id: number }) =>
  (state: DefaultRootState) => {
    return materialFoldersSelector(state, { type, id });
  };
