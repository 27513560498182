import { createAction } from '@reduxjs/toolkit';

export const ready = createAction('APP/READY');

export const prepared = createAction('APP/PREPARED');

export const hideSplashScreen = createAction('app/hideSplashScreen');

export const dialogShow = createAction('APP/DIALOG_SHOW', (id: string) => ({
  payload: {
    id,
  },
}));

export const dialogHide = createAction('APP/DIALOG_HIDE', (id: string) => ({
  payload: {
    id,
  },
}));

export const dialogRemove = createAction('APP/DIALOG_REMOVE', (id: string) => ({
  payload: {
    id,
  },
}));

export const initProtection = createAction('app/initProtection');

export const initProtectionDone = createAction('app/initProtectionDone');
