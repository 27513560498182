import { type FC, type PropsWithChildren } from 'react';
import { PanelsSizeContextProvider } from './panelsSizeContext';
import { PanelsResizingContextProvider } from './panelsResizingContext';

/**
 * Высокоуровневый провайдер, который внутри себя включает провайдер размеров и
 * провайдер флага "ресайзится ли сейчас". Вы можете использовать только его.
 */
export const PanelsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <PanelsSizeContextProvider>
      <PanelsResizingContextProvider>{children}</PanelsResizingContextProvider>
    </PanelsSizeContextProvider>
  );
};
