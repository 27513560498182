import { useMemo } from 'react';

import { usePerson } from 'hooks';

import subscriptionAppSumoConfig from 'data/subscriptionAppSumoConfig.json';
import subscriptionStripeConfig from 'data/subscriptionStripeConfig.json';

const useSubscriptionConfig = (): typeof subscriptionStripeConfig => {
  const { person } = usePerson('User', 'my');
  return useMemo(() => {
    if (!person) {
      return subscriptionStripeConfig;
    }
    if (person?.isAppsumo) {
      return subscriptionAppSumoConfig as unknown as typeof subscriptionStripeConfig;
    }
    return subscriptionStripeConfig;
  }, [person?.isAppsumo, subscriptionStripeConfig, subscriptionAppSumoConfig]);
};

export default useSubscriptionConfig;
