import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { isEqual } from 'lodash';
import { Box, CardContent, Typography } from '@mui/joy';

import * as spaceStore from 'screens/Space/store';

import { type AuthorType, type ImageType, type UserType } from 'app/entities';

import CompositePoster from 'components/CompositePoster';

import Icon from 'ui/Icon';
import useAuthor from '../model/useAuthor';
import { useCardPoster, useCardProps } from '../model/CardContext';

import AuthorBlock from '../ui/AuthorBlock';

interface SpaceFolderProps {
  handleAuthorPress?: (value: UserType | AuthorType | null) => void;
}

const SpaceFolder: FC<SpaceFolderProps> = (props) => {
  const { id } = useCardProps();

  const { handleAuthorPress } = props;

  const data = useSelector(spaceStore.selectors.folderById(id));
  const posterSources = useCardPoster();
  const { person } = useAuthor(data);

  const handlePress = useCallback(() => {
    if (!person) {
      return;
    }
    handleAuthorPress?.(person);
  }, [person]);

  return (
    <>
      <Box
        sx={{
          '@container (width > 400px )': {
            display: 'none',
          },
        }}
      >
        {posterSources && posterSources.length > 0 && <CompositePoster sources={posterSources} radius={8} aspectRatio={4 / 3} />}
        {(!posterSources || (posterSources && posterSources.length === 0)) && (
          <Box
            display="flex"
            flex={1}
            borderRadius={8}
            alignItems="center"
            justifyContent="center"
            sx={{
              contain: 'layout',
              containerType: 'inline-size',
              aspectRatio: 4 / 3,
              backgroundColor: 'color-mix(in srgb, var(--joy-palette-background-level1) 60%, var(--joy-palette-background-level2) 40%)',
            }}
          >
            <Icon
              name="rectangle-history"
              weight="thin"
              size="1x"
              sx={{
                opacity: 0.5,
                fontSize: '48px',
                '@container (width < 250px )': {
                  fontSize: '40px',
                },
                '@container (width < 200px )': {
                  fontSize: '32px',
                },
                '@container (width < 150px )': {
                  fontSize: '26px',
                },
                '@container (width < 100px )': {
                  fontSize: '20px',
                },
                '@container (width < 50px )': {
                  fontSize: '16px',
                },
              }}
            />
          </Box>
        )}
      </Box>
      <CardContent
        sx={{
          '--title-align': 'center',
          '--title-px': '16px',
          '--description-display': '-webkit-box',
          '@container (width > 400px )': {
            '--title-align': 'left',
            '--title-px': '0',
            '--description-display': 'none',
          },
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{
              '@container (width <= 400px )': {
                gap: 1.5,
              },
            }}
            flex={1}
          >
            {data?.title && (
              <Typography fontSize={18} fontWeight={500} sx={{ textAlign: 'var(--title-align)', px: 'var(--title-px)' }}>
                {data?.title}
              </Typography>
            )}
            {data?.description && (
              <Typography
                fontSize={16}
                sx={{
                  display: 'var(--description-display)',
                  wordBreak: 'break-word',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {data?.description}
              </Typography>
            )}
            <Box display="flex" flexDirection="row">
              <Typography
                fontSize={11}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {pluralize('item', data?.childrenCount, true)}
              </Typography>
              <Box flex={1} />
              {person && <AuthorBlock onPress={handlePress} createData={data?.createdAt} data={person} variant="slim" />}
            </Box>
          </Box>
          {posterSources && posterSources.length > 0 && (
            <Box
              sx={{
                ml: 1,
                '@container (width <= 400px )': {
                  display: 'none',
                },
              }}
            >
              <CompositePoster width={120} sources={posterSources} radius={8} aspectRatio={16 / 9} />
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default memo(SpaceFolder, isEqual);
