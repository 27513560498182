import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import type { SpaceType } from 'app/entities';

import type { SpaceRootStore } from '../types';

const initialState: SpaceRootStore = {
  data: null,
  isLoading: false,
};

const noteSlice = createSlice({
  name: 'space/root',
  initialState,
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    init: () => undefined,
    initDone: () => undefined,
    load: (state) => {
      state.isLoading = true;
    },
    loadDone: (state) => {
      state.isLoading = false;
    },
    set: (state, action: PayloadAction<{ data: SpaceType[] }>) => {
      const { data } = action.payload;
      state.data = {};

      data.forEach((space) => {
        state.data![space.id] = space;
      });
    },
  },
});

export const { reducer, actions } = noteSlice;
