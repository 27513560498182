import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { type SpaceContentType } from 'app/entities';

import { gtm } from 'utils';
import * as messageStore from 'store/nodes/message';
import * as spaceStore from 'screens/Space/store';
import { call, select } from 'store/utils/saga/effects';

import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type } = context;

  if (type !== 'library_add' || !context.data) {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  const data = context.data as SourceEntityType;
  const spaceLibraryId = yield* select(spaceStore.selectors.libraryIdBySpaceId(data.teamSpaceId));
  if (!data || !spaceLibraryId) {
    yield cancel();
    return;
  }
  const entity = sourceEntityToSpaceEntity(spaceLibraryId, data) as SpaceContentType;
  yield put(spaceStore.slices.spaceResource.actions.add({ entity, position: 'first' }));

  yield call(() =>
    gtm.dataLayer.push({
      event: 'add_to_folder',
      data_type: data.type,
      resource_id: entity.resourceId,
      resource_type: entity.resourceType,
      space_id: entity.spaceId,
      folder_id: entity.parentId,
    }),
  );
}
