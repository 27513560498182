import { type FC, memo } from 'react';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';

export interface HtmlPluginProps {
  onHtmlChanged: (html: string) => void;
}

const HtmlPlugin: FC<HtmlPluginProps> = (props) => {
  const { onHtmlChanged } = props;
  const [editor] = useLexicalComposerContext();

  return (
    <OnChangePlugin
      onChange={(editorState) => {
        editorState.read(() => {
          onHtmlChanged($generateHtmlFromNodes(editor));
        });
      }}
    />
  );
};

export default memo(HtmlPlugin);
