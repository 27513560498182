import { cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';
import { call, select } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import network from 'lib/network';

import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

import { actions as resourceActions } from '../slices/resource';
import * as selectors from '../selectors';

export const config = {
  action: resourceActions.loadFolder.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof resourceActions.loadFolder>) {
  const { folderId } = action.payload;

  const { data, hasError, errors } = yield* call(() =>
    network.request<SourceEntityType[]>(`/stack-2/team-space-folder`).query({ ids: folderId }).get(),
  );

  if (hasError || !data) {
    Alert.error(errors);
    yield put(resourceActions.loadFolderDone({ folderId }));
    yield cancel();
    return;
  }

  const libraryIds = yield* select(selectors.libraryIds);
  const items = sourceEntityToSpaceEntity(libraryIds, data);

  for (const item of items) {
    yield put(resourceActions.set({ id: item.id, data: item, mode: 'extend' }));
  }

  yield put(resourceActions.loadFolderDone({ folderId }));
}
