import type { NoteType, ResultLegacy } from 'app/entities';
import request from 'services/api/request';

export default async (resourceId: number, text: string): Promise<ResultLegacy<NoteType>> => {
  const { data, error } = await request.put<NoteType>(`/stack-1/content/${resourceId}/notes`, { body: { text } });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
