import { merge } from 'lodash';
import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import type { ModalType, ModalParams } from './types';

const selector = createCachedSelector(
  (state: DefaultRootState, name: ModalType, defaultParams: any) => name in state.modal.opened,
  (state: DefaultRootState, name: ModalType, defaultParams: any) => state.modal.opened[name] || null,
  (state: DefaultRootState, name: ModalType, defaultParams: any) => defaultParams,
  (isOpen, storedParams, defaultParams) => {
    const params = merge({}, defaultParams, storedParams);
    return {
      isOpen,
      params,
    };
  },
)((_, name, defaultParams) => `${name}::${JSON.stringify(defaultParams)}`);

export const state =
  <T extends ModalType>(name: T, defaultParams: Partial<ModalParams[T]> = {}) =>
  (s: DefaultRootState) =>
    selector(s, name, defaultParams) as never as {
      isOpen: boolean;
      params: ModalParams[T];
    };
