import type { PaginationResult, PaginationInput, ActivityType } from 'app/entities';
import { guard, requestCursorModifier } from 'utils';
import network from 'lib/network';

type LoginType = 'my' | string;

async function fetchUserActivity(
  login: LoginType = 'my',
  pagination: PaginationInput = { pageSize: 10, cursor: null },
): Promise<PaginationResult<ActivityType>> {
  const query = buildQuery(pagination);
  const url = getActivityUrl(login);

  const activityResponse = await loadActivity(url, query);
  if (!activityResponse.data) {
    return {
      data: null,
      error: activityResponse.error || new Error('Unknown'),
    };
  }

  // const items = prepareActivityItems(activityResponse.data.items);

  return {
    data: {
      items: activityResponse.data.items,
      paginationInfo: {
        hasNext: activityResponse.data.paginationInfo.hasNext,
        nextCursor: activityResponse.data.paginationInfo.nextCursor,
      },
    },
    error: null,
  };
}

function buildQuery(pagination: PaginationInput): PaginationInput {
  return {
    pageSize: pagination.pageSize || 10,
    cursor: pagination.cursor || undefined,
  };
}

function getActivityUrl(login: LoginType): string {
  switch (login) {
    case 'my':
      return '/stack-1/user/activity/feed';
    default:
      return `/stack-1/user/${login}/activity`;
  }
}

async function loadActivity(url: string, query: PaginationInput): Promise<PaginationResult<ActivityType>> {
  const response = await network.request<ActivityType[]>(url).query(query).get(requestCursorModifier());

  if (response.hasError || !response.data) {
    return {
      data: null,
      error: response.errors?.[0] || null,
    };
  }

  return {
    data: {
      items: response.data.items,
      paginationInfo: {
        hasNext: response.data.hasNext,
        nextCursor: response.data.nextCursor,
      },
    },
    error: null,
  };
}

// function prepareActivityItems(items: ActivityType[]): ActivityType[] {
//   return items.map((item) => {
//     if (guard.isActivityNewMaterial(item) || guard.isActivityCommentMaterial(item) || guard.isActivityCommentDocument(item)) {
//       const preparedItem = { ...item };
//       preparedItem.resourceType = 'Material';
//       preparedItem.resourceId = (item as any).item.id;
//       if ('content' in preparedItem) {
//         delete preparedItem.content;
//       }
//       return {
//         ...item,
//         contentId: {
//           id: item.content.id,
//         },
//       };
//     }
//     if (guard.isActivityNewCollection(item) || guard.isActivityCommentCollection(item) || guard.isActivityCommentDocument(item)) {
//       return item;
//     }
//     return item;
//   });
// }

export default fetchUserActivity;
