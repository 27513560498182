import { type FC, memo, useCallback, useMemo } from 'react';
import { Box } from '@mui/joy';
import { isEqual } from 'lodash';

import AdaptivePage from 'components/AdaptivePage';
import PlusButton from 'components/PlusButton';
import AddNoteButton from 'components/AddNoteButton';
import SearchInput from 'components/SearchInput';
import Panels from 'components/Panels';

import useGlobalDragCounter from 'hooks/useGlobalDragCounter';

import { CopilotModelSelect, CopilotShowToggle, CopilotUtilityBar } from 'widgets/Copilot';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';

import useLoadError from '../model/useLoadError';
import { useFolderRoute } from '../model/folderRoute';
import { SelectionProvider } from '../model/SelectionContext';
import usePermissionsGetter from '../model/usePermissionsGetter';

import FolderContent from '../elements/FolderContent';
import FolderCopilot from '../elements/FolderCopilot';

interface FolderProps {}

const Folder: FC<FolderProps> = () => {
  const dispatcher = useDispatcher();

  const { spaceId, folderId, itemId } = useFolderRoute();
  const { hasError, message: errorMessage } = useLoadError(spaceId, folderId);

  const isRightBarShow = useSelector((state) => copilotStore.selectors.rightBarIsShow(state));

  const spaceName = useSelector((state) => (!spaceId ? '' : state.spaceList?.data?.[spaceId]?.title));

  const permissionsGetter = usePermissionsGetter();
  const isDragActiveGlobal = useGlobalDragCounter();

  const hadPermissionWrite = useMemo(() => {
    if (!itemId) {
      return false;
    }
    return permissionsGetter.get(itemId)?.hasAction('WRITE');
  }, [permissionsGetter, itemId]);

  const handleAcceptedFiles = useCallback(
    async (files?: File[]) => {
      if (!files) {
        return;
      }
      dispatcher.plus.open({
        hasChangeFolder: false,
        parseFiles: {
          files: Array.from(files),
          spaceId,
          folderId,
          privacy: true,
        },
      });
    },
    [spaceId, folderId],
  );

  return (
    <SelectionProvider>
      <AdaptivePage
        hasTopBar
        desktopHeaderLeftPanel={false}
        desktopHeaderMiddlePanel={
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={2}
            alignSelf="flex-end"
            flex={1}
            width="100%"
            sx={{ containerType: 'inline-size' }}
          >
            <SearchInput
              key="space-search"
              size="medium"
              spaceId={spaceId}
              placeholder={hasError ? 'Search' : `Search in space "${spaceName}"`}
              disabled={hasError}
              width="80%"
              sx={{
                '@container (width < 800px )': {
                  width: '100%',
                },
              }}
            />
            <Box flex={1} />
            <CopilotShowToggle mr={0} isShowForce={hasError ? false : undefined} disabled={hasError} />
          </Box>
        }
        desktopHeaderRightPanel={
          <CopilotUtilityBar
            isShowForce={hasError ? false : undefined}
            minWidth="calc(var(--panels-space-folder-right-width) + 13px)"
            left={
              <>
                <CopilotUtilityBar.Item canHidden>
                  <CopilotModelSelect ml={2} />
                </CopilotUtilityBar.Item>
              </>
            }
            right={
              <>
                <AddNoteButton />
                <PlusButton />
              </>
            }
          />
        }
        bodyMaxWidth={1600}
        paddingHorizontalScheme={{ 320: 16 }}
        scrollToStart
      >
        <Panels
          storeKey="space/folder/panels"
          varPrefix="--panels-space-folder"
          varTarget="body"
          collapsedRight={!isRightBarShow}
          left={
            <FolderContent
              spaceId={spaceId}
              folderId={folderId}
              hadPermissionWrite={hadPermissionWrite}
              isDragActiveGlobal={isDragActiveGlobal}
              handleAcceptedFiles={handleAcceptedFiles}
            />
          }
          right={<>{spaceId && folderId && <FolderCopilot spaceId={spaceId} folderId={folderId} />}</>}
        />
      </AdaptivePage>
    </SelectionProvider>
  );
};

export default memo(Folder, isEqual);
