import { memo, useEffect, useMemo } from 'react';
import { Box, Typography, useColorScheme } from '@mui/joy';

import { useSelector } from 'store/utils/redux/hooks';
import * as askStore from 'store/nodes/ask';

import AdaptivePage from 'components/AdaptivePage';
import { useScrollMethods } from 'components/Scroller';
import AddNoteButton from 'components/AddNoteButton';
import PlusButton from 'components/PlusButton';

import Copilot, { CopilotUtilityBar, CopilotModelSelect, CopilotResetButton, CopilotShowToggle } from 'widgets/Copilot';
import PanelsBar from 'widgets/PanelsBar';

import FolderContent from 'screens/Space/elements/FolderContent';
import FolderCopilot from 'screens/Space/elements/FolderCopilot';
import Panels from 'components/Panels';
import * as copilotStore from 'widgets/Copilot/store';
import SearchResult from './components/SearchResult';
import SourceSelect from '../../components/SourceSelect';
import WebSearchToggle from '../../components/WebSearchToggle';
import DeepResearchToggle from '../../components/DeepResearchToggle';

import TopBarDesktop from './ui/TopBarDesktop';
import TopBarMobile from './ui/TopBarMobile';

const Regular = () => {
  const { mode } = useColorScheme();
  const { scrollToEnd } = useScrollMethods();
  const scope = useSelector(askStore.selectors.scope);
  const isRightBarShow = useSelector((state) => copilotStore.selectors.rightBarIsShow(state));

  useEffect(() => {
    setTimeout(() => {
      scrollToEnd();
    }, 0);
  }, []);

  const pagePaddingHorizontalScheme = useMemo(
    () => ({
      320: 16,
    }),
    [],
  );

  const contentMeta = useMemo(
    () => ({
      label: 'Ask',
      value: 'ask',
    }),
    [],
  );

  const copilotSlotProps = useMemo(
    () => ({
      input: {
        footer: (
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            mx={1.25}
            pb={1.5}
            gap={0.5}
            sx={{
              '@media (max-width: 767px)': {
                display: 'none',
                pb: 0,
              },
            }}
          >
            <WebSearchToggle size="small" />
            <DeepResearchToggle size="small" />
            <SourceSelect size="small" />
          </Box>
        ),
      },
      body: { sx: { mt: 0.375 } },
    }),
    [],
  );

  const contentRender = useMemo(
    () => (
      <>
        {scope === 'web' && <Copilot variant="web" windowScroll slotsProps={copilotSlotProps} />}
        {scope === 'research' && <Copilot variant="research" windowScroll slotsProps={copilotSlotProps} />}
        {scope === 'library-fulltext' && <Copilot variant="library-fulltext" windowScroll slotsProps={copilotSlotProps} />}
        {scope === 'global-fulltext' && <Copilot variant="global-fulltext" windowScroll slotsProps={copilotSlotProps} />}
      </>
    ),
    [scope, copilotSlotProps],
  );

  const panelRender = useMemo(
    () => [
      {
        label: 'Found materials',
        value: 'copilot',
        component: <SearchResult />,
      },
    ],
    [],
  );

  return (
    <AdaptivePage
      hasTopBar
      desktopHeaderLeftPanel={
        <>
          <CopilotModelSelect />
          <TopBarMobile />
        </>
      }
      desktopHeaderMiddlePanel={
        <Box display="flex" flexDirection="row" alignSelf="flex-end" gap={1}>
          <CopilotResetButton resources={[{ type: 'web' }, { type: 'global-fulltext' }, { type: 'library-fulltext' }, { type: 'research' }]} />
          <CopilotShowToggle mr={0} />
        </Box>
      }
      desktopHeaderRightPanel={
        <CopilotUtilityBar
          minWidth="calc(var(--panels-ask-right-width) + 13px)"
          left={
            <CopilotUtilityBar.Item canHidden>
              <Typography
                ml={1.75}
                fontSize={13}
                px={2}
                lineHeight="32px"
                borderRadius={16}
                bgcolor={mode === 'light' ? 'var(--joy-palette-neutral-700)' : 'var(--joy-palette-common-white)'}
                textColor={mode === 'light' ? 'var(--joy-palette-neutral-100)' : 'var(--joy-palette-neutral-900)'}
              >
                Found materials
              </Typography>
            </CopilotUtilityBar.Item>
          }
          right={
            <>
              <AddNoteButton />
              <PlusButton />
            </>
          }
        />
      }
      bodyMaxWidth={1600}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
    >
      <Panels
        storeKey="ask/panels"
        varPrefix="--panels-ask"
        varTarget="body"
        collapsedRight={!isRightBarShow}
        left={contentRender}
        right={<SearchResult />}
      />

      {/* <Box sx={{ '@media (max-width: 767px)': { mt: 5 } }}>
         <PanelsBar
          contentMeta={contentMeta}
          content={contentRender}
          panels={panelRender}
          slotProps={{
            tabs: {
              sx: {
                '@media (min-width: 768px)': {
                  display: 'none',
                },
              },
            },
          }}
          sx={{
            '@media (max-width: 767px)': {
              mx: -2,
            },
          }}
        /> 
      </Box> */}
    </AdaptivePage>
  );
};

export default memo(Regular);
