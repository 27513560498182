import { type PropsWithChildren, memo, useState, useMemo, useCallback } from 'react';
import { type StyleProp, StyleSheet, type ViewStyle } from 'react-native';

import type { ImageType } from 'app/entities';
import { unit } from 'utils';

import Picture from 'components/Picture';
import { View } from 'components/Themed';
import { Box } from '@mui/joy';
import { useThemeColor } from 'hooks';

export interface CompositePosterProps {
  style?: StyleProp<ViewStyle>;
  width?: number | string;
  sources: (ImageType | string | undefined)[];
  radius?: number;
  aspectRatio?: number | 'none';
}

const CompositePoster = (props: PropsWithChildren<CompositePosterProps>) => {
  const { style, width, children, sources, radius = 5, aspectRatio = 1 } = props;
  //                                   100       400        800
  const [sizes, setSizes] = useState<('small' | 'medium' | 'large')[]>(['small', 'small', 'small', 'small']);
  const sourcesFinal = useMemo(() => sources.filter((source) => !!source).slice(0, 4), [sources]);
  const emptyBgColor = useThemeColor({ light: '#EAEAEA', dark: '#282828' });

  const handleLayout = useCallback(
    ({ nativeEvent }: any) => {
      const { layout } = nativeEvent;
      if (sourcesFinal.length === 4 && layout.width <= 300) {
        setSizes(['small', 'small', 'small', 'small']);
      }
      if (sourcesFinal.length === 4 && layout.width > 300) {
        setSizes(['medium', 'medium', 'medium', 'medium']);
      }
      if (sourcesFinal.length === 3 && layout.width <= 300) {
        setSizes(['medium', 'small', 'small']);
      }
      if (sourcesFinal.length === 3 && layout.width > 300) {
        setSizes(['large', 'medium', 'medium']);
      }
      if (sourcesFinal.length === 2 && layout.width <= 300) {
        setSizes(['medium', 'medium']);
      }
      if (sourcesFinal.length === 2 && layout.width > 300) {
        setSizes(['large', 'large']);
      }
      if (sourcesFinal.length === 1 && layout.width <= 300) {
        setSizes(['medium']);
      }
      if (sourcesFinal.length === 1 && layout.width > 300) {
        setSizes(['large']);
      }
    },
    [sourcesFinal],
  );

  const componentStyles = useMemo(() => {
    let result: StyleProp<ViewStyle> = {
      position: 'relative',
    };
    if (style) {
      result = {
        ...result,
        ...StyleSheet.flatten(style),
      };
    }
    if (width) {
      result.width = typeof width === 'string' ? width : unit(width);
    }
    if (aspectRatio && aspectRatio !== 'none') {
      result.aspectRatio = aspectRatio;
    }
    return result;
  }, [aspectRatio, width, style]);

  const renderChildren = useMemo(() => {
    if (!children) {
      return null;
    }
    return <View style={styles.children}>{children}</View>;
  }, [children]);

  const [source1, source2, source3, source4] = sourcesFinal;

  if (source1 && source2 && source3 && source4) {
    return (
      <View style={componentStyles} onLayout={handleLayout}>
        <View style={[styles.row, styles.flex1]}>
          <Picture source={source1} size={sizes[0]} hasBackground radius={radius} style={[styles.flex1, styles.marginBottom2, styles.marginRight2]} />
          <Picture source={source2} size={sizes[1]} hasBackground radius={radius} style={[styles.flex1, styles.marginBottom2, styles.marginLeft2]} />
        </View>
        <View style={[styles.row, styles.flex1]}>
          <Picture source={source3} size={sizes[2]} hasBackground radius={radius} style={[styles.flex1, styles.marginTop2, styles.marginRight2]} />
          <Picture source={source4} size={sizes[3]} hasBackground radius={radius} style={[styles.flex1, styles.marginTop2, styles.marginLeft2]} />
        </View>
        {renderChildren}
      </View>
    );
  }

  if (source1 && source2 && source3) {
    return (
      <View style={componentStyles} onLayout={handleLayout}>
        <View style={[styles.row, styles.flex1]}>
          <Picture source={source1} size={sizes[0]} hasBackground radius={radius} style={[styles.flex1, styles.marginBottom4]} />
        </View>
        <View style={[styles.row, styles.flex1]}>
          <Picture source={source2} size={sizes[1]} hasBackground radius={radius} style={[styles.flex1, styles.marginRight2]} />
          <Picture source={source3} size={sizes[2]} hasBackground radius={radius} style={[styles.flex1, styles.marginLeft2]} />
        </View>
        {renderChildren}
      </View>
    );
  }

  if (source1 && source2) {
    return (
      <View style={componentStyles} onLayout={handleLayout}>
        <Picture source={source1} size={sizes[0]} hasBackground radius={radius} style={[styles.flex1, styles.marginBottom2]} />
        <Picture source={source2} size={sizes[1]} hasBackground radius={radius} style={[styles.flex1, styles.marginTop2]} />
        {renderChildren}
      </View>
    );
  }

  if (source1) {
    return (
      <View style={componentStyles} onLayout={handleLayout}>
        <Picture source={source1} size={sizes[0]} hasBackground radius={radius} style={styles.flex1} />
        {renderChildren}
      </View>
    );
  }

  if (!source1) {
    return (
      <View style={componentStyles} onLayout={handleLayout}>
        <Box display="flex" flex={1} bgcolor={emptyBgColor} borderRadius={radius} />
      </View>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  posterCols: {
    width: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  children: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  row: {
    flexDirection: 'row',
  },
  image: {
    flex: 0.5,
  },
  flex1: {
    flex: 1,
  },
  marginBottom4: {
    marginBottom: unit(4),
  },
  marginBottom2: {
    marginBottom: unit(2),
  },
  marginTop2: {
    marginTop: unit(2),
  },
  marginRight2: {
    marginRight: unit(2),
  },
  marginLeft2: {
    marginLeft: unit(2),
  },
});

export default memo(CompositePoster);
