import type { AuthorType, UserType } from 'app/entities';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as authorStore from 'store/nodes/author';
import { useEffect, useMemo } from 'react';

type Result<T> = {
  person: T | null;
  isExists: boolean;
  isLoading: boolean;
};

type UsePersonHook = {
  (
    params: { type: 'User'; login: 'my' | string } | { type: 'User'; id: number } | { type: 'Author'; id: number } | { type: 'User' | 'Author' } | {},
  ): Result<UserType | AuthorType>;
  (type: 'User', login?: 'my' | string, loadMode?: 'full' | 'basic'): Result<UserType>;
  (type: 'User', id?: number): Result<UserType>;
  (type: 'User', id?: number[]): Result<UserType[]>;
  (type: 'Author', id?: number): Result<AuthorType>;
  (type: 'None'): Result<null>;
};

/**
 * @deprecated
 * @param param1
 * @param param2
 * @param param3
 */
const usePerson: UsePersonHook = (
  param1:
    | 'User'
    | 'Author'
    | 'None'
    | { type: 'User'; login: 'my' | string }
    | { type: 'User'; id: number }
    | { type: 'Author'; id: number }
    | { type: 'User' | 'Author' }
    | {},
  param2?: 'my' | string | number | number[],
  param3: 'full' | 'basic' = 'full',
) => {
  const dispatcher = useDispatcher();

  const type = useMemo(() => {
    if (typeof param1 === 'object' && 'type' in param1) {
      return param1.type;
    }
    return param1;
  }, [param1]);

  const value = useMemo(() => {
    if (typeof param1 === 'object' && 'type' in param1) {
      if (param1.type === 'User' && 'login' in param1 && param1.login) {
        return String(param1.login);
      }
      if (param1.type === 'User' && 'id' in param1 && param1.id) {
        return Number(param1.id);
      }
      if (param1.type === 'Author' && 'id' in param1 && param1.id) {
        return Number(param1.id);
      }
    }
    return param2 ?? null;
  }, [param1, param2]);

  const isLoading = useSelector((state) => {
    if (!type || !value) {
      return false;
    }
    if (type === 'User' && typeof value === 'string') {
      return userStore.selectors.isLoading(value)(state);
    }
    if (type === 'Author' && typeof value === 'number') {
      return authorStore.selectors.isLoading(value)(state);
    }
    return false;
  });

  const person = useSelector((state) => {
    if (type === 'User' && typeof value === 'string') {
      return userStore.selectors.getByLogin(value)(state);
    }
    if (type === 'User' && (typeof value === 'number' || Array.isArray(value))) {
      return userStore.selectors.getById(value)(state);
    }
    if (type === 'Author' && typeof value === 'number') {
      return authorStore.selectors.getById(value)(state);
    }
    return 'impossible';
  });

  useEffect(() => {
    if (!type || !value) {
      return;
    }
    if (person === 'impossible') {
      return;
    }
    if (isLoading) {
      return;
    }
    if (person !== null) {
      return;
    }

    if (type === 'User' && typeof value === 'string' && value !== 'my' && param3 === 'basic') {
      dispatcher.user.loadUser([{ login: value }]);
    }
    if (type === 'User' && typeof value === 'string' && value !== 'my' && param3 === 'full') {
      dispatcher.user.loadProfile(value);
    }
    if (type === 'Author' && typeof value === 'number') {
      dispatcher.author.loadProfile(value);
    }
  }, [type, value, person, isLoading, param3, dispatcher]);

  return useMemo(() => {
    if (person === 'impossible') {
      return {
        person: null,
        isExists: false,
        isLoading: false,
      };
    }
    return {
      person: person as any,
      isExists: person != null,
      isLoading,
    };
  }, [person, isLoading]);
};

export default usePerson;
