import { memo, useCallback } from 'react';
import { Box, Button, Tooltip } from '@mui/joy';

import Icon from 'ui/Icon';
import { useNavigate } from 'navigation/hooks';
import useMyLibrary from 'screens/Space/model/useMyLibrary';

const NewDocumentButton = () => {
  const navigate = useNavigate();
  const myLibrary = useMyLibrary();

  const handleClick = useCallback(() => {
    navigate('Note', {
      id: `${myLibrary.spaceId}::${myLibrary.entityId}::create`,
    });
  }, [navigate, myLibrary]);

  return (
    <Tooltip arrow variant="outlined" size="sm" title="Create a new document">
      <Button
        variant="soft"
        color="neutral"
        sx={{
          fontWeight: 400,
          py: 3,
          borderRadius: 34,
          flex: 1,
          '@media (max-width: 460px)': {
            borderRadius: 22,
            py: 1.5,
          },
        }}
        endDecorator={
          <Box>
            <Icon family="kit" name="light-file-pen" color="inherit" />
          </Box>
        }
        onClick={handleClick}
      >
        New document
      </Button>
    </Tooltip>
  );
};

export default memo(NewDocumentButton);
