import { type SagaReturnType, put, cancel, takeLatest } from 'redux-saga/effects';

import network from 'lib/network';
import { call } from 'store/utils/saga/effects';
import * as collectionStore from 'store/nodes/playlist';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.addMaterialsToCollection.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.addMaterialsToCollection>) {
  const { collectionId, uploadSessionId } = action.payload;

  if (!collectionId) {
    yield put(actions.addMaterialsToCollectionDone());
    yield put(actions.close());
    Alert.error('Unknown error');
    yield cancel();
    return;
  }

  if (!uploadSessionId) {
    yield put(actions.setErrorResult('Error input data'));
    yield put(actions.parseDone());
    yield put(actions.close());
    Alert.error('Unknown error');
    yield cancel();
    return;
  }

  const { errors } = yield* call(() =>
    network
      .request<null>(`/stack-1/user/playlists/${collectionId}/updateWithBulk`)
      .body({ uploadSessionId } || {})
      .post(),
  );

  if (errors) {
    yield put(actions.setErrorResult(errors?.[0].message || 'Unknown error'));
    yield put(actions.addMaterialsToCollectionDone());
    yield put(actions.close());
    Alert.error(errors);
    yield cancel();
    return;
  }

  yield put(actions.addMaterialsToCollectionDone());
  yield put(collectionStore.actions.loadById({ id: collectionId }));
}
