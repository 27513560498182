import request from 'services/api/request';

import type { HighlightType, ResourceVariant, ResultLegacy } from 'app/entities';

export default async (resource: ResourceVariant, resourceId: number, text: string): Promise<ResultLegacy<HighlightType>> => {
  const { data, error } = await request.post<HighlightType>(`/stack-1/${resource}/${resourceId}/highlights`, { body: { text } });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
