import { memo, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useRoute } from 'navigation/hooks';

import * as contentStore from 'store/nodes/content';
import { useDispatcher } from 'store/utils/redux/hooks';

import PanelsBar from 'widgets/PanelsBar';

import usePanelsRender from '../hooks/usePanelsRender';
import useContentRender from '../hooks/useContentRender';

const LayoutDefault = () => {
  const route = useRoute<'Content'>();
  const { resourceId } = route.params;
  const dispatcher = useDispatcher();

  const data = useSelector(contentStore.selectors.dataById(resourceId));

  useEffect(() => {
    if (data?.id) {
      setTimeout(() => {
        dispatcher.copilot.open();
      }, 100);
    }
    return () => {
      // dispatcher.copilot.clear();
    };
  }, [data?.id]);

  const contentRender = useContentRender(data);
  const panelsRender = usePanelsRender(data);

  if (!data) {
    return null;
  }

  return (
    <PanelsBar
      contentMeta={{
        label: 'Content',
        value: 'content',
      }}
      content={contentRender}
      panels={panelsRender}
      sx={{
        '@media (max-width: 767px)': {
          mx: -2,
        },
      }}
    />
  );
};

export default memo(LayoutDefault);
