import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import { Box } from '@mui/joy';

import Tags from 'components/Tags';

interface DecoratedTagsProps {
  items?: string[];
  limit?: number;
  hidden?: boolean;
}

const DecoratedTags: FC<DecoratedTagsProps> = (props) => {
  const { items, limit = 10, hidden = false } = props;

  if (hidden || !Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        mr: 'auto',
        '--joy-palette-background-surface': 'transparent',
        '& .MuiChip-label': {
          'font-weight': '400!important',
        },
      }}
    >
      <Tags data={{ tags: items }} tagsLimit={limit} size="md" />
    </Box>
  );
};

export default memo(DecoratedTags, isEqual);
