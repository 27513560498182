import { type FC, memo } from 'react';

import Icon, { type IconNames } from 'components/LegacyIcon';

export interface MenuIconProps {
  name: IconNames;
  isSelected?: boolean;
  size?: number;
}

const MenuIcon: FC<MenuIconProps> = (props) => {
  const { name, size = 28, isSelected } = props;

  return <Icon name={name} darkColor={isSelected ? '#ffffff' : '#ffffff'} lightColor={isSelected ? '#000000' : '#000000'} size={size} />;
};

export default memo(MenuIcon);
