import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type MessageId = string;

export interface MessageStore {
  payload: Record<
    MessageId,
    {
      message: string;
      context: any;
    }
  >;
  queue: MessageId[];
  preventIds: MessageId[];
}

const initialState: MessageStore = {
  payload: {},
  queue: [],
  preventIds: [],
};

const noteSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    addToQueue(state, action: PayloadAction<{ id: MessageId; message: string; context: any }>) {
      const { id, message, context } = action.payload;
      state.payload[id] = {
        message,
        context,
      };
      state.queue.push(id);
    },
    remove(state, action: PayloadAction<{ id: MessageId }>) {
      const { id } = action.payload;
      delete state.payload[id];
      const queueIndex = state.queue.indexOf(id);
      if (queueIndex > -1) {
        state.queue.splice(queueIndex, 1);
      }
      const preventIndex = state.preventIds.indexOf(id);
      if (preventIndex > -1) {
        state.queue.splice(preventIndex, 1);
      }
    },
    prevent(state, action: PayloadAction<{ id: MessageId }>) {
      const { id } = action.payload;
      state.preventIds.push(id);
    },
  },
});

export const { reducer, actions } = noteSlice;
