import { useRoute } from 'navigation/hooks';

const useContentIdFromUrl = (screen: 'Material' | 'Document' | 'Collection') => {
  const route = useRoute();

  if (screen === 'Material' && !!route && route.isScreen('Content') && !!route.params && 'resourceId' in route.params && route.params.resourceId) {
    return Number(route.params.resourceId);
  }

  return null;
};

export default useContentIdFromUrl;
