import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';

import * as modalStore from 'widgets/modals/store';

import PermissionLayout from './layout/PermissionLayout';
import useSpaceById from '../model/useSpaceById';

export interface PermissionParams {
  spaceId: number;
}

const Permission = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('SpacePermission'));

  const { space } = useSpaceById(params?.spaceId);

  const handleDone = useCallback(() => {
    dispatcher.modal.close('SpacePermission');
  }, []);

  return (
    <Modal open={isOpen} onClose={handleDone}>
      <ModalDialog
        variant="soft"
        color="neutral"
        size="lg"
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 'min(540px, calc(100% - 32px))',
          maxWidth: 'min(670px, calc(100% - 32px))',
          borderRadius: '1.25rem',
          '--Card-padding': '2.5rem',
          '--FormLabel-color': '--joy-palette-neutral-plainDisabledColor',
          '--variant-solidDisabledBg': 'var(--joy-palette-background-level2)',
        }}
      >
        <ModalClose onClick={handleDone} size="sm" sx={{ borderRadius: '1rem', m: 2 }} />
        <PermissionLayout
          header={
            <Box display="flex" flexDirection="column" gap={1} flex={1} pb={1}>
              <Typography fontSize={17} fontWeight={500}>
                {space?.title}
              </Typography>
              <Divider sx={{ mt: 0.5 }} />
            </Box>
          }
          spaceId={params?.spaceId}
          onDone={handleDone}
        />
      </ModalDialog>
    </Modal>
  );
};

export default memo(Permission);
