import { type FC, memo, useCallback, useEffect } from 'react';
import { Box, Button } from '@mui/joy';

import Icon from 'ui/Icon';

import { useDispatcher } from 'store/utils/redux/hooks';
import { waitResult } from 'widgets/modals/store/utils';

import { useSelection } from '../../../model/SelectionContext';
import useStickToElement from '../../../model/useStickToElement';

interface SelectionBarProps {
  detectPositionSelector?: string;
  spaceId?: number;
  folderId?: string;
}

const SelectionBar: FC<SelectionBarProps> = (props) => {
  const dispatcher = useDispatcher();
  const { detectPositionSelector, spaceId, folderId } = props;
  const { selectedKeys, resetSelection } = useSelection();

  useEffect(() => {
    resetSelection();
  }, [spaceId, folderId]);

  const { left, right } = useStickToElement(detectPositionSelector);

  const handleDelete = useCallback(() => {
    dispatcher.spaceResource.remove({ entityIds: selectedKeys.map((item) => item.id) });
    resetSelection();
  }, [selectedKeys, resetSelection]);

  const handleToFolder = useCallback(async () => {
    dispatcher.modal.open('ResourcesToFolder', {
      mode: folderId === 'root' ? 'entityAdd' : 'entityMove',
      resourceIds: selectedKeys.map((item) => item.id),
    });
    const { isCancel } = await waitResult<{ isCancel: boolean }>('ResourcesToFolder');
    if (!isCancel) {
      resetSelection();
    }
  }, [selectedKeys, folderId, resetSelection]);

  return (
    <Box
      position="fixed"
      bottom={16}
      display="flex"
      flexDirection="row"
      zIndex={5}
      left={left}
      right={right}
      justifyContent="center"
      sx={{
        opacity: selectedKeys.length === 0 ? 0 : 1,
        transition: 'opacity 200ms ease',
        pointerEvents: 'none',
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        bgcolor="background.body"
        boxShadow="var(--joy-shadow-xs)"
        p={3}
        gap={2}
        alignItems="center"
        borderRadius="calc(84px / 2)"
        sx={{
          pointerEvents: selectedKeys.length === 0 ? 'none' : 'all',
        }}
      >
        {folderId === 'root' && (
          <Button startDecorator={<Icon name="folder-open" color="inherit" />} variant="soft" color="neutral" onClick={handleToFolder}>
            Add to collection
          </Button>
        )}
        {folderId !== 'root' && (
          <Button startDecorator={<Icon name="folder-open" color="inherit" />} variant="soft" color="neutral" onClick={handleToFolder}>
            Move to collection
          </Button>
        )}
        <Button
          startDecorator={<Icon name="trash" color="inherit" />}
          variant="soft"
          color="neutral"
          sx={{ color: 'var(--joy-palette-danger-plainColor)' }}
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button variant="soft" color="neutral" onClick={resetSelection}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default memo(SelectionBar);
