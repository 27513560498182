import { cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';

import type { SpaceUserPermissionType } from 'app/entities';

import network from 'lib/network';
import { call } from 'store/utils/saga/effects';

import { actions } from '../slices/permission';
import sourceUserPermissionToSpaceUserPermission, { type SourceUserPermissionType } from '../../model/sourceUserPermissionToSpaceUserPermission';

export const config = {
  action: actions.loadUserPermissions.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadUserPermissions>) {
  const { data } = action.payload;
  if (!Array.isArray(data) || data.length === 0) {
    yield cancel();
    return;
  }

  const ids = data.map((item) => (typeof item === 'number' ? item : item.id));

  const items = yield* call(() =>
    network
      .request<SourceUserPermissionType[]>(`/stack-2/user/team-space/actions`)
      .query({ ids: ids.join(',') })
      .get()
      .then((response) => response.data),
  );

  const map: Record<number, SpaceUserPermissionType> = {};
  items?.forEach((item) => {
    map[item.teamSpaceId] = sourceUserPermissionToSpaceUserPermission(item);
  });

  if (!items || items.length === 0) {
    yield cancel();
    return;
  }

  yield put(actions.setUserPermissions({ items: sourceUserPermissionToSpaceUserPermission(items) }));
  yield put(actions.loadUserPermissionsDone({ ids: !Array.isArray(data) ? ids[0] : ids }));
}
