import { useMemo } from 'react';

import type { CardPropsWithBoxProps } from '../types';

export default function useCardTargetProps(props: CardPropsWithBoxProps) {
  const {
    id,
    type,
    parent,
    hasDescription,
    descriptionLineLimit,
    hasTags,
    tagLimit,
    footerStart,
    hasSummary,
    hasHighlight,
    hasQueue,
    hasRemove,
    hasToCollection,
    hasLink,
    hasEdit,
    hasAuthor,
    hasIndexed,
    AuthorBlockComponent,
    ...restBoxProps
  } = props;

  const cardProps = useMemo(() => {
    return {
      id,
      type,
      parent,
      hasDescription,
      descriptionLineLimit,
      hasTags,
      tagLimit,
      footerStart,
      hasSummary,
      hasHighlight,
      hasQueue,
      hasRemove,
      hasToCollection,
      hasLink,
      hasEdit,
      hasAuthor,
      hasIndexed,
      AuthorBlockComponent,
    };
  }, [
    id,
    type,
    parent,
    hasDescription,
    descriptionLineLimit,
    hasTags,
    tagLimit,
    footerStart,
    hasSummary,
    hasHighlight,
    hasQueue,
    hasRemove,
    hasToCollection,
    hasLink,
    hasEdit,
    hasAuthor,
    hasIndexed,
    AuthorBlockComponent,
  ]);

  return useMemo(
    () => ({
      cardProps,
      restBoxProps,
    }),
    [cardProps, restBoxProps],
  );
}
