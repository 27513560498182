import type { EntityDataType, EntityErrorType, EntityType } from 'app/entities';
import network from 'lib/network';

import { guard, prepareHtml, requestCursorModifier } from 'utils';

function getCursorFromUrl(url: string): string | null {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get('cursor');
  } catch (e) {
    return null;
  }
}

/**
 * Загружает одну страницу и проверяет, что там действительно документ.
 * Если пришла ошибка или не тот тип, возвращаем EntityErrorType.
 */
async function fetchDocumentPage(id: number, query: string) {
  const baseUrl = `/stack-2/user/document/${id}`;
  const url = `${baseUrl}?${query}`;

  const response = await network.request<EntityType>(url).get(requestCursorModifier());
  const { data, errors } = response;

  if (!data || (errors && errors.length > 0)) {
    return {
      type: 'UnknownError',
      id,
      data: null,
    } as EntityErrorType<'UnknownError'>;
  }

  const { items, hasNext, nextUrl } = data;

  if (guard.isEntityError(items)) {
    return items;
  }

  if (!guard.isEntityDocument(items)) {
    return {
      type: 'UnknownError',
      id,
      data: null,
    } as EntityErrorType<'UnknownError'>;
  }

  return {
    entity: items,
    hasNext,
    nextUrl,
  };
}

/**
 * Загружает документ по ID, подшивает все страницы (если allPages=true).
 * Возвращает либо финальный документ, либо EntityErrorType (если произошла ошибка).
 */
async function loadPages(id: number, spaceId?: number, pageSize = 10000, allPages = true): Promise<EntityType> {
  const firstPage = await fetchDocumentPage(id, `${spaceId ? `teamSpaceId=${spaceId}&` : ''}pageSize=${pageSize}`);
  if (guard.isEntityError(firstPage)) {
    return firstPage;
  }

  const { entity } = firstPage;
  let { hasNext, nextUrl } = firstPage;
  let fullText = entity.data.text || '';

  while (allPages && hasNext && nextUrl) {
    const nextCursor = getCursorFromUrl(nextUrl);
    if (!nextCursor) {
      return {
        type: 'UnknownError',
        id,
        data: null,
      } as EntityErrorType<'UnknownError'>;
    }

    const nextPage = await fetchDocumentPage(
      id,
      `${spaceId ? `teamSpaceId=${spaceId}&` : ''}pageSize=${pageSize}&cursor=${encodeURIComponent(nextCursor)}`,
    );
    if (guard.isEntityError(nextPage)) {
      return nextPage;
    }

    fullText += nextPage.entity.data.text || '';
    hasNext = nextPage.hasNext;
    nextUrl = nextPage.nextUrl;
  }

  const preparedHtml = await prepareHtml({ text: fullText }, { async: true });

  const finalEntity: EntityDataType<'Document'> = {
    type: 'Document',
    id,
    data: {
      ...entity.data,
      isEditable: fullText.length <= 400000,
      text: preparedHtml || '',
    },
  };

  return finalEntity;
}

export default loadPages;
