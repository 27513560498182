import { createAction } from '@reduxjs/toolkit';

import type { PaginationType, RagMaterialType, SearchItemType } from 'app/entities';

export const triggerSearch = createAction('search/trigger', (payload: { spaceId: number }) => ({
  payload: {
    spaceId: payload.spaceId,
    triggerReload: true,
  },
}));

export const clear = createAction('search/clear');

export const setFilterTypeValue = createAction('search/setFilterTypeValue', (id: string | string[], triggerReload = true) => ({
  payload: {
    id,
    triggerReload,
  },
}));

export const toggleFilterTypeValue = createAction('search/toggleFilterTypeValue', (id: string | string[], triggerReload = true) => ({
  payload: {
    id,
    triggerReload,
  },
}));

export const setFilterText = createAction(
  'search/setFilterText',
  (payload: { spaceId: number; value: string; triggerReload: boolean; immediateUpdate: boolean }) => ({
    payload: {
      spaceId: Number(payload.spaceId),
      value: payload?.value || '',
      triggerReload: payload?.triggerReload || true,
      immediateUpdate: payload?.immediateUpdate || false,
    },
  }),
);

export const setFilterTags = createAction('search/setFilterTags', (tags?: string | string[] | null, triggerReload = true) => {
  let value: string[] = [];
  if (Array.isArray(tags) && tags.length > 0) {
    value = [...new Set(tags)];
  }
  if (typeof tags === 'string' && tags.length > 0) {
    value = [tags];
  }
  return {
    payload: {
      value,
      triggerReload,
    },
  };
});

export const loadPage = createAction('search/loadPage', (payload: { spaceId: number }) => ({
  payload,
}));

export const loadPageDone = createAction(
  'search/loadPageDone',
  (payload: { status: 'has-results' | 'no-results' | 'nothing-to-search' | 'error' | null }) => ({
    payload,
  }),
);

export const loadNextPage = createAction('search/loadNextPage');

export const loadNextPageDone = createAction(
  'search/loadNextPageDone',
  (payload: { status: 'has-results' | 'no-results' | 'nothing-to-search' | 'error' | null }) => ({
    payload,
  }),
);

export const addPage = createAction('search/setPage', (items: SearchItemType[], paginationInfo: PaginationType, reload = false) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));

export const setRagMaterial = createAction('search/setRagMaterial', (items: RagMaterialType[] | null) => ({
  payload: {
    items,
  },
}));

export const updateRagMaterialState = createAction('search/setRagMaterialState', (payload: { data: { id: string; isAdded: boolean }[] }) => ({
  payload,
}));

export const logOut = createAction('search/logout');
