import { useState, useCallback, useEffect, useMemo } from 'react';

type KeyId = string;

type DisplayMode = 'grid' | 'list' | 'table';

type Mode<T> = Record<KeyId, T>;

interface UseDisplayModeParams<T extends DisplayMode> {
  key: string;
  allowedValues: T[];
  defaultValue: T;
}

const useDisplayMode = <T extends DisplayMode>({ key, allowedValues, defaultValue }: UseDisplayModeParams<T>) => {
  const getInitialValue = () => {
    let initialState: Mode<T> = {};
    try {
      initialState = JSON.parse(localStorage.getItem('display-mode') || '{}');
    } catch {
      initialState = {};
    }
    return initialState;
  };

  const [modeMap, setModeMap] = useState<Mode<T>>(getInitialValue);

  useEffect(() => {
    if (modeMap) {
      localStorage.setItem('display-mode', JSON.stringify(modeMap));
    }
  }, [modeMap]);

  const mode = useMemo(() => modeMap[key] || defaultValue, [modeMap[key], defaultValue]);

  const setModeExplicit = useCallback(
    (newMode: T) => {
      if (allowedValues.includes(newMode)) {
        setModeMap((prevState) => {
          return {
            ...prevState,
            [key]: newMode,
          };
        });
      }
    },
    [key, allowedValues],
  );

  const toggleMode = useCallback(() => {
    const currentIndex = allowedValues.indexOf(modeMap[key] || defaultValue);
    const nextIndex = (currentIndex + 1) % allowedValues.length;
    setModeMap((prevState) => {
      return {
        ...prevState,
        [key]: allowedValues[nextIndex],
      };
    });
  }, [allowedValues, modeMap, key, defaultValue]);

  return useMemo(
    () => ({
      mode,
      setMode: setModeExplicit,
      toggleMode,
    }),
    [mode, setModeExplicit, toggleMode],
  );
};

export default useDisplayMode;
