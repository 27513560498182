import { memo, type FC, type PropsWithChildren } from 'react';
import { ThemeProvider as MaterialThemeProvider, THEME_ID as MATERIAL_THEME_ID } from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { MUiJoyTheme, MUiMaterialTheme } from 'lib/theme/MUiJoyTheme';

const Provider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MaterialThemeProvider
      theme={{ [MATERIAL_THEME_ID]: MUiMaterialTheme }}
      colorSchemeStorageKey="theme.type"
      modeStorageKey="theme.type"
      defaultMode="dark"
    >
      <JoyCssVarsProvider theme={MUiJoyTheme} colorSchemeStorageKey="theme.type" modeStorageKey="theme.type" defaultMode="dark">
        <CssBaseline enableColorScheme />
        {children}
      </JoyCssVarsProvider>
    </MaterialThemeProvider>
  );
};

export default memo(Provider);
