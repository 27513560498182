import { memo } from 'react';
import { type BoxProps, Box, IconButton, useColorScheme } from '@mui/joy';
import * as Popover from '@radix-ui/react-popover';
import { isEqual } from 'lodash';

import Icon from 'ui/Icon';

import useFilter from './model/useFilter';

import Label from './ui/Label';
import Item from './ui/Item';

export interface FilterSelectProps extends BoxProps {
  label?: string;
  size?: 'small' | 'regular';
}

const FilterSelect = (props: FilterSelectProps) => {
  const { label, size = 'regular', ...restProps } = props;

  const { mode } = useColorScheme();
  const { available, selected, handleClick } = useFilter();

  return (
    <Box display="flex" flexDirection="row" gap={1} {...restProps}>
      {available.map((item) => (
        <Item key={item.id} id={item.id} label={item.label} selected={selected.includes(item.id)} onClick={handleClick} />
      ))}
      {/* <Popover.Root onOpenChange={handleOpenChange}>
        <Popover.Trigger asChild>
          <IconButton size={size === 'small' ? 'sm' : 'md'} variant="plain" color="neutral">
            {!isEnabled && <Icon name="sliders" weight="light" fw size="1x" sx={{ transform: 'translateX(0)' }} />}
            {isEnabled && <Icon family="kit" name="light-sliders-gear" fw size="1x" sx={{ transform: 'translateX(1.75px)' }} />}
          </IconButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            sideOffset={6}
            style={{
              backgroundColor:
                mode === 'light'
                  ? 'var(--joy-palette-background-popup)'
                  : 'color-mix(in srgb, var(--joy-palette-background-popup) 15%, var(--joy-palette-background-level1) 85%)',
              borderRadius: 'var(--joy-radius-lg)',
              padding: '8px 12px 8px 8px',
              boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)',
            }}
          >
            {!!label && <Label value={label} onClick={handleReset} enabled={isEnabled} />}
            {available.map((item) => (
              <Item key={item.id} id={item.id} label={item.label} selected={selected.includes(item.id)} onClick={handleClick} />
            ))}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root> */}
    </Box>
  );
};

export default memo(FilterSelect, isEqual);
