import { type FC, type MouseEventHandler, memo } from 'react';
import { Box, Button, Typography, type BoxProps } from '@mui/joy';

export interface LabelProps extends Omit<BoxProps, 'onClick'> {
  value: string;
  enabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Label: FC<LabelProps> = (props) => {
  const { value, enabled, onClick } = props;
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mx={1} py={1}>
      <Typography textColor="text.primary" fontSize={12} fontWeight={500} sx={{ opacity: 0.5 }}>
        {value}
      </Typography>
      {enabled && (
        <Button
          variant="plain"
          color="danger"
          size="sm"
          sx={{
            fontSize: 12,
            fontWeight: 500,
            minHeight: '1rem',
            mr: -1.5,
            my: -0.5,
          }}
          onClick={onClick}
        >
          reset
        </Button>
      )}
    </Box>
  );
};

export default memo(Label);
