import { useCallback, useMemo } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import { intersection } from 'lodash';

import type { SpaceUserPermissionType } from 'app/entities';

import * as userStore from 'store/nodes/user';

import { parseItemId } from '../model/itemIdParser';

interface HasResolver {
  (name: 'READ' | 'WRITE' | 'DELETE' | 'INVITE' | 'CHANGE_PRIVACY'): boolean;
  (names: ('READ' | 'WRITE' | 'DELETE' | 'INVITE' | 'CHANGE_PRIVACY')[]): boolean;
}

interface SpaceUserPermissionResolver extends SpaceUserPermissionType {
  hasAction: HasResolver;
  actionsToString: () => string;
}

interface SpaceUserPermissionGetter {
  get(id: number): (SpaceUserPermissionType & { hasAction: HasResolver; actionsToString: () => string }) | null;
  get(itemId: string): (SpaceUserPermissionType & { hasAction: HasResolver; actionsToString: () => string }) | null;
}

function usePermissionsGetter(): SpaceUserPermissionGetter {
  const user = useSelector(userStore.selectors.getMy);
  const permissionMap = useSelector((state: DefaultRootState) => state.spacePermission.userPermissions);

  const permissions = useMemo<Record<number, SpaceUserPermissionResolver> | null>(() => {
    if (!user || !permissionMap) {
      return null;
    }

    const result: Record<number, SpaceUserPermissionResolver> = {};

    for (const [spaceIdStr, spacePermissions] of Object.entries(permissionMap)) {
      const spaceId = Number(spaceIdStr);
      const myPermission = spacePermissions.find((perm) => perm.user.id === user.id);
      if (myPermission) {
        const actions = myPermission.action.map((item) => item.name);
        result[spaceId] = {
          ...myPermission,
          actionsToString: () => {
            return actions.join(';');
          },
          hasAction: (nameOrNames) => {
            if (typeof nameOrNames === 'string') {
              return actions.includes(nameOrNames);
            }
            return intersection(actions, nameOrNames).length > 0;
          },
        };
      }
    }
    return result;
  }, [permissionMap, user]);

  const getter = useCallback(
    (idOrItemId: number | string) => {
      const spaceId = typeof idOrItemId === 'string' ? parseItemId(idOrItemId).spaceId : idOrItemId;
      const permission = permissions?.[spaceId];
      if (!permission) {
        return null;
      }
      return permission;
    },
    [permissions],
  );

  return { get: getter };
}

export default usePermissionsGetter;
