import { createSelector } from 'reselect';
import type { DefaultRootState } from 'react-redux';
import type { SpaceType } from 'app/entities';

const getSpacesSequence = (state: DefaultRootState) => state.spaceList.sequence;
const getSpaceData = (state: DefaultRootState) => state.spaceList.data;
const getSpaceHidden = (state: DefaultRootState) => state.spaceList.hidden;

export const selectSpaces = createSelector(
  [getSpacesSequence, getSpaceData, getSpaceHidden],
  (spacesSequence, spaceData, spaceHidden): SpaceType[] => {
    if (!spacesSequence || !spaceData || spacesSequence.length === 0) {
      return [];
    }
    return spacesSequence.map((spaceId) => spaceData[spaceId]).filter((item) => !spaceHidden.includes(item.id));
  },
);

export default selectSpaces;
