import { createAction } from '@reduxjs/toolkit';

import type { AuthorType, CommunityEntityType, CommunityItemVariant, LinkToContentType, UserType } from 'app/entities';

export const loadCollections = createAction('community/loadCollections');

export const loadCollectionDone = createAction('community/loadCollectionsDone');

export const setCollection = createAction(
  'community/setCollection',
  (data: CommunityEntityType<UserType | AuthorType | LinkToContentType<'Collection'>>[]) => ({
    payload: {
      data,
    },
  }),
);

export const setPanelName = createAction('community/setPanelName', (name: string) => ({
  payload: {
    name,
  },
}));

export const logOut = createAction('community/logout');
