import { useState, useCallback, useEffect, useRef } from 'react';

/**
 * Хук, который «приклеивает» элемент по left/right
 * к целевому блоку (найденному по CSS-селектору).
 * Используем ResizeObserver, чтобы реагировать на изменения размера самого блока.
 */
function useStickToElement(selector: string | undefined) {
  const [positions, setPositions] = useState<{ left: number; right: number }>({
    left: 0,
    right: 0,
  });

  // Храним ссылку на целевой элемент, чтобы не искать его в доме постоянно
  const elementRef = useRef<HTMLElement | null>(null);

  const computePositions = useCallback(() => {
    if (!elementRef.current) {
      setPositions({ left: 0, right: 0 });
      return;
    }
    const rect = elementRef.current.getBoundingClientRect();
    setPositions({
      left: rect.left,
      right: window.innerWidth - rect.right - 10,
    });
  }, []);

  useEffect(() => {
    // Если нет селектора, обнуляем позиции
    if (!selector) {
      elementRef.current = null;
      setPositions({ left: 0, right: 0 });
      return undefined;
    }

    // Находим элемент по селектору
    const el = document.querySelector(selector) as HTMLElement | null;
    elementRef.current = el;
    if (!el) {
      console.log('no element found');
      setPositions({ left: 0, right: 0 });
      return undefined;
    }

    // Первичное вычисление
    computePositions();

    // === Подписываемся на события прокрутки и resize окна ===
    window.addEventListener('resize', computePositions, { passive: true });
    window.addEventListener('scroll', computePositions, { passive: true, capture: true });

    // === Используем ResizeObserver, чтобы отслеживать изменения размеров целевого блока ===
    const resizeObserver = new ResizeObserver(() => {
      computePositions();
    });
    resizeObserver.observe(el);

    return () => {
      window.removeEventListener('resize', computePositions);
      window.removeEventListener('scroll', computePositions, true);
      resizeObserver.disconnect();
    };
  }, [selector, computePositions]);

  return positions;
}

export default useStickToElement;
