import { call, cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';
import { select } from 'store/utils/saga/effects';

import network from 'lib/network';

import { actions as resourceActions } from '../slices/resource';
import { actions } from '../slices/structure';
import spaceById from '../selectors/spaceById';
import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

export const config = {
  action: actions.loadPage.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadPage>) {
  const { spaceId, parentIds } = action.payload;
  const space = yield* select(spaceById(spaceId));

  if (!space) {
    yield put(actions.loadPageDone({ spaceId, parentIds }));
    yield cancel();
    return;
  }

  const responses: { data: SourceEntityType[] | null; hasError: boolean; errors: Error[] | null }[] = yield call(() =>
    Promise.all(
      parentIds.map((parentId) =>
        network.request<SourceEntityType[]>(`/stack-2/user/team-space/${spaceId}/${parentId}/structure`).query({ pageSize: 100 }).get(),
      ),
    ),
  );

  for (let key = 0; key < responses.length; key++) {
    const entities = sourceEntityToSpaceEntity(space.libraryId, responses[key]?.data || []);
    yield put(
      resourceActions.setPage({
        data: entities,
        treeName: 'structure',
      }),
    );
    yield put(actions.loadPageDone({ spaceId, parentIds }));
  }
}
