import { useEffect, useState, useRef } from 'react';

/**
 * Анимирует построковый вывод текста "от пустого до полного" при каждом обновлении `text`.
 * @param text Полный текст, который нужно "допечатать" с нуля
 * @param speed Интервал (мс) между символами
 */
export default function useTypewriter(text: string, speed = 30) {
  const [displayedText, setDisplayedText] = useState('');
  const intervalRef = useRef<any | null>(null);

  useEffect(() => {
    if (!text) {
      setDisplayedText('');
      return undefined;
    }

    let currentIndex = 0;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setDisplayedText('');

    intervalRef.current = setInterval(() => {
      currentIndex++;
      setDisplayedText(text.slice(0, currentIndex));

      if (currentIndex >= text.length) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, speed);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [text, speed]);

  return displayedText;
}
