import { type MouseEventHandler, type MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Popover from '@radix-ui/react-popover';

import { useDispatcher } from 'store/utils/redux/hooks';

import * as spaceStore from '../../../../../store';

function useFilter() {
  const dispatcher = useDispatcher();

  const allAvailable = useSelector(spaceStore.selectors.availableFilters);
  const selected = useSelector(spaceStore.selectors.selectedFilter);

  const available = useMemo(() => allAvailable.filter((item) => item.type === 'TAG'), [allAvailable]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, itemId: string) => {
      if (selected.includes(itemId)) {
        dispatcher.spaceResource.disableFilter({ id: itemId });
      } else {
        dispatcher.spaceResource.enableFilter({ id: itemId });
      }
    },
    [selected],
  );

  return useMemo(
    () => ({
      available,
      selected,
      handleClick,
    }),
    [available, selected, handleClick],
  );
}

export default useFilter;
