import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DialogTitle, Modal, ModalClose, ModalDialog, DialogContent, DialogActions, Box, Button, Typography, List, ListItem } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';

import * as modalStore from 'widgets/modals/store';
import * as plusSelectors from 'widgets/Plus/store/selectors';

const YoutubeAuth = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('YoutubeAuth'));
  const youtubeProcessingState = useSelector(plusSelectors.youtubeProcessingState);

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('YoutubeAuth');
  }, []);

  const handleSetCode = useCallback(async () => {
    if (!params?.userCode || !params.verificationUrl) {
      return;
    }
    dispatcher.plus.openYoutubeActivation(params);
  }, [params?.userCode, params?.verificationUrl]);

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          maxWidth: 640,
          '--ModalClose-radius': '1.125em',
        }}
      >
        <ModalClose onClick={handleModalClose} />
        <DialogTitle sx={{ mt: -1 }}>YouTube</DialogTitle>
        <DialogContent
          sx={{
            '--Card-padding': '3rem',
          }}
        >
          {youtubeProcessingState !== 'device-activation' && (
            <>
              <Typography>To enable transcription, please log into your YouTube account by following these steps:</Typography>
              <List component="ol" marker="decimal">
                <ListItem>Click &quot;Copy and Authorize&quot; to copy the authorization code and open the YouTube login window.</ListItem>
                <ListItem>Paste the authorization code into the appropriate field.</ListItem>
                <ListItem>Follow the on-screen instructions to complete the login process.</ListItem>
                <ListItem>Once authorization is complete, close the login window and return to IKI.</ListItem>
              </List>
              <Typography fontSize={28} textAlign="center">
                {params?.userCode || ''}
              </Typography>
            </>
          )}
          {youtubeProcessingState === 'device-activation' && (
            <Typography textAlign="center" my={2}>
              Authorizing you, please wait a few seconds...
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Box display="flex" justifyContent="center" flex={1} gap={1}>
            <Button
              color="neutral"
              onClick={handleSetCode}
              loading={youtubeProcessingState === 'code-activation' || youtubeProcessingState === 'device-activation'}
            >
              {youtubeProcessingState === 'device-activation' ? 'Processing' : 'Copy and Activate'}
            </Button>
          </Box>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(YoutubeAuth);
