import type { MaterialType, PaginationInput, PaginationResult } from 'app/entities';
import network from 'lib/network';
import { requestCursorModifier } from 'utils';

async function fetchAuthorContent(id: number, pagination?: PaginationInput): Promise<PaginationResult<number>> {
  if (!id) {
    return {
      data: {
        items: [],
        paginationInfo: {
          nextCursor: null,
          hasNext: false,
        },
      },
      error: null,
    };
  }

  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination.cursor;
  }

  const { data, hasError, errors } = await network
    .request<Pick<MaterialType, 'id'>[]>(`/stack-1/author/${id}/content`)
    .query(query)
    .get(requestCursorModifier());

  if (hasError || !data) {
    return {
      data: null,
      error: errors?.[0] || null,
    };
  }

  return {
    data: {
      items: data.items.map((item) => item.id),
      paginationInfo: {
        nextCursor: data.nextUrl,
        hasNext: Boolean(data.nextUrl),
      },
    },
    error: null,
  };
}

export default fetchAuthorContent;
