import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { SpaceMaterialFoldersType } from 'app/entities';

import * as spaceStore from 'screens/Space/store';
import { useDispatcher } from 'store/utils/redux/hooks';

interface UseMaterialFolders {
  isLoading: boolean;
  items: SpaceMaterialFoldersType[];
}

const useMaterialFolders = (type: 'Content' | 'Document' | 'Note', id: number): UseMaterialFolders => {
  const { isLoading, items } = useSelector(spaceStore.selectors.materialFolders({ type, id }));
  const [isRequested, setIsRequested] = useState(false);
  const dispatcher = useDispatcher();

  useEffect(() => {
    if (!isRequested && !isLoading) {
      dispatcher.spaceRelation.loadMaterialFolders({ type, id });
    }
    setIsRequested(true);
  }, [isRequested, isLoading]);

  return {
    isLoading,
    items,
  };
};

export default useMaterialFolders;
