import React, { type PropsWithChildren, type FC, createContext, useContext, memo, useMemo } from 'react';
import { isEqual } from 'lodash';

import { guard } from 'utils';
import type {
  CollectionType,
  MaterialType,
  NoteType,
  RagMaterialType,
  SpaceFolderType,
  DocumentType,
  ContentErrorType,
  ImageType,
} from 'app/entities';
import type { CardProps } from '../types';

import useIsIndexing from '../model/useIsIndexing';

const CardPropsContext = createContext<CardProps | null>(null);

const CardDataContext = createContext<
  CollectionType | MaterialType | RagMaterialType | NoteType | DocumentType | SpaceFolderType | ContentErrorType | null
>(null);

const CardPosterContext = createContext<ImageType[] | string[] | null>(null);

const CardDataLoadedContext = createContext<boolean>(false);

const CardTagsContext = createContext<string[] | null>(null);

const CardIndexContext = createContext<{
  isIndexing?: boolean | null;
  isIndexed?: boolean | null | undefined;
}>({});

export function useCardProps() {
  const context = useContext(CardPropsContext);
  if (!context) {
    throw new Error('useCardProps must be used within a CardPropsProvider');
  }
  return context;
}

export function useCardData() {
  return useContext(CardDataContext);
}

export function useCardPoster() {
  return useContext(CardPosterContext);
}

export function useCardDataLoaded() {
  return useContext(CardDataLoadedContext);
}

export function useCardTags() {
  return useContext(CardTagsContext);
}

export const useCardIndexState = () => {
  return useContext(CardIndexContext);
};

export interface CardProviderProps extends PropsWithChildren {
  props: CardProps;
  data: CollectionType | MaterialType | RagMaterialType | NoteType | DocumentType | SpaceFolderType | ContentErrorType | null;
}

export const ContextProvider: FC<CardProviderProps> = memo((params) => {
  const { children, data, props } = params;

  const loadedValue = !!data;

  const dataValue = useMemo(() => data ?? null, [data]);

  const posterValue = useMemo(() => {
    if (guard.isSpaceFolder(data)) {
      if (data?.cover) {
        return [data?.cover];
      }
      if (!data?.cover) {
        return data?.contentImages || [];
      }
      return [];
    }
    if (guard.isCollection(data)) {
      if (data?.cover) {
        return [data?.cover];
      }
      if (!data?.cover) {
        return data?.contentImages || [];
      }
      return [];
    }
    if (guard.isMaterial(data)) {
      if (data.image) {
        return [data.image.url];
      }
      return [];
    }
    if (guard.isNote(data)) {
      return [];
    }
    if (guard.isDocument(data)) {
      return [];
    }
    return [];
  }, [data]);

  const tagsValue = useMemo(() => {
    if (guard.isCollection(data) || guard.isMaterial(data)) {
      return data.tags || null;
    }
    return null;
  }, []);

  const indexValue = useIsIndexing(data);

  return (
    <CardPropsContext.Provider value={props}>
      <CardDataLoadedContext.Provider value={loadedValue}>
        <CardDataContext.Provider value={dataValue}>
          <CardIndexContext.Provider value={indexValue}>
            <CardPosterContext.Provider value={posterValue}>
              <CardTagsContext.Provider value={tagsValue}>{children}</CardTagsContext.Provider>
            </CardPosterContext.Provider>
          </CardIndexContext.Provider>
        </CardDataContext.Provider>
      </CardDataLoadedContext.Provider>
    </CardPropsContext.Provider>
  );
}, isEqual);
