import { type SagaReturnType, takeEvery, put, delay, cancel } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import * as socketActions from '../socketActions';
import { actions } from '../slice';
import * as selectors from '../selectors';

export const config = {
  action: [socketActions.messageInfo.type, socketActions.messageWarning.type, socketActions.messageError.type],
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof socketActions.messageInfo | typeof socketActions.messageWarning | typeof socketActions.messageError>,
) {
  const { id, message, context } = action.payload;
  yield put(actions.addToQueue({ id, message, context }));
  yield delay(500);
  const isPrevented = yield* select(selectors.isPrevented(id));
  if (isPrevented) {
    yield put(actions.remove({ id }));
    yield cancel();
    return;
  }
  if (action.type === socketActions.messageInfo.type) {
    yield call(() => Alert.info(message, { autoClose: 10000 }));
  }
  if (action.type === socketActions.messageWarning.type) {
    yield call(() => Alert.info(message, { autoClose: 10000 }));
  }
  if (action.type === socketActions.messageError.type) {
    yield call(() => Alert.error(message, { autoClose: 10000 }));
  }
  yield put(actions.remove({ id }));
}
