import { type PropsWithChildren, type ReactNode, memo, useCallback, useMemo } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { useFocusEffect, useRoute } from 'navigation/hooks';
import { unit } from 'utils';

import { useResponsive, useValueByScreenWidth } from 'hooks';

import * as menuStore from 'store/nodes/menu';
import { Page, View } from 'components/Themed';
import { useUIBar } from 'components/UIBar';
import { useScrollMethods } from 'components/Scroller';

export interface AdaptivePageProps {
  desktopHeaderLeftPanel?: ReactNode | 'search' | 'ask';
  desktopHeaderMiddlePanel?: ReactNode;
  desktopHeaderRightPanel?: ReactNode;
  hasTopBar?: boolean;
  bodyMaxWidth?: number;
  paddingHorizontalScheme?: Record<number, number>;
  scrollToStart?: boolean;
}

const AdaptivePage = (props: PropsWithChildren<AdaptivePageProps>) => {
  const {
    children,
    desktopHeaderLeftPanel,
    desktopHeaderMiddlePanel,
    desktopHeaderRightPanel,
    hasTopBar,
    bodyMaxWidth = unit(1280),
    paddingHorizontalScheme,
    scrollToStart,
  } = props;

  const route = useRoute();
  const responsive = useResponsive();
  const { set: UIBarSet } = useUIBar();
  const scrollMethods = useScrollMethods();
  const paddingHorizontal = useValueByScreenWidth(paddingHorizontalScheme);

  const leftBarWidth = useSelector(menuStore.selectors.leftBarWidth);

  useFocusEffect(
    useCallback(() => {
      UIBarSet({
        headerLeftPanel: desktopHeaderLeftPanel,
        headerMiddlePanel: desktopHeaderMiddlePanel,
        headerRightPanel: desktopHeaderRightPanel,
        hasTopBar,
      });
    }, [UIBarSet, desktopHeaderLeftPanel, desktopHeaderMiddlePanel, desktopHeaderRightPanel, hasTopBar]),
  );

  useFocusEffect(
    useCallback(() => {
      if (scrollToStart) {
        scrollMethods.scrollToStart();
      }
    }, [scrollToStart, scrollMethods, route.name, JSON.stringify(route.params)]),
  );

  const pageStyles = useMemo((): StyleProp<ViewStyle> => {
    let paddingTop = responsive.isLessThen.tablet ? unit(64) : unit(80);
    if (!responsive.isLessThen.tablet && !hasTopBar) {
      paddingTop = 0;
    }
    return {
      paddingTop,
      paddingLeft: responsive.isLessThen.tablet ? 0 : unit(leftBarWidth === 64 ? 0 : leftBarWidth),
    };
  }, [responsive.isLessThen.tablet, leftBarWidth, hasTopBar]);

  const bodyStyles = useMemo(
    (): StyleProp<ViewStyle> => [
      styles.body,
      {
        paddingHorizontal: unit(paddingHorizontal),
        maxWidth: bodyMaxWidth,
      },
    ],
    [bodyMaxWidth, paddingHorizontal],
  );

  return (
    <Page componentsProps={{}} fullScreen style={pageStyles}>
      <View style={bodyStyles}>{children}</View>
    </Page>
  );
};

const styles = StyleSheet.create({
  scroll: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  body: {
    width: '100%',
    alignSelf: 'center',
    flex: 1,
  },
});

export default memo(AdaptivePage, isEqual);
