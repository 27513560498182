import { type FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { components } from 'react-select';
import { difference } from 'lodash';

import { dispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import SelectInput from 'components/SelectInput';
import Button from 'components/Button';
import { Text } from 'components/Themed';

import ItemSelectInput from './ItemSelectInput';

export interface CollectionSelectInputProps {
  hasNewCollectionButton?: boolean;
  onChange?: (value: number | null) => void;
  value: number | null;
}

const CollectionSelectInput: FC<CollectionSelectInputProps> = (props) => {
  const { hasNewCollectionButton, onChange, value } = props;

  const collections = []; // useSelector(userStore.selectors.getCollections('my'));

  const prevCollectionsRef = useRef(collections);

  useEffect(() => {
    const prevCollections = prevCollectionsRef.current;
    const currentCollections = collections;

    if (prevCollectionsRef.current === currentCollections) {
      return;
    }

    const [newCollection] = difference(currentCollections, prevCollections);
    if (newCollection) {
      onChange?.(newCollection.id);
    }
  }, [collections, onChange]);

  const collectionOptions = useMemo(
    () =>
      collections.map((collection) => ({
        label: <ItemSelectInput title={collection.title} data={collection} />,
        value: `${collection.id}#${collection.title}`,
      })),
    [collections],
  );

  const valueFinal = useMemo(() => {
    let result = '';
    collections.forEach((collection) => {
      if (String(collection.id) === String(value)) {
        result = `${collection.id}#${collection.title}`;
      }
    });
    return result;
  }, [value, collections]);

  const handleChange = useCallback((newValue: string | null) => {
    if (!newValue) {
      onChange?.(null);
      return;
    }
    onChange?.(parseInt(newValue.replace(/#.*/, ''), 10));
  }, []);

  const handleAddCollectionPress = useCallback(() => {
    dispatcher.modal.open('AddorEditCollection', {
      create: {
        withItems: [],
      },
    });
  }, []);

  const selectMenuButton = useCallback(
    (innerProps: any) => (
      <components.MenuList {...innerProps}>
        {hasNewCollectionButton && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="button" variant="text" onPress={handleAddCollectionPress}>
              <Text size={15} weight="semi" darkColor="#497CFF" lightColor="#497CFF">
                Create new collection
              </Text>
            </Button>
          </div>
        )}
        {innerProps.children}
      </components.MenuList>
    ),
    [hasNewCollectionButton, handleAddCollectionPress],
  );

  return (
    <SelectInput
      value={valueFinal}
      onChange={handleChange}
      options={collectionOptions}
      closeOnSelect
      isClearable
      isSearchable
      colors={{
        outline: { light: '#FFFFFF', dark: '#000000' },
        controlBackground: { light: '#ffffff', dark: '#000000' },
        menuBackground: { light: '#ffffff', dark: '#000000' },
      }}
      components={{
        MenuList: selectMenuButton,
      }}
    />
  );
};

export default memo(CollectionSelectInput);
