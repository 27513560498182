import { type FC, memo } from 'react';
import { Box, Typography, useColorScheme } from '@mui/joy';

import useAnswerData from '../../model/useAnswerData';
import useTypewriter from './model/useTypewriter';
import useThinkingText from './model/useThinkingText';

export interface ThinkingProps {
  requestId: string;
  firstState?: string;
}

const Thinking: FC<ThinkingProps> = (props) => {
  const { requestId, firstState = '' } = props;

  const { mode } = useColorScheme();
  const text = useThinkingText(requestId, firstState);
  const typedText = useTypewriter(text, 5);
  const { html } = useAnswerData(typedText || '', 'typing');

  if (!text) {
    return null;
  }

  return (
    <Box sx={{ mt: -1, borderLeft: `1px solid var(--joy-palette-background-level${mode === 'light' ? 1 : 2})`, py: 0.5, pl: 1, ml: 1 }}>
      <Typography
        fontSize={14}
        sx={{
          opacity: 0.8,
          '& p': {
            pt: 1,
          },
          '& p:first-child': {
            pt: 0,
          },
          '& ol': {
            pt: 1,
            listStyle: 'decimal',
            listStylePosition: 'outside',
          },
          '& ol:first-child': {
            pt: 0,
          },
          '& ul': {
            pt: 1,
            listStyle: 'disc',
            listStylePosition: 'outside',
          },
          '& ul:first-child': {
            pt: 0,
          },
          '& li': {
            ml: 1.75,
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Typography>
    </Box>
  );
};

export default memo(Thinking);
