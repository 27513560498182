import { type SagaReturnType, takeEvery } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import { actions } from '../slice';

export const config = {
  action: [actions.setSelectedSource.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.setSelectedSource>) {
  yield call(() => localStorage.setItem('ask/source', JSON.stringify(action.payload.id)));
}
