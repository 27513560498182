import qs from 'query-string';

import type { MaterialType, PaginationResult, PaginationInput, CursorType } from 'app/entities';

import request from 'services/api/request';

type Filters = {
  type: string | null;
};

export default async (pagination: PaginationInput, filters?: Filters): Promise<PaginationResult<MaterialType>> => {
  const query: Record<string, any> = {
    pageSize: pagination.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }
  if (filters?.type) {
    query.type = filters?.type;
  }

  if (!filters?.type) {
    return {
      data: {
        items: [],
        paginationInfo: {
          hasNext: false,
          nextCursor: null,
        },
      },
      error: null,
    };
  }

  const { data, error } = await request.get<CursorType<MaterialType>>('/stack-1/user/content/feed', { query });
  if (error || !data) {
    return {
      data: null,
      error: (Array.isArray(error) ? error?.[0] : error) || null,
    };
  }

  const next = qs.parseUrl(data.nextUrl || '');

  return {
    data: {
      items: data?.items?.map((item) => ({
        ...item,
      })),
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: (next?.query?.cursor as string) || null,
      },
    },
    error: null,
  };
};
