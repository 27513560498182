import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, IconButton, Tooltip } from '@mui/joy';

import Icon from 'ui/Icon';
import { CopilotModelSelect } from 'widgets/Copilot';
import CopilotInput, { type CopilotInputRef } from 'widgets/Copilot/ui/CopilotInput';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as askStore from 'store/nodes/ask';
import * as spaceStore from 'screens/Space/store';

import SourceSelect from '../../../components/SourceSelect';
import WebSearchToggle from '../../../components/WebSearchToggle';
import DeepResearchToggle from '../../../components/DeepResearchToggle';

const CopilotInputConnector = () => {
  const dispatcher = useDispatcher();

  const copilotInputRef = useRef<CopilotInputRef>(null);

  const scope = useSelector(askStore.selectors.scope);
  const query = useSelector(askStore.selectors.query);
  const selectedSource = useSelector(askStore.selectors.selectedSource);
  const space = useSelector(spaceStore.selectors.spaceById(Number(selectedSource?.id)));

  useEffect(() => {
    copilotInputRef.current?.cursor?.toEnd();
  }, []);

  const handleTextChange = useCallback((text: string) => {
    dispatcher.ask.setQuery({ query: text });
  }, []);

  const handleSubmit = useCallback(() => {
    dispatcher.copilot.sendQuestion({
      text: query,
      resourceType: scope,
      resourceId: 0,
    });
    dispatcher.ask.setQuery({ query: '' });
  }, [query, scope]);

  useEffect(() => {
    copilotInputRef.current?.cursor?.toEnd();
  }, []);

  const sendButtonTitle = useMemo(() => {
    if (scope !== 'web' && !space?.hasContent) {
      return `The selected source "${space?.title}" contains no content.`;
    }
    return 'Send';
  }, [scope, space?.hasContent, space?.title]);

  return (
    <CopilotInput
      ref={copilotInputRef}
      autoFocus
      size="large"
      focusBorderSize="0.25em"
      onSubmit={handleSubmit}
      onChange={handleTextChange}
      value={query}
      endAdornment={
        <Box display="flex" mr={1} mb={1} alignSelf="flex-end">
          <Tooltip arrow variant="outlined" size="sm" title={sendButtonTitle}>
            <Box>
              <IconButton
                color="neutral"
                size="lg"
                onClick={handleSubmit}
                disabled={!((query && scope === 'web') || (query && scope !== 'web' && space?.hasContent))}
                sx={{ opacity: !query ? 0 : 1, transition: 'opacity 300ms ease' }}
              >
                <Icon name="circle-arrow-up" fw weight="solid" color="inherit" size="lg" />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      }
      footer={
        <Box display="flex" flexDirection="row" flex={1} mx={1.25} pb={1.5} gap={0.5}>
          <WebSearchToggle sx={{ '@media (max-width: 767px)': { display: 'none' } }} />
          <DeepResearchToggle sx={{ '@media (max-width: 767px)': { display: 'none' } }} />
          <SourceSelect />
          <Box flex={1} />
          <CopilotModelSelect />
        </Box>
      }
    />
  );
};

export default memo(CopilotInputConnector);
