import { useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { spaceById, userPermissionsById } from '../store/selectors';

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const useSpaceById = (id?: number) => {
  const space = useSelector(spaceById(id));
  const permissions = useSelector(userPermissionsById(id)) || [];

  const count = permissions.length;
  const prevCount = usePrevious(count) ?? null;

  return useMemo(
    () => ({
      space,
      spaceTeam: {
        permissions,
        count,
        prevCount,
      },
    }),
    [space, permissions, count, prevCount],
  );
};

export default useSpaceById;
