import { type FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { createUseStyles } from 'react-jss';
import { Box, type BoxProps, useColorScheme, useTheme } from '@mui/joy';

type AnimateType = 'beat' | 'beat-fade' | 'bounce' | 'fade' | 'flip' | 'shake' | 'spin' | 'spin-reverse' | 'spin-pulse';

type SizeType = 'default' | '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';

interface BasicType extends Omit<BoxProps, 'component' | 'className'> {
  name: string;
  size?: SizeType;
  color?: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'inherit' | 'current' | string;
  animate?: AnimateType;
  fw?: boolean | number;
}

interface IconKitProps extends BasicType {
  family?: 'kit';
  weight?: undefined;
}

interface IconBrandsProps extends BasicType {
  family?: 'brands';
  weight?: undefined;
}

interface IconClassicProps extends BasicType {
  family?: 'classic';
  weight?: 'solid' | 'regular' | 'light' | 'duotone' | 'thin';
}

interface IconSharpProps extends BasicType {
  family?: 'sharp';
  weight?: 'solid' | 'regular' | 'light';
}

const getAnimate = (name?: AnimateType) => {
  if (!name) {
    return '';
  }
  return {
    beat: 'fa-beat',
    'beat-fade': 'fa-beat-fade',
    bounce: 'fa-bounce',
    fade: 'fa-fade',
    flip: 'fa-flip',
    shake: 'fa-shake',
    spin: 'fa-spin',
    'spin-reverse': 'fa-spin fa-spin-reverse',
    'spin-pulse': 'fa-spin-pulse',
  }[name];
};

export type IconProps = IconKitProps | IconBrandsProps | IconClassicProps | IconSharpProps;

const Icon: FC<IconProps> = (props) => {
  const { family = 'classic', size = 'default', color = 'primary', weight, name, animate, fw, ...rest } = props;
  const theme = useTheme();
  const colorScheme = useColorScheme();

  const preparedColor = useMemo(() => {
    if (typeof color === 'undefined') {
      return undefined;
    }
    if (color === 'current') {
      return 'var(--Icon-color)';
    }
    if (!['primary', 'secondary', 'tertiary', 'icon', 'inherit'].includes(color)) {
      return color;
    }
    return color === 'inherit' ? 'inherit' : theme.palette.text[color as 'primary' | 'secondary' | 'tertiary' | 'icon'];
  }, [color, colorScheme]);

  const classes = useStyles({
    color: preparedColor,
    fw,
  });

  return (
    <Box
      {...rest}
      component="i"
      className={cn(
        `fa-${family}`,
        `fa-${name}`,
        {
          [`fa-${weight || 'regular'}`]: family !== 'kit',
          [`fa-${size}`]: size !== 'default',
          [getAnimate(animate)]: !!animate,
          'fa-fw': fw && typeof fw === 'boolean',
        },
        classes.i,
      )}
    />
  );
};

const useStyles = createUseStyles<
  'i',
  {
    color?: string;
    fw?: boolean | number;
  }
>({
  i: {
    lineHeight: 1,
    width: (props) => (typeof props.fw === 'number' ? `${props.fw}px` : undefined),
    color: (props) => props.color,
    textAlign: 'center',
  },
});
export default memo(Icon);
