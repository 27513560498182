import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import * as actions from 'store/nodes/auth/actions';

import { actions as rootActions } from '../slices/root';
import { actions as listActions } from '../slices/list';
import { actions as permissionActions } from '../slices/permission';
import { actions as resourceActions } from '../slices/resource';
import { actions as structureActions } from '../slices/structure';

export const config = {
  action: actions.logOutDone.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.logOutDone>) {
  const { error } = action.payload;

  if (error) {
    yield cancel();
    return;
  }

  yield put(rootActions.reset());
  yield put(listActions.reset());
  yield put(permissionActions.reset());
  yield put(resourceActions.reset());
  yield put(structureActions.reset());
}
