import { type FC, memo, useId } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import { Box, type BoxProps, Typography } from '@mui/joy';

import { usePerson } from 'hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as spaceStore from 'screens/Space/store';

import Avatar from 'components/Avatar';
import Icon from 'ui/Icon';

import Poster from './Poster';

interface HeaderProps extends BoxProps {
  spaceId?: number;
  folderId?: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { spaceId, folderId, ...rest } = props;

  const defaultSpaceId = useSelector(spaceStore.selectors.defaultSpaceId);
  const spaceTitle = useSelector(spaceStore.selectors.spaceTitleById(spaceId));
  const libraryId = useSelector(spaceStore.selectors.libraryIdBySpaceId(spaceId));
  const data = useSelector(spaceStore.selectors.folderById((folderId === 'root' ? libraryId : folderId) || ''));

  const { person } = usePerson('User', data?.userId);
  const id = useId();

  return (
    <Box
      flexDirection="column"
      alignSelf="stretch"
      {...rest}
      sx={{
        contain: 'layout',
        containerType: 'inline-size',
        ...rest.sx,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignSelf="stretch"
        sx={{
          '--title-size': '48px',
          '--description-size': '20px',
          '@container (width <= 500px)': {
            [`& [data-id="${id}"]`]: {
              display: 'none',
            },
            '--title-size': '40px',
            '--description-size': '18px',
          },
        }}
      >
        <Box flex={1}>
          {folderId !== 'root' && (
            <>
              <Box display="flex" flexDirection="row" alignItems="center" gap={2.5}>
                <Avatar user={person} vars={{ AvatarSize: '44px' }} />
                <Box display="flex" flexDirection="column">
                  <Typography fontSize={13}>{`${person?.name} ${person?.surname}`}</Typography>
                  <Typography fontSize={13}>{`${moment(data?.createdAt).fromNow()} · ${pluralize('item', data?.childrenCount, true)}`}</Typography>
                </Box>
              </Box>
              <Typography component="h1" fontSize="var(--title-size)" lineHeight={1} my={1.5}>
                {data?.title}
              </Typography>
              <Typography fontSize="var(--description-size)" lineHeight={1.2}>
                {data?.description}
              </Typography>
            </>
          )}
          {folderId === 'root' && (
            <>
              <Box display="flex" flexDirection="row" alignItems="center" gap={0.75}>
                <Icon name="inbox" weight="light" size="lg" fw />
                <Typography component="h1" fontSize={22} lineHeight={1} mb={0.25}>
                  {defaultSpaceId === spaceId && <>My Library</>}
                  {defaultSpaceId !== spaceId && <>{spaceTitle?.replace(/library\s*$/i, '')} Library</>}
                </Typography>
              </Box>
              <Typography fontSize={15} mt={1}>{`${pluralize('item', data?.childrenCount, true)}`}</Typography>
            </>
          )}
        </Box>
        {(data?.cover || data?.contentImages) && folderId !== 'root' && (
          <Poster
            data-id={id}
            cover={data?.cover}
            contentImages={data?.contentImages}
            aspectRatio={1}
            sx={{
              maxWidth: 180,
              ml: 4,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(Header, isEqual);
