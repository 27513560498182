import type { CollectionType, ContentErrorType, MaterialType, NoteType, DocumentType, SpaceFolderType } from 'app/entities';

import { guard } from 'utils';
import { usePerson } from 'hooks';

const useAuthor = (data?: CollectionType | MaterialType | NoteType | DocumentType | SpaceFolderType | ContentErrorType | null) => {
  let params: ({ type: 'User'; id: number } | { type: 'Author'; id: number }) | {} = {};
  if (guard.isCollection(data) && data.userId) {
    params = { type: 'User', id: data.userId };
  }
  if (guard.isMaterial(data) && data.authors?.[0]?.id) {
    params = { type: 'Author', id: data.authors?.[0]?.id };
  }
  if (guard.isNote(data) && data.user?.id) {
    params = { type: 'User', id: data.user?.id };
  }
  if (guard.isDocument(data) && data.user?.id) {
    params = { type: 'User', id: data.user?.id };
  }
  if (guard.isSpaceFolder(data) && data.userId) {
    params = { type: 'User', id: data.userId };
  }
  const { person, isLoading, isExists } = usePerson(params);
  return {
    person,
    isExists,
    isLoading,
  };
};

export default useAuthor;
