import { createReducer } from '@reduxjs/toolkit';

import { guard } from 'utils';

import type { UserStore } from './types';
import * as actions from './actions';

const initialState: UserStore = {
  my: null,
  data: {},
  idMap: {},
  emailMap: {},
  meta: {
    loadingMap: {},
    batchingMap: {},
    subscriptingMap: {},
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.loadUser, (state, action) => {
      action.payload.forEach((item) => {
        state.meta.loadingMap[item.id! || item.login! || item.email!] = true;
      });
    })
    .addCase(actions.setProcessingKey, (state, action) => {
      state.meta.batchingMap[action.payload.id! || action.payload.login! || action.payload.email!] = true;
    })
    .addCase(actions.loadUserDone, (state, action) => {
      action.payload.forEach((item) => {
        delete state.meta.loadingMap[item.id! || item.login! || item.email!];
        delete state.meta.batchingMap[item.id! || item.login! || item.email!];
      });
    })
    .addCase(actions.loadProfile, (state, action) => {
      state.meta.loadingMap[action.payload.login] = true;
    })
    .addCase(actions.loadProfileOptimistic, (state, action) => {
      state.meta.loadingMap[action.payload.login] = true;
    })
    .addCase(actions.loadProfileDone, (state, action) => {
      delete state.meta.loadingMap[action.payload.login];
    })
    .addCase(actions.updateProperty, (state, action) => {
      const { name, value } = action.payload;
      let target: string | null = null;
      if (typeof action.payload === 'string') {
        target = action.payload;
      }
      if (target === 'my' && state.my) {
        target = state.my.login;
      }
      if (typeof action.payload === 'number') {
        target = state.idMap[action.payload];
      }
      if (!target || !state.data[target]) {
        return;
      }
      if (target in state.data) {
        // @ts-ignore
        state.data[target][name] = value as never;
      }
    })
    .addCase(actions.setData, (state, action) => {
      const { data } = action.payload;
      if (!data || !Array.isArray(data) || !data.length) {
        return;
      }
      data.forEach((item) => {
        if (!guard.isLikeUser(item)) {
          return;
        }
        state.data[item.login] = {
          ...state.data[item.login],
          ...item,
        };
        state.idMap[item.id] = item.login;
        state.emailMap[item.email] = item.login;
        if ('isMy' in item && item.isMy) {
          state.my = {
            id: item?.id,
            login: item?.login,
          };
        }
      });
    })
    .addCase(actions.subscribe, (state, action) => {
      state.meta.subscriptingMap[action.payload.login] = true;
    })
    .addCase(actions.unsubscribe, (state, action) => {
      state.meta.subscriptingMap[action.payload.login] = true;
    })
    .addCase(actions.subscribeSuccess, (state, action) => {
      const { login } = action.payload;
      const data = state.data[login];
      if (!data || !guard.isUser(data)) {
        return;
      }
      data.isSubscribed = true;
      if (typeof data.subscribersCount === 'undefined' || data.subscribersCount === null) {
        data.subscribersCount = 0;
      }
      data.subscribersCount += 1;
    })
    .addCase(actions.subscribeDone, (state, action) => {
      delete state.meta.subscriptingMap[action.payload.login];
    })
    .addCase(actions.unsubscribeSuccess, (state, action) => {
      const { login } = action.payload;
      const data = state.data[login];
      if (!data || !guard.isUser(data)) {
        return;
      }
      data.isSubscribed = false;
      if (typeof data.subscribersCount === 'undefined' || data.subscribersCount === null) {
        data.subscribersCount = 0;
      }
      if (data.subscribersCount > 0) {
        data.subscribersCount -= 1;
      }
    })
    .addCase(actions.unsubscribeDone, (state, action) => {
      delete state.meta.subscriptingMap[action.payload.login];
    })
    .addCase(actions.logOut, (state) => {
      state.my = null;
    }),
);
