import { cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';
import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import network from 'lib/network';

import sourceMaterialFolderToSpaceMaterialFolder, { type SourceMaterialFoldersType } from '../../model/sourceMaterialFolderToSpaceMaterialFolder';

import { actions, actions as relationActions } from '../slices/relation';

export const config = {
  action: relationActions.loadMaterialFolders.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof relationActions.loadMaterialFolders>) {
  const { type, id } = action.payload;

  const { data, hasError, errors } = yield* call(() =>
    network.request<SourceMaterialFoldersType[]>(`/stack-2/user/team-space/resource/${type.toLowerCase()}/${id}/parents`).get(),
  );

  if (hasError || !data) {
    Alert.error(errors);
    yield put(actions.loadMaterialFoldersDone({ type, id }));
    yield cancel();
    return;
  }

  const items = sourceMaterialFolderToSpaceMaterialFolder(data);

  yield put(actions.setMaterialFolders({ type, id, items }));
  yield put(actions.loadMaterialFoldersDone({ type, id }));
}
