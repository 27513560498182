import React, { memo, useCallback } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';

import { guard } from 'utils';
import { useRoute } from 'navigation/hooks';

import * as materialStore from 'store/nodes/content';

import { Button, Tooltip, Typography } from '@mui/joy';
import Icon from 'ui/Icon';

const SourceButton = () => {
  const route = useRoute<'Content'>();
  const { resourceId } = route.params;

  const data = useSelector(materialStore.selectors.dataById(resourceId));
  const idDisabled = guard.isContentError(data);

  const handleOpen = useCallback(() => {
    if (!guard.isMaterial(data) || !data?.originalUrl) {
      return;
    }
    const link = document.createElement('a');
    link.setAttribute('href', data?.originalUrl);
    link.setAttribute('target', '_blank');
    link.click();
  }, [data?.__typename]);

  return (
    <Tooltip arrow variant="outlined" size="sm" title="Open source link">
      <Button
        size="sm"
        color="neutral"
        variant="soft"
        disabled={idDisabled}
        onClick={handleOpen}
        endDecorator={<Icon name="arrow-up-right" fw weight="regular" color="icon" />}
      >
        <Typography color="neutral" fontSize={16} fontWeight={400}>
          Open source
        </Typography>
      </Button>
    </Tooltip>
  );
};

export default memo(SourceButton);
