import { useCallback, useContext, useMemo } from 'react';

import { Context } from '../context';
import type { Config } from '../types';

const useUIBar = () => {
  const { setConfig, ...rest } = useContext<Config>(Context);

  const set = useCallback(
    (config: Omit<Config, 'setConfig'>) => {
      setConfig?.(config);
    },
    [setConfig],
  );

  return useMemo(
    () => ({
      ...rest,
      set,
    }),
    [rest, set],
  );
};

export default useUIBar;
