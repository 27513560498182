import type { ResultLegacy, UserType, UserInput } from 'app/entities';
import request from 'services/api/request';

export default async (params: UserInput): Promise<ResultLegacy<UserType>> => {
  const { data, error } = await request.post<UserType>('/stack-1/user/profile', {
    body: params,
  });
  return {
    data,
    error,
  };
};
