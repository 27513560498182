import { useCallback } from 'react';
import type { NavigateOptions } from 'react-router/lib/hooks';
import { createSearchParams } from 'react-router-dom';

import type { ScreenParams } from '../../types';
import { useNavigate as useNavigateOrigin, generatePath } from '../../router';
import linking from '../../linking';

// @todo добавить тоже самое в методы
const isOptions = (params: Record<string, any>) => {
  const { replace, state } = params;
  return typeof replace !== 'undefined' || typeof state !== 'undefined';
};

const prepareQueryString = (template: string, params: any) => {
  const searchParams: any = {};
  const keys = (template.match(/:[^/]+/g) || []).map((key) => key.replace(/:/, ''));
  Object.entries(params).forEach(([key, value]) => {
    if (keys.includes(key)) {
      return;
    }
    searchParams[key] = value;
  });
  if (Object.keys(searchParams).length === 0) {
    return '';
  }
  return `?${createSearchParams(searchParams).toString()}`;
};

export const useNavigate = () => {
  const navigate = useNavigateOrigin();
  return useCallback(
    <ScreenName extends keyof ScreenParams>(
      ...args: undefined extends ScreenParams[ScreenName]
        ? [name: ScreenName, options?: NavigateOptions]
        : [name: ScreenName, params: ScreenParams[ScreenName], options?: NavigateOptions]
    ) => {
      if (args.length === 1) {
        const [name] = args;
        navigate(linking[name]);
        return;
      }
      if (args.length === 2 && isOptions(args?.[1] as Record<string, any>)) {
        const [name, options] = args;
        navigate(linking[name], options as NavigateOptions);
        return;
      }
      if (args.length === 2) {
        const [name, params] = args;
        navigate(`${generatePath(linking[name], params as Record<string, any>)}${prepareQueryString(linking[name], params)}`);
        return;
      }
      if (args.length === 3) {
        const [name, params, options] = args;
        navigate(
          `${generatePath(linking[name], params as Record<string, any>)}${prepareQueryString(linking[name], params)}`,
          options as NavigateOptions,
        );
      }
    },
    [navigate],
  );
};
