import { memo, useCallback } from 'react';
import { Box, Typography } from '@mui/joy';
import { useNavigate } from 'navigation/hooks';

const NotFound = () => {
  const navigate = useNavigate();

  const handleToHome = useCallback(() => {
    navigate('Library', { interaction: 'all' });
  }, []);

  return (
    <Box display="flex" flexDirection="column" flex={1} alignItems="center" justifyContent="center">
      <Typography fontSize={20} fontWeight="bold">
        This screen doesn&apos;t exist.
      </Typography>
      <Typography color="primary" sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={handleToHome} fontSize={15}>
        Go to home screen!
      </Typography>
    </Box>
  );
};

export default memo(NotFound);
