import { memo, useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useRoute } from 'navigation/hooks';

import { guard, saveLastRoute } from 'utils';

import { useDispatcher } from 'store/utils/redux/hooks';

import AdaptivePage from 'components/AdaptivePage';
import { controller as modal } from 'components/Modal2';

import { useHasSession, usePerson } from 'hooks';
import { Box, Divider, Stack } from '@mui/joy';
import Info from './elements/Info';
import Jobs from './elements/Jobs';
import Skills from './elements/Skills';

const Profile = () => {
  const navigate = useNavigate();
  const route = useRoute();
  const dispatcher = useDispatcher();
  const hasSession = useHasSession();

  const { person, isExists, isLoading } = usePerson(
    route.isScreen('Author')
      ? { type: 'Author' as const, id: Number((route.params as Record<string, number>).id) }
      : { type: 'User' as const, login: String((route.params as Record<string, string>).login || 'my') },
  );

  useEffect(() => {
    const { login } = route.params as Record<string, string>;
    if (login === 'my' && !hasSession) {
      saveLastRoute();
      navigate('Auth/Start');
    }
  }, [hasSession, route.params]);

  const handleSubscribe = useCallback(() => {
    if (!guard.isLikeUser(person)) {
      return;
    }
    if (!person?.login || person.isMy) {
      return;
    }
    dispatcher.user.subscribe(person?.login);
  }, [person]);

  const handleUnsubscribe = useCallback(() => {
    if (!guard.isLikeUser(person)) {
      return;
    }
    if (!person?.login || person.isMy) {
      return;
    }
    dispatcher.user.unsubscribe(person?.login);
  }, [person]);

  const handleRelationPersonPress = useCallback((login: string) => {
    modal.popup.relations.close(() => {
      navigate('Profile', { login });
    });
  }, []);

  const handleRelationsPress = useCallback(() => {
    if (!guard.isLikeUser(person)) {
      return;
    }
    modal.popup.relations.open({
      login: person?.login,
      onPress: handleRelationPersonPress,
    });
  }, [person]);

  const pagePaddingHorizontalScheme = useMemo(
    () => ({
      320: 16,
    }),
    [],
  );

  const userLoginOrUndefined = useMemo(() => (guard.isLikeUser(person) ? person.login : undefined), [person]);
  const AuthorIdOrUndefined = useMemo(() => (guard.isAuthor(person) ? person.id : undefined), [person]);

  return (
    <AdaptivePage
      hasTopBar
      desktopHeaderLeftPanel="ask"
      desktopHeaderMiddlePanel={false}
      bodyMaxWidth={1600}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
      scrollToStart
    >
      <Info
        userLogin={userLoginOrUndefined}
        authorId={AuthorIdOrUndefined}
        onSubscribe={handleSubscribe}
        onUnsubscribe={handleUnsubscribe}
        onRelationsPress={handleRelationsPress}
      />
      <Divider
        sx={{
          mt: 2,
          mb: 2,
        }}
      />
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <Skills userLogin={userLoginOrUndefined} />
        {/* deprecated */}
        {/* <Playlists userLogin={userLoginOrUndefined} editable /> */}
        {/* <Contents userLogin={userLoginOrUndefined} authorId={guard.isAuthor(person) ? person.id : undefined} editable /> */}
        <Jobs userLogin={userLoginOrUndefined} />
      </Box>
    </AdaptivePage>
  );
};

export default memo(Profile);
