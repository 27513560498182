import { takeLatest, put, cancel, take, type SagaReturnType, delay } from 'redux-saga/effects';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import { DateTime } from 'luxon';

import type { JobType, JobInput, UserType, UserInput, ImageType } from 'app/entities';
import extensionByContentType from 'utils/extensionByContentType';

import { navigate } from 'navigation/methods';

import Alert from 'components/Alert';

import Storage from 'lib/Storage';

import * as api from 'services/api';

import { select, call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.updateData.type,
  method: takeLatest,
};

const toEntity = (data: UserInput | UserType): UserType => {
  let photo: ImageType | null | undefined = null;
  if (typeof data.photo === 'undefined') {
    photo = null;
  }
  if (typeof data.photo === 'string') {
    photo = {
      id: 0,
      url: data.photo,
      createdAt: DateTime.now().toISOTime(),
      updatedAt: DateTime.now().toISOTime(),
      width: 0,
      height: 0,
    };
  }
  if (!!data?.photo && typeof data?.photo === 'object' && 'data' in data.photo && 'extension' in data.photo) {
    photo = {
      id: 0,
      url: data.photo.data,
      createdAt: DateTime.now().toISOTime(),
      updatedAt: DateTime.now().toISOTime(),
      width: 0,
      height: 0,
    };
  }
  if (!!data?.photo && typeof data?.photo === 'object' && 'id' in data.photo && 'url' in data.photo) {
    photo = {
      ...data?.photo,
    };
  }

  return {
    ...data,
    login: data?.login || '',
    name: data?.name || '',
    surname: data?.surname || '',
    subscribersCount: data?.subscribersCount || 0,
    subscriptionsCount: data?.subscriptionsCount || 0,
    photo,
    jobExperience: data?.jobExperience.map(
      (job, key): JobType => ({
        id: key,
        ...job,
      }),
    ),
    howUse: data?.howUse,
    isMy: data?.isMy || false,
    isSubscribed: data?.isSubscribed || false,
    isGoalsDisplayed: data?.isGoalsDisplayed || false,
    isSkillsDisplayed: data?.isSkillsDisplayed || false,
    isConfirmedEmail: data?.isConfirmedEmail || false,
    createdAt: data?.createdAt || '1970-01-01T00:00:00.000000+00:00',
    updatedAt: data?.updatedAt || data?.createdAt || '1970-01-01T00:00:00.000000+00:00',
  } as any;
};

const prepareUpdate = (userProfileOld: UserType, userProfileNew: UserInput) => {
  const { jobExperience: jobsOld, ...profileOld } = cloneDeep(userProfileOld);
  const { jobExperience: jobsNew, ...profileNew } = cloneDeep(userProfileNew);

  const hasProfileUpdates = !isEqual(profileOld, profileNew);

  if (!!profileNew.photo && typeof profileNew.photo === 'object' && 'url' in profileNew.photo) {
    delete profileNew.photo;
  }

  if (hasProfileUpdates && typeof profileNew.photo === 'string' && /^data:image\/[a-z]+;base64,/.test(profileNew.photo)) {
    const [, contentType, data] = profileNew.photo.match(/^data:(image\/[a-z]+);base64,(.*)/) || [];
    const extension = extensionByContentType(contentType, 'jpg');
    profileNew.photo = {
      data,
      extension,
    };
  }

  const hasJobsUpdates = !isEqual(jobsOld, jobsNew);
  const jobsCreated: JobInput[] = [];
  const jobsUpdated: JobInput[] = [];
  const jobsDeleted: JobInput[] = [];
  if (hasJobsUpdates) {
    const jobOldIds: number[] = [];
    const jobOldMap: Record<number, JobInput> = {};
    jobsOld.forEach((job) => {
      if (!job.id) {
        return;
      }
      jobOldIds.push(job.id);
      jobOldMap[job.id] = job;
    });
    const jobNewIds: number[] = [];
    jobsNew.forEach((job) => {
      if (!job.id) {
        return;
      }
      jobNewIds.push(job.id);
    });

    jobsNew.forEach((job) => {
      if (!job.id || !jobOldIds.includes(job.id)) {
        if (job.id) {
          delete job.id;
        }
        jobsCreated.push(job);
      }
      if (job.id && jobOldIds.includes(job.id) && !isEqual(job, jobOldMap[job.id])) {
        jobsUpdated.push(job);
      }
    });

    jobsOld.forEach((job) => {
      if (!jobNewIds.includes(job.id)) {
        jobsDeleted.push(job);
      }
    });
  }

  return {
    profile: {
      hasUpdates: hasProfileUpdates,
      data: profileNew as UserInput,
    },
    jobs: {
      hasUpdates: hasJobsUpdates,
      created: jobsCreated,
      updated: jobsUpdated,
      deleted: jobsDeleted,
    },
    meta: {
      optimistic: toEntity(userProfileNew),
      rollback: userProfileOld,
    },
  };
};

export function* func(action: SagaReturnType<typeof actions.updateData>) {
  const { payload } = action;
  const { theme, ...updatedUserData } = payload.data;

  const userData = yield* select(userStore.selectors.getMy);
  if (!userData) {
    yield cancel();
    yield put(actions.updateDataDone());
    return;
  }

  const userProfileNew = {
    ...userData,
    ...updatedUserData,
  };

  const prepared = prepareUpdate(userData, userProfileNew);

  yield put(userStore.actions.setData({ data: prepared.meta.optimistic }));

  const results = yield* call(() =>
    Promise.all([
      ...prepared.jobs.created.map((job) => api.resource.profile.job.post(job)),
      ...prepared.jobs.updated.map((job) => api.resource.profile.job.put(job.id, job)),
      ...prepared.jobs.deleted.map((job) => api.resource.profile.job.delete(job.id)),
    ]),
  );
  results.forEach((result) => {
    if (result?.error) {
      Alert.error(result?.error);
    }
  });

  if (prepared.profile.hasUpdates) {
    const profile = yield* call(() => api.resource.profile.post(prepared.profile.data));
    if (profile.error || !profile.data) {
      Alert.error(profile.error);
      yield put(actions.updateDataDone((Array.isArray(profile.error) ? profile.error?.[0].message : profile.error?.message) || 'unknown error'));
      yield put(userStore.actions.setData({ data: prepared.meta.rollback }));
      yield cancel();
      return;
    }
    yield put(userStore.actions.setData({ data: profile.data }));
  }
  if (theme) {
    yield* call(() => Storage.set('theme.type', theme));
  }
  yield put(userStore.actions.loadMe());
  yield take(userStore.actions.loadMeDone.type);
  yield delay(10);

  const { successNavigate } = payload;
  if (successNavigate) {
    yield* call(() => navigate(successNavigate as any));
  }

  yield put(actions.updateDataDone());
}
