import { type ForwardedRef, useImperativeHandle, useMemo, useRef } from 'react';

import type { CopilotInputRef } from '../types';

const useInputHandlers = (forwardedRef: ForwardedRef<CopilotInputRef>, props: { multiline: boolean }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useImperativeHandle(
    forwardedRef,
    () => ({
      cursor: {
        toStart: () => {
          if (props.multiline) {
            textareaRef.current?.setSelectionRange(0, 0);
          } else {
            inputRef.current?.setSelectionRange(0, 0);
          }
        },
        toEnd: () => {
          if (props.multiline) {
            const length = textareaRef.current?.value.length ?? 0;
            textareaRef.current?.setSelectionRange(length, length);
          } else {
            const length = inputRef.current?.value.length ?? 0;
            inputRef.current?.setSelectionRange(length, length);
          }
        },
      },
    }),
    [props.multiline],
  );

  return useMemo(() => ({ inputRef, textareaRef }), []);
};

export default useInputHandlers;
