import { type SagaReturnType, put, cancel, takeEvery } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';

import * as actions from '../actions';

export const config = {
  action: actions.setRagMaterial.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.setRagMaterial>) {
  const { items } = action.payload;

  if (!Array.isArray(items) || items.length === 0) {
    yield cancel();
    return;
  }

  const urls = items.map((item) => item.originalUrl);

  const { data, hasError } = yield* call(() =>
    network
      .request<boolean[]>('/stack-1/content/exist')
      .query({
        urls: btoa(JSON.stringify(urls)),
      })
      .get(),
  );

  if (hasError || !data) {
    yield cancel();
    return;
  }

  const itemsState = items.map((item, key) => ({
    id: item.id,
    isAdded: data[key],
  }));

  yield put(actions.updateRagMaterialState({ data: itemsState }));
}
