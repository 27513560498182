import React, { createContext, useContext, useState, useCallback, type ReactNode, useMemo } from 'react';
import type { SpaceEntityKey } from 'app/entities';

type SelectionContextValue = {
  selectedKeys: SpaceEntityKey[];
  hasSelections: Boolean;
  isSelected: (key: SpaceEntityKey) => boolean;
  toggleSelection: (key: SpaceEntityKey) => void;
  resetSelection: () => void;
};

const SelectionContext = createContext<SelectionContextValue | null>(null);

export function useSelection() {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider');
  }
  return context;
}

interface SelectionProviderProps {
  children: ReactNode;
}

export function SelectionProvider({ children }: SelectionProviderProps) {
  const [selectedKeys, setSelectedKeys] = useState<SpaceEntityKey[]>([]);

  // Проверяем, выбран ли элемент
  const isSelected = useCallback(
    (key: SpaceEntityKey) => {
      return selectedKeys.some((selected) => selected.id === key.id && selected.__typename === key.__typename);
    },
    [selectedKeys],
  );

  // Добавляем или убираем элемент
  const toggleSelection = useCallback((key: SpaceEntityKey) => {
    setSelectedKeys((prev) => {
      const found = prev.some((selected) => selected.id === key.id && selected.__typename === key.__typename);
      if (found) {
        // Удаляем
        return prev.filter((item) => !(item.id === key.id && item.__typename === key.__typename));
      }
      // Добавляем
      return [...prev, key];
    });
  }, []);

  // Сбрасываем всё
  const resetSelection = useCallback(() => {
    setSelectedKeys([]);
  }, []);

  const value: SelectionContextValue = useMemo(
    () => ({
      selectedKeys,
      hasSelections: selectedKeys.length > 0,
      isSelected,
      toggleSelection,
      resetSelection,
    }),
    [selectedKeys, isSelected, toggleSelection, resetSelection],
  );

  return <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>;
}
