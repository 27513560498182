import { type FC, memo } from 'react';
import Picture from 'components/Picture';

export interface ImageViewerProps {
  src?: string;
}

const ImageViewer: FC<ImageViewerProps> = (props) => {
  const { src } = props;

  if (!src) {
    return null;
  }
  return <Picture source={src} />;
};

export default memo(ImageViewer);
