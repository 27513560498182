import React, { useMemo } from 'react';
import type { ContentErrorType, MaterialType } from 'app/entities';
import ContentPanel from 'screens/Content/Page/panels/ContentPanel';

const useContentRender = (data: MaterialType | ContentErrorType | undefined | null) =>
  useMemo(() => {
    if (!data) {
      return null;
    }
    return <ContentPanel hasTitle resourceId={data.id} />;
  }, [(data as MaterialType)?.id]);

export default useContentRender;
