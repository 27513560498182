import { type FC, type ReactElement, memo, useMemo, cloneElement, useCallback } from 'react';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import { Box } from '@mui/joy';

import * as subscriptionStore from './store';
import useBarLabels from './model/useBarLabels';

export interface ManagementProps {
  buttonTextProperty: string;
  buttonClickProperty: string;
  buttonElement: ReactElement;
}

const Billing: FC<ManagementProps> = (props) => {
  const { buttonTextProperty, buttonClickProperty, buttonElement } = props;

  const dispatcher = useDispatcher();
  const currentPlan = useSelector(subscriptionStore.selectors.currentPlan);
  const { portal } = useBarLabels();

  const handleOpenPortal = useCallback(() => {
    if (!portal.isClickable) {
      return;
    }
    dispatcher.subscription.openBillingPortal({ openMode: 'blank' });
  }, [portal.isClickable]);

  const modalOpenerConnector = useMemo(() => {
    return cloneElement(buttonElement as ReactElement, {
      [buttonTextProperty]: <Box component="span">{portal.label}</Box>,
      [buttonClickProperty]: handleOpenPortal,
    });
  }, [buttonTextProperty, buttonElement, currentPlan]);

  if (!currentPlan.isLoaded || currentPlan.id === '0') {
    return null;
  }

  return modalOpenerConnector;
};

export default memo(Billing);
