import { useCallback, useMemo, type MouseEvent } from 'react';
import * as Linking from 'expo-linking';

import { useResourceInfo } from 'hooks';

import type { ContentErrorType, ResourceType } from 'app/entities';
import { useDispatcher } from 'store/utils/redux/hooks';

import Alert from 'components/Alert';

import { guard, copyToClipboard } from 'utils';

/**
 * @todo удалить
 * @param data
 */
export default (data: ResourceType | ContentErrorType | null) => {
  const dispatcher = useDispatcher();

  const resourceInfo = useResourceInfo(data);

  const handleEdit = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      if (resourceInfo.type === 'Material') {
        dispatcher.modal.open('EditMaterial', { id: resourceInfo.id });
      }
    },
    [resourceInfo],
  );

  const handleToCollection = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      const resourceType = {
        Material: 'Content',
        Document: 'Document',
        Note: 'Note',
      }[resourceInfo?.type as string];
      dispatcher.modal.open('ResourcesToFolder', {
        mode: 'resourceAdd',
        resourceId: resourceInfo.id,
        resourceType,
      });
    },
    [resourceInfo],
  );

  const handleLink = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (guard.isMaterial(data) && data.originalUrl) {
        copyToClipboard(data.originalUrl);
      } else {
        const internalUrl = !!data && 'internalUrl' in data ? data.internalUrl : null;
        if (!internalUrl) {
          return;
        }
        const link = Linking.createURL(internalUrl);
        copyToClipboard(link);
      }
      Alert.success('Link was copied');
    },
    [data],
  );

  return useMemo(
    () => ({
      handleToCollection,
      handleLink,
      handleEdit,
    }),
    [handleToCollection, handleLink, handleEdit],
  );
};
