import { type FC, type MouseEventHandler, memo, useCallback } from 'react';
import { type BoxProps, Box, Button, Typography } from '@mui/joy';
import Icon from 'ui/Icon';

export interface DisplayModeSelectProps extends Omit<BoxProps, 'onChange'> {
  value?: 'list' | 'grid';
  onChange?: (newValue: 'list' | 'grid') => void;
}

const DisplayModeSelect: FC<DisplayModeSelectProps> = (props) => {
  const { value, onChange, ...restProps } = props;

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const itemId = event.currentTarget.getAttribute('itemId') as 'list' | 'grid' | undefined;
      if (!itemId || itemId === value) {
        return;
      }
      onChange?.(itemId);
    },
    [value, onChange],
  );

  // bgcolor="color-mix(in srgb, var(--joy-palette-background-level1) 50%, var(--joy-palette-background-body) 50%)"

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={0.5}
      bgcolor="var(--joy-palette-background-level1)"
      borderRadius={18}
      height={32}
      alignItems="center"
      px={0.5}
      {...restProps}
    >
      <Button
        itemID="grid"
        size="sm"
        variant="plain"
        color="neutral"
        startDecorator={
          <Box>
            <Icon name="grid" weight="light" fw size="1x" color="inherit" />
          </Box>
        }
        sx={{
          minHeight: 24,
          py: 0,
          backgroundColor: value === 'grid' ? 'var(--joy-palette-background-body)!important' : undefined,
          color: value === 'grid' ? 'var(--joy-palette-text-primary)!important' : undefined,
          cursor: value === 'grid' ? 'default' : undefined,
        }}
        onClick={handleClick}
      >
        <Typography fontSize={12} fontWeight={400} textColor="inherit">
          Cards
        </Typography>
      </Button>
      <Button
        itemID="list"
        size="sm"
        variant="soft"
        color="neutral"
        startDecorator={
          <Box>
            <Icon name="list" weight="light" fw size="1x" color="inherit" />
          </Box>
        }
        sx={{
          minHeight: 24,
          py: 0,
          backgroundColor: value === 'list' ? 'var(--joy-palette-background-body)!important' : undefined,
          color: value === 'list' ? 'var(--joy-palette-text-primary)!important' : undefined,
          cursor: value === 'list' ? 'default' : undefined,
        }}
        onClick={handleClick}
      >
        <Typography fontSize={12} fontWeight={400} textColor="inherit">
          List
        </Typography>
      </Button>
    </Box>
  );
};

export default memo(DisplayModeSelect);
