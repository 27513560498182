import { type FC, type SyntheticEvent, memo, useCallback } from 'react';
import { Box, Divider, FormControl, Stack, Switch, Typography, Select, useColorScheme, Option } from '@mui/joy';

import { useGetOpenLink } from 'hooks';
import type { UserType } from 'app/entities';
import Storage from 'lib/Storage';
import Icon from 'ui/Icon';

export interface GeneralLayoutProps {
  formData: UserType | null;
  handleChange: (event: { target: { name: string; value: boolean } }) => void;
}

type Mode = 'light' | 'dark' | 'system';

const GeneralLayout: FC<GeneralLayoutProps> = (props) => {
  const { formData, handleChange } = props;

  const openLink = useGetOpenLink();

  const { mode, setMode } = useColorScheme();

  const handleThemeChange = useCallback(
    (event: SyntheticEvent | null, newValue: Mode | null) => {
      if (!newValue) {
        return;
      }
      Storage.set('theme.type', newValue);
      setMode(newValue);
    },
    [setMode],
  );

  const handlePrivacyPolicy = useCallback(() => {
    openLink('https://www.iubenda.com/privacy-policy/39334813');
  }, []);

  const handleCookiePolicy = useCallback(() => {
    openLink('https://www.iubenda.com/privacy-policy/39334813/cookie-policy');
  }, []);

  const handleTermsConditions = useCallback(() => {
    openLink('https://www.iubenda.com/terms-and-conditions/39334813');
  }, []);

  return (
    <Stack
      direction="column"
      spacing={2}
      width="calc(100% - 11rem - 3.5rem)"
      mt={3}
      sx={{
        '@media (max-width: 767px)': {
          width: '100%',
        },
      }}
    >
      <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: 13 }}>Show my topics in the feed</Typography>
        <Switch
          size="lg"
          color="primary"
          checked={formData?.isGoalsDisplayed}
          onChange={(e) => {
            const event = {
              target: {
                name: 'isGoalsDisplayed',
                value: e.target.checked,
              },
            };
            handleChange(event);
          }}
        />
      </FormControl>
      <Divider />
      <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: 13 }}>Show my topics in profile</Typography>
        <Switch
          size="lg"
          color="primary"
          checked={formData?.isSkillsDisplayed}
          onChange={(e) => {
            const event = {
              target: {
                name: 'isSkillsDisplayed',
                value: e.target.checked,
              },
            };
            handleChange(event);
          }}
        />
      </FormControl>
      <Divider />
      <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: 13 }}>Theme</Typography>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label id="theme-selector-indicator" htmlFor="theme-selector">
          <Select
            sx={{
              '--variant-borderWidth': 0,
              boxShadow: 'none',
              '--joy-palette-background-surface': 'transparent',
            }}
            slotProps={{
              button: {
                id: 'theme-selector',
                'aria-labelledby': 'theme-selector-indicator theme-selector',
              },
            }}
            indicator={<Icon name="chevron-down" sx={{ pointerEvents: 'none' }} />}
            onChange={handleThemeChange}
            value={mode}
            size="sm"
            variant="plain"
          >
            <Option value="light">Light</Option>
            <Option value="dark">Dark</Option>
            <Option value="system">System</Option>
          </Select>
        </label>
      </FormControl>
      <Divider />
      <Box>
        <Typography
          onClick={handlePrivacyPolicy}
          sx={{
            fontSize: 13,
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 500,
            },
          }}
        >
          Privacy policy
        </Typography>
      </Box>
      <Divider />
      <Box>
        <Typography
          onClick={handleCookiePolicy}
          sx={{
            fontSize: 13,
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 500,
            },
          }}
        >
          Cookie policy
        </Typography>
      </Box>
      <Divider />
      <Box>
        <Typography
          onClick={handleTermsConditions}
          sx={{
            fontSize: 13,
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 500,
            },
          }}
        >
          Terms & Conditions
        </Typography>
      </Box>
    </Stack>
  );
};

export default memo(GeneralLayout);
