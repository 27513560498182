import { type FC, memo, useCallback } from 'react';
import { AspectRatio, Box, Card, CardContent, Skeleton, Typography } from '@mui/joy';
import { isEqual } from 'lodash';

import { useSelector } from 'react-redux';

import * as contentStore from 'store/nodes/content';

import openLink from 'widgets/Copilot/model/openLink';

import { guard, unit } from 'utils';
import TextExpand from 'ui/TextExpand';
import Tags from 'components/Tags';

export interface MaterialProps {
  materialId: number;
}

const Material: FC<MaterialProps> = (props) => {
  const { materialId } = props;

  const data = useSelector(contentStore.selectors.dataById(materialId));

  const handleClick = useCallback(() => {
    if (!data?.id) {
      return;
    }
    const resourceId = data.id;
    if (guard.isMaterial(data) && data?.type) {
      const resourceType = data.type.toLowerCase?.();
      openLink(`/content/${resourceType}/${resourceId}`, '_blank');
    }
  }, [data]);

  return (
    <Card
      onClick={handleClick}
      orientation="horizontal"
      variant="soft"
      color="neutral"
      size="md"
      sx={{
        '--Card-radius': (theme) => theme.radius.lg,
        backgroundColor: 'transparent',
        transition: '300ms background-color ease',
        gap: 1.5,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--joy-palette-neutral-softBg)',
          '@media (max-width: 767px)': {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <CardContent>
        <Box display="flex" flexDirection="column" gap={1}>
          {(!data || !!data?.title) && (
            <TextExpand
              lines={2}
              slotsProps={{
                typography: {
                  level: 'title-sm',
                },
              }}
            >
              <Skeleton loading={!data}>{data?.title || '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0'}</Skeleton>
            </TextExpand>
          )}
          {(!data || !!(data?.description || data?.gptSummary)) && (
            <Typography
              level="body-sm"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
              }}
            >
              <Skeleton loading={!data}>
                {data?.description ||
                  data?.gptSummary ||
                  '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0'}
              </Skeleton>
            </Typography>
          )}
          {!data && (
            <Box display="flex" gap={1}>
              <Typography>
                <Skeleton sx={{ borderRadius: 12 }}>0 0 0</Skeleton>
              </Typography>
              <Typography>
                <Skeleton sx={{ borderRadius: 12 }}>0 0 0</Skeleton>
              </Typography>
              <Typography>
                <Skeleton sx={{ borderRadius: 12 }}>0 0 0</Skeleton>
              </Typography>
            </Box>
          )}
          {!!data && data?.tags && <Tags data={data} tagsLimit={6} />}
        </Box>
      </CardContent>
      {(!data || !!data?.image?.url) && (
        <AspectRatio ratio="4/3" sx={{ width: 100 }}>
          <Skeleton loading={!data} variant="overlay">
            <img src={data?.image?.url || ''} loading="lazy" alt="" />
          </Skeleton>
        </AspectRatio>
      )}
    </Card>
  );
};
export default memo(Material, isEqual);
