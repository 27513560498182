import { takeLatest, put, cancel, type SagaReturnType } from 'redux-saga/effects';

import * as api from 'services/api';

import { select, call } from 'store/utils/saga/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.view.type, actions.shown.type],
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.view | typeof actions.shown>) {
  const { payload } = action;
  const type = payload.type as 'view' | 'show';
  const { resource, resourceId } = payload;

  const isLoading = yield* select(selectors.isLoading(type, resource, resourceId));
  if (isLoading) {
    yield cancel();
    return;
  }

  yield put(actions.updateInteraction(type, resource, resourceId));

  const data = yield* select((state) => {
    if (resource === 'content') {
      return {
        ...state.content.data[resourceId],
        ...state?.interaction?.content?.[resourceId],
      };
    }
    if (resource === 'playlist') {
      return {
        ...state.playlist.data[resourceId],
        ...state?.interaction?.playlist?.[resourceId],
      };
    }
    return null;
  });

  if (!data) {
    yield put(actions.updateInteractionDone(type, resource, resourceId));
    yield cancel();
    return;
  }

  const oldValue = {
    isShown: data.isShown,
    isViewed: data.isViewed,
  };
  const newValue = {
    isShown: type === 'show' ? true : data.isShown,
    isViewed: type === 'view' ? true : data.isViewed,
  };

  if (['content', 'playlist'].includes(resource)) {
    yield put(actions.setValues(resource, resourceId, type, newValue));
  }

  const result = yield* call(() => api.resource.interaction.set(resource, resourceId, type));

  if (result.error || !result.data) {
    yield put(actions.setValues(resource, resourceId, type, oldValue));
    yield put(actions.updateInteractionDone(type, resource, resourceId));
    yield cancel();
    return;
  }

  const confirmValue = {
    isShown: result.data.isShown,
    isViewed: result.data.isViewed,
  };

  if (['content', 'playlist'].includes(resource)) {
    yield put(actions.setValues(resource, resourceId, type, confirmValue));
  }

  yield put(actions.updateInteractionDone(type, resource, resourceId));
}
