import React, { memo, useMemo } from 'react';
import { Box, type BoxProps } from '@mui/joy';

import { AnthropicIcon, OpenAiIcon, XaiIcon } from './assets';

/**
 * Зависит от структуры ваших моделей:
 *  0,1,3,4 -> openai
 *  2,6 -> anthropic
 *  5 -> xai
 * если понадобится, можете расширять.
 */
function getIconById(id: number) {
  if ([0, 1, 3, 4].includes(id)) return 'openai';
  if ([2, 6].includes(id)) return 'anthropic';
  if ([5].includes(id)) return 'xai';
  // fallback
  return 'xai';
}

interface ModelIconProps extends BoxProps {
  modelId: number;
  size?: number; // px
}

/**
 * Компонент, который отображает иконку компании,
 * соответствующую выбранной модели (из Redux).
 */
const ModelIcon = memo((props: ModelIconProps) => {
  const { modelId, size = 32, sx, ...restProps } = props;

  const iconName = useMemo(() => {
    if (!modelId) {
      return 'xai';
    }
    return getIconById(modelId);
  }, [modelId]);

  let IconComp: React.ElementType = XaiIcon;
  if (iconName === 'openai') IconComp = OpenAiIcon;
  if (iconName === 'anthropic') IconComp = AnthropicIcon;

  return (
    <Box
      {...restProps}
      sx={{
        display: 'inline-flex',
        width: size,
        height: size,
        // 'svg': { fill: 'currentColor' },
        ...sx,
      }}
    >
      <IconComp width="100%" height="100%" />
    </Box>
  );
});

export default ModelIcon;
