import { useCallback, useMemo } from 'react';
import { type DefaultRootState, useStore } from 'react-redux';

import type { ContentErrorType, ResourceType, SpaceFolderType, SpaceType } from 'app/entities';
import Alert from 'components/Alert';
import { guard, stripTags, truncateText } from 'utils';

interface ShareData {
  url?: string;
  title: string;
  text: string;
}

interface PreparedData {
  uri: string | null;
  title: string | null;
  text: string | null;
}

interface SpaceSymLink {
  id: number;
  type: 'Space';
}

interface FolderSymLink {
  id: string;
  type: 'Folder';
}

type ShareDataType = SpaceType | SpaceFolderType | ResourceType | ContentErrorType | SpaceSymLink | FolderSymLink;

function isSymLink(data?: SpaceSymLink | FolderSymLink | unknown): data is SpaceSymLink | FolderSymLink {
  return (
    !!data &&
    typeof data === 'object' &&
    'type' in data &&
    (data.type === 'Space' || data.type === 'Folder') &&
    'id' in data &&
    (typeof data.id === 'string' || typeof data.id === 'number')
  );
}

function createWebURL(path: string): string {
  const baseUrl = window.location.origin;
  return new URL(path, baseUrl).toString();
}

function prepare(data?: ShareDataType | null): PreparedData {
  if (guard.isMaterial(data) || guard.isCollection(data)) {
    return {
      uri: data.internalUrl,
      title: truncateText(data?.title || '', 150),
      text: truncateText(data?.description || '', 250),
    };
  }
  if (guard.isNote(data) || guard.isDocument(data)) {
    const plainText = stripTags(data.text || '');
    return {
      uri: data.internalUrl || null,
      title: truncateText(plainText, 150),
      text: truncateText(plainText, 250),
    };
  }
  if (guard.isSpace(data)) {
    return {
      uri: `/space/${data.id}/folder/root`,
      title: truncateText(data.title || '', 150),
      text: truncateText(data.description || '', 250),
    };
  }
  if (guard.isSpaceFolder(data)) {
    return {
      uri: `/space/${data.spaceId}/folder/${data.id}`,
      title: truncateText(data.title || '', 150),
      text: truncateText(data.description || '', 250),
    };
  }
  return { uri: null, title: null, text: null };
}

const useGetShare = () => {
  const store = useStore();

  const getResourceBySymLink = useCallback((data: SpaceSymLink | FolderSymLink) => {
    const state = store.getState() as DefaultRootState;
    if (data.type === 'Space') {
      return state.spaceList.data?.[data.id] || null;
    }
    if (data.type === 'Folder') {
      return state.spaceResource.data?.[data.id] || null;
    }
    return null;
  }, []);

  const share = useCallback(
    (source?: ShareDataType | null) => {
      let data = source;
      if (isSymLink(data)) {
        data = getResourceBySymLink(data);
      }
      if (guard.isEntityError(data) || !data) {
        Alert.error(`This resource can't be shared`);
        return;
      }

      const { uri, title, text } = prepare(data);

      const url = !uri ? undefined : createWebURL(uri);

      const shareData: ShareData = { url, title: title || '', text: text || '' };

      if (!url) {
        return;
      }

      if (navigator.canShare && navigator.canShare(shareData)) {
        navigator
          .share(shareData)
          .then(() => {
            // Alert.success('Link sent');
          })
          .catch((error) => {
            // Alert.error('Error send link');
          });
        return;
      }

      navigator.clipboard
        .writeText(url)
        .then(
          () => {
            Alert.success('Copied to clipboard', {
              toastId: 23,
            });
          },
          (err) => {
            Alert.error('Error copy link');
          },
        )
        .catch((error) => {
          Alert.error('Error copy link');
        });
    },
    [store],
  );

  return useMemo(() => share, [share]);
};

export default useGetShare;
