import { type FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Box } from '@mui/joy';

import type { SpaceEntityKey } from 'app/entities';
import * as copilotStore from 'widgets/Copilot/store';

import List, { type ListImperativeHandler } from 'components/List';
import { useIsPanelsResizing } from 'components/Panels/model/usePanelsResizing';

import useIsFolderLoading from '../../../model/useIsFolderLoading';
import useFolderHasNext from '../../../model/useFolderHasNext';
import useFolderChildren from '../../../model/useFolderChildren';
import useFolderNextPage from '../../../model/useFolderNextPage';

import ItemRender from './Item';
import Progress from './Progress';

import useElementTop from '../model/useElementTop';

export interface EntitiesProps {
  spaceId?: number;
  folderId?: string;
  displayMode?: 'grid' | 'list';
  canSelect?: boolean;
  canRemove?: boolean;
}

const Entities: FC<EntitiesProps> = (props) => {
  const { spaceId = 0, folderId = 'root', displayMode = 'list', canSelect, canRemove } = props;

  // @todo это тоже хранить в контексте с отдельными ключами
  const isCopilotShow = useSelector((state: DefaultRootState) => copilotStore.selectors.rightBarIsShow(state));

  const listRef = useRef<ListImperativeHandler>(null);
  const listContainerRef = useRef<HTMLDivElement | null>(null);

  const isLoading = useIsFolderLoading(spaceId, folderId);
  const hasNext = useFolderHasNext(spaceId, folderId);
  const items = useFolderChildren(spaceId, folderId);
  const { handler: nextPageHandler } = useFolderNextPage(spaceId, folderId);

  const elementTop = useElementTop(listContainerRef.current);

  const isPanelsResizing = useIsPanelsResizing('space/folder/panels', {
    onResizingStop: useCallback(() => {
      const { current: list } = listRef;
      if (!list) {
        return;
      }
      list.resize();
    }, []),
  });

  // @todo тоже переделать на контекст
  useEffect(() => {
    const { current: list } = listRef;
    if (!list) {
      return;
    }
    setTimeout(() => {
      list.resize();
    }, 10);
  }, [isCopilotShow]);

  const getKey = useCallback((item: SpaceEntityKey) => {
    if (!item || typeof item !== 'object') {
      return 'unknown';
    }
    return `${item.__typename}-${item.id}`;
  }, []);

  const preparedList = useMemo((): typeof items => {
    if (items.length > 0) {
      return items;
    }
    return [
      {
        __typename: 'Content',
        id: 'plus',
      },
    ];
  }, [items]);

  const isFirstLoading = useMemo(() => isLoading && items.length === 0, [isLoading, items.length]);

  return (
    <>
      {isFirstLoading && <Progress />}
      {!isFirstLoading && (
        <List
          ref={listRef}
          containerRef={listContainerRef}
          items={preparedList}
          getKey={getKey}
          columnsScheme={displayMode === 'list' ? '320:1' : '320:1;528:2;672:3;960:4;1300:5'}
          loading={isLoading}
          hasNextPage={!isLoading && hasNext}
          onEndReached={nextPageHandler}
          sx={{
            opacity: isPanelsResizing ? 0 : 1,
          }}
          resizeOptions={{
            detect: 'manual',
            batchDelay: -1,
          }}
          slots={{
            item: ItemRender,
          }}
          slotProps={{
            item: {
              canSelect: canSelect ?? false,
              canRemove: canRemove ?? false,
            },
          }}
        />
      )}
      <Box
        position="absolute"
        left={0}
        top={elementTop - 80}
        right={0}
        bottom="7rem"
        bgcolor="color-mix(in srgb, var(--joy-palette-background-level1) 50%, var(--joy-palette-background-body) 50%)"
        zIndex={10}
        borderRadius="var(--joy-radius-xl)"
        sx={{
          pointerEvents: 'none',
          opacity: isPanelsResizing ? 1 : 0,
        }}
      />
      <Box height={0} mb={12} />
    </>
  );
};

export default memo(Entities, isEqual);
