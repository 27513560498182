import { type FC, memo, useCallback, type KeyboardEventHandler } from 'react';
import { Box, Button, IconButton, Input, type InputProps } from '@mui/joy';
import { isEqual } from 'lodash';

import Icon from 'ui/Icon';

import { usePromoState, type AppliedData } from './usePromoState';

export type OnPromoInputEventHandler = (
  event:
    | {
        type: 'applied';
        code: string;
        data: AppliedData;
      }
    | {
        type: 'error';
        code: string;
        error: string;
      }
    | {
        type: 'reset';
      },
) => void;

export type PromoDataType = AppliedData;

interface PromoInputProps extends InputProps {
  onEvent?: OnPromoInputEventHandler;
  isApplied?: boolean;
}

const PromoInput: FC<PromoInputProps> = (props) => {
  const { onEvent, isApplied, ...rest } = props;

  const { value, onChange, reset, apply, isLoading } = usePromoState({
    handleApplied: useCallback(
      (code, data) => {
        onEvent?.({
          type: 'applied',
          code,
          data,
        });
      },
      [onEvent],
    ),
    handleError: useCallback((code, error) => {
      onEvent?.({
        type: 'error',
        code,
        error,
      });
    }, []),
    handleReset: useCallback(() => {
      onEvent?.({
        type: 'reset',
      });
    }, []),
  });

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        apply();
      }
    },
    [apply],
  );

  return (
    <Input
      {...rest}
      disabled={isApplied}
      readOnly={isLoading}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      size="lg"
      placeholder="Use promo code"
      sx={{
        '--Input-radius': '10px',
        ...rest.sx,
      }}
      endDecorator={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1.5}
          sx={{
            mr: -0.375,
          }}
        >
          {isApplied && (
            <IconButton sx={{ pointerEvents: 'all', mr: -0.5 }} size="sm" onClick={reset}>
              <Icon name="xmark" size="lg" sx={{ transform: 'translateY(1px)', color: 'var(--joy-palette-danger-plainColor)' }} />
            </IconButton>
          )}
          <Button
            onClick={apply}
            disabled={!value || isApplied}
            loading={isLoading}
            sx={{
              '--Button-radius': 0,
              '--Button-minHeight': '2.6875rem',
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            {!isApplied ? 'Apply' : 'Applied'}
          </Button>
        </Box>
      }
    />
  );
};

export default memo(PromoInput, isEqual);
