import { Dimensions } from 'react-native';
import { takeLatest, put, cancel, type SagaReturnType, delay } from 'redux-saga/effects';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import { controller as modal } from 'components/Modal2';
import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.addMaterial.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.addMaterial>) {
  const material = action.payload;

  if (!material?.type) {
    Alert.error('"Content type" is required');
    yield put(actions.addMaterialDone());
    yield cancel();
    return;
  }
  if (!material?.title) {
    Alert.error('"Title" is required');
    yield put(actions.addMaterialDone());
    yield cancel();
    return;
  }

  const { data, error } = yield* call(() => api.resource.myContent.add(material));
  if (error || !data) {
    Alert.error(error);
    yield put(actions.addMaterialDone());
    yield cancel();
    return;
  }

  yield put(actions.addMaterialSetLastCreatedId(data.id));
  yield put(actions.addMaterialClearData());

  yield put(actions.addMaterialDone());

  if (Dimensions.get('window').width <= 425) {
    modal.info.addMaterialComplete.open();
    modal.info.addMaterialComplete.delay(2400).close();
  } else {
    Alert.success('Material added');
  }

  yield delay(2000);
  yield put(userStore.actions.loadMe());
}
