import type { ResultLegacy } from 'app/entities';
import * as authStore from 'store/nodes/auth';
import { put } from 'redux-saga/effects';

export function* checkUnauthorized(result: Omit<ResultLegacy<unknown>, 'data'>) {
  if (!result.error) {
    return;
  }
  const error = Array.isArray(result.error) ? result.error[0] : result.error;
  if (error.message.toLowerCase().includes('unauthorized')) {
    yield put(authStore.actions.logOut());
  }
}
