import { createCachedSelector } from 're-reselect';
import type { DefaultRootState } from 'react-redux';

const baseSelector = (state: DefaultRootState, entityId: string | 'root') => state.spaceResource.data?.[entityId];

const entityByIdSelector = createCachedSelector(baseSelector, (entity) => entity)((_state, entityId) => entityId);

export default (id?: string) => (state: DefaultRootState) => {
  return entityByIdSelector(state, id || '');
};
