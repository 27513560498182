import * as uuid from 'uuid';

import { dispatcher } from 'store/utils/redux/hooks';
import type { BodyType, ButtonType, ButtonAliasType } from 'components/Dialog/types';

class Controller {
  private readonly callbacks: Record<string, Record<string, (id: string) => void>>;

  private readonly body: Record<string, BodyType>;

  private readonly buttons: Record<string, ButtonAliasType[]>;

  constructor() {
    this.callbacks = {};
    this.body = {};
    this.buttons = {};
  }

  public show = (body: BodyType, buttons: ButtonType[]) => {
    const id = uuid.v4();

    this.callbacks[id] = {};
    this.body[id] = body;
    this.buttons[id] = [];

    buttons.forEach(({ handle, ...rest }) => {
      const handleId = uuid.v4();
      this.callbacks[id][handleId] = handle;
      this.buttons[id].push({
        ...rest,
        handleId,
      });
    });

    dispatcher.app.dialogShow(id);
  };

  public hide = (id: string) => {
    dispatcher.app.dialogHide(id);
    delete this.callbacks[id];
    delete this.body[id];
    delete this.buttons[id];
  };

  public getHandler = (dialogId: string, handleId: string) => {
    return this.callbacks?.[dialogId]?.[handleId];
  };

  public getBody = (dialogId: string) => {
    return this.body?.[dialogId] || null;
  };

  public getButtons = (dialogId: string) => {
    return this.buttons?.[dialogId] || null;
  };
}

export default new Controller();
