import { type SagaReturnType, cancel, put, takeLatest } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';
import type { SpaceType } from 'app/entities';

import dialog from 'components/Dialog';
import Alert from 'components/Alert';
import { navigate } from 'navigation/methods';

import { actions as listActions } from '../slices/list';

export const config = {
  action: listActions.delete.type,
  method: takeLatest,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: {
          icon: {
            name: 'circle-exclamation',
            weight: 'solid',
          },
          content: 'Confirmation',
        },
        content: <>Are you sure want to delete this space?</>,
      },
      [
        {
          label: 'Yes',
          color: 'danger',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
        {
          label: 'Cancel',
          variant: 'plain',
          color: 'neutral',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
      ],
    );
  });

export function* func(action: SagaReturnType<typeof listActions.delete>) {
  const { id } = action.payload;

  if (!id) {
    yield cancel();
    return;
  }

  const isConfirmed = yield* call(confirm);
  if (!isConfirmed) {
    yield put(listActions.deleteDone({ id }));
    yield cancel();
    return;
  }

  yield put(listActions.hide({ id }));
  const response: { data: SpaceType | null; hasError: boolean; errors: Error[] | null } = yield call(() =>
    network.request(`/stack-2/user/team-space/${id}`).delete(),
  );

  if (response.hasError) {
    Alert.error('Error delete collection');
    yield put(listActions.show({ id }));
    yield put(listActions.deleteDone({ id }));
    yield cancel();
    return;
  }

  yield* call(() =>
    navigate('Profile', {
      login: 'my',
    }),
  );
  yield put(listActions.deleteDone({ id }));
}
