import { type FC, type ReactNode, memo, useCallback } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Switch,
  Typography,
} from '@mui/joy';
import type { ImageInput, ImageType, SpaceFolderType } from 'app/entities';
import { isEqual } from 'lodash';
import ImagePicker from 'components/ImagePicker';
import Preview from 'components/Preview';

interface FolderSettingsLayoutProps {
  header?: ReactNode;
  data?: Partial<Pick<SpaceFolderType, 'title' | 'description' | 'isPrivate' | 'cover'>> | null;
  processing?: boolean;
  submitLabel?: string;
  onChange: (event: { target: { name: string; value: string | boolean | ImageType | null } }) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const FolderSettingsLayout: FC<FolderSettingsLayoutProps> = (props) => {
  const { header, data, processing, submitLabel = 'Done', onChange, onSubmit, onCancel } = props;

  const handleCoverChange = useCallback((event: { type: 'select' | 'delete'; value: ImageType | null }) => {
    if (event.type === 'select') {
      onChange({ target: { name: 'cover', value: event.value } });
    }
    if (event.type === 'delete') {
      onChange({ target: { name: 'cover', value: null } });
    }
  }, []);

  const previewRender = useCallback((value?: ImageType | null) => <Preview uri={value?.url} />, []);
  return (
    <>
      <DialogTitle>
        <Box display="flex" flexDirection="column" mt={-1} flex={1}>
          {header}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" mx={0.125} gap={3} flex={1}>
          <Box display="flex" flexDirection="row" gap={3} alignSelf="stretch">
            <ImagePicker render={previewRender} onChange={handleCoverChange} value={data?.cover} />
            <Box display="flex" flexDirection="column" gap={3} flex={1} mb={-0.125}>
              <FormControl required>
                <FormLabel sx={{ fontSize: 13, fontWeight: 400, mb: 0 }}>Name</FormLabel>
                <Input
                  variant="outlined"
                  value={data?.title || ''}
                  name="title"
                  placeholder="Enter folder name"
                  onChange={onChange}
                  fullWidth
                  autoFocus
                  sx={{
                    backgroundColor: 'transparent',
                    borderRadius: 'var(--joy-radius-xl)',
                    boxShadow: 'none',
                    mt: 0.25,
                  }}
                />
              </FormControl>
              <FormControl sx={{ flex: 1 }}>
                <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>Description</FormLabel>
                <Textarea
                  variant="outlined"
                  value={data?.description || ''}
                  name="description"
                  onChange={onChange}
                  placeholder="A brief overview of the folder's purpose"
                  sx={{
                    backgroundColor: 'transparent',
                    borderRadius: 'var(--joy-radius-xl)',
                    boxShadow: 'none',
                    mt: 0.25,
                    height: 'calc(100% - 1.5rem)',
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 0, gap: 1 }}>
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          onClick={onSubmit}
          disabled={!data?.title}
          loading={processing}
          sx={{ fontWeight: 400, fontSize: 13 }}
        >
          {submitLabel}
        </Button>
        <Button onClick={onCancel} variant="outlined" color="neutral" size="sm" disabled={processing} sx={{ fontWeight: 400, fontSize: 13 }}>
          Cancel
        </Button>
        <Box flex={1} />
        <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          <Switch checked={!!data?.isPrivate} onChange={(event) => onChange({ target: { name: 'isPrivate', value: event.target.checked } })} />
          <Typography>{data?.isPrivate ? 'private' : 'public'}</Typography>
        </FormControl>
      </DialogActions>
    </>
  );
};

export default memo(FolderSettingsLayout, isEqual);
