import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Box, CardContent, Tooltip, Typography } from '@mui/joy';

import Icon from 'ui/Icon';
import { type AuthorType, type ImageType, type UserType } from 'app/entities';

import * as collectionStore from 'store/nodes/playlist';

import CompositePoster from 'components/CompositePoster';

import useAuthor from '../model/useAuthor';
import { useCardProps, useCardPoster } from '../model/CardContext';

import DescriptionBlock from '../ui/DescriptionBlock';
import AuthorBlock from '../ui/AuthorBlock';

export interface CollectionProps {
  handleAuthorPress?: (value: UserType | AuthorType | null) => void;
}

const Collection: FC<CollectionProps> = (props) => {
  const { handleAuthorPress } = props;
  const { id, AuthorBlockComponent, descriptionLineLimit, hasDescription, hasAuthor } = useCardProps();

  const data = useSelector(collectionStore.selectors.dataById(id));
  const posterSources = useCardPoster();

  const { person } = useAuthor(data);

  const handlePress = useCallback(() => {
    if (!person) {
      return;
    }
    handleAuthorPress?.(person);
  }, [person]);

  return (
    <>
      <Box
        sx={{
          '@container (width > 400px )': {
            display: 'none',
          },
        }}
      >
        {posterSources && posterSources.length > 0 && <CompositePoster sources={posterSources} radius={8} aspectRatio={4 / 3} />}
      </Box>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{
              '@container (width <= 400px )': {
                gap: 1.5,
              },
            }}
          >
            {data?.title && (
              <Typography
                fontSize={17}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={600}
              >
                {data.isPrivate && (
                  <Tooltip placement="top" arrow disableInteractive variant="outlined" size="sm" title="This collection is visible only to you.">
                    <Typography component="span" fontSize={16}>
                      <Icon size="sm" marginRight={1} weight="solid" color="var(--joy-palette-primary-solidDisabledColor)" name="eye-slash" />
                    </Typography>
                  </Tooltip>
                )}
                {data?.title?.trim?.()}
              </Typography>
            )}
            {hasDescription && data?.description && (
              <DescriptionBlock ellipsizeMode="tail" numberOfLines={descriptionLineLimit || 20} text={data.description} />
            )}
            {!!AuthorBlockComponent && AuthorBlockComponent}
            {!AuthorBlockComponent && hasAuthor && !!person && <AuthorBlock onPress={handlePress} createData={data?.createdAt} data={person} />}
          </Box>
          {posterSources && posterSources.length > 0 && (
            <Box
              sx={{
                ml: 1,
                '@container (width <= 400px )': {
                  display: 'none',
                },
              }}
            >
              <CompositePoster width={120} sources={posterSources} radius={8} aspectRatio={16 / 9} />
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default memo(Collection);
