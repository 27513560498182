import { type FC, memo } from 'react';
import { isEqual } from 'lodash';

import { ContextProvider } from './model/CardContext';
import { CardActionsProvider } from './model/CardActionsContext';
import useCardTargetProps from './model/useCardTargetProps';
import useData from './model/useData';
import useLoadData from './model/useLoadData';

import Card from './ui/Card';

import type { CardPropsWithBoxProps } from './types';

const DataConnector: FC<CardPropsWithBoxProps> = (props) => {
  const { cardProps, restBoxProps } = useCardTargetProps(props);

  const { id, type } = cardProps;

  const data = useData(type, id);
  useLoadData(id, type);

  return (
    <ContextProvider props={cardProps} data={data}>
      <CardActionsProvider data={data}>
        <Card {...restBoxProps} />
      </CardActionsProvider>
    </ContextProvider>
  );
};

export default memo(DataConnector, isEqual);
