import { type FC, memo, useMemo } from 'react';
import { Box, type BoxProps } from '@mui/joy';
import { isEqual } from 'lodash';

import type { ImageType } from 'app/entities';

import CompositePoster from 'components/CompositePoster';

interface PosterProps extends BoxProps {
  cover?: ImageType | null;
  contentImages?: ImageType[];
  aspectRatio?: number;
}

const Poster: FC<PosterProps> = (props) => {
  const { cover, contentImages, aspectRatio, ...rest } = props;

  const sources = useMemo(() => {
    if (cover) {
      return [cover];
    }
    if (!cover) {
      return contentImages || [];
    }
    return [];
  }, [cover, contentImages]);

  return (
    <Box {...rest}>
      <CompositePoster sources={sources} aspectRatio={aspectRatio} radius={10} />
    </Box>
  );
};

export default memo(Poster, isEqual);
