import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatcher } from 'store/utils/redux/hooks';
import { useSelector } from 'react-redux';
import type { SpaceFolderType, ImageType } from 'app/entities';
import * as spaceStore from 'screens/Space/store';
import { newImage } from 'utils/prepare';

const useFolderState = (folderId?: SpaceFolderType['id']) => {
  const dispatcher = useDispatcher();

  const source = useSelector(spaceStore.selectors.folderById(folderId));

  const [data, setData] = useState<Partial<Pick<SpaceFolderType, 'title' | 'description' | 'isPrivate' | 'cover'>>>({
    title: source?.title,
    description: source?.description,
    isPrivate: source?.isPrivate,
    cover: source?.cover,
  });

  useEffect(() => {
    if (source) {
      setData({
        title: source.title,
        description: source.description,
        isPrivate: source.isPrivate,
        cover: source?.cover,
      });
    }
  }, [source]);

  const change = useCallback((event: { target: { name: string; value: string | boolean | ImageType | null } }) => {
    const { name, value } = event.target;
    setData((prev) => (prev ? { ...prev, [name]: value } : prev));
  }, []);

  const update = useCallback(() => {
    if (!data || !folderId) return;
    dispatcher.spaceResource.updateFolder({ id: folderId, data: { ...data, cover: newImage(data?.cover) } });
  }, [data, folderId, dispatcher]);

  return useMemo(
    () => ({
      data,
      change,
      update,
    }),
    [data, change, update],
  );
};

export default useFolderState;
