import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import { Box, type BoxProps } from '@mui/joy';

interface DividerProps extends BoxProps {}

const Divider: FC<DividerProps> = (props) => {
  return <Box height={0} borderBottom="1px solid var(--joy-palette-divider)" {...props} />;
};

export default memo(Divider, isEqual);
