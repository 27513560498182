import { useMemo } from 'react';
import { type ContentErrorType, type MaterialType } from 'app/entities';
import { guard } from 'utils';

const useMaterialType = (data?: MaterialType | ContentErrorType | null) =>
  useMemo(() => {
    if (!guard.isMaterial(data)) {
      return null;
    }
    const type = data?.type?.toLowerCase?.();
    if (type === 'video' && /\/\/(youtube.com|vimeo.com)/i.test(data?.originalUrl || '')) {
      return 'video' as const;
    }
    if (type === 'pdf' && /\.pdf(\??.*)/i.test(data?.originalUrl || '')) {
      return 'pdf' as const;
    }
    if (type === 'link' && /\.(png|jpe?g|gif|webp|tiff?)(\??.*)/i.test(data?.originalUrl || '')) {
      return 'image' as const;
    }
    return type;
  }, [(data as MaterialType)?.type]);

export default useMaterialType;
