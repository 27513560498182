import { useCallback } from 'react';
import { usePanelsSizeContext } from './panelsSizeContext';

export function usePanelsSize(storeKey: string) {
  const { getPanelsSize } = usePanelsSizeContext();
  return getPanelsSize(storeKey);
}

export function useSetPanelsSize(storeKey: string) {
  const { setPanelsSize } = usePanelsSizeContext();
  return useCallback(
    (left: number, right: number) => {
      setPanelsSize(storeKey, left, right);
    },
    [storeKey, setPanelsSize],
  );
}
