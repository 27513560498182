import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';
import network from 'lib/network';

import { actions } from '../slices/permission';
import sourceUserPermissionToSpaceUserPermission, { type SourceUserPermissionType } from '../../model/sourceUserPermissionToSpaceUserPermission';

export const config = {
  action: actions.createByEmail.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.createByEmail>) {
  const { spaceId, userEmail, permissionId } = action.payload;

  if (!spaceId || !userEmail || !permissionId) {
    yield put(actions.createByEmailDone({ spaceId, userEmail, permissionId }));
    yield cancel();
    return;
  }

  const { data, hasError } = yield* call(() =>
    network
      .request<SourceUserPermissionType[]>(`/stack-2/user/team-space/${spaceId}/createPermission`)
      .body({ email: userEmail, permissionId })
      .post(),
  );

  if (hasError || !data) {
    Alert.error('Error creating permission');
    yield put(actions.createByEmailDone({ spaceId, userEmail, permissionId }));
    yield cancel();
    return;
  }

  yield put(actions.setUserPermissions({ items: sourceUserPermissionToSpaceUserPermission(data), mode: 'reset' }));
  yield put(actions.createByEmailDone({ spaceId, userEmail, permissionId }));
}
