import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (playlistId: number | number[]): Promise<SuccessResult> => {
  const ids = !Array.isArray(playlistId) ? [playlistId] : playlistId;
  if (!ids || ids.length === 0) {
    return {
      data: { success: true },
      error: null,
    };
  }

  let error: Error | null = null;
  const responses = await Promise.all(ids.map((id) => request.delete<never>(`/stack-1/user/playlists/${id}`)));
  responses.forEach((response) => {
    if (error) {
      return;
    }
    error = response.error;
  });

  return {
    data: { success: !error },
    error,
  };
};
