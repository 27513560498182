import React, { type ReactNode, createContext, useRef, useCallback, useContext, useMemo, forwardRef, useImperativeHandle } from 'react';
import type { UseTreeItem2Interactions } from '@mui/x-tree-view/hooks/useTreeItem2Utils/useTreeItem2Utils';

/**
 * Интерфейс значений, доступных в контексте:
 * - setTreeItemInteractions: регистрация/удаление методов для itemId
 * - getTreeItemInteractions: получение методов для itemId
 */
interface TreeViewInteractionsContextValue {
  setTreeItemInteractions: (itemId: string, interactions?: UseTreeItem2Interactions) => void;
  getTreeItemInteractions: (itemId: string) => UseTreeItem2Interactions | undefined;
}

/**
 * Создаем контекст (по умолчанию null), чтобы мы могли
 * отлавливать использование вне провайдера.
 */
const TreeViewInteractionsContext = createContext<TreeViewInteractionsContextValue | null>(null);

/**
 * Провайдер, внутри которого будем хранить
 * Map<itemId, UseTreeItem2Interactions>.
 */
export function TreeViewInteractionsProvider({ children }: { children: ReactNode }) {
  // Храним Map в ref, чтобы не триггерить ререндер при изменениях
  const storeRef = useRef(new Map<string, UseTreeItem2Interactions>());

  /**
   * Регистрируем/обновляем методы для конкретного itemId.
   * Если interactions не передан, то удаляем запись из storeRef.
   */
  const setTreeItemInteractions = useCallback((itemId: string, interactions?: UseTreeItem2Interactions) => {
    if (!interactions) {
      storeRef.current.delete(itemId);
    } else {
      storeRef.current.set(itemId, interactions);
    }
  }, []);

  /**
   * Получаем сохраненные методы для itemId.
   */
  const getTreeItemInteractions = useCallback((itemId: string) => {
    return storeRef.current.get(itemId);
  }, []);

  /**
   * Мемоизируем контекст, чтобы не пересоздавать объект
   * на каждом рендере.
   */
  const contextValue = useMemo(
    () => ({
      setTreeItemInteractions,
      getTreeItemInteractions,
    }),
    [setTreeItemInteractions, getTreeItemInteractions],
  );

  return <TreeViewInteractionsContext.Provider value={contextValue}>{children}</TreeViewInteractionsContext.Provider>;
}

/**
 * Хук для работы с контекстом: можно вызывать
 * setTreeItemInteractions/getTreeItemInteractions где угодно
 * внутри провайдера.
 */
export function useTreeViewInteractions() {
  const context = useContext(TreeViewInteractionsContext);
  if (!context) {
    throw new Error('useTreeViewInteractions must be used within a <TreeViewInteractionsProvider />');
  }
  return context;
}

/**
 * Интерфейс для ref, который отдаёт наш Bridge-компонент.
 * Здесь вы описываете методы, которые хотите «пробросить» наружу.
 */
export interface TreeViewInteractionsBridgeRef {
  getTreeItemInteractions: (itemId: string) => UseTreeItem2Interactions | undefined;
  setTreeItemInteractions: (itemId: string, interactions?: UseTreeItem2Interactions) => void;
}

/**
 * Компонент, который "мостом" (Bridge) пробрасывает наружу
 * методы взаимодействия через ref. Если название "Bridge" не нравится,
 * можете заменить его на что-то вроде TreeViewInteractionsHandle / Portal / etc.
 */
export const TreeViewInteractionsBridge = forwardRef<TreeViewInteractionsBridgeRef, {}>(function TreeViewInteractionsBridge(_props, ref) {
  const { getTreeItemInteractions, setTreeItemInteractions } = useTreeViewInteractions();

  useImperativeHandle(ref, () => ({
    getTreeItemInteractions,
    setTreeItemInteractions,
  }));

  // Компонент ничего не рендерит, его задача – только отдавать методы через ref
  return null;
});
