import { type FC, memo, useMemo } from 'react';

import { isEqual } from 'lodash';

import type { Resource } from './store/types';

import { ConfigProvider, type ConfigType } from './model/config';

import DefaultLayout from './layouts/DefaultLayout';

import type { ResourceType } from './types';

export { default as CopilotUtilityBar } from './ui/UtilityBar';
export { default as CopilotShowToggle } from './ui/ShowToggle';
export { default as CopilotResetButton } from './ui/ResetButton';
export { default as CopilotScopeSwitcher } from './ui/ScopeSwitcher';
export { default as CopilotModelSelect } from './ui/ModelSelect';

export interface BaseProps {
  windowScroll?: boolean;
  disabled?: boolean;
  slotsProps?: ConfigType;
}

export interface MaterialProps extends BaseProps {
  variant: 'material';
  resourceId: number;
}

export interface CollectionProps extends BaseProps {
  variant: 'collection';
  resourceId: number;
}

export interface NoteProps extends BaseProps {
  variant: 'note';
  resourceId: number;
}

export interface LibraryProps extends BaseProps {
  variant: 'library';
}

export interface WebProps extends BaseProps {
  variant: 'web';
}

export interface ResearchProps extends BaseProps {
  variant: 'research';
}

export interface GlobalFulltextProps extends BaseProps {
  variant: 'global-fulltext';
}

export interface LibraryFulltextProps extends BaseProps {
  variant: 'library-fulltext';
}

export interface SpaceProps extends BaseProps {
  variant: 'Space';
  spaceId: number;
}

export interface SpaceFolderProps extends BaseProps {
  variant: 'SpaceFolder';
  spaceId: number;
  folderId: string;
}

type CopilotProps =
  | MaterialProps
  | CollectionProps
  | LibraryProps
  | NoteProps
  | WebProps
  | ResearchProps
  | GlobalFulltextProps
  | LibraryFulltextProps
  | SpaceProps
  | SpaceFolderProps;

const Copilot: FC<CopilotProps> = (props) => {
  const { variant, windowScroll, disabled, slotsProps } = props;

  const params = useMemo(() => {
    if (variant === 'material' || variant === 'collection' || variant === 'note') {
      return {
        resourceId: props.resourceId,
      };
    }
    if (variant === 'Space') {
      return {
        resourceId: props.spaceId,
      };
    }
    if (variant === 'SpaceFolder') {
      return {
        resourceId: `${props.spaceId}::${props.folderId}`,
      };
    }
    return {};
  }, [variant, props]);

  const includeItemsForResource = useMemo(() => {
    const map: Record<ResourceType, Resource[]> = {
      material: [],
      collection: [],
      note: [],
      library: [],
      web: [
        { type: 'research', id: null },
        { type: 'global-fulltext', id: null },
        { type: 'library-fulltext', id: null },
      ],
      research: [
        { type: 'web', id: null },
        { type: 'global-fulltext', id: null },
        { type: 'library-fulltext', id: null },
      ],
      'global-fulltext': [
        { type: 'web', id: null },
        { type: 'research', id: null },
        { type: 'library-fulltext', id: null },
      ],
      'library-fulltext': [
        { type: 'web', id: null },
        { type: 'research', id: null },
        { type: 'global-fulltext', id: null },
      ],
      Space: [],
      SpaceFolder: [],
    } as const;
    return map[variant];
  }, [variant]);

  return (
    <ConfigProvider
      value={{
        answer: {
          actionsBar: !slotsProps?.answer?.actionsBar ? ['Reload', 'ToClipboard', 'ToNote'] : slotsProps?.answer?.actionsBar,
        },
        input: slotsProps?.input,
      }}
    >
      <DefaultLayout
        resourceType={variant}
        {...params}
        includeItemsForResource={includeItemsForResource}
        windowScroll={windowScroll}
        disabled={disabled}
        {...slotsProps?.body}
      />
    </ConfigProvider>
  );
};

export default memo(Copilot, isEqual);
