import { type SagaReturnType, call, put, takeLatest, cancel, take } from 'redux-saga/effects';

import network from 'lib/network';
import type { SpaceType } from 'app/entities';

import * as modalsActions from 'widgets/modals/store/actions';

import { actions as listActions } from '../slices/list';
import { actions as permissionActions } from '../slices/permission';
import { actions as structureActions } from '../slices/structure';

export const config = {
  action: listActions.create.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof listActions.create>) {
  const { description, title, instruction } = action.payload.data;

  yield put(
    modalsActions.update('SpaceManageData', {
      processing: true,
    }),
  );

  const response: { data: SpaceType | null; hasError: boolean; errors: Error[] | null } = yield call(() =>
    network
      .request(`/stack-2/user/team-space`)
      .body({
        title,
        description,
        instruction,
        is_private: true,
      })
      .post(),
  );

  if (response.hasError || !response.data) {
    console.error('Error', response.errors);
    yield cancel();
    return;
  }

  yield put(permissionActions.loadUserPermissions({ data: [response.data] }));
  yield put(listActions.set({ data: response.data, mode: 'extend' }));
  yield take(permissionActions.loadUserPermissionsDone.type);

  yield put(
    modalsActions.update('SpaceManageData', {
      processing: false,
      data: response.data,
      step: 'permission',
    }),
  );

  yield put(structureActions.loadFull({ spaceId: response.data.id, folderId: 'root' }));
}
