import { type FC, memo } from 'react';
import { Card, Typography, Box, type BoxProps, useColorScheme } from '@mui/joy';

interface EmptyPanelProps extends BoxProps {
  text: string;
}

const EmptyPanel: FC<EmptyPanelProps> = (props) => {
  const { text, ...rest } = props;

  const { mode } = useColorScheme();

  return (
    <Box {...rest}>
      <Card
        color="neutral"
        sx={{
          background:
            mode === 'light'
              ? `color-mix(in srgb, var(--joy-palette-background-popup) 50%, var(--joy-palette-background-level1) 50%)`
              : 'var(--joy-palette-background-level1)',
          minHeight: 128,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 'var(--joy-radius-xl)',
          border: 0,
        }}
      >
        <Typography fontSize={16} fontWeight={500}>
          {text}
        </Typography>
      </Card>
    </Box>
  );
};

export default memo(EmptyPanel);
