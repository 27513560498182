import { type FC, memo } from 'react';
import { type BoxProps } from '@mui/joy';
import { isEqual } from 'lodash';

import { useCardDataLoaded, useCardProps, useCardTags } from '../model/CardContext';
import { useCardActions } from '../model/CardActionsContext';

import RagMaterial from '../layout/RagMaterial';
import Wireframe from '../layout/Wireframe';
import Collection from '../layout/Collection';
import SpaceFolder from '../layout/SpaceFolder';
import Material from '../layout/Material';
import Document from '../layout/Document';
import Note from '../layout/Note';

import Footer from '../ui/Footer';
import Body from '../ui/Body';
import DecoratedTags from '../ui/DecoratedTags';

const Card: FC<BoxProps> = (props) => {
  const { type, hasTags, tagLimit } = useCardProps();
  const isLoaded = useCardDataLoaded();
  const tags = useCardTags();

  const { handleBodyPress, handleAuthorPress } = useCardActions();

  return (
    <Body handleBodyPress={handleBodyPress} {...props} data-type="resource-card" data-resource-type={type} data-resource-id={props.id}>
      {!isLoaded && <Wireframe />}
      {isLoaded && type === 'Collection' && <Collection handleAuthorPress={handleAuthorPress} />}
      {isLoaded && type === 'Folder' && <SpaceFolder handleAuthorPress={handleAuthorPress} />}
      {isLoaded && type === 'Material' && <Material handleAuthorPress={handleAuthorPress} />}
      {isLoaded && type === 'RagMaterial' && <RagMaterial />}
      {isLoaded && type === 'Document' && <Document handleAuthorPress={handleAuthorPress} />}
      {isLoaded && type === 'Note' && <Note handleAuthorPress={handleAuthorPress} />}
      {isLoaded && !!tags && <DecoratedTags items={tags} limit={tagLimit} hidden={!hasTags} />}
      {isLoaded && <Footer />}
    </Body>
  );
};

export default memo(Card, isEqual);
