import { memo, useCallback, useMemo, useRef } from 'react';

import { useRoute } from 'navigation/hooks';

import Panels from 'components/Panels';

import { useSelector } from 'store/utils/redux/hooks';

import { parseItemId } from 'screens/Space/model/itemIdParser';
import usePermissionsGetter from 'screens/Space/model/usePermissionsGetter';
import * as copilotStore from 'widgets/Copilot/store';

import useIsMy from '../model/useIsMy';
import useIsEditable from '../model/useIsEditable';
import useIsLoading from '../model/useIsLoading';

import EditorProcessor, { type ContentCommandMethods } from '../components/EditorProcessor';
import Viewer from '../components/Viewer';
import LoadingComponent from '../components/LoadingComponent';
import DocumentCopilot from '../components/DocumentCopilot';

function usePrepareId(sourceId?: string | number) {
  const permissionsGetter = usePermissionsGetter();
  return useMemo(() => {
    if (!sourceId) {
      return {
        id: NaN,
        spaceId: undefined,
        folderId: undefined,
      };
    }
    if (typeof sourceId === 'number') {
      return {
        id: sourceId,
        spaceId: undefined,
        folderId: undefined,
      };
    }
    if (!Number.isNaN(Number(sourceId))) {
      return {
        id: Number(sourceId),
        spaceId: undefined,
        folderId: undefined,
      };
    }
    if (sourceId === 'create') {
      return {
        id: Number(sourceId),
        spaceId: undefined,
        folderId: undefined,
      };
    }
    const { spaceId, entityId: folderId } = parseItemId(sourceId.replace(/::create/, ''));
    if (!permissionsGetter.get(sourceId)?.hasAction('WRITE')) {
      return {
        id: 'create',
        spaceId: undefined,
        folderId: undefined,
      } as const;
    }
    return {
      id: 'create',
      spaceId,
      folderId,
    } as const;
  }, [sourceId, permissionsGetter]);
}

const Layout = () => {
  const route = useRoute<'Note'>();

  const editorRef = useRef<ContentCommandMethods>(null);
  const isRightBarShow = useSelector((state) => copilotStore.selectors.rightBarIsShow(state));

  const { id, spaceId, folderId } = usePrepareId(route.params?.id);
  const isMy = useIsMy(id);
  const isEditable = useIsEditable(id);
  const isLoading = useIsLoading(id);

  const handleCopyToText = useCallback((text: string) => {
    editorRef.current?.insert(text);
  }, []);

  const contentRender = useMemo(() => {
    if (isMy && isEditable && isLoading) {
      return <LoadingComponent isLoading={isLoading} />;
    }
    if ((isMy && isEditable) || id === 'create') {
      return <EditorProcessor ref={editorRef} id={id} autofocus={id === 'create'} createParams={{ spaceId, folderId }} />;
    }
    return (
      <Viewer
        id={id}
        wrapperClassName="tiptap ProseMirror prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full"
        thresholdOffsetPx={1000}
        initialBatchSize={50}
        batchSize={50}
        loaderIndicator={<LoadingComponent isLoading />}
      />
    );
  }, [isLoading, id, EditorProcessor, Viewer, isMy, isEditable, spaceId, folderId]);

  if (!id) {
    return null;
  }

  return (
    <Panels
      storeKey="document/panels"
      varPrefix="--panels-document"
      varTarget="body"
      collapsedRight={!isRightBarShow}
      left={contentRender}
      right={<DocumentCopilot id={id} onCopyToText={handleCopyToText} />}
    />
  );
};

export default memo(Layout);
