import { type CSSProperties, memo, type PropsWithChildren, type MutableRefObject } from 'react';
import { Card, Box, type BoxProps } from '@mui/joy';

export interface BodyProps extends BoxProps {
  style?: CSSProperties;
  hoverRef?: MutableRefObject<null>;
  handleBodyPress?: () => void;
}

const Body = (props: PropsWithChildren<BodyProps>) => {
  const { children, style, hoverRef, handleBodyPress, sx, ...rest } = props;

  return (
    <Box
      sx={{
        containerType: 'inline-size',
        ...sx,
      }}
      ref={hoverRef}
      {...rest}
    >
      <Card
        variant="soft"
        sx={{
          '&': {
            '--Card-radius': 'var(--joy-radius-xl)',
            backgroundColor: 'unset',
            transition: '200ms box-shadow ease, 200ms background-color ease',
            cursor: 'pointer',
            gap: 1,
            '--card-hover-opacity': 0,
            '--nestedBg': 'var(--joy-palette-background-level1)',
            '--nestedHoverBg': 'var(--joy-palette-background-level1)',
            '--nestedActiveBg': 'var(--joy-palette-background-level2)',
            '--variant-plainHoverBg': 'var(--nestedHoverBg)',
            '--variant-plainActiveBg': 'var(--nestedActiveBg)',
            '--variant-outlinedHoverBg': 'var(--nestedHoverBg)',
            '--variant-outlinedActiveBg': 'var(--nestedActiveBg)',
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'var(--joy-palette-background-level1)',
            '--card-hover-opacity': 1,
            '--nestedBg': 'var(--joy-palette-background-level2)',
            '--nestedHoverBg': 'var(--joy-palette-background-level2)',
            '--nestedActiveBg': 'var(--joy-palette-background-level3)',
          },
          '@container (width <= 400px )': {
            '&': {
              gap: 1.5,
              backgroundColor: 'var(--joy-palette-background-level1)',
              '--nestedBg': 'var(--joy-palette-background-level2)',
              '--nestedHoverBg': 'var(--joy-palette-background-level2)',
              '--nestedActiveBg': 'var(--joy-palette-background-level3)',
            },
            '&:hover': {
              boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)',
            },
          },
        }}
        onClick={handleBodyPress}
      >
        {children}
      </Card>
    </Box>
  );
};

export default memo(Body);
