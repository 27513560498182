import { track } from '@amplitude/analytics-browser';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

function* trackSearchEvent(text: string) {
  const user = yield* select(userStore.selectors.getMy);

  yield* call(() =>
    track('Library:Title', {
      userId: user?.id,
      userName: user?.name,
      text,
    }),
  );
}

export default trackSearchEvent;
