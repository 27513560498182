import { takeLatest, put, cancel } from 'redux-saga/effects';

import Storage from 'lib/Storage';

import Alert from 'components/Alert';
import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.loadFilters.type,
  method: takeLatest,
};

const loadFromCache = (): string[] | null => {
  const key = '@content/types';
  let cache: {
    value: string[];
    releaseTime: string;
  } | null = null;

  try {
    const buffer = Storage.get(key);
    if (buffer) {
      cache = JSON.parse(buffer);
    }
  } catch (error) {
    cache = null;
  }

  if (!cache || cache.releaseTime !== process.env.RELEASE_TIME || !Array.isArray(cache)) {
    return null;
  }

  return cache.value;
};

const saveToCache = (list: string[] | null): void => {
  if (!Array.isArray(list)) {
    return;
  }
  const key = '@content/types';
  Storage.set(
    key,
    JSON.stringify({
      value: list,
      releaseTime: process.env.RELEASE_TIME,
    }),
  );
};

export function* func() {
  const options = yield* call(() => loadFromCache());

  if (!options) {
    const response = yield* call(() => api.resource.content.filter.items());
    if (response.error || !response.data) {
      Alert.error(response?.error);
      yield put(actions.loadFiltersDone(true));
      yield cancel();
      return;
    }
    yield* call(() => saveToCache(response.data));
    yield put(
      actions.setFilters({
        type: {
          options: response.data,
        },
      }),
    );
    yield put(actions.loadFiltersDone());
    yield cancel();
    return;
  }

  yield put(
    actions.setFilters({
      type: {
        options,
      },
    }),
  );
  yield put(actions.loadFiltersDone());

  const response = yield* call(() => api.resource.content.filter.items());
  if (response.error || !response.data) {
    yield cancel();
    return;
  }
  yield* call(() => saveToCache(response.data));
  yield put(
    actions.setFilters({
      type: {
        options: response.data,
      },
    }),
  );
}
