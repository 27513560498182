import network from 'lib/network';
import type { Result, AuthorType } from 'app/entities';

async function fetchAuthor(id: number): Promise<Result<AuthorType>> {
  const { data, hasError, errors } = await network.request<AuthorType>(`/stack-1/author/${id}`).get();

  if (hasError || !data) {
    return {
      data: null,
      error: {
        hasErrors: !!errors && errors?.length > 0,
        list: errors,
      },
    };
  }

  data.contentIds = [];

  return {
    data,
    error: {
      hasErrors: false,
      list: [],
    },
  };
}

export default fetchAuthor;
