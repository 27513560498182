import { put, type SagaReturnType, take, takeEvery } from 'redux-saga/effects';

import type { SpaceContentType } from 'app/entities';

import { select } from 'store/utils/saga/effects';

import { guard } from 'utils';

import { actions as resourceActions } from '../slices/resource';
import * as selectors from '../selectors';

export const config = {
  action: resourceActions.removeByResourceId.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof resourceActions.removeByResourceId>) {
  const { resources } = action.payload;

  const libraryIds = yield* select(selectors.libraryIds);

  const spaceResourceMap = yield* select((state) => state.spaceResource.data);
  const resourcesMap = resources.map((resource) => `${resource.spaceId}-${resource.folderId}-${resource.resourceId}`);

  const entityIds: SpaceContentType['id'][] = [];

  for (const [entityId, entity] of Object.entries(spaceResourceMap)) {
    if (guard.isSpaceFolder(entity)) {
      continue;
    }
    const folderId = libraryIds.includes(entity.parentId) ? 'root' : entity.parentId;
    if (resourcesMap.includes(`${entity.spaceId}-${folderId}-${entity.resourceId}`)) {
      entityIds.push(entityId);
    }
  }

  if (entityIds.length > 0) {
    yield put(resourceActions.remove({ entityIds }));
    yield take(resourceActions.removeDone.type);
    console.log('ОБНОВЛЯЕМ');
  }
}
