import { type MouseEvent, type FC, memo, useCallback } from 'react';
import { Box, Checkbox } from '@mui/joy';

import type { SpaceEntityKey } from 'app/entities';
import Card from 'widgets/Card';

import { guard } from 'utils';

import useEntityById from '../../../model/useEntityById';
import { useSelection } from '../../../model/SelectionContext';

import CardPlus from './CardPlus';

export interface ItemProps {
  data: SpaceEntityKey;
  index: number;
  canSelect?: boolean;
  canRemove?: boolean;
}

const Item: FC<ItemProps> = (props) => {
  const { data, canSelect, canRemove } = props;

  const entity = useEntityById(data.id);
  const { isSelected, hasSelections, toggleSelection } = useSelection();
  const selected = isSelected(data);

  const handleCheckboxClick = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
      toggleSelection(data);
    },
    [data, toggleSelection],
  );

  if (data.id === 'plus') {
    return (
      <Box
        boxShadow={`0 0 0 1px ${selected ? 'var(--joy-palette-primary-solidBg)' : 'transparent'}`}
        borderRadius={16}
        sx={{ transition: 'boxShadow 200ms ease' }}
      >
        <CardPlus />
      </Box>
    );
  }
  if (guard.isSpaceFolder(entity)) {
    return (
      <Box
        boxShadow={`0 0 0 1px ${selected ? 'var(--joy-palette-primary-solidBg)' : 'transparent'}`}
        borderRadius={16}
        sx={{ transition: 'boxShadow 200ms ease' }}
      >
        <Card
          id={Number(entity.id)}
          type="Folder"
          footerStart={
            canSelect ? (
              <Box position="relative" zIndex={5} sx={{ transition: 'opacity 0.3s', opacity: hasSelections ? 1 : 'var(--card-hover-opacity)' }}>
                <Checkbox checked={selected} onClick={handleCheckboxClick} />
              </Box>
            ) : undefined
          }
          hasDescription={false}
          hasSummary
          hasHighlight
          hasTags
          hasRemove={canRemove}
          hasQueue={false}
          hasLink
          hasEdit
          hasAuthor
          hasToCollection
          hasIndexed
        />
      </Box>
    );
  }
  if (guard.isSpaceContent(entity)) {
    return (
      <Box
        boxShadow={`0 0 0 1px ${selected ? 'var(--joy-palette-primary-solidBg)' : 'transparent'}`}
        borderRadius={16}
        sx={{ transition: 'boxShadow 200ms ease' }}
      >
        <Card
          id={Number(entity.resourceId)}
          type={entity.resourceType}
          parent={{ spaceId: entity?.spaceId, folderId: entity?.parentId }}
          footerStart={
            canSelect ? (
              <Box position="relative" zIndex={5} sx={{ transition: 'opacity 0.3s', opacity: hasSelections ? 1 : 'var(--card-hover-opacity)' }}>
                <Checkbox checked={selected} onClick={handleCheckboxClick} />
              </Box>
            ) : undefined
          }
          hasDescription={false}
          hasSummary
          hasHighlight
          hasTags
          hasRemove={canRemove}
          hasQueue={false}
          hasLink
          hasEdit
          hasAuthor
          hasToCollection={false}
          hasIndexed
        />
      </Box>
    );
  }
  return null;
};

export default memo(Item);
