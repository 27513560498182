import { type FC, memo } from 'react';
import { isEqual } from 'lodash';
import Copilot from 'widgets/Copilot';
import { Box } from '@mui/joy';

export interface FolderCopilotProps {
  spaceId: number;
  folderId: string;
}

const FolderCopilot: FC<FolderCopilotProps> = (props) => {
  const { spaceId, folderId } = props;

  return (
    <Box
      position="fixed"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      width="var(--panels-space-folder-right-width)"
      height="calc(100vh - 80px)"
    >
      <Copilot {...(folderId === 'root' ? { variant: 'Space', spaceId } : { variant: 'SpaceFolder', spaceId, folderId })} />
    </Box>
  );
};

export default memo(FolderCopilot, isEqual);
