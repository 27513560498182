import { v4 as uuidV4 } from 'uuid';
import { type ReactElement, memo, useCallback, useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle, ForwardedRef } from 'react';
import { MasonryScroller, type RenderComponentProps, useContainerPosition, useInfiniteLoader, usePositioner, useResizeObserver } from 'masonic';
import { Box } from '@mui/joy';
import { useWindowSize } from 'usehooks-ts';
import { isEqual } from 'lodash';

import getNumColumnsByScheme from './methods/getNumColumnsByScheme';
import useLayoutWidth from './hooks/useLayoutWidth';

import type { ListProps, ListImperativeHandler } from './types';

export type { ListProps, ListImperativeHandler };

const List = forwardRef(<T,>(props: ListProps<T>, forwardedRef: ForwardedRef<ListImperativeHandler>) => {
  const {
    containerRef,
    columnsScheme,
    renderItem,
    hasNextPage,
    loading,
    items,
    getKey,
    onEndReached,
    columnGutter = 16,
    slots,
    slotProps,
    resizeOptions,
    ...rest
  } = props;

  const [numColumns, setNumColumns] = useState(0);

  const conRef = useRef<HTMLDivElement | null>(null);

  const handleRef = useCallback(
    (ref: HTMLDivElement) => {
      conRef.current = ref;
      if (typeof containerRef === 'object' && 'current' in containerRef) {
        containerRef.current = ref;
      }
    },
    [containerRef],
  );

  const windowSize = useWindowSize();
  const { offset } = useContainerPosition(conRef);
  const { width: containerWidth, updateWidth } = useLayoutWidth(conRef, {
    batchDelay: resizeOptions?.batchDelay || 200,
    detect: resizeOptions?.detect,
  });

  const columnWidth = useMemo(
    () => (containerWidth && numColumns ? Math.floor((containerWidth - columnGutter * (numColumns - 1)) / numColumns) : 0),
    [containerWidth, numColumns, columnGutter],
  );

  useImperativeHandle(
    forwardedRef,
    () => ({
      resize: () => {
        updateWidth();
      },
    }),
    [updateWidth],
  );

  useEffect(() => {
    if (containerWidth !== null) {
      setNumColumns((prevNumColumns) => {
        const newNumColumns = getNumColumnsByScheme(containerWidth, columnsScheme);
        return prevNumColumns !== newNumColumns ? newNumColumns : prevNumColumns;
      });
    }
  }, [columnsScheme, containerWidth]);

  const loadMore = useCallback(() => {
    if (onEndReached && !loading && hasNextPage) {
      onEndReached();
    }
  }, [onEndReached, loading, hasNextPage]);

  const maybeLoadMore = useInfiniteLoader(loadMore, {
    isItemLoaded: (index) => index < items.length,
    totalItems: hasNextPage ? items.length + 1 : items.length,
  });

  const itemsKeyRef = useRef<string>(uuidV4());
  const previousItemsRef = useRef<T[]>([]);

  const itemsKey = useMemo(() => {
    const previousItems = previousItemsRef.current;

    let needToResetPositioner = false;

    const itemsAreEqual = (prevItems: T[], newItems: T[]) =>
      prevItems.every((prevItem, index) => (getKey ? getKey(prevItem, index) === getKey(newItems[index], index) : prevItem === newItems[index]));

    if (previousItems.length === 0) {
      // Первая отрисовка, ничего не делаем
    } else if (items.length > previousItems.length && itemsAreEqual(previousItems, items.slice(0, previousItems.length))) {
      // Элементы были добавлены в конец, позиционер автоматически добавит их
    } else {
      // Массив items изменился в середине или длина уменьшилась
      needToResetPositioner = true;
    }

    if (needToResetPositioner) {
      itemsKeyRef.current = uuidV4();
    }

    // Обновляем previousItemsRef на текущие items
    previousItemsRef.current = items;

    return itemsKeyRef.current;
  }, [items, getKey]);

  const positioner = usePositioner(
    {
      width: containerWidth || 0,
      columnWidth: columnWidth || 200,
      columnGutter,
    },
    [numColumns, containerWidth, columnGutter, itemsKey],
  );

  const resizeObserver = useResizeObserver(positioner);

  const ItemRender = useMemo(() => renderItem || slots?.item || (() => null), [renderItem, slots?.item]);

  const ItemWithSlotsRender = useCallback(
    (listProps: RenderComponentProps<T>) => {
      return <ItemRender {...listProps} {...(slotProps?.item || {})} />;
    },
    [ItemRender, slotProps?.item],
  );

  return (
    <Box ref={handleRef} {...rest}>
      {containerWidth && (
        <MasonryScroller
          positioner={positioner}
          resizeObserver={resizeObserver}
          items={items}
          render={ItemWithSlotsRender}
          onRender={maybeLoadMore}
          height={windowSize.height}
          offset={offset}
          itemKey={getKey}
        />
      )}
    </Box>
  );
});

export default memo(List, isEqual) as <T, E extends {} = {}>(props: ListProps<T, E>) => ReactElement;
