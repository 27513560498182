import { type FC, memo, useEffect, useRef } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import { unit } from 'utils';

import source from '../../assets/lottie/63274-loading-animation_iki.json';

export interface SpinnerDottedProps {
  size?: number;
  loop?: boolean;
}

const SpinnerDotted: FC<SpinnerDottedProps> = (props) => {
  const { size = 100, loop = true } = props;

  const lottie = useRef<any>(null);

  useEffect(() => {
    if (lottie.current && 'play' in lottie.current) {
      lottie.current.play?.();
    }
  }, [lottie]);

  return (
    <DotLottieReact
      style={{
        width: unit(size),
      }}
      data={source}
      loop={loop}
      autoplay
    />
  );
};

export default memo(SpinnerDotted);
