import { useState, useCallback, useMemo, type MouseEvent } from 'react';

export interface UseHoverStateOptions {
  /**
   * Функция, принимающая событие наведения.
   * Если возвращает true – разрешается установить isHovered в true,
   * если false – событие игнорируется.
   */
  shouldSetHovered?: (event: MouseEvent<HTMLElement>) => boolean;
}

export interface HoverState {
  isHovered: boolean;
  handleMouseEnter: (e: MouseEvent<HTMLElement>) => void;
  handleMouseLeave: (e: MouseEvent<HTMLElement>) => void;
}

/**
 * Хук для отслеживания состояния наведения с возможностью кастомного условия.
 *
 * @param options Опциональные параметры, в том числе shouldSetHovered для проверки события.
 * @returns Объект с текущим состоянием наведения и обработчиками событий.
 */
function useHoverState(options?: UseHoverStateOptions): HoverState {
  const { shouldSetHovered } = options || {};

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (shouldSetHovered === undefined || shouldSetHovered(e)) {
        setIsHovered(true);
      }
    },
    [shouldSetHovered],
  );

  const handleMouseLeave = useCallback((e: MouseEvent<HTMLElement>) => {
    setIsHovered(false);
  }, []);

  return useMemo(
    () => ({
      isHovered,
      handleMouseEnter,
      handleMouseLeave,
    }),
    [isHovered, handleMouseEnter, handleMouseLeave],
  );
}

export default useHoverState;
