import { type FC, memo } from 'react';
import { View, StyleSheet } from 'react-native';

import { unit } from 'utils';

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => <View style={styles.Header} />;

const styles = StyleSheet.create({
  Header: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#4E4E53',
    marginHorizontal: unit(16),
  },
});

export default memo(Header);
