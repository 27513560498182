import { type DefaultRootState } from 'react-redux';

const libraryIdBySpaceId = (spaceId?: number) => (state: DefaultRootState) => {
  if (!spaceId) {
    return null;
  }
  const data = state.spaceList.data?.[spaceId];
  return data?.libraryId;
};

export default libraryIdBySpaceId;
