import { type CSSProperties, type KeyboardEvent, type ChangeEvent, type ReactNode, memo, forwardRef, useCallback, useEffect } from 'react';
import { Box, type BoxProps } from '@mui/joy';
import { createUseStyles } from 'react-jss';
import TextareaAutosize from 'react-textarea-autosize';
import cn from 'classnames';

import { useFontProps, useColorScheme } from 'hooks';
import { isLineBreakCombination } from 'utils';

import useInputHandlers from './model/useInputHandlers';
import useFocusState from './model/useFocusState';

import type { CopilotInputRef } from './types';

export { type CopilotInputRef };

export interface CopilotInputProps extends Omit<BoxProps, 'onChange' | 'onFocus' | 'onBlur'> {
  size?: 'small' | 'regular' | 'large';
  focusBorderSize?: number | string;
  value?: string;
  autoFocus?: boolean;
  className?: string;
  onSubmit?: () => void;
  disabled?: boolean;
  multiline?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  footer?: ReactNode;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const CopilotInput = forwardRef<CopilotInputRef, CopilotInputProps>((props, forwardedRef) => {
  const {
    size = 'regular',
    focusBorderSize = '0.470588235em',
    value,
    autoFocus,
    className,
    onSubmit,
    disabled,
    multiline = true,
    startAdornment,
    endAdornment,
    footer,
    onChange,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const { inputRef, textareaRef } = useInputHandlers(forwardedRef, { multiline });
  const { isFocused, handleFocus, handleBlur } = useFocusState({ onFocus, onBlur });

  const colorScheme = useColorScheme();
  const fontProps = useFontProps<CSSProperties>({
    size: (
      {
        small: 15,
        regular: 17,
        large: 22,
      } as const
    )[size],
  });

  const classes = useStyle({
    focusBorderSize,
    colorScheme,
    size,
    fontFamily: fontProps.fontFamily,
    fontSize: fontProps.fontSize,
    lineHeight: fontProps.lineHeight,
    letterSpacing: fontProps.letterSpacing,
  });

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, []);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if ((event.key || event.code) === 'Enter') {
        if (multiline && isLineBreakCombination(event)) {
          event.preventDefault();
          const textarea = event.target as HTMLTextAreaElement;
          const cursorPosition = textarea.selectionStart;
          const textBeforeCursor = textarea.value.slice(0, cursorPosition);
          const textAfterCursor = textarea.value.slice(cursorPosition);
          textarea.value = `${textBeforeCursor}\n${textAfterCursor}`;
          textarea.selectionStart = cursorPosition + 1;
          textarea.selectionEnd = cursorPosition + 1;
          window.dispatchEvent(new Event('resize'));
        } else {
          event.preventDefault();
          onSubmit?.();
        }
      }
    },
    [onSubmit, multiline],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange],
  );

  return (
    <Box
      display="flex"
      position="relative"
      flexDirection="column"
      flex={1}
      bgcolor="var(--joy-palette-background-level1)"
      borderRadius="1.411764706em"
      className={cn(classes.container, {
        [classes.isFocused]: isFocused,
        [classes.disabled]: disabled,
      })}
      {...rest}
    >
      <Box display="flex" flexDirection="row" position="relative" alignSelf="stretch" flex={1}>
        {startAdornment}
        {!multiline && (
          <input
            key="CopilotInput"
            ref={inputRef}
            className={cn(classes.input, className)}
            placeholder="Ask IKI assistant"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        )}
        {multiline && (
          <TextareaAutosize
            key="CopilotTextarea"
            ref={textareaRef}
            className={cn(classes.input, classes.multi, className)}
            placeholder="Ask IKI assistant"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            maxRows={6}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        )}
        {endAdornment}
      </Box>
      {!!footer && <>{footer}</>}
    </Box>
  );
});

const useStyle = createUseStyles<
  'container' | 'isFocused' | 'disabled' | 'multi' | 'input',
  {
    colorScheme?: 'light' | 'dark';
    focusBorderSize?: string | number;
    fontFamily?: string;
    fontSize?: number | string;
    fontWeight?: string;
    lineHeight?: number | string;
    letterSpacing?: number | string;
    size?: 'small' | 'regular' | 'large';
  }
>({
  container: {
    transition: 'box-shadow 200ms ease',
  },
  isFocused: {
    boxShadow: (props) => `0 0 0 ${props.focusBorderSize} #497CFF`,
  },
  disabled: {
    '& > *': {
      opacity: 0.5,
    },
  },
  multi: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.75rem',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--joy-palette-background-level3)',
      borderRadius: '0.75rem',
      border: '2px solid transparent',
      backgroundClip: 'content-box',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'var(--joy-palette-primary-softDisabledColor)',
    },
  },
  input: {
    '&': {
      display: 'flex',
      alignSelf: 'stretch',
      border: 0,
      flex: 1,
      outline: 'none',
      resize: 'none',
      padding: (props) =>
        (
          ({
            small: '0.6652em 1em',
            regular: '0.764705882em 1.2em',
            large: '0.764705882em 1.2em',
          }) as const
        )[props.size || 'regular'],
      backgroundColor: 'transparent',
      boxShadow: '0 0 0 0 transparent',
      color: 'var(--joy-palette-text-primary)',
      fontFamily: (props) => props.fontFamily,
      fontSize: (props) => props.fontSize,
      lineHeight: (props) => props.lineHeight,
      letterSpacing: (props) => props.letterSpacing,
    },
    '&::placeholder': {
      color: 'var(--joy-palette-background-level3)',
      fontFamily: (props) => props.fontFamily,
      fontSize: (props) => props.fontSize,
      fontWeight: 400,
      lineHeight: (props) => props.lineHeight,
      letterSpacing: (props) => props.letterSpacing,
    },
  },
});

export default memo(CopilotInput);
