import { memo, useCallback, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/joy';
import { isEqual } from 'lodash';

import { useListLayout } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import AdaptivePage from 'components/AdaptivePage';
import List from 'components/List';
import { useScrollMethods } from 'components/Scroller';
import SearchInput from 'components/SearchInput';

import Card from 'widgets/Card';

import useSearchState from '../model/useSearchState';
import useSearchParams from '../model/useSearchParams';

import SearchStateMessage from '../elements/SearchStateMessage';

const Search = () => {
  const dispatcher = useDispatcher();
  const { spaceId, text } = useSearchParams();
  const spaceName = useSelector((state) => (!spaceId ? '' : state.spaceList?.data?.[spaceId]?.title));

  const { scrollTo } = useScrollMethods();

  const sequence = useSelector(searchStore.selectors.sequence);
  const paginationInfo = useSelector(searchStore.selectors.paginationInfo);
  const isPageLoading = useSelector(searchStore.selectors.isPageLoading);
  const listMeta = useSelector(searchStore.selectors.listMeta);

  const { listLayout } = useListLayout('search.list.mode', 'row');

  const searchState = useSearchState();

  useEffect(() => {
    scrollTo(0);
  }, [listMeta.firstPage.isLoading]);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.search.loadNextPage();
  }, [listMeta.isConsistent]);

  const getKey = useCallback((item: (typeof sequence)[0]) => {
    if (!item || typeof item !== 'object') {
      return 'unknown';
    }
    return `${item.type}-${item.id}`;
  }, []);

  const renderItem = useCallback(
    ({ data }: any) => {
      const [highlight] = data?.searchMeta?.highlights || [];
      const extraData: Record<string, string> = {};
      if (highlight) {
        extraData.description = highlight;
      }

      return (
        <Card
          id={data.id}
          key={data.id}
          type={data.type}
          descriptionLineLimit={4}
          hasDescription
          hasAuthor
          hasSummary
          hasHighlight
          hasLink
          hasTags
          hasToCollection
          hasIndexed
          hasRemove
        />
      );
    },
    [listLayout.isRow],
  );

  return (
    <AdaptivePage
      hasTopBar
      desktopHeaderLeftPanel={false}
      desktopHeaderMiddlePanel={
        <Box display="flex" flexDirection="row" alignItems="center" gap={2} alignSelf="flex-end" flex={1} width="100%">
          <SearchInput key="space-search" size="medium" spaceId={spaceId} placeholder={`Search in space "${spaceName}"`} />
          <Box flex={1} />
        </Box>
      }
      bodyMaxWidth={1600}
      paddingHorizontalScheme={{ 320: 16 }}
    >
      {searchState !== 'list' && <SearchStateMessage />}
      {searchState === 'list' && (
        <>
          <Box height={48} sx={{ '@media (min-width: 768px)': { height: 0 } }} />
          <List
            items={sequence}
            getKey={getKey}
            renderItem={renderItem}
            columnsScheme={listLayout.isRow ? '320:1' : '320:1;528:2;672:3;960:4'}
            loading={isPageLoading}
            hasNextPage={(isPageLoading || sequence.length > 0) && paginationInfo.hasNext}
            onEndReached={handleNextPage}
          />
          <Box sx={{ '@media (min-width: 768px)': { height: 16 } }} />
        </>
      )}
      {searchState === 'list' && isPageLoading && (
        <Box display="flex" flexDirection="column" flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </AdaptivePage>
  );
};

export default memo(Search, isEqual);
