import { type SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useOnInputEnter, usePerson } from 'hooks';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import * as userStore from 'store/nodes/user';
import * as onboardingStore from 'widgets/Onboarding/store';

const useAboutState = () => {
  const dispatcher = useDispatcher();
  const { person } = usePerson('User', 'my');
  const isLoading = useSelector(onboardingStore.selectors.isLoading);
  const isSkipping = useSelector(onboardingStore.selectors.isSkipLoading);

  const [profession, setProfession] = useState<string | null>(null);
  const [howUse, setHowUse] = useState<string | null>(null);

  useEffect(() => {
    if (person) {
      setHowUse(person?.howUse);
      setProfession(person?.profession);
    }
  }, [person]);

  const handleChangeRole = useCallback((event: { target: { value: SetStateAction<string | null> } }) => {
    setProfession(event.target.value);
  }, []);

  const handleChangeUseCase = useCallback((event: { target: { value: SetStateAction<string | null> } }) => {
    setHowUse(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!profession || !howUse) {
      return;
    }
    dispatcher.onboarding.tellUsAbout({
      profession,
      howUse,
    });
  }, [profession, howUse]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleSubmit();
    }, [handleSubmit]),
  );

  const onSkip = useCallback(() => {
    dispatcher.onboarding.skip({ target: 'about' });
  }, []);

  return useMemo(
    () => ({
      profession: profession || '',
      howUse: howUse || '',
      submitByEnter: onKeyDown,
      onChangeRole: handleChangeRole,
      onSubmit: handleSubmit,
      onChangeUseCase: handleChangeUseCase,
      onSkip,
      isLoading,
      isSkipping,
    }),
    [profession, howUse, handleChangeRole, handleChangeUseCase, onKeyDown, onSkip, isLoading, isSkipping],
  );
};

export default useAboutState;
