import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type SpringEvent } from 'react-spring-bottom-sheet/dist/types';

import { useDispatcher } from 'store/utils/redux/hooks';

import BottomSheet from 'components/BottomSheet';
import Plus from 'widgets/Plus';
import { useScreenSize } from 'hooks';
import * as plusStore from 'widgets/Plus/store';

import * as modalStore from '../store';

const PlusBottomSheet = () => {
  const dispatcher = useDispatcher();
  const screenSize = useScreenSize();

  const { isOpen, params } = useSelector(modalStore.selectors.state('PlusBottomSheet'));
  const [collectionId, setCollectionId] = useState<number | null>(null);
  const [resultType, setResultType] = useState<'material' | 'materials' | null>(null);
  const result = useSelector(plusStore.selectors.result);

  useEffect(() => {
    if (params) {
      dispatcher.plus.setProps(params);
    }
    if (params?.toCollection && 'id' in params.toCollection && params.toCollection.id) {
      setCollectionId(Number(params.toCollection.id));
    }
  }, [params]);

  const handlePlusClose = useCallback(async () => {
    dispatcher.modal.close('PlusBottomSheet');
  }, []);

  const handleCollectionChange = useCallback((value: number | null) => {
    setCollectionId(Number(value));
  }, []);

  const handleChangeStep = useCallback((value: 'add' | 'processing' | 'material' | 'materials') => {
    if (value === 'material' || value === 'materials') {
      setResultType(value);
    }
  }, []);

  const handleSpringEnd = useCallback(
    (event: SpringEvent) => {
      // if (event.type === 'CLOSE' && resultType === 'materials' && collectionId && result?.__typename === 'ResultMany') {
      //   dispatcher.plus.addMaterialsToCollection(collectionId, result.uploadSessionId);
      // }
      // if (event.type === 'CLOSE' && resultType === 'material' && collectionId && result?.__typename === 'ResultOne') {
      //   dispatcher.playlist.addMaterialItem({
      //     collectionId,
      //     itemType: 'material',
      //     data: result,
      //   });
      // }
      if (event.type === 'OPEN') {
        setResultType(null);
      }
    },
    [resultType, collectionId, result],
  );

  if (!screenSize.is499) {
    return null;
  }

  return (
    <BottomSheet onDismiss={handlePlusClose} onSpringEnd={handleSpringEnd} open={isOpen}>
      <Plus variant="panel" onCollectionChange={handleCollectionChange} onStepChange={handleChangeStep} />
    </BottomSheet>
  );
};

export default memo(PlusBottomSheet);
