import { useCallback, useEffect, useState, type ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { ToastContainer, Slide, toast, type ToastPosition, type ToastContent, type ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SummaryPrepared, CollectionProvided } from 'components/Toast';
import type { ToastName, ToastProps } from 'components/Toast/types';

import { useColorScheme, useThemeColor } from 'hooks';
import Alert from 'components/Alert/index';

type MessageType = 'info' | 'success' | 'error';

const getPosition = (): ToastPosition => {
  const width = window.innerWidth;
  if (width <= 480) {
    return 'bottom-center';
  }
  return 'bottom-right';
};

const getVariant = (type: MessageType, initialOptions?: ToastOptions) => {
  if (type === 'success') {
    return (content: ToastContent, options?: ToastOptions) => toast.success(content, { ...initialOptions, ...options });
  }
  if (type === 'error') {
    return (content: ToastContent, options?: ToastOptions) => toast.error(content, { ...initialOptions, ...options });
  }
  return (content: ToastContent, options?: ToastOptions) => toast.info(content, { ...initialOptions, ...options, icon: false });
};

const store = new (class Store {
  private ids: (string | number)[] = [];

  public add = (toastId: string | number) => {
    this.ids.push(toastId);
  };

  public remove = (toastId: string | number) => {
    const index = this.ids.findIndex((id) => id === toastId);
    if (index === -1) {
      return;
    }
    this.ids.splice(index, 1);
  };

  public hasIds = (): boolean => this.ids.length > 0;

  public getAll = (): (string | number)[] => [...this.ids];
})();

// @ts-ignore
window.store = store;

export const Init = () => {
  const [position, setPosition] = useState<ToastPosition>(getPosition());

  const colorScheme = useColorScheme();
  const titleColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const backgroundColor = useThemeColor({ light: '#F2F2F1', dark: '#232326' });

  const handleResize = useCallback(() => {
    const newPosition = getPosition();
    if (position !== newPosition) {
      setPosition(newPosition);
      toast.dismiss();
    }
  }, [position]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return createPortal(
    <ToastContainer
      position={position}
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      transition={Slide}
      theme={colorScheme}
      style={{
        zIndex: 20000,
        filter: 'drop-shadow(0 0 1em rgba(0,0,0,0.2))',
      }}
      toastStyle={{
        borderRadius: '1em',
        backgroundColor,
      }}
      bodyStyle={{
        color: titleColor,
        backgroundColor,
        fontSize: '1em',
      }}
    />,
    document.body,
  );
};

const show = (type: MessageType, content: ToastContent, options?: ToastOptions): void => {
  const method = getVariant(type, options);
  if (getPosition() === 'bottom-center') {
    store.getAll().forEach((id) => {
      toast.dismiss(id);
    });
    setTimeout(
      () => {
        const toastId = method(content, {
          onClose: () => {
            store.remove(toastId);
          },
        });
        store.add(toastId);
      },
      store.hasIds() ? 900 : 0,
    );
    return;
  }
  method(content);
};

export const info = <P extends ToastName>(content: P | ToastContent, options?: ToastOptions<ToastProps[P]>) => {
  let contentFinal = content;

  if (content === 'SummaryPrepared') {
    contentFinal = <SummaryPrepared {...options?.data} />;
  }
  if (content === 'CollectionProvided') {
    contentFinal = <CollectionProvided {...options?.data} />;
  }
  show('info', contentFinal, options);
};

export const error = (
  data: Error | Error[] | string | string[] | object | object[] | null | undefined,
  options?: ToastOptions & { defaultMessage?: string },
): void => {
  if (!data) {
    return;
  }
  const { defaultMessage, ...rest } = options || {};
  let message = defaultMessage || '';
  const object: Error | string | object = Array.isArray(data) ? data[0] : data;
  if (object && object instanceof Error) {
    message = object.message || '';
  }
  if (typeof object === 'string') {
    message = object || '';
  }
  if (typeof object === 'object' && 'message' in object && object.message) {
    message = object.message;
  }
  show('error', message, rest);
};

export const success = (message: string, options?: ToastOptions): void => {
  show('success', message, options);
};
