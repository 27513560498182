import { type FC, memo } from 'react';
import { Box } from '@mui/joy';

import useHTMLLoader from 'screens/Content/Page/hooks/useHTMLLoader';
import Lottie from 'components/Lottie';
import HtmlReader from 'components/HtmlReader';
import { useThemeColor } from 'hooks';

export interface HtmlViewerProps {
  content?: string;
}

const HtmlViewer: FC<HtmlViewerProps> = (props) => {
  const { content } = props;

  const isUrl = /^https?:\/\/[^\s]*/.test(content || '');

  const { html: loadedHtml, isLoading } = useHTMLLoader(isUrl ? content : undefined);

  const lottieColor = useThemeColor({ light: '#497CFF', dark: '#497CFF' });

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Lottie name="LoadingCircle" loop width={160} height={160} color={lottieColor} />
      </Box>
    );
  }

  const html = !isUrl ? content : loadedHtml;

  return <HtmlReader html={html || 'No content'} />;
};

export default memo(HtmlViewer);
