import { useSelector } from 'store/utils/redux/hooks';
import { guard } from 'utils';

const useIsContentError = (contentType: 'Material' | 'Document' | 'Folder' | 'Collection', contentId?: number | null) => {
  return useSelector((state) => {
    if (!contentId) {
      return null;
    }
    if (contentType === 'Material' && guard.isContentError(state.content.data?.[contentId])) {
      return true;
    }
    if (contentType === 'Document' && guard.isContentError(state.note.data?.[contentId])) {
      return true;
    }
    if (contentType === 'Collection' && guard.isContentError(state.playlist.data?.[contentId])) {
      return true;
    }
    return false;
  });
};

export default useIsContentError;
