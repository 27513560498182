import { memo, useCallback, useEffect, useState } from 'react';
import {
  DialogTitle,
  FormControl,
  FormLabel,
  Textarea,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Button,
  Box,
  Switch,
  DialogContent,
  DialogActions,
} from '@mui/joy';
import { useDispatcher } from 'store/utils/redux/hooks';

import { useSelector } from 'react-redux';
import * as modalStore from 'widgets/modals/store';
import * as contentStore from 'store/nodes/content';
import { type MaterialType } from 'app/entities';
import TagsInput from 'components/TagsInput';

const EditMaterial = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('EditMaterial'));

  const originalData = useSelector(contentStore.selectors.dataById(Number(params?.id)));

  const [formData, setFormData] = useState<MaterialType | null>(originalData);
  useEffect(() => {
    if (originalData) {
      setFormData({
        ...originalData,
      });
    }
  }, [originalData]);

  const handleChange = useCallback((event: { target: { name: string; value: string | boolean | null | string[] } }) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...(prevFormData as MaterialType),
      [name]: value,
    }));
  }, []);

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('EditMaterial');
  }, []);

  const handleSubmit = useCallback(() => {
    if (!formData) {
      return;
    }
    dispatcher.myContent.editMaterial(formData, { showSuccessToast: true });
    handleModalClose();
  }, [formData, dispatcher, handleModalClose]);

  if (!formData) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          width: 500,
          '--ModalClose-radius': '1.125em',
        }}
      >
        <ModalClose onClick={handleModalClose} />
        <DialogTitle sx={{ mt: -1 }}>Edit material</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Textarea variant="plain" maxRows={3} value={formData?.title} name="title" onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea variant="plain" maxRows={5} value={formData?.description} name="description" onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <TagsInput
                value={formData?.tags || []}
                onChange={(selectedOptions) => {
                  const event = {
                    target: {
                      name: 'tags',
                      value: selectedOptions,
                    },
                  };
                  handleChange(event);
                }}
              />
            </FormControl>
            <FormControl orientation="horizontal">
              <FormLabel>{formData?.private ? 'Private content' : 'Not private content'}</FormLabel>
              <Switch
                sx={{ alignSelf: 'flex-start' }}
                size="md"
                checked={formData?.private}
                onChange={(e) => {
                  const event = {
                    target: {
                      name: 'private',
                      value: e.target.checked,
                    },
                  };
                  handleChange(event);
                }}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Box display="flex" justifyContent="center" flex={1}>
            <Button disabled={JSON.stringify(originalData) === JSON.stringify(formData)} onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(EditMaterial);
