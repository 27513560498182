import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '../../../store/selectors';

/**
 * Хук, который возвращает «очищенный» текст из массива thinkingItems.
 *
 * @param requestId Идентификатор запроса
 * @param firstState Текст по умолчанию, если массив пуст
 */
export default function useThinkingText(requestId: string, firstState = ''): string {
  const thinkingItems = useSelector(selectors.thinkingItems(requestId));

  return useMemo(() => {
    if (!thinkingItems || thinkingItems.length === 0) {
      return firstState;
    }

    let rawText = thinkingItems[thinkingItems.length - 1].text || '';
    if (!rawText) {
      return firstState;
    }

    // Удаляем code-блоки вида ```...``` (многострочные)
    // [\s\S]*? позволяет матчить в т.ч. переносы строк
    rawText = rawText.replace(/```[\s\S]*?```/g, '');

    // Удаляем изображения ![title](url)
    rawText = rawText.replace(/!\[.*?]\(.*?\)/g, '');

    // Удаляем ссылки [text](url)
    rawText = rawText.replace(/\[.*?]\(.*?\)/g, '');

    // Удаляем [anythingInBrackets]
    rawText = rawText.replace(/\[.*?]/g, '');

    return rawText;
  }, [thinkingItems, firstState]);
}
