import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import type { UserType } from 'app/entities';

import Storage from 'lib/Storage';
import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';

import * as actions from '../actions';

export const config = {
  action: [actions.loadMe.type, actions.loadProfile.type, actions.loadProfileOptimistic.type],
  method: takeEvery,
};

const loadFromCache = (): UserType | null => {
  const key = `@profile/${api.credentials.sessionId()}`;
  let cache: {
    value: UserType;
    releaseTime: string;
  } | null = null;

  try {
    const buffer = Storage.get(key);
    if (buffer) {
      cache = JSON.parse(buffer);
    }
  } catch (error) {
    cache = null;
  }

  if (!cache || cache.releaseTime !== process.env.RELEASE_TIME || cache?.value?.id !== api.credentials.sessionId()) {
    return null;
  }

  return cache.value;
};

const saveToCache = (data: UserType): void => {
  const key = `@profile/${api.credentials.sessionId()}`;
  Storage.set(
    key,
    JSON.stringify({
      value: data,
      releaseTime: process.env.RELEASE_TIME,
    }),
  );
};

export function* func(action: SagaReturnType<typeof actions.loadMe | typeof actions.loadProfile | typeof actions.loadProfileOptimistic>) {
  const { payload } = action;
  const { login } = payload;
  const isMy = login === 'my';

  let data: UserType | null = null;

  if (isMy) {
    data = yield* call(() => loadFromCache());
  }

  const doneCallback = action.type === actions.loadProfile.type ? actions.loadProfileDone : actions.loadMeDone;

  if (!payload.optimistic || !data) {
    const profile = yield* call(() => api.resource.user.profile(login));
    yield checkUnauthorized(profile);

    if (profile.error || !profile.data) {
      yield put(
        doneCallback({
          login,
          error: (profile.error && (Array.isArray(profile.error) ? profile.error[0].message : profile.error.message)) ?? 'Error load profile',
        }),
      );
      yield cancel();
      return;
    }
    const result: UserType = profile.data;
    if (isMy) {
      yield call(() => saveToCache(result));
    }
    yield put(actions.setData({ data: [result] }));
    yield put(doneCallback({ login }));
    yield cancel();
    return;
  }

  yield put(actions.setData({ data: [data] }));
  yield put(doneCallback({ login }));

  const profile = yield* call(() => api.resource.user.profile(login));
  yield* checkUnauthorized(profile);

  if (profile.error || !profile.data) {
    yield cancel();
    return;
  }
  const result: UserType = profile.data;
  if (isMy) {
    yield* call(() => saveToCache(result));
  }
  yield put(actions.setData({ data: [result] }));
}
