import { type SagaReturnType, takeEvery, cancel } from 'redux-saga/effects';

import network from 'lib/network';

import { actions } from '../slice';

export const config = {
  action: actions.clear.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.clear>) {
  if (!action?.payload?.resources) {
    yield cancel();
    return;
  }

  const resources = !Array.isArray(action.payload.resources) ? [action.payload.resources] : action.payload.resources;
  if (!resources.length) {
    yield cancel();
    return;
  }

  const map: any = {
    document: '/copilot/chat/type/document',
    web: '/copilot/chat/type/web',
    'global-fulltext': '/copilot/chat/type/fulltext',
    'library-fulltext': '/copilot/chat/type/team-space',
    research: '/copilot/chat/type/research',
  };
  resources.forEach((resource) => {
    if (!map[resource.type]) {
      return;
    }
    const request = network.request<any>(map[resource.type], {
      baseUrl: process.env.COPILOT_DOMAIN,
    });
    if (resource.type === 'note' && typeof resource.id === 'number') {
      request.body({
        note_id: resource.id,
      });
    }
    request.post();
  });
}
