import { memo } from 'react';
import { createUseStyles } from 'react-jss';

const PrismDarkTheme = () => {
  useStyles();
  return null;
};

const useStyles = createUseStyles({
  '@global': {
    'code[class*="language-"], pre[class*="language-"]': {
      '&': {
        color: '#ccc',
        background: 'none',
        fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
        fontSize: '1em',
        textAlign: 'left',
        whiteSpace: 'pre',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        wordWrap: 'normal',
        lineHeight: '1.5',
        mozTabSize: '4',
        oTabSize: '4',
        tabSize: '4',
        webkitHyphens: 'none',
        mozHyphens: 'none',
        msHyphens: 'none',
        hyphens: 'none',
      },
      '& .token': {
        '&.comment, &.block-comment, &.prolog, &.doctype, &.cdata': {
          color: '#999',
        },
        '&.punctuation': {
          color: '#ccc',
        },
        '&.tag, &.attr-name, &.namespace, &.deleted': {
          color: '#e2777a',
        },
        '&.function-name': {
          color: '#6196cc',
        },
        '&.boolean, &.number, &.function': {
          color: '#f08d49',
        },
        '&.property, &.class-name, &.constant, &.symbol': {
          color: '#f8c555',
        },
        '&.selector, &.important, &.atrule, &.keyword, &.builtin': {
          color: '#cc99cd',
        },
        '&.string, &.char, &.attr-value, &.regex, &.variable': {
          color: '#7ec699',
        },
        '&.operator, &.entity, &.url': {
          color: '#67cdcc',
        },
        '&.important, &.bold': {
          fontWeight: 'bold',
        },
        '&.entity': {
          cursor: 'help',
        },
        '&.inserted': {
          color: 'green',
        },
        '&.bold': {
          fontWeight: 'bold',
        },
        '&.italic': {
          fontStyle: 'italic',
        },
      },
    },
    '@media print': {
      'code[class*="language-"], pre[class*="language-"]': {
        textShadow: 'none',
      },
    },
    'pre[class*="language-"]': {
      padding: '1em',
      margin: '.75em 0',
      overflow: 'auto',
    },
    ':not(pre) > code[class*="language-"], pre[class*="language-"]': {
      background: '#232323',
      borderRadius: '0.3125em',
    },
    ':not(pre) > code[class*="language-"]': {
      padding: '.1em',
      borderRadius: '.3em',
      whiteSpace: 'normal',
    },
  },
});

export default memo(PrismDarkTheme);
