import { type SagaReturnType, takeEvery, cancel, put, delay } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as messageStore from 'store/nodes/message';

import { actions as listActions } from '../slices/list';

import * as selectors from '../selectors';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type } = context;

  if (type !== 'teamSpaceCreated' || !('teamSpaceId' in context) || typeof context.teamSpaceId !== 'number') {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  yield delay(500);
  const space = yield* select(selectors.spaceById(context.teamSpaceId));
  if (space) {
    yield cancel();
    return;
  }

  yield put(listActions.load({ id: context.teamSpaceId }));
}
