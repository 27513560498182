import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import type { CopilotThinkingStep, CopilotThinkingStep1, CopilotThinkingStep2 } from 'app/entities';

import * as messageStore from 'store/nodes/message';
import * as contentStore from 'store/nodes/content';
import * as copilotStore from 'widgets/Copilot/store';
import { guard } from 'utils';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context: data } = action.payload;

  if (data.type !== 'thinking_steps') {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  if (guard.isCopilotThinkingStep1(data)) {
    yield put(
      copilotStore.actions.setThinkItem({
        step: data.step,
        requestId: data.requestId,
        text: data.modelThought,
      }),
    );
  }
  if (guard.isCopilotThinkingStep2(data)) {
    yield put(
      copilotStore.actions.setThinkItem({
        step: data.step,
        requestId: data.requestId,
        text: data.modelObservation,
      }),
    );
  }
}
