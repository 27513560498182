import { useEditor } from 'novel';
import { forwardRef, memo, useImperativeHandle } from 'react';
import { DOMParser as ProseMirrorDOMParser } from 'prosemirror-model';

export interface ContentCommandMethods {
  insert: (text: string) => void;
  focus: () => void;
}

const ContentCommand = forwardRef<ContentCommandMethods>((props, forwardedRef) => {
  const { editor } = useEditor();

  function parseHtmlToProseMirror(htmlString: string) {
    if (!editor) {
      return null;
    }
    const dom = new window.DOMParser().parseFromString(htmlString, 'text/html');
    return ProseMirrorDOMParser.fromSchema(editor.view.state.schema).parse(dom.body);
  }

  useImperativeHandle(forwardedRef, () => ({
    insert: (html: string) => {
      if (!editor) {
        return;
      }

      const docFragment = parseHtmlToProseMirror(html);
      if (!docFragment) {
        return;
      }
      const { selection } = editor.view.state;
      editor
        .chain()
        .focus()
        .insertContentAt(
          {
            from: selection.from,
            to: selection.to,
          },
          docFragment.toJSON(),
        )
        .run();
    },
    focus: () => {
      if (!editor) {
        return;
      }
      editor.chain().focus().run();
    },
  }));

  return null;
});

export default memo(ContentCommand);
