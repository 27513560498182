import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import { guard } from 'utils';
import { select, call } from 'store/utils/saga/effects';

import * as api from 'services/api';

import Alert from 'components/Alert';
import dialog from 'components/Dialog';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.noteDelete.type,
  method: takeEvery,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: 'Confirmation',
        content: 'Are you sure want to delete\nthis note?',
      },
      [
        {
          label: 'Yes',
          color: 'danger',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
        {
          label: 'Cancel',
          variant: 'plain',
          color: 'neutral',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
      ],
    );
  });

export function* func(action: SagaReturnType<typeof actions.noteDelete>) {
  const { id, resourceId } = action.payload;

  const isConfirmed = yield* call(() => confirm());

  if (!id || !resourceId || !isConfirmed) {
    yield put(actions.noteDeleteDone(resourceId, id));
    yield cancel();
    return;
  }

  const material = yield* select(selectors.dataById(resourceId));
  if (!material || guard.isContentError(material)) {
    yield put(actions.noteDeleteDone(resourceId, id));
    yield cancel();
    return;
  }
  const { myNotes = [], remarks = [] } = material;

  const oldMyNotes = [...myNotes];
  const newMyNotes = myNotes.filter((note) => note.id !== id);
  const oldRemarks = [...remarks];
  const newRemarks = remarks.filter((remark) => remark.type === 'highlight' || (remark.type === 'note' && remark.id !== id));

  yield put(
    actions.setItem([
      {
        ...material,
        myNotes: newMyNotes,
        remarks: newRemarks,
      },
    ]),
  );

  const { data, error } = yield* call(() => api.resource.content.note.delete(resourceId));
  if (error || !data) {
    yield put(
      actions.setItem([
        {
          ...material,
          myNotes: oldMyNotes,
          remarks: oldRemarks,
        },
      ]),
    );
    yield put(actions.noteDeleteDone(resourceId, id));
    Alert.error(error);
    yield cancel();
    return;
  }

  yield put(actions.noteDeleteDone(resourceId, id));

  yield put(actions.loadById({ id: resourceId }));
}
