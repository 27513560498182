import { useMemo } from 'react';
import { useRoute } from 'navigation/hooks';

import useMyLibrary from './useMyLibrary';

const useSearchParams = () => {
  const { params } = useRoute();
  const { spaceId: defaultSpaceId } = useMyLibrary();

  return useMemo(() => {
    const spaceId = (params && 'spaceId' in params ? params.spaceId : null) || defaultSpaceId;
    const text = (params && 'text' in params ? params.text : null) || defaultSpaceId;
    return {
      spaceId,
      text,
    };
  }, [params, defaultSpaceId]);
};

export default useSearchParams;
