import { useSelector } from 'react-redux';
import isFolderLoading from '../store/selectors/isFolderLoading';

/**
 * Хук для проверки, происходит ли загрузка фолдера.
 */
function useIsFolderLoading(spaceId: number, folderId: string | number | 'root'): boolean {
  return useSelector(isFolderLoading(spaceId, folderId));
}

export default useIsFolderLoading;
