import { type FC, memo, useCallback } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/joy';

import { usePerson } from 'hooks';

import { controller as modal } from 'components/Modal2';

import Icon from 'ui/Icon';
import EmptyPanel from 'screens/Profile/elements/EmptyPanel';
import Job from './elements/Job';

export interface JobsProps {
  userLogin?: string;
  bigLabel?: boolean;
}

const Jobs: FC<JobsProps> = (props) => {
  const { userLogin, bigLabel = false } = props;

  const { person } = usePerson('User', userLogin);
  const jobs = person?.jobExperience || [];

  const handleAddJob = useCallback(() => {
    modal.popup.jobAdd.open();
  }, []);

  const handleEdit = useCallback((id: number) => {
    modal.popup.jobAdd.open({ id });
  }, []);

  if (!userLogin || !person) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography fontSize={bigLabel ? 22 : 20} fontWeight={500}>
          Experience
        </Typography>
        <Box sx={{ flex: 1 }} />
        {person.isMy && (
          <Tooltip arrow variant="outlined" size="sm" title="Add job experience">
            <IconButton onClick={handleAddJob}>
              <Icon size="xl" name="plus" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {person.isMy && (!Array.isArray(jobs) || !jobs.length) && (
        <EmptyPanel mt={2} sx={{ cursor: 'pointer' }} onClick={handleAddJob} text="Add your experience here" />
      )}
      {!person.isMy && (!Array.isArray(jobs) || !jobs.length) && <EmptyPanel mt={2} text={`${person.name} did not specify experience`} />}
      {Array.isArray(jobs) &&
        jobs.map((job) => (
          <Job
            id={job.id}
            key={job.company}
            location={job.location}
            company={job.company}
            startDate={job.startDate}
            endDate={job.endDate}
            position={job.position}
            description={job.description}
            editable={person.isMy}
            onEdit={handleEdit}
          />
        ))}
    </Box>
  );
};

export default memo(Jobs);
