import { type SagaReturnType, put, cancel, takeLatest, take, fork } from 'redux-saga/effects';
import { channel, END } from 'redux-saga';

import { type SpaceContentType } from 'app/entities';

import { uploadFilesToS3 } from 'utils';
import network from 'lib/network';

import { call, select } from 'store/utils/saga/effects';
// import * as modalStore from 'widgets/modals/store';
import * as spaceStore from 'screens/Space/store';
import * as materialStore from 'store/nodes/content';

import Alert from 'components/Alert';
import { track } from '@amplitude/analytics-browser';

import getSpaceParams from 'screens/Space/store/model/getSpaceParams';
import sourceEntityToSpaceEntity, { type SourceEntityType } from 'screens/Space/model/sourceEntityToSpaceEntity';

import * as actions from '../actions';
import * as selectors from '../selectors';

import type { ManyInfoType, ResultManyType, ResultOneType } from '../types';

export const config = {
  action: [actions.parseText.type, actions.parseFiles.type],
  method: takeLatest,
};

type TextType = {
  text: string;
  plain_text: string;
  private: boolean;
};

type BulkType = {
  bulk_content_file: {
    data: string;
    extension: string;
  };
  private: boolean;
};

type FileType = {
  s3_file_info: {
    file_name: string;
    file_hash: string;
    extension: string;
  }[];
  private: boolean;
};

type BodyType = (TextType | BulkType | FileType) & {
  team_space_id?: number;
  team_space_folder_id?: number;
};

function* loadMaterial(id: number) {
  yield put(materialStore.actions.loadById({ id, options: { mode: 'preview' } }));
  while (true) {
    const { payload }: { payload: { id: number | number[] } } = yield take(materialStore.actions.loadByIdDone);
    if ((!Array.isArray(payload.id) ? [payload.id] : payload.id).includes(id)) {
      break;
    }
  }
}

export function* func(action: SagaReturnType<typeof actions.parseText | typeof actions.parseFiles>) {
  let body: BodyType | null = null;

  const { spaceId, folderId } = action.payload;
  const spaceParams = yield* getSpaceParams(spaceId, folderId);
  const spaceLibraryId = yield* select(spaceStore.selectors.libraryIdBySpaceId(spaceParams?.teamSpaceId));

  if (spaceParams) {
    yield put(actions.setDestination({ spaceId: spaceParams.teamSpaceId, folderId: String(spaceParams.teamSpaceFolderId) }));
  }

  if (action.type === actions.parseText.type) {
    const { value: html, privacy } = action.payload;
    const text = html.replace(/<[^>]*>/g, '');
    body = { text: html.trim(), plain_text: text.trim(), private: privacy };
  }

  if (action.type === actions.parseFiles.type) {
    const processChannel = channel();

    function* watchProcessChannel() {
      while (true) {
        const actionFromChannel: { payload: { percent: number } } | END = yield take(processChannel);
        if (actionFromChannel === END) {
          break;
        }
        const { percent } = (actionFromChannel as { payload: { percent: number } }).payload;
        yield put(
          actions.setUploadingInfo({
            percent,
          }),
        );
      }
    }
    yield fork(watchProcessChannel);

    const { files, privacy } = action.payload;
    let processedCount = 0;
    const uploadedResults = yield* call(() =>
      uploadFilesToS3('plus', files, () => {
        processedCount += 1;
        processChannel.put({
          type: 's3/uploaded',
          payload: { percent: (processedCount / files.length) * 100 },
        });
      }),
    );
    processChannel.close();

    const successUploaded = uploadedResults.filter((item) => !item.error);
    body =
      successUploaded.length > 0
        ? {
            s3_file_info: successUploaded.map(({ data }) => ({
              file_name: data?.file?.name || '',
              file_hash: data?.hash || '',
              extension: data?.file?.extension || '',
            })),
            private: privacy,
          }
        : null;
  }

  if (!body) {
    yield put(actions.setErrorResult({ message: 'Error input data', __typename: 'ResultError' }));
    yield put(actions.parseDone());
    yield put(actions.close());
    Alert.error('Unknown error');
    yield cancel();
    return;
  }

  const requestInfo = yield* select(selectors.requestInfo);
  const {
    data: sourceData,
    hasError,
    errors,
  } = yield* call(() =>
    network
      .request<SourceEntityType | ManyInfoType>('/stack-2/content/space/upload')
      .body({ request_id: requestInfo.id, ...body, ...spaceParams })
      .post(),
  );

  if (['Usage_limits_pins'].includes(errors?.[0]?.type || '')) {
    yield put(actions.parseDone());
    yield put(actions.close());
    yield cancel();
    return;
  }

  if (!sourceData || hasError) {
    yield put(actions.setErrorResult({ message: 'Unknown error', __typename: 'ResultError' }));
    yield put(actions.parseDone());
    yield put(actions.close());
    Alert.error(errors);
    yield cancel();
    return;
  }

  let data: ResultOneType | ResultManyType;
  if ('type' in sourceData) {
    data = {
      content: sourceEntityToSpaceEntity(spaceLibraryId ? [spaceLibraryId] : [], sourceData) as SpaceContentType,
      __typename: 'ResultOne',
    };
  } else {
    data = {
      ...sourceData,
      __typename: 'ResultMany',
    };
  }

  if (data.__typename === 'ResultOne') {
    yield put(spaceStore.slices.spaceResource.actions.add({ entity: data.content, position: 'first' }));
    yield loadMaterial(data.content.resourceId);
    yield put(actions.setMaterialResult(data));
    yield* call(() => track('PLUS:AddOneMaterial'));
  } else {
    yield put(actions.setMaterialsResult(data));
    yield* call(() => track('PLUS:AddManyMaterials'));
  }

  yield put(actions.parseDone());
}
