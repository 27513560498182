import { useCallback, type DragEvent } from 'react';

import { parseItemId } from './itemIdParser';

interface UseTreeDropParams {
  onFilesDrop?: (spaceId: number, folderId: string, files: FileList) => void;
}

export function useTreeDrop({ onFilesDrop }: UseTreeDropParams) {
  /**
   * Обработчик события onDrop.
   * Принимает files из dataTransfer,
   * парсит itemId (spaceId::folderId) из evt.currentTarget
   * и вызывает onFilesDrop если нужно.
   */
  const handleDrop = useCallback(
    (event: DragEvent<HTMLElement>) => {
      event.preventDefault();

      const { files } = event.dataTransfer;
      if (!files || files.length === 0) {
        return;
      }

      const rawItemId = (event.target as HTMLElement).closest('[role="treeitem"]')?.getAttribute('itemID');
      if (!rawItemId) {
        console.warn('No itemid attribute found on drop target');
        return;
      }

      const { spaceId, entityId: folderId } = parseItemId(rawItemId);

      onFilesDrop?.(spaceId, folderId, files);
    },
    [onFilesDrop],
  );

  /**
   * Обработчик для onDragOver, чтобы разрешить drop на элементах
   * (и не отменять событие браузером).
   */
  const handleDragOver = useCallback((event: DragEvent<HTMLElement>) => {
    event.preventDefault();
  }, []);

  return {
    handleDrop,
    handleDragOver,
  };
}

export default useTreeDrop;
