import React, { type ReactElement, memo, useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet, useWindowDimensions } from 'react-native';
import { MacScrollbar } from 'mac-scrollbar';

import type { TopicType } from 'app/entities';
import * as api from 'services/api';

import { useColorScheme, usePerson, useResponsive } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import { controller as modal } from 'components/Modal2';
import { TextInput } from 'components/Form';
import { styleSheetToCss, unit } from 'utils';
import { Text, View } from 'components/Themed';
import Alert from 'components/Alert';
import ModalToolbar from 'components/ModalToolbar';
import Button from 'components/Button';

export type SkillsProps = unknown;

const Skills = () => {
  const dispatch = useDispatcher();
  const batchRef = useRef<any>();
  const responsive = useResponsive();
  const dimensions = useWindowDimensions();

  const { person } = usePerson('User', 'my');
  const { isLoading: isProfileUpdating } = useSelector((state) => state.profile.meta.update);

  const [query, setQuery] = useState<string>('');
  const [directions, setDirections] = useState<Omit<TopicType, 'directionId' | 'rank'>[]>([]);
  const [skills, setSkills] = useState<TopicType[]>([]);
  const [selectedDirectionId, setSelectedDirectionId] = useState<number>(0);
  const [selectedSkills, setSelectedSkills] = useState<TopicType[]>([]);
  const [isSentUpdate, setSentUpdate] = useState<boolean>(false);
  const colorScheme = useColorScheme();
  // const handleScrollLayoutWeb = useCallback((event: any) => {
  //   if (Platform.OS !== 'web') {
  //     return;
  //   }
  //   const target = event.nativeEvent?.target as HTMLDivElement;
  //   if (!target) {
  //     return;
  //   }
  //   target.setAttribute('data-block', 'scrollable');
  // }, []);

  useEffect(() => {
    api.resource.skills.direction().then((response) => {
      if (response.error || !response.data) {
        Alert.error(response.error);
        setDirections([]);
        return;
      }
      setDirections(response.data);
    });
  }, []);

  useEffect(() => {
    batchRef.current = setTimeout(() => {
      api.resource.skills.search(query, selectedDirectionId, 100).then((response) => {
        if (response.error || !response.data) {
          Alert.error(response.error);
          setSkills([]);
          return;
        }
        setSkills(response.data);
      });
    }, 200);
    return () => {
      if (batchRef.current) {
        clearTimeout(batchRef.current);
        batchRef.current = null;
      }
    };
  }, [query, selectedDirectionId]);

  useEffect(() => {
    if (isSentUpdate && !isProfileUpdating) {
      setSentUpdate(false);
      modal.popup.skills.close();
    }
  }, [isProfileUpdating, isSentUpdate]);

  const handleSwitchSkills = useCallback(
    (event: any, context: any) => {
      const itemIndex = selectedSkills.findIndex((item) => item.text === context.text);
      if (itemIndex === -1) {
        setSelectedSkills([...selectedSkills, { ...context }]);
      } else {
        const newSelected = [...selectedSkills];
        newSelected.splice(itemIndex, 1);
        setSelectedSkills(newSelected);
      }
    },
    [selectedSkills],
  );

  const handleClose = useCallback(() => {
    modal.popup.skills.close();
  }, []);

  const handleReset = useCallback(() => {
    setSelectedSkills((person?.skills || []).map((skill) => ({ id: 0, text: skill, directionId: 0 }) as TopicType));
  }, [person?.skills]);

  useEffect(() => {
    handleReset();
  }, [person?.skills]);

  const handleSubmit = useCallback(() => {
    const newSkills = selectedSkills.map((skill) => skill.text);
    dispatch.profile.updateData({
      skills: newSkills,
    });
    setSentUpdate(true);
  }, [dispatch.profile, selectedSkills]);

  const isUpdated = useMemo(() => {
    const left = [...(person?.skills || [])].sort((a, b) => (a < b ? -1 : 1));
    const right = [...(selectedSkills || [])].map((item) => item.text).sort((a, b) => (a < b ? -1 : 1));
    return JSON.stringify(left) !== JSON.stringify(right);
  }, [person?.skills, selectedSkills]);

  const componentStyles = useMemo(() => {
    let result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.Skills),
      height: unit(dimensions.height - 80),
    };
    if (responsive.isMoreThen.mobile) {
      result = {
        ...result,
        ...StyleSheet.flatten(styles.desktop),
      };
    }
    return result;
  }, [responsive.isMoreThen.mobile, dimensions.height]);

  return (
    <View style={componentStyles} lightColor="#e7e7e7" darkColor="#181818" pointerEvents="auto">
      <ModalToolbar title="Topics" isLoading={isProfileUpdating} isDisabled={!isUpdated} onCancel={handleClose} onDone={handleSubmit} />
      <View style={styles.form}>
        <View style={styles.search}>
          <TextInput placeholder="Search" onChange={setQuery} value={query} style={styles.input} />
        </View>
        <View style={styles.selected}>
          <Text size={17}>Selected</Text>
          <Button
            type="button"
            variant="outlined"
            height={18}
            lightColor="#9A99A2"
            darkColor="#696969"
            style={styles.btnReset}
            onPress={handleReset}
            // isDisabled={!об}
          >
            <Text size={13} lightColor="#696969" darkColor="#696969">
              reset
            </Text>
          </Button>
        </View>
        <View style={styles.tags}>
          {selectedSkills.length === 0 && (
            <View style={{ opacity: 0.4 }}>
              <Button type="button" variant="contained" height={28} isDisabled style={{ marginRight: unit(4), marginBottom: unit(8) }}>
                <Text lightColor="#ffffff" darkColor="#ffffff" size={13}>
                  No selected topics
                </Text>
              </Button>
            </View>
          )}
          {selectedSkills.length > 0 &&
            selectedSkills?.map((item) => (
              <Button
                key={`selected-${item.text}`}
                context={item}
                type="button"
                variant="contained"
                height={28}
                onPress={handleSwitchSkills}
                isDisabled={isProfileUpdating}
                lightColor="#497CFF"
                darkColor="#497CFF"
                style={{ marginRight: unit(4), marginBottom: unit(8) }}
              >
                <Text lightColor="#ffffff" darkColor="#ffffff" size={13}>
                  {item.text}
                </Text>
              </Button>
            ))}
        </View>
        {skills?.length > 0 && (
          <>
            <Text size={17} style={styles.itemText}>
              Found skills
            </Text>
            <MacScrollbar skin={colorScheme} style={styleSheetToCss(styles.skillsScroll)}>
              <View style={styles.tags}>
                {skills?.map((item) => {
                  const isSelected = selectedSkills.findIndex((selected) => selected.text === item.text) > -1;
                  return (
                    <Button
                      key={`skill-${item.id}`}
                      context={item}
                      type="button"
                      variant={isSelected ? 'contained' : 'outlined'}
                      height={28}
                      lightColor={isSelected ? '#c7c7c7' : '#a6a6a6'}
                      darkColor={isSelected ? '#5b5b5b' : '#797979'}
                      isDisabled={isProfileUpdating}
                      onPress={handleSwitchSkills}
                      style={{ marginRight: unit(4), marginBottom: unit(8) }}
                    >
                      <Text size={13} lightColor="#000000" darkColor="#ffffff">
                        {item.text}
                      </Text>
                    </Button>
                  );
                })}
              </View>
            </MacScrollbar>
          </>
        )}
        {skills?.length === 0 && (
          <View style={styles.notFound}>
            <Text size={15} lightColor="#a6a6a6" darkColor="#4E4E53" align="center">
              Type some text for start searching
            </Text>
          </View>
        )}
        {responsive.isMoreThen.mobile && (
          <Button
            type="button"
            variant="contained"
            height={40}
            lightColor="#497CFF"
            darkColor="#497CFF"
            radius="half"
            isLoading={isProfileUpdating}
            isDisabled={!isUpdated}
            onPress={handleSubmit}
            style={styles.btnSubmit}
          >
            <Text lightColor="#ffffff" darkColor="#ffffff" size={17}>
              Save updates
            </Text>
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  Skills: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingTop: unit(18),
    paddingBottom: unit(32),
    borderTopLeftRadius: unit(40),
    borderTopRightRadius: unit(40),
  },
  desktop: {
    borderRadius: unit(40),
    marginTop: unit(40),
    marginBottom: unit(40),
  },
  form: {
    alignItems: 'stretch',
    marginTop: unit(16),
    flex: 1,
  },
  field: {
    marginTop: unit(16),
  },
  search: {
    flexDirection: 'row',
    borderRadius: unit(10),
  },
  selected: {
    marginTop: unit(24),
    flexDirection: 'row',
  },
  tags: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: unit(8),
  },
  skillsScroll: {
    flex: 1,
    marginHorizontal: unit(-16),
    paddingHorizontal: unit(16),
  },
  notFound: {
    flex: 1,
    justifyContent: 'center',
  },
  btnReset: {
    marginTop: unit(2),
    marginLeft: unit(8),
  },
  btnSubmit: {
    marginTop: unit(24),
    alignSelf: 'center',
    marginBottom: unit(8),
  },
  itemText: {
    marginTop: unit(24),
  },
  input: {
    flex: 1,
  },
});

export default memo(Skills);
