import { useState, useLayoutEffect } from 'react';

/**
 * Хук, который отслеживает координату `top` HTMLDivElement (element),
 * пересчитывая её при изменении размеров окна, самого элемента или его родителя.
 */
export default function useElementTop(element: HTMLDivElement | null) {
  const [top, setTop] = useState(0);

  useLayoutEffect(() => {
    if (!element) {
      return undefined;
    }

    function updateTop() {
      const rect = element?.getBoundingClientRect();
      if (rect) {
        setTop(Math.round(rect.top + window.scrollY));
      }
    }

    updateTop();

    const roElement = new ResizeObserver(() => {
      updateTop();
    });
    roElement.observe(element);

    let roParent: ResizeObserver | null = null;
    if (element.parentElement) {
      roParent = new ResizeObserver(() => {
        updateTop();
      });
      roParent.observe(element.parentElement);
    }

    function handleWindowResize() {
      updateTop();
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      roElement.disconnect();
      if (roParent) roParent.disconnect();
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [element]);

  return top;
}
