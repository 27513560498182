function useIcon(label: string): string {
  switch (label.toLowerCase()) {
    case 'library':
      return 'inbox';
    case 'bookmarked':
      return 'bookmark';
    case 'collection':
      return 'files';
    case 'course':
      return 'graduation-cap';
    case 'article':
      return 'file-text';
    case 'video':
      return 'video';
    case 'source':
      return 'database';
    case 'link':
      return 'link';
    case 'pdf':
      return 'file-pdf';
    case 'image':
      return 'image';
    case 'note':
      return 'sticky-note';
    default:
      return 'file';
  }
}

export default useIcon;
