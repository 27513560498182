import { createSlice } from '@reduxjs/toolkit';

export interface SubscriptionStore {}

const initialState: SubscriptionStore = {};

const gtmSlice = createSlice({
  name: 'gtmEvent',
  initialState,
  reducers: {
    signUp(state, action) {},
    login(state, action) {},
    setUserId(state, action) {},
    onboardingComplete(state) {},
    generateLead() {},
    purchase(state, action) {},
    addToLibrary(state, action) {},
    createCollection() {},
    ask(state, action) {},
  },
});

export const { reducer, actions } = gtmSlice;
