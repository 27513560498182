import { takeLatest, put, cancel, delay } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { navigate } from 'navigation/methods';

import Alert from 'components/Alert';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as contentStore from 'store/nodes/content';
import * as communityStore from 'store/nodes/community';

import credentials from 'services/api/credentials';
import network from 'lib/network';
import * as actions from '../actions';

export const config = {
  action: actions.logOut.type,
  method: takeLatest,
};

export function* func() {
  const refresh = credentials.getRefresh();

  const { errors } = yield* call(() =>
    network
      .request<{
        success: boolean;
      }>('/stack-1/auth/logout', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({ refresh })
      .post(),
  );
  if (errors) {
    Alert.error(errors);
    credentials.clear();
    yield put(actions.logOutDone(errors[0]?.message));
    yield cancel();
    return;
  }

  credentials.clear();

  yield put(userStore.actions.logOut());
  yield put(contentStore.actions.logOut());
  yield put(communityStore.actions.logOut());

  yield delay(20);
  yield put(actions.logOutDone());

  yield* call(() => track('Logout'));

  yield* call(() => navigate('Ask'));
}
