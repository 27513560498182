import Alert from 'components/Alert';

import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { guard } from 'utils';

import { select } from 'store/utils/saga/effects';
import * as messageStore from 'store/nodes/message';
import * as materialStore from 'store/nodes/content';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, message, context } = action.payload;
  const { materialId, summary } = context;

  if (message !== 'Summary prepared' || typeof materialId !== 'number' || typeof summary !== 'string') {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  const material = yield* select(materialStore.selectors.dataById(materialId));
  if (!material || guard.isContentError(material)) {
    yield cancel();
    return;
  }

  Alert.info('SummaryPrepared', {
    closeOnClick: true,
    autoClose: 3000,
    data: {
      resourceId: material.id,
      type: material.type,
      title: material.title,
      summary,
    },
  });
}
