import moment from 'moment-timezone';
import { type ContentErrorType, type MaterialType } from 'app/entities';
import { guard } from 'utils';

const usePublishedAgo = (data?: MaterialType | ContentErrorType | null): string | null => {
  if (!data || guard.isContentError(data)) {
    return null;
  }
  return typeof data?.publishedAt === 'string' ? moment(data.publishedAt).fromNow() : null;
};

export default usePublishedAgo;
