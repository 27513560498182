import { useCallback, useEffect, useRef } from 'react';
import { usePanelsResizingContext } from './panelsResizingContext';

interface UseIsPanelsResizingParams {
  onResizingStart?: () => void;
  onResizingStop?: () => void;
}

/**
 * Хук, который возвращает текущее значение isResizing,
 * а также вызывает колбэки при старте/остановке ресайза.
 */
export function useIsPanelsResizing(storeKey: string, params: UseIsPanelsResizingParams = {}): boolean {
  const { onResizingStart, onResizingStop } = params;

  const { getIsResizing } = usePanelsResizingContext();
  const isResizing = getIsResizing(storeKey);

  const prevRef = useRef(isResizing);

  useEffect(() => {
    if (!prevRef.current && isResizing) {
      onResizingStart?.();
    } else if (prevRef.current && !isResizing) {
      onResizingStop?.();
    }
    prevRef.current = isResizing;
  }, [isResizing, onResizingStart, onResizingStop]);

  return isResizing;
}

export function useSetPanelsResizing(storeKey: string) {
  const { setIsResizing } = usePanelsResizingContext();
  return useCallback(
    (value: boolean) => {
      setIsResizing(storeKey, value);
    },
    [storeKey, setIsResizing],
  );
}
