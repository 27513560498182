import { type FC, type PropsWithChildren, type MouseEventHandler, createContext, useContext, useMemo, useCallback, memo } from 'react';
import { isEqual } from 'lodash';
import * as Linking from 'expo-linking';
import { track } from '@amplitude/analytics-browser';

import { useNavigate } from 'navigation/hooks';
import { guard, copyToClipboard } from 'utils';
import type {
  AuthorType,
  CollectionType,
  ContentErrorType,
  DocumentType,
  MaterialType,
  NoteType,
  RagMaterialType,
  SpaceFolderType,
  UserType,
} from 'app/entities';

import { useResourceInfo, useGetShare } from 'hooks';
import { useDispatcher } from 'store/utils/redux/hooks';

import Alert from 'components/Alert';
import { controller as modal } from 'components/Modal2';

import { useCardProps } from '../model/CardContext';

interface CardActionsContextType {
  handleAuthorPress: (value: UserType | AuthorType | null) => void;
  handleBodyPress: () => void;
  handleQueue: (event?: { stopPropagation?: () => void }) => void;
  handleToCollection: MouseEventHandler<HTMLButtonElement>;
  handleShare: () => void;
  handleLink: (event?: { stopPropagation: () => void }) => void;
  handlePlaySummary: () => void;
  handleTLDR: () => void;
  handleShowRelated: () => void;
  handleRemove: (event?: { stopPropagation: () => void }) => void;
  handleEdit: (event?: { stopPropagation: () => void }) => void;
  handleAddRagMaterialLink: (event?: any) => void;
}

const CardActionsContext = createContext<CardActionsContextType | null>(null);

export const useCardActions = () => {
  const context = useContext(CardActionsContext);
  if (!context) {
    throw new Error('useCardActions must be used within a CardActionsProvider');
  }
  return context;
};

export interface CardActionsProviderProps extends PropsWithChildren {
  data: CollectionType | MaterialType | RagMaterialType | NoteType | DocumentType | SpaceFolderType | ContentErrorType | null;
}

export const CardActionsProvider: FC<CardActionsProviderProps> = memo((props) => {
  const { data, children } = props;
  const { id, type, parent } = useCardProps();

  const dispatcher = useDispatcher();
  const navigate = useNavigate();

  const resourceInfo = useResourceInfo(data);
  const share = useGetShare();

  const handleAddRagMaterialLink = useCallback(
    (event?: any) => {
      event?.stopPropagation?.();
      if (!guard.isRagMaterial(data)) {
        return;
      }
      dispatcher.search.updateRagMaterialState({ data: [{ id: String(id), isAdded: true }] });
      dispatcher.plus.parseText({ value: data?.originalUrl, privacy: false });
    },
    [data, dispatcher, id],
  );

  const handleQueue = useCallback(
    (event?: { stopPropagation?: () => void }) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      if (resourceInfo.type === 'Material') {
        dispatcher.interaction.toggleBookmark(resourceInfo.legacy.type, resourceInfo.id);
      }
    },
    [resourceInfo, dispatcher],
  );

  const handleToCollection = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      const resourceType = {
        Material: 'Content',
        Document: 'Document',
        Note: 'Note',
      }[resourceInfo?.type as string];
      dispatcher.modal.open('ResourcesToFolder', {
        mode: 'resourceAdd',
        resourceId: resourceInfo.id,
        resourceType,
      });
    },
    [resourceInfo, dispatcher],
  );

  const handleShare = useCallback(() => {
    share(data);
  }, [share, data]);

  const handleLink = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (guard.isMaterial(data) && data.originalUrl) {
        copyToClipboard(data.originalUrl);
      } else {
        const internalUrl = !!data && 'internalUrl' in data ? data.internalUrl : null;
        if (!internalUrl) {
          return;
        }
        const link = Linking.createURL(internalUrl);
        copyToClipboard(link);
      }
      Alert.success('Link was copied');
    },
    [data],
  );

  const handlePlaySummary = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(Play audio summary)');
  }, []);

  const handleTLDR = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(TLDR with GPT4)');
  }, []);

  const handleShowRelated = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(Show related)');
  }, []);

  const handleRemove = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (!resourceInfo || !resourceInfo.type) {
        return;
      }
      if (resourceInfo.type === 'Folder') {
        dispatcher.spaceResource.remove({ entityIds: [resourceInfo.id] });
      }
      if (resourceInfo.type !== 'Folder' && parent) {
        dispatcher.spaceResource.removeByResourceId({
          resources: [{ resourceId: resourceInfo.id, spaceId: parent.spaceId, folderId: parent.folderId }],
        });
      }
    },
    [resourceInfo, dispatcher, parent],
  );

  const handleEdit = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      if (resourceInfo.type === 'Material') {
        dispatcher.modal.open('EditMaterial', { id: resourceInfo.id });
      }
    },
    [resourceInfo, dispatcher],
  );

  const handleAuthorPress = useCallback((value: UserType | AuthorType | null) => {
    if (guard.isLikeUser(value)) {
      navigate('Profile', {
        login: value.login,
      });
      return;
    }
    if (guard.isAuthor(value) && value.owner) {
      navigate('Profile', {
        login: value.owner.login,
      });
      return;
    }
    if (guard.isAuthor(value) && !value.owner) {
      navigate('Author', {
        id: value.id,
      });
    }
  }, []);

  const materialType = type === 'Material' && data && 'type' in data ? data.type : undefined;
  const originalUrl =
    (type === 'Material' && data && 'originalUrl' in data ? data.originalUrl : undefined) ||
    (type === 'RagMaterial' && data && 'originalUrl' in data ? data.originalUrl : undefined);
  const spaceId = type === 'Folder' && data && 'spaceId' in data ? Number(data.spaceId) : undefined;
  const folderId = type === 'Folder' && data && 'id' in data ? String(data.id) : undefined;

  const handleBodyPress = useCallback(() => {
    if (!data) {
      return;
    }

    if (type === 'Material' && materialType) {
      const resourceId = id;
      const resourceType = materialType.toLowerCase();
      if (resourceType && originalUrl && /^https?:\/\//i.test(originalUrl)) {
        navigate('Content', { resourceId, resourceType });
      }
    } else if (type === 'Collection') {
      navigate('Playlist', { resourceId: id });
    } else if (type === 'Note') {
      navigate('Note', { id });
    } else if (type === 'Document') {
      navigate('Note', { id });
    } else if (type === 'Folder' && spaceId && folderId) {
      navigate('Space', { spaceId, folderId });
    } else if (type === 'RagMaterial' && originalUrl) {
      const link = document.createElement('a');
      link.setAttribute('href', originalUrl);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, [navigate, id, type, materialType, originalUrl, spaceId, folderId]);

  const value = useMemo(
    () => ({
      handleAuthorPress,
      handleBodyPress,
      handleQueue,
      handleToCollection,
      handleShare,
      handleLink,
      handlePlaySummary,
      handleTLDR,
      handleShowRelated,
      handleRemove,
      handleEdit,
      handleAddRagMaterialLink,
    }),
    [
      handleAuthorPress,
      handleBodyPress,
      handleQueue,
      handleToCollection,
      handleShare,
      handleLink,
      handlePlaySummary,
      handleTLDR,
      handleShowRelated,
      handleRemove,
      handleEdit,
      handleAddRagMaterialLink,
    ],
  );

  return <CardActionsContext.Provider value={value}>{children}</CardActionsContext.Provider>;
}, isEqual);
