import { computePosition } from '@floating-ui/dom';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection } from 'lexical';
import { type ForwardedRef, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import DefaultToolbar, { type DefaultToolbarCoords } from '../toolbars/DefaultToolbar';
import usePointerInteractions from './model/usePointerInteractions';

export interface FloatingMenuPluginMethodsType {
  close: () => void;
}

export interface FloatingMenuPluginProps {
  isLink: boolean;
  setIsLinkEditMode: (value: boolean) => void;
  isLinkEditMode: boolean;
  hasHeadingPanel?: boolean;
  hasListPanel?: boolean;
  hasRichPanel?: boolean;
}

const FloatingMenuPlugin = (props: FloatingMenuPluginProps, forwardedRef: ForwardedRef<FloatingMenuPluginMethodsType>) => {
  const { setIsLinkEditMode, isLink, isLinkEditMode, hasHeadingPanel, hasListPanel, hasRichPanel } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState<DefaultToolbarCoords | undefined>(undefined);
  const [editor] = useLexicalComposerContext();

  const { isPointerDown, isPointerReleased } = usePointerInteractions();

  useImperativeHandle(forwardedRef, () => ({
    close: () => {
      setCoords(isLinkEditMode ? coords : undefined);
    },
  }));

  const calculatePosition = useCallback(() => {
    const domSelection = getSelection();
    const domRange = domSelection?.rangeCount !== 0 && domSelection?.getRangeAt(0);

    if (!domRange || !ref.current || isPointerDown) {
      setCoords(undefined);
      return;
    }

    computePosition(domRange, ref.current, { placement: 'top' })
      .then((pos) => {
        setCoords({ x: pos.x, y: pos.y - 10 });
      })
      .catch(() => {
        setCoords(undefined);
      });
  }, [isPointerDown]);

  const $handleSelectionChange = useCallback(() => {
    if (editor.isComposing() || editor.getRootElement() !== document.activeElement) {
      setCoords(undefined);
      return;
    }

    const selection = $getSelection();

    if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
      calculatePosition();
    } else {
      setCoords(undefined);
    }
  }, [editor, calculatePosition]);

  const show = coords !== undefined;

  useEffect(() => {
    if (!show && isPointerReleased) {
      editor.getEditorState().read(() => $handleSelectionChange());
    }
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => $handleSelectionChange());
    });
  }, [show, editor, $handleSelectionChange]);

  return createPortal(
    <DefaultToolbar
      hasHeadingPanel={hasHeadingPanel}
      hasListPanel={hasListPanel}
      hasRichPanel={hasRichPanel}
      isLinkEditMode={isLinkEditMode}
      setIsLinkEditMode={setIsLinkEditMode}
      isLink={isLink}
      ref={ref}
      editor={editor}
      coords={coords}
    />,
    document.body,
  );
};

export default memo(forwardRef(FloatingMenuPlugin));
