import { type PayloadAction } from '@reduxjs/toolkit';
import { take, race, delay, select, put, fork, cancel } from 'redux-saga/effects';

import type { BasicUserType, UserType, NoUserExistsType } from 'app/entities';

import network from 'lib/network';

import { call } from 'store/utils/saga/effects';

import { loadUser, loadUserDone, setProcessingKey, setData } from '../actions';
import * as selectors from '../selectors';

type Param =
  | { login: string; email?: never; id?: never; reload?: boolean }
  | { email: string; login?: never; id?: never; reload?: boolean }
  | { id: number; login?: never; email?: never; reload?: boolean };

type RequestParam = { type: 'id'; value: number } | { type: 'email'; value: string } | { type: 'login'; value: string };

export const config = {
  method: 'fork' as const,
};

function getQueryParams(params: Param[]): RequestParam[] {
  return params.map<RequestParam>((p) => {
    if ('login' in p) {
      return { type: 'login', value: p.login || '' };
    }
    if ('email' in p) {
      return { type: 'email', value: p.email || '' };
    }
    return { type: 'id', value: p.id || NaN };
  });
}

/**
 * Воркер, который загружает данные для данного батча.
 * Здесь вы делаете реальные запросы,
 * а потом диспатчите loadUserDone (или что-то аналогичное).
 */
function* loadBatchWorker(params: Param[]) {
  console.log('Starting batch load:', params);

  const response = yield* call(() =>
    network
      .request<Partial<UserType | BasicUserType>[]>('/stack-1/user/profiles')
      .query({
        request: btoa(JSON.stringify(getQueryParams(params))),
      })
      .get(),
  );

  if (!response.data || response.hasError) {
    yield put(loadUserDone(params));
    yield cancel();
    return;
  }

  const itemsMap: Record<string, number> = {};
  response.data.forEach((item, pos) => {
    itemsMap[`id#${item.id}`] = pos;
    itemsMap[`login#${item.login}`] = pos;
    itemsMap[`email#${item.email}`] = pos;
  });

  console.log('response.data', response.data);

  const data: (Partial<BasicUserType> | Partial<UserType> | NoUserExistsType)[] = [];
  for (const item of params) {
    const user = response.data[itemsMap[`id#${item.id!}`] || itemsMap[`login#${item.login!}`] || itemsMap[`email#${item.email!}`]];
    if (user) {
      data.push(user);
    }
    data.push({
      identifier: item.id! || item.login! || item.email!,
      __typename: 'NoUserExists',
    });
  }
  yield put(setData({ data }));
  yield put(loadUserDone(params));
}

/**
 * Генератор-функция, которая принимает массив Param, проверяет
 * для каждого из них через selectors.isProcessing(state, param),
 * и если там false — вызывает setProcessingKey, добавляет в результат.
 *
 * Возвращает массив "Param[]", где нет уже обрабатываемых ключей.
 */
function* filterNotProcessingAndMark(incoming: Param[]) {
  const filtered: Param[] = [];

  for (const param of incoming) {
    const processing: boolean = yield select(selectors.isProcessing(param.id! || param.login! || param.email!));

    if (!processing) {
      yield put(setProcessingKey(param));
      filtered.push(param);
    }
  }

  return filtered;
}

export function* func() {
  while (true) {
    const firstAction: PayloadAction<Param[]> = yield take(loadUser);

    const params: Param[] = yield* filterNotProcessingAndMark(firstAction.payload);

    while (true) {
      const {
        nextAction,
      }: {
        nextAction?: PayloadAction<Param[]>;
        timeout?: true;
      } = yield race({
        nextAction: take(loadUser),
        timeout: delay(50),
      });

      if (nextAction) {
        const newItems: Param[] = yield* filterNotProcessingAndMark(nextAction.payload);
        params.push(...newItems);
      } else {
        break;
      }
    }

    if (params.length === 0) {
      continue;
    }

    yield fork(loadBatchWorker, params);
  }
}
