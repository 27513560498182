import { type FC, type ReactNode, memo } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Input, ModalClose, Textarea, Typography } from '@mui/joy';

import type { SpaceType } from 'app/entities';

export interface DataLayoutProps {
  header?: ReactNode;
  data?: SpaceType | null;
  processing: boolean;
  submitLabel?: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const DataLayout: FC<DataLayoutProps> = (props) => {
  const { header, data, processing, submitLabel = 'Done', onChange, onSubmit, onCancel } = props;

  return (
    <>
      <DialogTitle>
        <Box display="flex" flexDirection="column" mt={-1} flex={1}>
          {header}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" mx={0.125} gap={3} flex={1}>
          <FormControl required>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, mb: 0 }}>Name</FormLabel>
            <Input
              variant="outlined"
              value={data?.title || ''}
              name="title"
              placeholder="Enter Space name"
              onChange={onChange}
              fullWidth
              autoFocus
              sx={{ backgroundColor: 'transparent', borderRadius: 'var(--joy-radius-xl)', boxShadow: 'none', mt: 0.25 }}
            />
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>Description</FormLabel>
            <Textarea
              variant="outlined"
              value={data?.description || ''}
              name="description"
              onChange={onChange}
              placeholder="A brief overview of the Space's purpose and audience"
              sx={{
                backgroundColor: 'transparent',
                borderRadius: 'var(--joy-radius-xl)',
                boxShadow: 'none',
                mt: 0.25,
                height: 'calc(100% - 1.5rem)',
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 0, gap: 1 }}>
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          onClick={onSubmit}
          disabled={!data?.title}
          loading={processing}
          sx={{ fontWeight: 400, fontSize: 13 }}
        >
          {submitLabel}
        </Button>
        <Button onClick={onCancel} variant="outlined" color="neutral" size="sm" disabled={processing} sx={{ fontWeight: 400, fontSize: 13 }}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default memo(DataLayout);
