import { type SetStateAction, memo, useCallback, useMemo, useState } from 'react';
import { Button, Box, FormControl, Typography, FormHelperText, FormLabel, Input, useColorScheme } from '@mui/joy';
import { StyleSheet } from 'react-native';

import { useNavigate } from 'navigation/hooks';

import { dispatcher, useDispatcher } from 'store/utils/redux/hooks';

import { unit } from 'utils';

import Picture from 'components/Picture';
import Divider from 'components/Divider';
import DualPanels from 'widgets/DualPanels';

import Icon from 'ui/Icon';

import { useOnInputEnter } from 'hooks';

import useSocialAuth from 'hooks/useSocialAuth';
import videoBackground from './assets/videoBackground.mp4';

type FormValues = {
  email: string | null;
};

const validate = (values: FormValues): { isValid: boolean; errors: Partial<FormValues> } => {
  let isValid = true;
  const errors: Partial<FormValues> = {};
  if (!values?.email || !/.+@.+\..+/.test(values?.email)) {
    isValid = false;
    if (values?.email !== null) {
      errors.email = 'not valid';
    }
  }
  return {
    isValid,
    errors,
  };
};

const AuthStart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatcher();
  const { mode } = useColorScheme();
  const [email, setEmail] = useState<string | null>(null);

  const validation = useMemo(() => validate({ email }), [email]);

  const { linkedIn, google, inProcess } = useSocialAuth();

  const handleChangeEmail = useCallback((event: { target: { value: SetStateAction<string | null> } }) => {
    setEmail(event.target.value);
  }, []);

  const handleAuthEmail = useCallback(() => {
    if (!email || !validation.isValid) {
      return;
    }
    dispatch.auth.setSignInEmail(email);
    navigate('Auth/SignIn');
  }, [email, validation.isValid]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleAuthEmail();
    }, [handleAuthEmail]),
  );

  const handleVideoOpen = useCallback(() => {
    dispatcher.modal.open('ProductVideo');
  }, []);

  return (
    <DualPanels
      leftPanel={
        <Box display="flex" flexDirection="column" minWidth={320}>
          <Box sx={{ alignSelf: 'center' }}>
            {mode === 'dark' && <Picture source={require('assets/images/new-logo-circle-white.png')} style={styles.logo} />}
            {mode === 'light' && <Picture source={require('assets/images/new-logo-circle-black.png')} style={styles.logo} />}
          </Box>
          <Box alignSelf="center" mt={4}>
            <Typography fontSize={52} fontWeight={500}>
              Sign up IKI.AI
            </Typography>
            <Typography sx={{ color: 'var(--joy-palette-text-tertiary)', fontSize: 16 }} textAlign="center" mt={-0.5}>
              your intelligent knowledge interface
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} mb={2} mt={4}>
            <Box>
              <Button
                size="lg"
                onClick={google}
                fullWidth
                variant="soft"
                loading={inProcess === 'google'}
                disabled={inProcess !== 'google' && !!inProcess}
              >
                <Icon name="google" fw family="brands" size="lg" color="var(--joy-palette-background-body)" marginRight={1} />
                <Typography
                  sx={{
                    color: 'var(--joy-palette-background-body)',
                  }}
                  fontSize={16}
                  fontWeight={400}
                >
                  Continue with Google
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                size="lg"
                onClick={linkedIn}
                fullWidth
                variant="soft"
                loading={inProcess === 'linkedin'}
                disabled={inProcess !== 'linkedin' && !!inProcess}
              >
                <Icon name={'Linkedin'.toLowerCase()} fw family="brands" size="lg" color="var(--joy-palette-background-body)" marginRight={1} />
                <Typography
                  sx={{
                    color: 'var(--joy-palette-background-body)',
                  }}
                  fontSize={16}
                  fontWeight={400}
                >
                  Continue with Linkedin
                </Typography>
              </Button>
            </Box>
          </Box>
          <Divider orientation="horizontal">
            <Typography fontSize={16} px={1} py={2} bgcolor="var(--joy-palette-background-body)" alignSelf="center">
              OR
            </Typography>
          </Divider>
          <Box display="flex" flexDirection="column">
            <FormControl size="md" inputMode="email">
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <FormLabel sx={{ color: 'var(--joy-palette-neutral-plainColor)' }}>Email</FormLabel>
                {!validation.isValid && <FormHelperText sx={{ color: '#DB3327' }}>{validation.errors.email}</FormHelperText>}
              </Box>
              <Input
                placeholder="Enter your email"
                variant="soft"
                value={email || ''}
                sx={{
                  '--_Input-focusedHighlight': 'var(--joy-palette-neutral-softColor)',
                }}
                name="email"
                disabled={!!inProcess}
                onChange={handleChangeEmail}
                onKeyDown={onKeyDown}
                size="lg"
              />
            </FormControl>
            <Button
              fullWidth
              disabled={!validation.isValid || !!inProcess}
              onClick={handleAuthEmail}
              size="lg"
              variant="soft"
              color="neutral"
              sx={{
                mt: 4,
              }}
              endDecorator={<Icon name="arrow-right" color="inherit" />}
            >
              Continue
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" gap={1} mt={4}>
            <Box display="flex" flexDirection="row" gap={1}>
              <Typography color="neutral" fontSize={13} component="a" href="https://www.iubenda.com/terms-and-conditions/39334813" target="_blank">
                Terms of use
              </Typography>
              <Typography color="neutral" fontSize={12}>
                |
              </Typography>
              <Typography color="neutral" fontSize={13} component="a" href="https://www.iubenda.com/privacy-policy/39334813" target="_blank">
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      rightPanel={
        <Box display="flex" flex={1} alignItems="center" justifyContent="center">
          <Box display="flex" flexDirection="column" alignItems="center" mx={4}>
            <Typography
              textAlign="center"
              position="relative"
              lineHeight={1.1}
              fontSize={50}
              fontWeight={500}
              pt={7}
              maxWidth={650}
              sx={{ textWrap: 'balance' }}
            >
              Add web pages, PDFs, and YouTube videos to your smart library in one click.
            </Typography>
            <Typography
              textAlign="center"
              position="relative"
              lineHeight="normal"
              fontSize={34}
              fontWeight={400}
              pt={2}
              maxWidth={650}
              sx={{ textWrap: 'balance' }}
            >
              Use advanced AI, powered by the latest ChatGPT models, to analyze your content.
            </Typography>
            <Button
              sx={{
                mt: 4,
                py: 2,
                color: 'var(--joy-palette-text-primary)',
                '--variant-outlinedBorder': 'var(--joy-palette-text-primary)',
                '--variant-outlinedHoverBg': 'var(--joy-palette-neutral-100)',
                fontWeight: 500,
                borderRadius: 30,
              }}
              endDecorator={<Icon name="play" weight="solid" color="inherit" fontSize="1.75em" />}
              variant="outlined"
              size="lg"
              onClick={handleVideoOpen}
            >
              Play product video
            </Button>
          </Box>
        </Box>
      }
      rightPanelBackGround={{
        videoBackground,
      }}
    />
  );
};

const styles = StyleSheet.create({
  logo: {
    width: unit(32),
    height: unit(32),
    backgroundColor: 'transparent',
  },
});

export default memo(AuthStart);
