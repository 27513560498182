import { type PropsWithChildren, memo, useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import { useColorScheme, useTheme } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';

import { useSelector, useDispatcher } from 'store/utils/redux/hooks';
import * as selectors from '../store/selectors';

export interface ModalLayoutMethods {
  open: () => void;
  close: () => void;
}

const ModalLayout = forwardRef<ModalLayoutMethods, PropsWithChildren>((props, forwardedRef) => {
  const { children } = props;
  const dispatcher = useDispatcher();
  const theme = useTheme();
  const { colorScheme } = useColorScheme();

  const isPricingShow = useSelector(selectors.isPricingShow);

  const handleOpen = useCallback(() => {
    dispatcher.subscription.showPricing();
  }, []);

  const handleClose = useCallback(() => {
    dispatcher.subscription.hidePricing();
  }, []);

  useImperativeHandle(
    forwardedRef,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    [],
  );

  return (
    <Modal
      open={isPricingShow}
      onClose={handleClose}
      sx={{
        '--ModalOverflow-paddingY': 0,
        '& > .MuiModalOverflow-root': {
          '--ModalOverflow-paddingY': 0,
        },
        '& .MuiModalOverflow-root': {
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '0.5rem',
          },
          '&::-webkit-scrollbar-track': {
            background: () => (colorScheme === 'dark' ? '#00000030' : 'transparent'),
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
            borderRadius: '0.5rem',
            backgroundClip: 'content-box',
          },
          '&:hover::-webkit-scrollbar-thumb:hover': {
            backgroundColor: colorScheme === 'dark' ? theme.palette.neutral['400'] : theme.palette.neutral['600'],
          },
          '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: colorScheme === 'dark' ? theme.palette.neutral['600'] : theme.palette.neutral['400'],
          },
        },
        '& .MuiModal-backdrop': {
          backdropFilter: 'blur(16px)',
        },
      }}
    >
      <ModalOverflow>
        <ModalDialog
          aria-labelledby="modal-dialog-overflow"
          layout="fullscreen"
          sx={{
            '.data-variant &': {},
            backgroundColor: () => (colorScheme === 'dark' ? '#00000040' : 'transparent'),
          }}
        >
          <ModalClose
            sx={{
              position: 'fixed',
              top: '8px',
              right: '16px',
              borderRadius: theme.radius.xl,
            }}
          />
          {children}
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
});

export default memo(ModalLayout);
