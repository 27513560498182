import { type DefaultRootState } from 'react-redux';

const userPermissionsById = (spaceId?: number) => (state: DefaultRootState) => {
  if (!spaceId) {
    return null;
  }

  const data = state.spacePermission.userPermissions?.[spaceId];
  if (!data) {
    return null;
  }

  return data;
};

export default userPermissionsById;
