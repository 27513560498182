import { type FC, type MouseEvent, memo, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import type { BoxProps } from '@mui/joy/Box';

import ChipArray from 'ui/ChipList';

import { useRoute } from 'navigation/hooks';
import { useResourceActions } from 'hooks';

import { type CollectionType, type MaterialType } from 'app/entities';

export interface TagsProps {
  sx?: BoxProps['sx'];
  data?: MaterialType | CollectionType | { tags: string[] };
  tagsLimit?: number;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  inactive?: boolean;
}

const Tags: FC<TagsProps> = (props) => {
  const { data, tagsLimit, sx, size = 'sm', disabled, inactive } = props;

  const route = useRoute();
  const resourceActions = useResourceActions(data);

  const handleTagPress = useCallback(
    (event: MouseEvent<HTMLElement>, tag: number | string) => {
      event.stopPropagation();
      event.preventDefault();
      if (disabled || inactive) {
        return;
      }
      resourceActions.filterLibraryByTag(tag.toString());
    },
    [resourceActions.searchByTag, resourceActions.filterLibraryByTag, disabled, inactive],
  );

  const tagsArray = useMemo(() => {
    if (!data?.tags || !Array.isArray(data?.tags) || data?.tags.length === 0) {
      return [];
    }
    let tags = [...new Set(data?.tags)];
    if (tagsLimit && tagsLimit > 0) {
      tags = tags.slice(0, tagsLimit);
    }
    return tags.map((tag) => ({
      id: tag,
      label: tag,
      deletable: false,
    }));
  }, [data, tagsLimit]);

  return (
    <ChipArray
      variant="outlined"
      size={size}
      sx={sx}
      disabled={disabled}
      inactive={inactive || !route.isScreen('Library')}
      options={tagsArray}
      onClick={handleTagPress}
    />
  );
};

export default memo(Tags, isEqual);
