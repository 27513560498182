import type { ContentErrorType, EntityType, EntityDataType, EntityErrorType } from 'app/entities';
import { capitalize, guard, prepare } from 'utils';

function prepareData(entity: EntityType) {
  if (guard.isEntityError(entity)) {
    return {
      id: entity.id,
      __typename: entity.type,
    } as ContentErrorType;
  }
  if (!guard.isEntityMaterial(entity)) {
    return {
      id: (entity as any).id || 0,
      __typename: 'UnknownError',
    } as ContentErrorType;
  }
  const { data } = entity;
  const { type } = entity;
  return {
    ...data,
    internalUrl: `/content/${(type || 'article').toLowerCase()}/${data.id}`,
    type: capitalize(data.type || 'article'),
    source: prepare.materialSource(data),
  };
}

export default prepareData;
