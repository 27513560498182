import { useSelector } from 'react-redux';

import type { SpaceEntityType } from 'app/entities';

import entityById from '../store/selectors/entityById';

const useEntityById = <T = SpaceEntityType | null>(id: string) => {
  return useSelector(entityById(id)) as T;
};

export default useEntityById;
