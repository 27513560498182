import { type FC, memo, useMemo } from 'react';
import { isEqual } from 'lodash';

import type { ImageType } from 'app/entities';

import Picture from 'components/Picture';
import Icon from 'ui/Icon';

export interface CollectionSingleCoverProps {
  data:
    | {
        cover?: ImageType | null;
        contentImages?: ImageType[];
      }
    | null
    | undefined;
  heightPicture?: number;
  widthPicture?: number;
  radiusPicture?: number;
  iconSize?: number;
  defaultIcon?: 'rectangle-history' | 'inbox';
}

const CollectionSingleCover: FC<CollectionSingleCoverProps> = (props) => {
  const { data, heightPicture = 24, widthPicture = 24, radiusPicture = 5, iconSize = 24, defaultIcon = 'rectangle-history' } = props;

  if (!data) {
    return null;
  }

  const posterSource = useMemo<ImageType | null>(() => {
    if (data?.cover) {
      return data?.cover;
    }
    const [materialImage] = data?.contentImages || [];
    if (materialImage) {
      return materialImage;
    }
    return null;
  }, [data]);

  if (!posterSource) {
    return <Icon name={defaultIcon} weight="light" size="1x" fw style={{ fontSize: Math.round(iconSize * 0.85), opacity: 0.6 }} />;
  }

  return <Picture source={posterSource} width={widthPicture} height={heightPicture} radius={radiusPicture} />;
};

export default memo(CollectionSingleCover, isEqual);
