import * as auth from './auth';
import * as oauth from './oauth';
import profile from './profile';
import content from './content/route';
import myContent from './myContent/route';
import * as skills from './skills';
import user from './user/route';
import playlist from './playlist';
import credentials from './credentials';
import interaction from './interaction/route';
import reviewSummary from './review/summary';

export type { ResultLegacy, MapResult } from 'app/entities';

export { default as request } from './request';

const resource = {
  auth,
  oauth,
  profile,
  content,
  myContent,
  skills,
  user,
  playlist,
  interaction,
  review: {
    summary: reviewSummary,
  },
};

export { resource, credentials };
