import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as spaceStore from 'screens/Space/store';

import updateImageSize from 'utils/updateImageSize';

import { parseItemId } from '../model/itemIdParser';

const useFolderMeta = (itemId: string) => {
  const { spaceId, entityId } = parseItemId(itemId);

  const spaceData = useSelector(spaceStore.selectors.spaceById(spaceId));
  const folderData = useSelector(spaceStore.selectors.folderById(entityId));

  const type = useMemo((): 'space' | 'root' | 'node' => {
    if (entityId === 'space') {
      return 'space';
    }
    if (folderData?.title === 'LIBRARY_ROOT') {
      return 'root';
    }
    return 'node';
  }, [entityId, folderData?.title]);

  const isPrivate = useMemo(() => {
    if (type === 'space') {
      return spaceData?.isPrivate;
    }
    if (type === 'root') {
      return spaceData?.isPrivate;
    }
    return folderData?.isPrivate;
  }, [type, spaceData?.isPrivate, folderData?.isPrivate]);

  const childrenCount = useMemo(() => {
    if (type === 'space') {
      return null;
    }
    return folderData?.childrenCount || null;
  }, [type, folderData?.childrenCount]);

  const icon = useMemo(() => {
    if (type === 'space') {
      return null;
    }
    if (type === 'root') {
      return 'svg://inbox';
    }
    let sourceUrl = '';
    if (Array.isArray(folderData?.contentImages) && folderData?.contentImages.length > 0) {
      sourceUrl = folderData?.contentImages[0].url;
    }
    if (folderData?.cover?.url) {
      sourceUrl = folderData?.cover?.url;
    }
    return sourceUrl || 'svg://folder';
    // return updateImageSize(sourceUrl, 'small')?.url || 'svg://folder';
  }, [type, folderData?.contentImages, folderData?.cover]);

  return useMemo(
    () => ({
      icon,
      isPrivate,
      childrenCount,
      type,
    }),
    [icon, isPrivate, childrenCount, type],
  );
};

export default useFolderMeta;
