import { take } from 'redux-saga/effects';
import { store } from 'store';

import { type ModalType } from './types';
import * as actions from './actions';

export function* waitResultGenerator<R = any>(name?: ModalType) {
  let result: { payload: { name: string; result: any } };
  while (true) {
    result = yield take(actions.close.type);
    if (result.payload.name === name) {
      break;
    }
  }
  return result.payload.result as R;
}

export function waitResult<R = any>(name: ModalType): Promise<R> {
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (name in state.modal.results) {
        const result = state.modal.results[name] as R;
        unsubscribe();
        resolve(result);
      }
    });
  });
}
