import { call, type SagaReturnType } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as spaceStore from 'screens/Space/store';
import { getRoute } from 'navigation/methods';

function getCurrentRouteParams() {
  const route = getRoute<'Space'>();
  if (!route.isScreen('Space')) {
    return null;
  }
  return (route.params || null) as { spaceId: number; folderId: string } | null;
}

function* getSpaceParams(
  forcedSpaceId?: number,
  forcedFolderId?: string | 'space' | 'root' | null,
): Generator<any, { teamSpaceId: number; teamSpaceFolderId: number } | null, any> {
  if (forcedSpaceId) {
    const rootLibraryId = yield* select(spaceStore.selectors.libraryIdBySpaceId(forcedSpaceId));
    return {
      teamSpaceId: Number(forcedSpaceId),
      teamSpaceFolderId: !forcedFolderId || forcedFolderId === 'space' || forcedFolderId === 'root' ? Number(rootLibraryId) : Number(forcedFolderId),
    };
  }

  const currentRouteParams: SagaReturnType<typeof getCurrentRouteParams> = yield call(getCurrentRouteParams);
  if (currentRouteParams) {
    const { spaceId, folderId } = currentRouteParams;
    const rootLibraryId = yield* select(spaceStore.selectors.libraryIdBySpaceId(spaceId));
    return {
      teamSpaceId: Number(spaceId),
      teamSpaceFolderId: !folderId || folderId === 'space' || folderId === 'root' ? Number(rootLibraryId) : Number(folderId),
    };
  }

  const spaceId = yield* select(spaceStore.selectors.defaultSpaceId);
  if (spaceId) {
    const rootLibraryId = yield* select(spaceStore.selectors.libraryIdBySpaceId(spaceId));
    return {
      teamSpaceId: Number(spaceId),
      teamSpaceFolderId: Number(rootLibraryId),
    };
  }

  return null;
}

export default getSpaceParams;
