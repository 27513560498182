import qs from 'query-string';

import type { PaginationResult, PaginationInput, MaterialType, CollectionType, SearchItemType } from 'app/entities';

import { requestCursorModifier, guard, highlightText } from 'utils';
import network from 'lib/network';

type Filters = Record<string, any>;

type MetadataType = {
  isMaterialsExists?: boolean;
};

const loadItems = async (
  spaceId: number,
  pagination: PaginationInput,
  filters?: Filters,
  extraParams?: Record<string, string | number>,
): Promise<PaginationResult<SearchItemType, { metadata: MetadataType }>> => {
  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
    ...extraParams,
  };
  Object.entries(filters || {}).forEach(([key, value]) => {
    if (typeof value === 'string') {
      query[key] = value;
      return;
    }
    if (Array.isArray(value) && value.length > 0) {
      query[key] = value.join(',').trim();
      return;
    }
    if (typeof value === 'number') {
      query[key] = value.toString();
    }
  });

  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  if (!query.q && !query.tags) {
    return {
      data: {
        items: [],
        paginationInfo: {
          hasNext: false,
          nextCursor: null,
        },
        metadata: {},
      },
      error: null,
    };
  }

  let metadata: MetadataType = {};
  const { data, errors } = await network
    .request<SearchItemType[]>(`/stack-2/user/team-space/${spaceId}/search`)
    .query(query)
    .get(
      requestCursorModifier((response) => {
        const item: SearchItemType[] = response.data.map((element: MaterialType | CollectionType, index) => {
          const highlights = (response.metadata?.[index]?.highlights || response.metadata?.metadataHighlights?.[index].highlights || []) as string[];
          return {
            element,
            searchMeta: {
              highlights: highlights.map((text) => (!query.q ? text : highlightText(text, query.q))),
            },
          };
        });
        metadata = response?.metadata;
        return {
          data: item,
          nextUrl: response?.nextUrl,
        };
      }),
    );

  if ((Array.isArray(errors) && errors.length > 0) || !data) {
    return {
      data: null,
      error: errors?.[0] || null,
    };
  }

  const next = qs.parseUrl(data.nextUrl || '');

  return {
    data: {
      items: (data?.items || []).map((item) => {
        return {
          element: item.element,
          searchMeta: item.searchMeta,
        };
      }),
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: (next?.query?.cursor as string) || null,
      },
      metadata,
    },
    error: null,
  };
};

export default loadItems;
