import { type DefaultRootState } from 'react-redux';

const spaceById = (id?: number) => (state: DefaultRootState) => {
  if (!id) {
    return null;
  }

  const data = state.spaceList.data?.[id];
  if (!data) {
    return null;
  }

  return data;
};

export default spaceById;
