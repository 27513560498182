import { type PropsWithChildren, memo } from 'react';
import { type TextProps as OriginTextProps, Text as DefaultText } from 'react-native';
import { isEqual } from 'lodash';

import { type FontProps } from 'font';

import { useThemeColor, useFontProps } from 'hooks';

export interface TextProps extends OriginTextProps, FontProps {
  lightColor?: string;
  darkColor?: string;
}

/**
 * @deprecated
 */
const Text = (props: PropsWithChildren<TextProps>) => {
  const { style, lightColor, darkColor, font, size, weight, align, opacity, ...otherProps } = props;

  const color = useThemeColor({
    light: lightColor || '#000000',
    dark: darkColor || '#ffffff',
  });
  const config = useFontProps({
    size,
    weight,
    align,
    font,
    opacity,
  });

  return <DefaultText style={[{ color }, config, style]} {...otherProps} />;
};

export default memo(Text, isEqual);
