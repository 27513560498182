import { type FC, memo } from 'react';
import { Box, type BoxProps } from '@mui/joy';

import Icon from 'ui/Icon';

interface PreviewProps extends BoxProps {
  uri?: string | null;
}

const Preview: FC<PreviewProps> = (props) => {
  const { uri, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: 200,
        height: 200,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'var(--joy-radius-xl)',
        overflow: 'hidden',
        backgroundColor: `color-mix(in srgb, var(--joy-palette-background-level1) 50%, var(--joy-palette-background-level2) 50%)`,
        ...rest.sx,
      }}
    >
      <Icon name="rectangle-history" weight="thin" size="3x" sx={{ opacity: 0.5 }} />
      {uri && (
        <Box
          component="img"
          src={uri}
          loading="lazy"
          sx={{
            position: 'absolute',
            objectFit: 'cover',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            aspectRatio: 1,
            borderRadius: 'var(--joy-radius-xl)',
          }}
        />
      )}
    </Box>
  );
};

export default memo(Preview);
