import { type DefaultRootState, useSelector } from 'react-redux';

import * as contentStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as noteStore from 'store/nodes/note';
import * as searchStore from 'store/nodes/search';
import * as spaceStore from 'screens/Space/store';

import { guard } from 'utils';

import type { CardType } from 'widgets/Card/types';

const useData = (resourceType: CardType, resourceId: number) => {
  return useSelector((state: DefaultRootState) => {
    if (guard.resourceKeyIsMaterial(resourceType)) {
      return contentStore.selectors.dataById(resourceId)(state);
    }
    if (guard.resourceKeyIsCollection(resourceType)) {
      return collectionStore.selectors.dataById(resourceId)(state);
    }
    if (guard.resourceKeyIsNote(resourceType)) {
      return noteStore.selectors.dataById(resourceId)(state);
    }
    if (guard.resourceKeyIsDocument(resourceType)) {
      return noteStore.selectors.dataById(resourceId)(state);
    }
    if (guard.resourceKeyIsRagMaterial(resourceType)) {
      return searchStore.selectors.dataById(resourceId)(state);
    }
    if (guard.resourceKeyIsSpaceFolder(resourceType)) {
      return spaceStore.selectors.folderById(resourceId)(state);
    }
    return null;
  });
};

export default useData;
