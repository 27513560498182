import { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Snackbar, Box, IconButton, Typography } from '@mui/joy';
import { useCopyToClipboard } from 'usehooks-ts';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as modalStore from 'widgets/modals/store';
import * as menuStore from 'store/nodes/menu';
import * as navigationStore from 'store/nodes/navigation';
import * as subscriptionStore from 'widgets/Subscription/store';

import Alert from 'components/Alert';
import Icon from 'ui/Icon';
import { delay } from 'utils';
import { useCurrentPromo } from 'hooks';

export interface BFBannerParams {}

const BFBanner = () => {
  const { isOpen: isOpenMarker } = useSelector(modalStore.selectors.state('BFBanner'));

  const dispatcher = useDispatcher();
  const leftBarWidth = useSelector(menuStore.selectors.leftBarWidth);
  const currentPlan = useSelector(subscriptionStore.selectors.currentPlan);
  const [, currentPath] = useSelector(navigationStore.selectors.pathname);
  const currentPromo = useCurrentPromo();

  const isOpen = useMemo(() => {
    if (/^\/(auth|index|welcome)/i.test(currentPath)) {
      return false;
    }
    return isOpenMarker;
  }, [isOpenMarker, currentPath]);

  useEffect(() => {
    if (!currentPromo.hasPromo) {
      return;
    }
    if (sessionStorage.getItem('promoBannerWasClosed') === 'true') {
      return;
    }
    if (currentPlan?.id === '0') {
      delay(1000).then(() => {
        dispatcher.modal.open('BFBanner');
      });
    }
  }, [currentPlan.id]);

  const [, copy] = useCopyToClipboard();

  const handleModalClose = useCallback(() => {
    dispatcher.modal.close('BFBanner');
    sessionStorage.setItem('promoBannerWasClosed', 'true');
  }, []);

  const handlePromoClick = useCallback(() => {
    copy('PH25').then(() => {
      Alert.success('Copied to clipboard');
    });
    dispatcher.subscription.showPricing();
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isOpen}
      sx={{
        borderRadius: '2rem',
        width: '60%',
        maxWidth: 600,
        marginLeft: leftBarWidth > 64 ? `${leftBarWidth / 2}px` : 0,
        borderWidth: 2,
        borderColor: 'var(--joy-palette-background-popup)',
        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'var(--joy-palette-text-primary)',
        color: 'var(--joy-palette-background-popup)',
        userSelect: 'none',
        '@media (max-width: 768px)': {
          width: 400,
          maxWidth: 'unset',
        },
        '@media (max-width: 767px)': {
          display: 'none',
        },
      }}
      startDecorator={<Icon name="gift" weight="solid" color="var(--joy-palette-focusVisible)" size="xl" />}
      endDecorator={
        <Box onClick={handleModalClose}>
          <IconButton size="sm" variant="soft" slotProps={{ root: { sx: { '--IconButton-size': '1.75rem' } } }}>
            <Icon name="close" />
          </IconButton>
        </Box>
      }
    >
      <Typography level="body-lg" textColor="inherit">
        <Box component="span">
          {currentPromo?.description}
          {`: `}
        </Box>
        <Typography fontWeight={500} onClick={handlePromoClick} sx={{ cursor: 'pointer' }} textColor="inherit">
          {currentPromo?.code}
        </Typography>
      </Typography>
    </Snackbar>
  );
};

export default memo(BFBanner);
