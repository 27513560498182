import { type CSSProperties, memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Box, useColorScheme } from '@mui/joy';

import { useNavigate, useRoute } from 'navigation/hooks';

import { saveLastRoute } from 'utils';
import Storage from 'lib/Storage';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as userProfile from 'store/nodes/user';

import ButtonLegacy from 'components/Button';
import Settings from 'components/UIBar/bars/LeftBar/elements/Settings';
import MyProfile from 'components/UIBar/bars/LeftBar/elements/MyProfile';
import BottomPanel from 'components/UIBar/bars/panels/BottomPanel';
import TutorialStep from 'components/TutorialStep';
import ProductHuntBanner from 'components/ProductHuntBanner';

import Icon from 'ui/Icon';
import ScrollBox from 'ui/ScrollBox';

import SubscriptionBanner from 'widgets/Subscription/Banner';
import SpaceMenuTree from 'screens/Space/bars/MenuTree';

import Extension from './elements/Extension';
import ThemeSwitcher from './elements/ThemeSwitcher';
import MenuButton from '../../elements/MenuButton';

import useDividerVisibility from './model/useDividerVisibility';

// import FollowPanel from '../panels/FollowPanel';
import UserPanel from '../panels/UserPanel';

const LeftBar = () => {
  const navigate = useNavigate();
  const dispatcher = useDispatcher();
  const route = useRoute();
  const { boxRef, spaceMenuTreeRef, shouldShowDivider } = useDividerVisibility();

  const user = useSelector(userProfile.selectors.getMy);
  const userId = useSelector(userProfile.selectors.getIdByLogin('my'));
  const { mode } = useColorScheme();

  const [isSlim, setSlim] = useState(Storage.get('menu.leftBar.width') === '64');

  useEffect(() => {
    const newValue = isSlim ? 64 : 240;
    dispatcher.menu.setLeftBarWidth(newValue);
    Storage.set('menu.leftBar.width', String(newValue));
    document.documentElement.style.setProperty('--menu-bar-width', `${newValue}px`);
    if (isSlim) {
      document.body.classList.add('menu-collapsed');
    } else {
      document.body.classList.remove('menu-collapsed');
    }
  }, [isSlim]);

  const handleToggleSize = useCallback(() => {
    setSlim(!isSlim);
  }, [isSlim]);

  const handleHome = useCallback(() => {
    if (!userId) {
      saveLastRoute();
    }
    dispatcher.search.clear();
    navigate('Ask');
  }, [userId]);

  const handleAddPeoplePress = useCallback(() => {
    dispatcher.modal.open('SearchUser');
  }, []);

  const containerStyle = useMemo(() => {
    const result: CSSProperties = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      backgroundColor: isSlim ? 'transparent' : 'var(--joy-palette-background-body)',
      width: isSlim ? 64 : 240,
      transform: 'translateZ(0)',
      pointerEvents: isSlim ? 'none' : 'auto',
      boxShadow: 'none',
    };
    if (mode === 'light' && !isSlim) {
      result.boxShadow = '0 0 0 1px rgba(0, 0, 0, 0.025)';
    }
    if (mode === 'dark' && !isSlim) {
      result.boxShadow = '0 0 0 1px rgba(0, 0, 0, 0.25)';
    }
    return result;
  }, [isSlim, mode]);

  const mainMenuRender = useMemo(
    () => (
      <TutorialStep
        placement="left"
        stepIndex={3}
        title="Ask IKI"
        description="to fetch information, perform analysis or just answer your question. Augment your results with web
          search or choose Deep Research mode to draft a comprehensive report"
      >
        <MenuButton
          style={{ marginVertical: '0.125rem' }}
          href="/ask"
          onPress={handleHome}
          iconLeft={
            <Icon
              name="magnifying-glass-plus"
              fw={24}
              size="lg"
              weight={route.name === 'Ask' ? 'regular' : 'light'}
              color={route.name === 'Ask' ? 'primary' : 'secondary'}
            />
          }
          text="Ask IKI"
          isSlim={isSlim}
          isSelected={route.name === 'Ask'}
        />
      </TutorialStep>
    ),
    [route.name, isSlim, handleHome],
  );

  return (
    <Box sx={containerStyle}>
      <Box display="flex" flexDirection="row" sx={{ pointerEvents: 'auto' }}>
        <ButtonLegacy
          type="button-icon"
          onPress={handleToggleSize}
          lightColor="transparent"
          darkColor="transparent"
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            marginLeft: '0.5rem',
            height: '4rem',
            width: '3rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon name="bars" fw size="lg" weight="light" color="primary" />
        </ButtonLegacy>
        {!isSlim && <UserPanel />}
      </Box>
      {!isSlim && (
        <ScrollBox display="flex" flexDirection="column" pb={2} sx={{ height: 'calc(100vh - 10px - (54px + 8px + 8px))' /* -72px for Banner */ }}>
          {mainMenuRender}
          {!!user && (
            <Divider sx={{ mt: 3, mb: 2.5, ml: 2.5, mr: 2, borderBottom: 'var(--Divider-thickness) solid var(--Divider-lineColor)', height: 0 }} />
          )}
          <Box ref={boxRef} sx={{ flex: 1 }}>
            <SpaceMenuTree ref={spaceMenuTreeRef} sx={{ mx: 1 }} />
          </Box>
          <Divider
            sx={{
              my: 3,
              ml: 2.5,
              mr: 2,
              borderBottom: 'var(--Divider-thickness) solid var(--Divider-lineColor)',
              height: 0,
              opacity: shouldShowDivider ? 1 : 0,
            }}
          />
          <Box height={0} />
          {/* {!!userId && !isSlim && user?.type === 'default' && <FollowPanel onAddPress={handleAddPeoplePress} />} */}
          {!!userId && !isSlim && user?.type === 'default' && <SubscriptionBanner />}
          {!isSlim && !user && (
            <Divider sx={{ mt: 3, mb: 2.5, ml: 2.5, mr: 2, borderBottom: 'var(--Divider-thickness) solid var(--Divider-lineColor)', height: 0 }} />
          )}
          <MyProfile isSlim={isSlim} />
          {user?.type === 'default' && <Settings isSlim={isSlim} />}
          <ThemeSwitcher isSlim={isSlim} />
          <Extension isSlim={isSlim} />
          {!isSlim && (
            <Divider sx={{ mt: 3, mb: 2.5, ml: 2.5, mr: 2, borderBottom: 'var(--Divider-thickness) solid var(--Divider-lineColor)', height: 0 }} />
          )}
          <BottomPanel isSlim={isSlim} />
        </ScrollBox>
      )}
      {/* <ProductHuntBanner mt={1} mx={1.5} display={!isSlim ? 'block' : 'none'} /> */}
    </Box>
  );
};

export default memo(LeftBar);
