import { useEffect, useMemo } from 'react';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as relationStore from 'store/nodes/relation';

import { usePerson } from 'hooks';

import { useFolderRoute } from 'screens/Space/model/folderRoute';

const useUsers = () => {
  const dispatcher = useDispatcher();
  // const { spaceId, folderId } = useFolderRoute();
  // console.log(spaceId, folderId, 'route');

  const { person } = usePerson('User', 'my');

  const listSubscriptions = useSelector(relationStore.selectors.list('subscriptions'));
  const listSubscribers = useSelector(relationStore.selectors.list('subscribers'));

  const filteredUsers = useMemo(() => {
    return [...new Set([...listSubscriptions.sequence, ...listSubscribers.sequence].map((item) => item.id))];
  }, [listSubscriptions.sequence, listSubscribers.sequence]);

  const { person: users } = usePerson('User', filteredUsers);

  useEffect(() => {
    if (!person) {
      return;
    }
    dispatcher.relation.load(person.login);
  }, [person]);

  return users;
};

export default useUsers;
