import qs from 'query-string';

import { guard } from 'utils';

import type { MaterialType, CollectionType, PaginationResult, PaginationInput, CursorType } from 'app/entities';
import { request } from 'services/api';
import interaction from 'services/api/interaction/route';

export default async (topicName: string, pagination: PaginationInput): Promise<PaginationResult<MaterialType | CollectionType>> => {
  const query: Record<string, any> = {
    topicName,
    pageSize: pagination.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  const { data, error } = await request.get<CursorType<MaterialType | CollectionType>>('/stack-1/user/library/news', { query });
  if (error || !data) {
    return {
      data: null,
      error: (Array.isArray(error) ? error?.[0] : error) || null,
    };
  }

  const materialIds: number[] = [];
  const collectionIds: number[] = [];

  data?.items?.forEach((item) => {
    if (guard.isMaterial(item)) {
      materialIds.push(item.id);
    }
    if (guard.isCollection(item)) {
      collectionIds.push(item.id);
    }
  });

  const [materialsInteractionMap, collectionsInteractionMap] = await Promise.all([
    materialIds ? interaction.items('content', materialIds) : null,
    collectionIds ? interaction.items('playlist', collectionIds) : null,
  ]);

  const next = qs.parseUrl(data.nextUrl || '');

  return {
    data: {
      items: data?.items?.map((item) => {
        if (guard.isMaterial(item)) {
          return {
            ...item,
            ...(materialsInteractionMap?.data?.[item.id] || {}),
          };
        }
        if (guard.isCollection(item)) {
          return {
            ...item,
            ...(collectionsInteractionMap?.data?.[item.id] || {}),
          };
        }
        return item;
      }),
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: (next?.query?.cursor as string) || null,
      },
    },
    error: null,
  };
};
