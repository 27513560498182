import { type FC, memo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/joy';
import { isEqual } from 'lodash';

import { useDispatcher } from 'store/utils/redux/hooks';

import Avatar from 'components/Avatar';
import { useUser } from 'hooks';
import { guard } from 'utils';

import PermissionSelect from '../ui/PermissionSelect';

export interface UserItemProps {
  id: number;
  status: 'PENDING' | 'SUCCESS';
  user: {
    id?: number;
    email?: string;
  };
  permissionId: string;
  resourceId?: number;
}

const UserItem: FC<UserItemProps> = (props) => {
  const { id, status, user, permissionId, resourceId } = props;
  const [permission, setPermission] = useState(permissionId);
  const { data: teammate } = useUser({ id: user.id || 0 });

  const dispatcher = useDispatcher();

  const handleSelectChange = useCallback(
    (_: any, newValue: string) => {
      setPermission(newValue);
      if (!newValue || !resourceId) {
        return;
      }
      if (newValue === 'delete') {
        dispatcher.spacePermission.delete({
          id,
          spaceId: resourceId,
        });
        setPermission(permissionId);
        return;
      }
      if (!user.id) {
        return;
      }
      dispatcher.spacePermission.update({
        userId: user.id,
        permissionId: Number(newValue),
        spaceId: resourceId,
      });
    },
    [id, user, resourceId, permissionId],
  );

  if (!user.id || guard.isNoUserExists(teammate)) {
    return null;
  }

  // console.log('teammate', teammate);
  return (
    <Box display="flex" flexDirection="row" gap={1.5} alignItems="center" alignSelf="stretch" sx={{ '--Avatar-size': '48px', userSelect: 'none' }}>
      <Avatar {...(teammate ? { user: teammate } : { name: user?.email })} vars={{ Background: 2 }} />
      <Box display="flex" flexDirection="column">
        {status === 'SUCCESS' && (
          <Typography fontSize={16} fontWeight={400} lineHeight="var(--joy-lineHeight-xs)">
            {!teammate ? user?.email : `${teammate?.name || ''} ${teammate?.surname}`.trim()}
          </Typography>
        )}
        {status === 'PENDING' && (
          <Typography fontSize={16} fontWeight={400} lineHeight="var(--joy-lineHeight-xs)">
            {user?.email}
          </Typography>
        )}
        {teammate?.email && (
          <Typography fontSize={16} fontWeight={400} sx={{ opacity: 0.5 }} lineHeight="var(--joy-lineHeight-xs)">
            {teammate?.email}
          </Typography>
        )}
      </Box>
      <Box flex={1} />
      {status === 'SUCCESS' && <PermissionSelect disabled={teammate?.isMy} variant="success" value={permission} onChange={handleSelectChange} />}
      {status === 'PENDING' && <PermissionSelect variant="pending" value={permission} onChange={handleSelectChange} />}
    </Box>
  );
};

export default memo(UserItem, isEqual);
