import { useSelector } from 'react-redux';

import folderChildren from '../store/selectors/folderChildren';

/**
 * Хук для получения списка дочерних ресурсов.
 */
export default function useChildren(spaceId: number, folderId: string | number | 'root') {
  return useSelector(folderChildren(spaceId, folderId));
}
